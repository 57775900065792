import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import uiLogger from "src/Utils/UiLogger";
import { ActorTypes } from "../../../entity/models/FrontendActor";
import { LedgerConfiguration } from "../../../entity/recon-entity/ReconInterfaces";
import {
  BUSINESS_PARTNER_LEDGER,
  Customized,
  DOCUMENT_TYPE_NAMES,
  ledgerUploadName,
  OWN_LEDGER,
} from "../../../Utils/Recon/Recon360/Constants";
import { uiLoggerName, uiLoggerNamesRecon } from "../../../Utils/Recon/UiLogger/Constants";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import { Recon360Context } from "../Recon360";
import "../Recon360.scss";
import DeleteDocTypeSubGrp from "./DeleteDocTypeSubGrp";
import EditGroupName from "./EditGroupName";

export const DocumentTypeConfigContext = createContext<any>(null);
export interface Codes {
  code: string;
  sign: number;
  addParticulars: string[];
  excludeParticulars: string[];
  group: { groupId: string; groupName: string } | null | undefined | Record<string, unknown>;
  useReference: boolean;
}

export interface Element {
  name: typeof DOCUMENT_TYPE_NAMES[number];
  group?: { groupId: string; groupName: string };
  codes: Codes[];
}
interface Result {
  [key: string]: Codes[];
}
const DEFAULT_CODES = {
  code: "",
  sign: 0,
  addParticulars: [] as string[],
  excludeParticulars: [] as string[],
  group: { groupId: "All", groupName: "All" },
  useReference: false,
};

function getFlattenCode(code: Codes): string {
  const flatAdds = code.addParticulars.join("+");
  const flatExcs = code.excludeParticulars.join("-");
  let joinedString = "";

  if (flatAdds.length) joinedString = "+" + flatAdds;
  else if (flatExcs.length) joinedString = "-" + flatExcs;

  if (flatAdds.length && flatExcs.length) joinedString = "+" + flatAdds + "-" + flatExcs;

  return code.sign + code.code + joinedString;
}

function drag(ev: React.DragEvent<HTMLElement>, ele: Element, flattenCode: string) {
  ev.dataTransfer.dropEffect = "move";
  ev.dataTransfer.setData("identifier", `${ele.name}-${ele.group?.groupId}-${ele.group?.groupName}`);
  ev.dataTransfer.setData("code", flattenCode);
}

function allowDrop(ev: React.DragEvent<HTMLDivElement>) {
  ev.preventDefault();
}

type DelRow = (element: Element, index: number) => void;

const getConfigTagsPerSign = (element: Element, sign: 0 | 1 | 2, delRow: DelRow, configMapped) => {
  // Helper function to compare arrays
  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  const getTagBackgroundColor = (tag, ele) => {
    if (configMapped) {
      const config = configMapped;
      if (
        config &&
        ele.name in config &&
        config[element.name].some(
          (item) =>
            item.code === tag.code &&
            item.group.groupId === tag.group.groupId &&
            item.sign === tag.sign &&
            arraysAreEqual(item.addParticulars, tag.addParticulars) &&
            arraysAreEqual(item.excludeParticulars, tag.excludeParticulars)
        )
      ) {
        return "secondary"; // Secondary color for matching tags
      }
    }
    return "default"; // Default color for non-matching tags
  };
  return (
    <>
      {element.codes
        .filter((code) => code.sign === sign && code.code.trim() !== "")
        .map((code, index) => (
          <Chip
            key={index}
            label={
              <span>
                {code.code}
                <span style={{ color: "#00c1ff" }}>
                  {code.addParticulars?.length > 0 ? "+" + code.addParticulars.join("+") : ""}
                </span>
                <span style={{ color: "red" }}>
                  {code.excludeParticulars?.length > 0 ? "-" + code.excludeParticulars.join(" - ") : ""}
                </span>
              </span>
            }
            onDelete={() => {
              const codeIndex = element.codes.findIndex((_code) => getFlattenCode(_code) === getFlattenCode(code));
              delRow(element, codeIndex);
            }}
            style={{
              cursor: "move",
              userSelect: "text",
              marginLeft: "5px",
            }}
            size="medium"
            color={getTagBackgroundColor(code, element)}
            draggable={true}
            onDragStart={(e) => drag(e, element, getFlattenCode(code))}
          />
        ))}
    </>
  );
};

const getConfigTags = (element: Element, delRow: DelRow, configMapped) => {
  return (
    <>
      {element.codes.filter((c) => c.sign === 0).length > 0 && (
        <div className="all_row">
          <span className="transacation_type">All Transaction </span>
          {getConfigTagsPerSign(element, 0, delRow, configMapped)}
        </div>
      )}
      {element.codes.filter((c) => c.sign === 1).length > 0 && (
        <div className="debit_row">
          <span className="transacation_type">Debit Transaction </span>
          {getConfigTagsPerSign(element, 1, delRow, configMapped)}
        </div>
      )}
      {element.codes.filter((c) => c.sign === 2).length > 0 && (
        <div className="credit_row">
          <span className="transacation_type">Credit Transaction </span>
          {getConfigTagsPerSign(element, 2, delRow, configMapped)}
        </div>
      )}
      {element.codes.length === 0 && <div className="no-data">No Data to show</div>}
    </>
  );
};

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface DocTypeConfigProps {
  backTemplate: () => void;
  disableSpecificConfigCheckbox?: boolean;
  getConfig?: () => Promise<void>;
  headerName: Record<string, any[]> | LedgerConfiguration;
  heading: string;
  name: string;
  saveRulesApi: (header: LedgerConfiguration) => void;
  setColumnHeaderConfigDialog: StateDispatch<any>;
  setCreditAmountSignReverseFlag: StateDispatch<boolean>;
  setDebitAmountSignReverseFlag: StateDispatch<boolean>;
  setHeaderName: StateDispatch<LedgerConfiguration>;
  setNewMapping?: StateDispatch<boolean>;
  setOpenDialog: StateDispatch<boolean>;
  setShowCustomOption?: StateDispatch<boolean>;
  setSpecificConfig?: StateDispatch<boolean>;
  setTemplateName?: StateDispatch<string>;
  setChangedTemplateName?: StateDispatch<string>;
  specificConfig?: boolean;
  subTab?: "Payment Advice" | "Ledger" | string;
  uiLogger?: (functionName: string) => any;
  editingTemplate?: boolean;
}

const DocumentTypeConfig = (props: DocTypeConfigProps) => {
  const { actor } = useContext(userContext);
  const context = useContext(Recon360Context) || {
    configMappedOwn: null,
    configMappedBp: null,
    setDocumentTypesArr: null,
    finaldocumentTypesObj: null,
    documentTypeSuggestionMap: null,
    changeDocumentTypeConfig: null,
    setOpenDocumentTypeDialog: null,
    setFetchDataFromERP: null,
    setConfigUserType: null,
    setOpenUnassignedDoctypedDialogFromView: null,
    businessPartnerSelectedRef: null,
    companyId: null,
    branchCode: null,
  };
  const {
    configMappedOwn,
    configMappedBp,
    setDocumentTypesArr,
    finaldocumentTypesObj,
    documentTypeSuggestionMap,
    changeDocumentTypeConfig,
    setOpenDocumentTypeDialog,
    setFetchDataFromERP,
    setConfigUserType,
    setOpenUnassignedDoctypedDialogFromView,
    businessPartnerSelectedRef,
    companyId,
    branchCode,
  } = context;
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [addElementObj, setAddElementObj] = useState<any>(null);
  const [addElementIndex, setAddElementIndex] = useState<number>(null);
  // const labels: any =
  //   props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE ? documentTypeLabelForPaymentAdvice : documentTypeLabelForLedger;
  const [documentTypeRow, setDocumentTypeRow] = useState<Element[]>(
    DOCUMENT_TYPE_NAMES.map((documentTypeName) => ({
      name: documentTypeName,
      codes: [DEFAULT_CODES],
    }))
  );
  const [elementStates, setElementStates] = useState({});
  const [checkedAddParticular, setCheckedAddParticular] = useState<boolean>(false);
  const [addParticularText, setAddParticularText] = useState<string>("");
  const [addParticularTextArr, setAddParticularTextArr] = useState<string[]>([]);
  const [checkedExcludeParticular, setCheckedExcludeParticular] = useState<boolean>(false);
  const [excludeParticularText, setExcludeParticularText] = useState<string>("");
  const [excludeParticularTextArr, setExcludeParticularTextArr] = useState<string[]>([]);
  const [duplicateTagName, setDuplicateTagName] = useState(null);
  const [openEditGroupName, setOpenEditGroupName] = useState(false);
  const [editGroupEle, setEditGroupEle] = useState(undefined);
  const [isOpenDeleteDocTypeSubGrp, setIsOpenDeleteDocTypeSubGrp] = useState(false);
  const [openConfigSaveConfirmModal, setOpenConfigSaveConfirmModal] = useState<boolean>(false);
  const [checkedReference, setCheckedReference] = React.useState(false);

  const handleChangeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedReference(event.target.checked);
  };

  const handleChangeSaveValueDocumentType = (e, elementName, groupId) => {
    const { value } = e.target;
    const key = `${elementName}-${groupId}`;
    setElementStates((prevStates) => ({
      // ...prevStates,
      [key]: {
        ...prevStates[key],
        saveValueDocumentType: value,
      },
    }));
  };

  const handleTransactionChange = (e, elementName, groupId) => {
    const { value } = e.target;
    const key = `${elementName}-${groupId}`;
    setElementStates((prevStates) => ({
      // ...prevStates,
      [key]: {
        ...prevStates[key],
        transactionValue: value,
      },
    }));
  };

  const docTypeLabels = (word: string) => {
    const replacements = {
      invoice: "Invoice",
      payment: props.subTab === "Payment Advice" ? "Discount/Rebate" : "Payment",
      debitAndCreditNotes: "DN/CN",
      reversal: "Reversal",
      interDocument: "Internal Document",
      tds: "TDS",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };

  function convertToDoctypeWithGrp(doctypeWithoutGrp: Element[]): Element[] {
    const groupedByNameAndGroup: Element = {} as Element;

    doctypeWithoutGrp?.forEach((item) => {
      const { name, codes } = item;
      if (codes?.length > 0) {
        codes.forEach((codeItem) => {
          const { code, sign, addParticulars, excludeParticulars, group, useReference } = codeItem;

          const key = `${name}-${group?.groupId}`;

          if (!groupedByNameAndGroup[key]) {
            groupedByNameAndGroup[key] = {
              name,
              group,
              codes: [],
              // codes:[{
              //   code: "",
              //   sign: 0,
              //   addParticulars: [] as string[],
              //   excludeParticulars: [] as string[],
              //   group,
              // }]
            };
          }

          groupedByNameAndGroup[key].codes.push({
            code,
            sign,
            addParticulars,
            excludeParticulars,
            group,
            useReference,
          });
        });
      } else {
        groupedByNameAndGroup[name] = {
          name,
          group: { groupId: item.group?.groupId || "All", groupName: item.group?.groupName || "All" },
          codes: [],
          // codes:[{
          //   code: "",
          //   sign: 0,
          //   addParticulars: [] as string[],
          //   excludeParticulars: [] as string[],
          //   group:item.group || "All",
          // }]
        };
      }
    });

    const doctypeWithGrp = Object.values(groupedByNameAndGroup);

    return doctypeWithGrp;
  }

  function convertToDoctypeWithoutGrp(doctypeWithGrp) {
    const doctypeWithoutGrp = [];

    doctypeWithGrp?.forEach((item) => {
      const { name, group, codes } = item;
      // Check if a matching entry exists in abc2
      const existingEntry = doctypeWithoutGrp.find((entry) => entry.name === name);

      if (existingEntry) {
        // If an entry with the same name exists, add the codes to it
        if (codes.length === 0) {
          existingEntry.codes.push({
            code: "",
            sign: 0,
            addParticulars: [],
            excludeParticulars: [],
            group,
          });
        } else {
          existingEntry.codes.push(...codes);
        }
      } else {
        // If no matching entry exists, create a new entry

        doctypeWithoutGrp.push({
          name,
          codes,
        });
      }
    });

    doctypeWithoutGrp.forEach((item) => {
      if (item.codes && item.codes.length > 0) {
        const allGroupIndex = item.codes.findIndex((codeItem) => codeItem.group?.groupId === "All");
        if (allGroupIndex === -1) {
          // If "All" group is not present, add it with empty code
          item.codes.push({
            code: "",
            sign: 0,
            addParticulars: [],
            excludeParticulars: [],
            group: { groupId: "All", groupName: "All" },
          });
        }
      }
    });

    doctypeWithoutGrp.forEach((item) => {
      if (item.codes && item.codes.length > 0) {
        const allGroupIndex = item.codes.findIndex((codeItem) => codeItem.group?.groupId === "All");
        if (allGroupIndex !== -1 && allGroupIndex !== 0) {
          // If "All" group is present and not already at index 0, move it to index 0
          const allGroup = item.codes.splice(allGroupIndex, 1)[0];
          item.codes.unshift(allGroup);
        }
      }
    });

    return doctypeWithoutGrp;
  }

  useEffect(() => {
    const tempDocumentTypes = DOCUMENT_TYPE_NAMES.map((name) => {
      const value = props.headerName[name] || [];
      return {
        name,
        codes: value,
      };
    });
    const doctypeWithGrp = convertToDoctypeWithGrp(tempDocumentTypes);
    setDocumentTypeRow(doctypeWithGrp);
  }, [props.headerName]);

  const onBackClick = () => {
    const doctypeWithoutGrp = convertToDoctypeWithoutGrp(documentTypeRow);
    const payload = doctypeWithoutGrp.reduce((result: Result, item: Element) => {
      result[item.name] = item.codes;
      return result;
    }, {});

    const tempObj = props.headerName;
    Object.assign(tempObj, payload);
    props.backTemplate();
  };
  const onSaveClickError = () => {
    if (props.uiLogger)
      uiLogger(
        props.name === ledgerUploadName.ownSide
          ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_CLICK.functionName
          : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SAVE_CLICK.functionName,
        companyId.current,
        branchCode.current,
        {
          message:
            props.name === ledgerUploadName.ownSide
              ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_CLICK.message
              : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SAVE_CLICK.message,
          businessPartnerId: businessPartnerSelectedRef.current,
        }
      );

    setOpenConfigSaveConfirmModal(false);
    const doctypeWithoutGrp = convertToDoctypeWithoutGrp(documentTypeRow);
    const payload = doctypeWithoutGrp.reduce((result: Result, item: Element) => {
      result[item.name] = item.codes;
      return result;
    }, {});

    const tempObj = props.headerName;
    Object.assign(tempObj, payload);
    props.saveRulesApi(tempObj as LedgerConfiguration);
  };

  const delRow = (element: Element, ind: number) => {
    element.codes.splice(ind, 1);
    const newData = documentTypeRow;
    const user = newData.find((d) => d.name === element.name && d.group?.groupId === element.group?.groupId);
    Object.assign(user, element);
    setDocumentTypeRow([...newData]);
    // createdocumentTypeTags([...newData]);
    if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
      props.setTemplateName(Customized);
      props.setChangedTemplateName(Customized);
    }
  };

  const closeConfigModal = async () => {
    if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
      await props.getConfig();
      props.setShowCustomOption(false);
    }
    setOpenConfigSaveConfirmModal(false);
    props.setOpenDialog(false);
    props.setColumnHeaderConfigDialog(true);
    if (props.uiLogger)
      props.uiLogger(
        props.name === ledgerUploadName.ownSide ? uiLoggerName.ui_OwnConfigClose : uiLoggerName.ui_BpConfigClose
      );
  };

  const checkIsDuplicateSign = (oldSign: 0 | 1 | 2, newSign: 0 | 1 | 2) => {
    if (oldSign === 0) return true;
    else if (oldSign === newSign) return true;
    else if (oldSign !== newSign && newSign === 0) return true;
    else return false;
  };

  const checkArrayValueEqual = (ComparandArray: string[], ComparatorArray: string[]) => {
    if (ComparandArray.length !== ComparatorArray.length) return false;
    ComparandArray.sort();
    ComparatorArray.sort();
    for (let i = 0; i < ComparandArray.length; i++) {
      if (ComparandArray[i].trim().toLowerCase() !== ComparatorArray[i].trim().toLowerCase()) return false;
    }
    return true;
  };

  const checkIsDuplicateCode = (newCode: Codes, docTypeCodes: Element[], _addCodeIndex: number) => {
    let isDuplicateCode = false;
    let duplicateDocTypeName = null;

    // if (newCode.excludeParticulars.length !== 0) {
    //   docTypeCodes[addCodeIndex].codes.forEach((existingCode) => {
    //     if (
    //       existingCode.code.trim().toLowerCase() === newCode.code.trim().toLowerCase() &&
    //       checkArrayValueEqual(newCode.addParticulars, existingCode.addParticulars) &&
    //       checkArrayValueEqual(newCode.excludeParticulars, existingCode.excludeParticulars) &&
    //       newCode.sign === existingCode.sign
    //     ) {
    //       isDuplicateCode = true;
    //       duplicateDocTypeName = docTypeCodes[addCodeIndex];
    //     }
    //   });
    // } else {
    docTypeCodes.forEach((docTypeCode) => {
      docTypeCode.codes.forEach((existingCode) => {
        if (existingCode.code.trim().toLowerCase() === newCode.code.trim().toLowerCase()) {
          if (
            checkArrayValueEqual(existingCode.addParticulars, newCode.addParticulars) &&
            checkArrayValueEqual(existingCode.excludeParticulars, newCode.excludeParticulars)
          ) {
            const duplicateSign = checkIsDuplicateSign(existingCode.sign as 0 | 1 | 2, newCode.sign as 0 | 1 | 2);

            if (duplicateSign) isDuplicateCode = true;
            if (isDuplicateCode) {
              duplicateDocTypeName = docTypeCode;
              return;
            }
          }
        }
      });
    });
    // }

    return { isDuplicateCode, duplicateDocTypeName };
  };

  function drop(ev: React.DragEvent<HTMLDivElement>, ele: Element) {
    ev.preventDefault();
    const dataCode = ev.dataTransfer.getData("code");
    const identifier = ev.dataTransfer.getData("identifier");

    document.querySelectorAll(".drop-area").forEach((el) => el.classList.remove("active"));
    const currentIdentifier = ele;
    const foundIdentifier = documentTypeRow?.find(
      (el) => `${el.name}-${el.group?.groupId}-${el.group?.groupName}` === identifier
    );
    const foundCode = foundIdentifier?.codes?.find((code) => getFlattenCode(code) === dataCode);
    foundCode.group = currentIdentifier.group;
    if (foundCode) currentIdentifier?.codes?.push(foundCode);
    foundIdentifier?.codes?.splice(foundIdentifier.codes.indexOf(foundCode), 1);

    setDocumentTypeRow(Array.from(documentTypeRow));
  }

  ondragend = () => {
    document.querySelectorAll(".drop-area").forEach((el) => el.classList.remove("active"));
  };

  function getNextGroupName(presentElements) {
    // Extract existing group IDs
    const existingGroupIds = presentElements.map((element) => element.group.groupId);

    // Find the first missing group number starting from 1
    let nextGroupNumber = 1;
    while (existingGroupIds.includes(`Group ${nextGroupNumber}`)) {
      nextGroupNumber++;
    }

    // Generate the next group name
    return `Group ${nextGroupNumber}`;
  }

  const addNewGroup = (el: any) => {
    const presentElements = documentTypeRow.filter((element) => element.name === el.name);
    // let numberOfElements = presentElements.length;

    // if (presentElements.some((element: any) => element.group?.groupId === `Group ${numberOfElements}`)) {
    //   numberOfElements = numberOfElements + 1;
    // }

    const newGrpName = getNextGroupName(presentElements);

    const addNewGroupVal = convertToDoctypeWithGrp(documentTypeRow);
    addNewGroupVal.push({
      name: el.name,
      // group: { groupId: `Group ${numberOfElements}`, groupName: `Group ${numberOfElements}` },
      group: { groupId: newGrpName, groupName: newGrpName },
      codes: [
        {
          code: "",
          sign: 0,
          addParticulars: [] as string[],
          excludeParticulars: [] as string[],
          group: { groupId: newGrpName, groupName: newGrpName },
          useReference: false,
        },
      ],
    });

    const sortedDocVal = [];

    DOCUMENT_TYPE_NAMES.forEach((docType) => {
      const matchingElements = addNewGroupVal.filter((element) => element.name === docType);
      sortedDocVal.push(...matchingElements);
    });

    // Function to remove duplicate objects in the 'codes' array of each element
    const removeDuplicateCodes = (data) => {
      return data.map((element) => ({
        ...element,
        codes: element.codes.reduce((uniqueCodes, codeObj) => {
          const codeString = JSON.stringify(codeObj);
          if (!uniqueCodes[codeString]) {
            uniqueCodes[codeString] = true;
            return [...uniqueCodes, codeObj];
          }
          return uniqueCodes;
        }, []),
      }));
    };

    // Apply the function to your data
    const newData = removeDuplicateCodes(sortedDocVal);

    setDocumentTypeRow(newData);
  };

  // Function to transform ignoredoctypelist to the desired format
  const transformIgnoreList = (ignoredoctypelist) => {
    const transformedList = [];

    // Transforming neverAskAgain items
    if (ignoredoctypelist && ignoredoctypelist.neverAskAgain) {
      ignoredoctypelist.neverAskAgain.forEach((item) => {
        transformedList.push({
          occurenceCount: "-",
          suggestedDoctype: "NeverAskAgain",
          unidentifiedText: item.code,
        });
      });
    }

    // Transforming ignoreThisTime items
    if (ignoredoctypelist && ignoredoctypelist.ignoreThisTime) {
      ignoredoctypelist.ignoreThisTime.forEach((item) => {
        transformedList.push({
          occurenceCount: "-",
          suggestedDoctype: "Ask",
          unidentifiedText: item,
        });
      });
    }

    return transformedList;
  };

  const DocumentTypeConfigContextValue = {
    openEditGroupName,
    setOpenEditGroupName,
    editGroupEle,
    documentTypeRow,
    setDocumentTypeRow,
    isOpenDeleteDocTypeSubGrp,
    setIsOpenDeleteDocTypeSubGrp,
  };

  return (
    <>
      <DocumentTypeConfigContext.Provider value={DocumentTypeConfigContextValue}>
        <header className="modal-card-head">
          <div className="space_between w_100_per">
            <div className="create_config">Create Configuration</div>
            <div className="step_text">Step 2 of 2 - Map Document Types</div>
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setOpenConfigSaveConfirmModal(true);
                }}
              />
            </div>
          </div>
        </header>
        <section className="modal-card-body">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column ">
                {props?.name !== "Configuration File" && (
                  <>
                    {(props.headerName?.ignoreDocTypeList?.ignoreThisTime?.length > 0 ||
                      props.headerName?.ignoreDocTypeList?.neverAskAgain?.length > 0) && (
                      <div className="mb_16">
                        <Alert
                          severity="info"
                          action={
                            <Button
                              variant="contained"
                              style={{ borderRadius: "4px", marginRight: "8px" }}
                              onClick={() => {
                                // closeConfigModal();
                                setOpenConfigSaveConfirmModal(false);
                                props.setOpenDialog(false);
                                props.setColumnHeaderConfigDialog(true);
                                const transformedList = transformIgnoreList(props.headerName.ignoreDocTypeList);
                                setDocumentTypesArr(transformedList);
                                finaldocumentTypesObj.current = Object.fromEntries(
                                  transformedList.map((item) => [
                                    item.unidentifiedText,
                                    {
                                      documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                                      transactionType: 0,
                                    },
                                  ])
                                );

                                changeDocumentTypeConfig(
                                  transformedList[0].unidentifiedText,
                                  documentTypeSuggestionMap[transformedList[0].suggestedDoctype]
                                );
                                setFetchDataFromERP(true);
                                setConfigUserType(
                                  props.name === ledgerUploadName.ownSide ? OWN_LEDGER : BUSINESS_PARTNER_LEDGER
                                );

                                setOpenDocumentTypeDialog(true);
                                setOpenUnassignedDoctypedDialogFromView(true);
                              }}
                            >
                              VIEW
                            </Button>
                          }
                        >
                          <AlertTitle>Unmapped Document Types</AlertTitle>
                          <Typography sx={{ fontSize: "14px" }}>
                            These are Document Types which are marked as ‘Ignore this time’ or ‘Never ask again’
                          </Typography>
                        </Alert>
                      </div>
                    )}
                    <div className="mb_16">
                      <Typography style={{ fontSize: "12px" }} className="vertical_center_align">
                        {" "}
                        <span
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            backgroundColor: "#9C27B0", // or any color you want
                            marginLeft: "4px", // optional, adds some space between text and rectangle
                            marginRight: "8px",
                            borderRadius: "2px",
                          }}
                        ></span>
                        Doc Types which are being used for the file(s)
                      </Typography>
                    </div>
                  </>
                )}
                {documentTypeRow.map((element, docTypeIndex) => {
                  return (
                    <Grid
                      key={`key${docTypeIndex}`}
                      style={{ marginLeft: element.group?.groupId !== "All" ? "30px" : "" }}
                    >
                      <Grid className="theme_bg space_between br_4 d_flex" item={true} xs={12} sm={12} md={12}>
                        <Grid className="b label_head" item={true} xs={6} sm={6} md={6}>
                          {/* <div className="control"> */}
                          {/* <h3 className="fw_900">{labels[docTypeIndex]}</h3> */}
                          <h3 className="fw_900">
                            {element.group?.groupId === "All"
                              ? docTypeLabels(element.name)
                              : element.group?.groupId !== element.group?.groupName
                              ? `${element.group?.groupId} : ${element.group?.groupName}`
                              : element.group?.groupName}
                          </h3>
                          {/* </div> */}
                        </Grid>
                        <Grid className="c right_align vertical_center_align" item={true} xs={8} sm={8} md={8}>
                          {element.group?.groupId === "All" ? (
                            <span
                              onClick={() => {
                                addNewGroup(element);
                              }}
                              style={{
                                display: "flex",
                                color: "white",
                                marginRight: "16px",
                                cursor: "pointer",
                                fontWeight: "500",
                              }}
                            >
                              <AddIcon style={{ color: "white", marginRight: "5px", cursor: "pointer" }} />
                              NEW GROUP
                            </span>
                          ) : (
                            <span>
                              <span
                                onClick={() => {
                                  setOpenEditGroupName(true);
                                  setEditGroupEle(element);
                                }}
                              >
                                <EditIcon style={{ color: "white", marginRight: "10px", cursor: "pointer" }} />
                              </span>
                              <span
                                onClick={() => {
                                  setIsOpenDeleteDocTypeSubGrp(true);
                                  setEditGroupEle(element);
                                }}
                              >
                                <DeleteIcon style={{ color: "white", marginRight: "5px", cursor: "pointer" }} />
                              </span>
                            </span>
                          )}
                        </Grid>
                      </Grid>

                      {/* NEW UI */}

                      <Grid container spacing={1.5} className="mt_6">
                        <Grid item xs={3.5}>
                          <TextField
                            fullWidth
                            className="update-custom-border-radius"
                            variant="outlined"
                            placeholder="Enter a new doc type"
                            size="small"
                            error={
                              duplicateTagName &&
                              Object.keys(elementStates).includes(`${element.name}-${element?.group?.groupId}`)
                            }
                            value={
                              elementStates[`${element.name}-${element?.group?.groupId}`]?.saveValueDocumentType || ""
                            }
                            onChange={(e) => {
                              handleChangeSaveValueDocumentType(e, element.name, element.group.groupId);
                              setDuplicateTagName(null);
                            }}
                          />
                          {duplicateTagName &&
                          Object.keys(elementStates).includes(`${element.name}-${element?.group?.groupId}`) ? (
                            <div className="mb_15 error_text_config">
                              This tag is already mapped in {duplicateTagName?.name}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={3.5}>
                          <FormControl size="small" fullWidth className="update-custom-border-radius">
                            <Select
                              value={
                                elementStates[`${element.name}-${element?.group?.groupId}`]?.transactionValue || null
                              }
                              onChange={(e) => handleTransactionChange(e, element.name, element.group.groupId)}
                              displayEmpty
                              inputProps={{ "aria-label": "Transaction Type" }}
                              disabled={
                                Object.keys(elementStates).length > 0 &&
                                elementStates[
                                  `${element?.name}-${element?.group?.groupId}`
                                ]?.saveValueDocumentType?.trim().length > 0
                                  ? false
                                  : true
                              }
                            >
                              <MenuItem value={null} disabled>
                                Select Transaction
                              </MenuItem>
                              <MenuItem value={3}>
                                All Transaction <small className="default_text">(Default)</small>
                              </MenuItem>
                              <MenuItem value={1}>Debit Transaction</MenuItem>
                              <MenuItem value={2}>Credit Transaction</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                          <FormControl size="small" fullWidth className="update-custom-border-radius">
                            <Select
                              value={null}
                              onChange={(e) => {
                                setAddElementObj(element);
                                setAddElementIndex(docTypeIndex);

                                setCheckedReference(false);
                                setOpenAddDialog(true);
                                setDuplicateTagName("");
                                if (e.target.value === 1) {
                                  setCheckedAddParticular(true);
                                } else if (e.target.value === 2) {
                                  setCheckedExcludeParticular(true);
                                } else if (e.target.value === 3) {
                                  setCheckedAddParticular(true);
                                  setCheckedExcludeParticular(true);
                                }
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Particular/ Narration" }}
                              disabled={
                                Object.keys(elementStates).length > 0 &&
                                elementStates[
                                  `${element?.name}-${element?.group?.groupId}`
                                ]?.saveValueDocumentType?.trim().length > 0
                                  ? false
                                  : true
                              }
                            >
                              <MenuItem value={null} disabled>
                                Particular/ Narration
                              </MenuItem>
                              <MenuItem value={1}>Add</MenuItem>
                              <MenuItem value={2}>Exclude</MenuItem>
                              <MenuItem value={3}>Add & Exclude</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={1.5} className="right_align">
                          <Button
                            className="green_btn"
                            style={{ width: "91px", height: "40px" }}
                            disabled={
                              Object.keys(elementStates).length > 0 &&
                              elementStates[
                                `${element?.name}-${element?.group?.groupId}`
                              ]?.saveValueDocumentType?.trim().length > 0
                                ? false
                                : true
                            }
                            onClick={() => {
                              const { isDuplicateCode, duplicateDocTypeName } = checkIsDuplicateCode(
                                {
                                  code: elementStates[`${element?.name}-${element?.group?.groupId}`]
                                    ?.saveValueDocumentType,
                                  sign: Number(
                                    elementStates[`${element?.name}-${element?.group?.groupId}`]?.transactionValue !==
                                      undefined &&
                                      elementStates[`${element?.name}-${element?.group?.groupId}`]?.transactionValue !==
                                        3
                                      ? elementStates[`${element?.name}-${element?.group?.groupId}`]?.transactionValue
                                      : 0
                                  ),
                                  addParticulars: addParticularTextArr,
                                  excludeParticulars: excludeParticularTextArr,
                                  group: element?.group,
                                  useReference: checkedReference,
                                },
                                documentTypeRow,
                                docTypeIndex
                              );

                              if (isDuplicateCode) setDuplicateTagName(duplicateDocTypeName);
                              if (!isDuplicateCode) {
                                if (
                                  elementStates[`${element?.name}-${element?.group?.groupId}`]
                                    ?.saveValueDocumentType !== "" &&
                                  elementStates[`${element?.name}-${element?.group?.groupId}`]
                                    ?.saveValueDocumentType !== null
                                ) {
                                  documentTypeRow[docTypeIndex].codes = documentTypeRow[docTypeIndex].codes.concat({
                                    code: elementStates[`${element?.name}-${element?.group?.groupId}`]
                                      ?.saveValueDocumentType,
                                    sign: Number(
                                      elementStates[`${element?.name}-${element?.group?.groupId}`]?.transactionValue !==
                                        undefined &&
                                        elementStates[`${element?.name}-${element?.group?.groupId}`]
                                          ?.transactionValue !== 3
                                        ? elementStates[`${element?.name}-${element?.group?.groupId}`]?.transactionValue
                                        : 0
                                    ),
                                    addParticulars: addParticularTextArr,
                                    excludeParticulars: excludeParticularTextArr,
                                    group: element.group,
                                    useReference: checkedReference,
                                  });
                                  if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
                                    props.setTemplateName(Customized);
                                    props.setChangedTemplateName(Customized);
                                  }
                                }
                                if (documentTypeRow[docTypeIndex].codes.length > 1) {
                                  const removeEmptyVal = documentTypeRow[docTypeIndex].codes.filter((el) => {
                                    return el.code !== "";
                                  });
                                  documentTypeRow[docTypeIndex].codes = removeEmptyVal;
                                }
                                if (
                                  elementStates[`${element?.name}-${element?.group?.groupId}`]
                                    ?.saveValueDocumentType !== null
                                ) {
                                  for (const obj of documentTypeRow) {
                                    obj.codes.sort((a, b) => a.sign - b.sign);
                                  }
                                }
                                // createdocumentTypeTags(documentTypeRow);
                                setDocumentTypeRow(documentTypeRow);

                                setOpenAddDialog(false);
                                setAddParticularTextArr([]);
                                setExcludeParticularTextArr([]);
                                setCheckedAddParticular(false);
                                setCheckedExcludeParticular(false);
                                setAddParticularText("");
                                setExcludeParticularText("");
                                setElementStates({});
                              }
                              if (props.uiLogger)
                                uiLogger(
                                  props.name === ledgerUploadName.ownSide
                                    ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_NEXT_ADD_CLICK.functionName
                                    : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_NEXT_ADD_CLICK.functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message:
                                      props.name === ledgerUploadName.ownSide
                                        ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_NEXT_ADD_CLICK.message
                                        : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_NEXT_ADD_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                      {/*  */}
                      <div
                        className="drop-zone"
                        onDrop={(e) => drop(e, element)}
                        onDragOver={(e) => allowDrop(e)}
                        onDragEnter={(e) => {
                          e.stopPropagation();
                          const el = e.target as HTMLElement;
                          const dropAreaParent = el.querySelector(".drop-area") ? el : el.parentElement;
                          dropAreaParent?.querySelector(".drop-area")?.classList.add("active");
                        }}
                        onDragLeave={(e) => e.stopPropagation()}
                      >
                        <div className="drop-area">
                          <svg
                            width="80px"
                            height="80px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cls_1"
                          >
                            <path d="M4,7V6A2,2,0,0,1,6,4H7.29 M7,20H6a2,2,0,0,1-2-2V17" />
                            <path d="m20 17v1a2 2 0 0 1-2 2h-1" />
                            <line x1="20" x2="20" y1="10.59" y2="14" />
                            <line x1="4" x2="4" y1="10.59" y2="14" />
                            <path d="m17 4h1a2 2 0 0 1 2 2v1" />
                            <line x1="10.4" x2="14" y1="4" y2="4" />
                            <line x1="10.4" x2="14" y1="20" y2="20" />
                          </svg>
                        </div>

                        {getConfigTags(
                          element,
                          delRow,
                          props.name === ledgerUploadName.ownSide ? configMappedOwn : configMappedBp
                        )}
                      </div>
                    </Grid>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot right_align">
          <div>
            <Button className="grey_btn mr_10" style={{ width: "143px" }} onClick={() => onBackClick()}>
              Back
            </Button>
          </div>
          <div>
            <Button className="green_btn" style={{ width: "143px" }} onClick={onSaveClickError}>
              Save
            </Button>
          </div>
        </footer>
        <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(true)}>
          <header className="modal-card-head">
            <p className="modal-card-title">
              {" "}
              {checkedAddParticular && checkedExcludeParticular
                ? "Add & Exclude Particular/ Narration"
                : checkedAddParticular
                ? "Add Particular/ Narration"
                : checkedExcludeParticular
                ? "Exclude Particular/ Narration"
                : "Particular/ Narration"}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setOpenAddDialog(false);
                setCheckedAddParticular(false);
                setCheckedExcludeParticular(false);
                setAddParticularTextArr([]);
                setExcludeParticularTextArr([]);
                setAddParticularText("");
                setExcludeParticularText("");
              }}
            />
          </header>
          <section className="modal-card-body">
            {duplicateTagName ? (
              <div className="mb_15 error_text_config">This tag is already mapped in {duplicateTagName?.name}</div>
            ) : (
              ""
            )}

            <Grid xs={12} md={12}>
              <div>
                <div className="particular_text">
                  <p>Add the word that you want to match that will appear in particular/narration column.</p>
                  <p>
                    You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank ,
                    TDS for ‘Particular’
                  </p>
                </div>
                <div className="mt_20">
                  {checkedAddParticular && (
                    <div>
                      <label className="fw_600">Particular/Narration to be Added</label>
                      <TextField
                        className="update-custom-border-radius mt_6"
                        id="outlined-name-addParticularText"
                        placeholder="Enter Particular/ Narration"
                        label="Enter Particular/ Narration"
                        fullWidth={true}
                        value={addParticularText}
                        onChange={(e: any) => {
                          setDuplicateTagName(null);
                          const strAdd = e.target.value.replace(/\s\s+/g, " ");
                          const arrAdd = strAdd.split(",");
                          const arrWithoutEmptyStringAdd = arrAdd.filter((entry: any) => entry.trim() !== "");
                          const arrWithTrimmedStringAdd = arrWithoutEmptyStringAdd.map((stringAdd: any) =>
                            stringAdd.trim()
                          );
                          const uniqArrAdd: any = [...new Set(arrWithTrimmedStringAdd)];
                          setAddParticularTextArr(uniqArrAdd);
                          setAddParticularText(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt_20">
                  {checkedExcludeParticular && (
                    <div>
                      <label className="fw_600">Particular/Narration to be Exclude</label>
                      <TextField
                        className="update-custom-border-radius mt_6"
                        id="outlined-name-excludeParticularText"
                        placeholder="Enter Particular/ Narration"
                        label="Enter Particular/ Narration"
                        fullWidth={true}
                        value={excludeParticularText}
                        onChange={(e: any) => {
                          setDuplicateTagName(null);
                          const str = e.target.value.replace(/\s\s+/g, " ");
                          const arr = str.split(",");
                          const arrWithoutEmptyString = arr.filter((entry: any) => entry.trim() !== "");
                          const arrWithTrimmedStringExclude = arrWithoutEmptyString.map((stringExclude: any) =>
                            stringExclude.trim()
                          );
                          const uniqArr: any = [...new Set(arrWithTrimmedStringExclude)];
                          setExcludeParticularTextArr(uniqArr);
                          setExcludeParticularText(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt_20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedReference}
                        onChange={handleChangeReference}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={<Typography style={{ fontWeight: 500 }}>Include Reference Also</Typography>}
                  />
                </div>
              </div>
            </Grid>
          </section>
          <footer className="modal-card-foot right_align">
            <div>
              <Button
                className={
                  addParticularTextArr?.length === 0 && excludeParticularTextArr?.length === 0
                    ? "grey_btn"
                    : "green_btn"
                }
                style={{ width: "160px" }}
                disabled={addParticularTextArr?.length === 0 && excludeParticularTextArr?.length === 0}
                onClick={() => {
                  const { isDuplicateCode, duplicateDocTypeName } = checkIsDuplicateCode(
                    {
                      code: elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]
                        ?.saveValueDocumentType,
                      sign: Number(
                        elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]?.transactionValue !==
                          undefined &&
                          elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]?.transactionValue !==
                            3
                          ? elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]?.transactionValue
                          : 0
                      ),
                      addParticulars: addParticularTextArr,
                      excludeParticulars: excludeParticularTextArr,
                      group: addElementObj.group,
                      useReference: checkedReference,
                    },
                    documentTypeRow,
                    addElementIndex
                  );

                  if (isDuplicateCode) setDuplicateTagName(duplicateDocTypeName);
                  if (!isDuplicateCode) {
                    if (
                      elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]
                        ?.saveValueDocumentType !== "" &&
                      elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]
                        ?.saveValueDocumentType !== null
                    ) {
                      documentTypeRow[addElementIndex].codes = documentTypeRow[addElementIndex].codes.concat({
                        code: elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]
                          ?.saveValueDocumentType,
                        sign: Number(
                          elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]?.transactionValue !==
                            undefined &&
                            elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]
                              ?.transactionValue !== 3
                            ? elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]?.transactionValue
                            : 0
                        ),
                        addParticulars: addParticularTextArr,
                        excludeParticulars: excludeParticularTextArr,
                        group: addElementObj.group,
                        useReference: checkedReference,
                      });
                      if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
                        props.setTemplateName(Customized);
                        props.setChangedTemplateName(Customized);
                      }
                    }
                    if (documentTypeRow[addElementIndex].codes.length > 1) {
                      const removeEmptyVal = documentTypeRow[addElementIndex].codes.filter((el) => {
                        return el.code !== "";
                      });
                      documentTypeRow[addElementIndex].codes = removeEmptyVal;
                    }
                    if (
                      elementStates[`${addElementObj?.name}-${addElementObj?.group?.groupId}`]
                        ?.saveValueDocumentType !== null
                    ) {
                      for (const obj of documentTypeRow) {
                        obj.codes.sort((a, b) => a.sign - b.sign);
                      }
                    }
                    // createdocumentTypeTags(documentTypeRow);

                    setDocumentTypeRow(documentTypeRow);

                    setOpenAddDialog(false);
                    setAddParticularTextArr([]);
                    setExcludeParticularTextArr([]);
                    setCheckedAddParticular(false);
                    setCheckedExcludeParticular(false);
                    setAddParticularText("");
                    setExcludeParticularText("");
                    setElementStates({});
                  }
                }}
              >
                Save and Add
              </Button>
            </div>
          </footer>
        </Dialog>
        {openEditGroupName && <EditGroupName />}
        {isOpenDeleteDocTypeSubGrp && <DeleteDocTypeSubGrp />}
        {/* config close confirm modal */}
        <Dialog open={openConfigSaveConfirmModal} onClose={() => setOpenConfigSaveConfirmModal(true)}>
          <header className="modal-card-head">
            <p className="modal-card-title">Configuration save?</p>
          </header>
          <section className="modal-card-body">
            <div>Do you want to save the changes done in configuration?</div>
          </section>
          <footer className="modal-card-foot space_between">
            <Button
              className="theme_outline_btn"
              size="small"
              onClick={() => {
                closeConfigModal();
              }}
            >
              Cancel
            </Button>
            <Button className="theme_btn" size="small" onClick={onSaveClickError}>
              Save
            </Button>
          </footer>
        </Dialog>
      </DocumentTypeConfigContext.Provider>
    </>
  );
};

export default DocumentTypeConfig;

import { Add } from "@mui/icons-material";
import { Box, CircularProgress, Fab, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useContext, useRef } from "react";
import { useLocation } from "react-router";
import { userContext } from "src/Components/Contexts/userContext";
import AddNewBusinessPartner from "../../NewBusinessPartner/AddNewBusinessPartner";
import { CommonAutoComplete } from "../../PartnerCommunication/CommonComponents";
import { Recon360Context } from "../../Recon360";
import { OptionObj } from "../../Recon360";

export default function PartnerSelectSection() {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const {
    businessPartnerSelected,
    businessPartnerSelect,
    listCompaniesForRecon,
    listBranchesForRecon,
    selectedCompany,
    selectedBranch,
    branchNameSelect,
    companyNameSelect,
    companiesLoaded,
    branchesLoaded,
    ownBusinessPartnersLoaded,
    selectedBusinessPartnerName,
    options,
    selectedBusinessPartnerVendorCode,
    listAllOwnBusinessPartnerMapping,
  } = useContext(Recon360Context);
  const addNewBpFunc = useRef(null);
  const optionsListCompaniesForRecon = listCompaniesForRecon?.map((option: any) => {
    const firstLetter = option.companyName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const optionsListBranchForRecon = listBranchesForRecon?.map((option: any) => {
    const firstLetter = option.branchName[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.vendorCode} ${option.name}`,
  });
  return (
    <Grid container={true} xs={12}>
      <Grid md={12}>
        <div className="top_bar_container">
          <div className="business_content">
            {actor.integration === true && (
              <div className="width_30_per d_block">
                {/* company dropdown when actor.integration === true */}
                <CommonAutoComplete
                  lastSelected={selectedCompany}
                  onChangeSelectFn={(e, value) => companyNameSelect(e, value)}
                  optionsList={optionsListCompaniesForRecon}
                  optionLabelKey={"companyName"}
                  loaded={companiesLoaded}
                  label="Select Company"
                />
              </div>
            )}

            {actor.integration === true && actor.branchLevelReconcilation === true && (
              <div className="width_30_per d_block">
                {/* branch dropdown when  actor.branchLevelReconcilation === true */}
                <CommonAutoComplete
                  lastSelected={selectedBranch}
                  onChangeSelectFn={(e, value) => branchNameSelect(e, value)}
                  optionsList={optionsListBranchForRecon}
                  optionLabelKey={"branchName"}
                  loaded={branchesLoaded}
                  label="Branch / Plant / Unit"
                />
              </div>
            )}
            {/* Partner select dropdown always */}
            <div className="width_30_per d_block">
              <Card sx={{ p: "8px !important" }} style={{ position: "relative" }}>
                <Autocomplete
                  size="small"
                  className="dropdown1"
                  onChange={(evt, value) => businessPartnerSelect(evt, value as OptionObj)}
                  id="businessPartner"
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  loading={!ownBusinessPartnersLoaded}
                  // disableClearable={true}
                  value={{
                    name: selectedBusinessPartnerName,
                    id: businessPartnerSelected,
                    vendorCode: selectedBusinessPartnerVendorCode,
                  }}
                  defaultValue={
                    location.state?.businessPartnerId
                      ? {
                          name: location.state?.businessPartnerName,
                          id: location.state?.businessPartnerId,
                          vendorCode: location.state?.vendorCode ? location.state?.vendorCode : "",
                        }
                      : null
                  }
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option: any) =>
                    option.vendorCode ? `${option.name} (Vendor Code: ${option.vendorCode})` : option.name
                  }
                  filterOptions={filterOptions}
                  renderOption={(props, option) => (
                    <Box
                      key={`${option.id}-${option.vendorCode}`}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                      style={{ display: "block" }}
                    >
                      <div>{option.name}</div>
                      <div>
                        <span style={{ color: "#666666", fontSize: "12px" }}>Vendor Code: {option.vendorCode}</span>
                      </div>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Partner Name"
                      label="Partner Name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!ownBusinessPartnersLoaded ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Card>
            </div>

            {!actor.integration && (
              <div className="ml_10" style={{ position: "relative" }}>
                <AddNewBusinessPartner
                  listAllOwnBusinessPartnerMapping={listAllOwnBusinessPartnerMapping}
                  from={"Recon360"}
                  className={"theme_btn"}
                  component={
                    <FormControl sx={{ alignItems: "center" }}>
                      <Fab
                        // size="small"
                        color="primary"
                        aria-label="add business partner"
                        onClick={(_e) => addNewBpFunc.current && addNewBpFunc.current()}
                      >
                        <Add />
                      </Fab>
                    </FormControl>
                  }
                  onClick={(f) => (addNewBpFunc.current = f)}
                />
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

import { Close } from "@mui/icons-material";
import { Box, Button, FormControl, IconButton, Stack, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { uiLoggerLrPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import { SingleIssue } from "../BalanceConfirmationPortal/FinalPage";
import { NdButton, NdStepIcon, NdTextField } from "../PartnerPortal/CommonComponents";
import { LoadingPage } from "../PartnerPortal/PartnerPortal.common";
import $ from "../PartnerPortal/PartnerPortal.module.scss";
import { RaisedIssueBc, StateDispatch } from "./CommonComponents";
import { CommonUploaderBox, UploadedLedgerData } from "./LedgerRequestPortalCommon";

interface RaiseRequestData {
  name: string;
  email: string;
  phone?: string;
  reason: string;
}

export interface LrPortalFinalPageDetails {
  partnerName: string;
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
}

const FinalPage = ({ partnerName, openDialog, setOpenDialog }: LrPortalFinalPageDetails) => {
  const [files, setFiles] = useState<string[]>([]);

  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const GetUploadedLedgers = () =>
    useFetch<{ details: UploadedLedgerData }>("/api/ledgerRequestPortal/GetUploadedLedgers", "GET", {
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setFiles(res.data.details.uploadedLedgers);
      },
    });

  useEffect(() => {
    GetUploadedLedgers();
  }, []);

  const FinalPageDetails = ({ openDialog, setOpenDialog }) => {
    return (
      <>
        <Stack className="fade_in details_box" py={4} gap={3} maxWidth={900} mx={"auto"}>
          <Box className={$.BR_fix + " vertical_center_align"} px={4} py={3} gap={2} bgcolor={"#2E7D32"} color={"#fff"}>
            <NdStepIcon icon={1} active={false} completed={true} sx={{ color: "#fff !important" }} />
            <Typography variant="h5">
              Your response has been sent to {partnerName[0]?.toUpperCase() + partnerName?.slice(1)}
            </Typography>
          </Box>
          <Stack gap={1}>
            <CommonUploaderBox setFileNames={null} viewMode={true} viewModeLedgerNames={files} />
          </Stack>
          <Box
            className={$.BR_fix + " vertical_center_align space_between"}
            px={2}
            py={"12px"}
            gap={2}
            border="1px solid #D7D7D7"
          >
            <Typography variant="subtitle1">
              If you think it's a mistake, please raise a request to resolve it
            </Typography>
            <Button
              className={$.BR_fix}
              onClick={() => {
                setOpenDialog(true);
                uiLoggerPortal(uiLoggerLrPortal.ui_PpLrPostSubmitRaiseRequestClick, encryptedData);
              }}
            >
              Raise Request
            </Button>
          </Box>
        </Stack>
        <RaiseRequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} finalPage={true} />
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingPage noHeader={true} />}>
        <FinalPageDetails openDialog={openDialog} setOpenDialog={setOpenDialog} />
      </Suspense>
    </>
  );
};

interface RaiseRequestDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  finalPage?: boolean;
}

export const RaiseRequestDialog = ({ openDialog, setOpenDialog, finalPage }: RaiseRequestDialogProps) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [partnerName, setPartnerName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isNewIssue, setIsNewIssue] = useState<boolean>(true);
  const [issues, setIssues] = useState<RaisedIssueBc[]>([]);

  useEffect(() => {
    if (openDialog) {
      getAllPreviouslyRaisedIssues();
      uiLoggerPortal(uiLoggerLrPortal.ui_PpLrRaiseIssueClick, encryptedData);
    }
  }, [openDialog]);

  useEffect(() => {
    getLedgerRequestDetailsStatus();
  }, []);

  const getLedgerRequestDetailsStatus = () =>
    useFetch<{ partnerName: string; status: string }>("/api/ledgerRequestPortal/GetLedgerRequestDetailsStatus", "GET", {
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (response) => {
        setPartnerName(response.data?.partnerName);
      },
    });

  const getAllPreviouslyRaisedIssues = async () => {
    setLoading(true);
    useFetch<{ previousRequest: RaisedIssueBc[] }>("/api/ledgerRequestPortal/GetAllPreviouslyRaisedIssues", "GET", {
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (response) => {
        const { previousRequest } = response.data;
        if (previousRequest) {
          setIsNewIssue(previousRequest.length === 0);
          setIssues(previousRequest);
        }
        setLoading(false);
      },
    });
  };

  const markIssueAsResolved = async (issueId: number) => {
    useFetch("/api/ledgerRequestPortal/PartnerMarkIssueAsResolved", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        issueId,
      },
      thenCallBack: (_res) => {
        getAllPreviouslyRaisedIssues();
      },
    });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        {loading ? (
          <LoadingPage noHeader={true} />
        ) : isNewIssue ? (
          <DialogContentNewIssue openDialog={openDialog} setOpenDialog={setOpenDialog} finalPage={finalPage} />
        ) : (
          <DialogContentExistingIssue
            setOpen={setOpenDialog}
            setIsNewIssue={setIsNewIssue}
            issues={issues}
            bpName={partnerName}
            markIssueResolved={markIssueAsResolved}
          />
        )}
      </Dialog>
    </>
  );
};

const DialogContentNewIssue = ({
  openDialog,
  setOpenDialog,
  finalPage,
}: {
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  finalPage?: boolean;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [notFilled, setNotFilled] = useState(true);
  const [filledData, setFilledData] = useState<RaiseRequestData>({ name: "", email: "", phone: "", reason: "" });

  const [submittingRequest, setSubmittingRequest] = useState(false);

  const checkFormInvalid = () => {
    for (const key in filledData) {
      if (key === "phone") filledData.phone = filledData.phone.trim();
      else if (!filledData[key]) return true;
    }
    return false;
  };

  useEffect(() => {
    if (checkFormInvalid()) setNotFilled(true);
    else setNotFilled(false);
  }, [filledData]);

  useEffect(() => {
    if (!openDialog) setFilledData({ name: "", email: "", phone: "", reason: "" });
  }, [openDialog]);

  const submitRequest = async () => {
    setSubmittingRequest(true);
    useFetch("/api/ledgerRequestPortal/RaiseIssue", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        name: filledData.name,
        email: filledData.email,
        phone: filledData.phone,
        reason: filledData.reason,
      },
      thenCallBack: (_res) => {
        setOpenDialog(false);
        setSubmittingRequest(false);
      },
      catchCallBack: () => {
        setSubmittingRequest(false);
      },
    });
  };

  return (
    <>
      <DialogTitle>Raise Query/ Issue</DialogTitle>
      <DialogContent className={$.flex_column} sx={{ pb: 2, px: 3, gap: 2 }}>
        <Stack gap={2} pt={2}>
          <FormControl className="d_flex" sx={{ flexFlow: "row", gap: 2, "&>div": { width: "33%" } }}>
            <NdTextField
              label="Full Name"
              required
              value={filledData.name}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, name: _e.target.value }))}
            />
            <NdTextField
              label="Email Address"
              required
              value={filledData.email}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, email: _e.target.value }))}
            />
            <NdTextField
              label="Phone"
              type="number"
              value={filledData.phone}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, phone: _e.target.value }))}
            />
          </FormControl>
          <NdTextField
            label="Reason"
            required
            fullWidth
            multiline
            maxRows={4}
            value={filledData.reason}
            onChange={(_e) => setFilledData((_d) => ({ ..._d, reason: _e.target.value }))}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button className={$.BR_fix} onClick={() => setOpenDialog(false)} color="inherit">
          Cancel
        </Button>
        <NdButton
          onClick={() => {
            submitRequest();
            if (finalPage) {
              uiLoggerPortal(uiLoggerLrPortal.ui_PpLrPostSubmitRaiseRequestSubmitClick, encryptedData);
            } else {
              uiLoggerPortal(uiLoggerLrPortal.ui_PpLrRaiseIssueSubmitClick, encryptedData);
            }
          }}
          variant="contained"
          disabled={notFilled || submittingRequest}
        >
          <LoadingIcon loading={submittingRequest} />
          Submit
        </NdButton>
      </DialogActions>
    </>
  );
};

interface DialogContentExistingIssueProps {
  setOpen: StateDispatch<boolean>;
  setIsNewIssue: StateDispatch<boolean>;
  issues: RaisedIssueBc[];
  bpName: string;
  markIssueResolved: (issueId: number) => Promise<void>;
}

const DialogContentExistingIssue = ({ setOpen, setIsNewIssue, issues, bpName }: DialogContentExistingIssueProps) => {
  return (
    <>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <Box className="left vertical_center_align">
          <DialogTitle>Previously Raised Query/ Issues</DialogTitle>
          <NdButton variant="contained" onClick={() => setIsNewIssue(true)}>
            Raise New Issue
          </NdButton>
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent className={$.flex_column} sx={{ p: 2, gap: 2 }}>
        <Stack gap={2} className="Issues_container">
          {issues.map((issue) => (
            <SingleIssue key={issue.issueId} bpName={bpName} {...issue} markIssueResolved={null} />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
};

export default FinalPage;

import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { TabPanel } from "../../ReconSettings/ReconSettings";
import SendReminderTab from "../Common/SendReminderSettings";
import { TallyOrSapCompanyId } from "../CommonComponents";

const MsmeConfirmationSettings = ({
  companyId,
  branchCode,
  ...props
}: {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  listUserEmailTemplate: (name: string) => void;
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Send Reminder" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <SendReminderTab
          companyId={companyId}
          branchCode={branchCode}
          listUserEmailTemplate={props.listUserEmailTemplate}
          useCaseType={UseCaseType.msme}
        />
      </TabPanel>
    </>
  );
};

export default MsmeConfirmationSettings;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterAlt, FilterAltOff, Settings } from "@mui/icons-material";
import { Download, MailOutline, Visibility } from "@mui/icons-material";
import { Autocomplete, Avatar, Button, createFilterOptions, ListItemIcon, MenuItem } from "@mui/material";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { MRT_Row } from "material-react-table";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { Dialog } from "src/Components/Dialog/Dialog";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import {
  BulkMailStatusResponse,
  BusinessPartnersListForMsme,
  BusinessPartnersListForMsmeRes,
  EmailSendCount,
  EmailTemplate,
  ListOwnTallyCompanies,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  MailThread,
  MsmeBulkMailStatusResponse,
  MsmeMailThread,
  PartnerCommunicationSelected,
  UploadFileType,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import {
  updateEmailResponseMsgMSME,
  updateIsSendingBulkMailsMSME,
  updateMailFailedUsersMSME,
  updateMailFailedWorkbookMSME,
  updateOpenBulkMailReportDialogMSME,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { exists } from "src/Utils/Common/Validators";
import { MsmeColorMap, MsmeConfirmationStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import ListBusinessPartnerUsers from "../../PartnerMaster/ListBusinessPartnerUsers";
import PartnerRequests from "../BalanceConfirmationBeta/PartnerRequests";
import BulkMailReportDialog from "../BulkMailReportDialog";
import { PageSelectModeToggle } from "../Common/CommonComponents";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
} from "../CommonComponents";
import CreateEmailTemplate from "../CreateEmailTemplate";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import MailThreadDialog from "../MailThreadDialog";
import "../PartnerCommunication.scss";
import { PartnerCommsContext } from "../PartnerCommunicationContext";
import SelectEmailTemplate from "../SelectEmailTemplate";
import SendEmailTemplate from "../SendEmailTemplate";
import MsmeConfirmationFilter from "./MsmeConfirmationFilter";
import MsmeConfirmationSettings from "./MsmeConfirmationSettings";

let intervalId: ReturnType<typeof setTimeout>;
interface IMsmeContext {
  rowsDataMsme: BusinessPartnersListForMsme[];
  storeRowsDataMsme: BusinessPartnersListForMsme[];
  setRowsDataMsme: StateDispatch<BusinessPartnersListForMsme[]>;
  setStoreRowsDataMsme: StateDispatch<BusinessPartnersListForMsme[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const MsmeContext = createContext<IMsmeContext>(null);
const filter = createFilterOptions<any>();

const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: " ",
      businessPartnerId: 0,
      allCategories: [],
      category: [],
      msmeStatus: null,
      segment: null,
      udyamRegistrationNumber: "",
      msmeConfirmationDocument: false,
      udyamCertificate: false,
      vendorCode: " ",
    } as BusinessPartnersListForMsme);
  }
  return dataArray;
};

const Msme = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnersListForMsme[]>([]);
  const [rowsDataMsme, setRowsDataMsme] = useState<BusinessPartnersListForMsme[]>(getBlankData());
  const [storeRowsDataMsme, setStoreRowsDataMsme] = useState<BusinessPartnersListForMsme[]>([]);
  const [openMsmeSettings, setOpenMsmeSettings] = useState<boolean>(false);
  const [openMsmeFilter, setOpenMsmeFilter] = useState<boolean>(false);
  const storeAllCategories = useRef<string[]>([]);
  const storeMsmeData = useRef<BusinessPartnersListForMsme[]>([]);
  const [isFilterAppliedMsme, setIsFilterAppliedMsme] = useState<boolean>(false);
  const [tempLoader, setTempLoader] = useState<boolean>(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  // ----//

  const [signatureImgBase64, setSignatureImgBase64] = useState<string>(null);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [lastSelectedTemplateID, SetLastSelectedTemplateID] = useState(0);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [mailLoading, setMailLoading] = useState(false);
  const [pageSelectMode, setPageSelectMode] = useState(false);

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
  } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();
  const {
    isSendingBulkMailsMSME,
    openBulkMailReportDialogMSME,
    emailResponseMsgMSME,
    mailFailedUsersMSME,
    mailFailedWorkbookMSME,
  } = useSelector((state: any) => state.bulkMailsStatus);

  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);
  // Email States END

  const storeAllBusinessPartnerCheck = useRef<{ [k: number]: boolean }>([]); // not in use

  const [_lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(null);

  const [_lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(null);

  // ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<MRT_Row<BusinessPartnersListForMsme>>(null);

  // reset status
  const [downloadMsmeReportBase64, setDownloadMsmeReportBase64] = useState<string>(null);

  const [openRaiseReq, setOpenRaiseReq] = useState<boolean>(false);
  const [raisedIssues, setRaisedIssues] = useState<
    { businessPartnerName: string; vendorCode: string; issues: RaisedIssueBc[] }[]
  >([]);

  const [loadingPartnerRequests, setLoadingPartnerRequests] = useState(false);

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  const columnDefinitionMsme = useMemo(
    (): ColDef<BusinessPartnersListForMsme>[] => [
      {
        header: "Business Partner",
        Header: () => <div>Business Partner</div>,
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        filterVariant: "autocomplete",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase() || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        maxSize: 300,
        minSize: 280,
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row.vendorCode || "",
        enableSorting: true,
        size: 200,
        filterVariant: "autocomplete",
      },

      {
        header: "MSME Status",
        id: "Status",
        accessorFn: (row) => row.msmeStatus || "",
        maxSize: 360,
        minSize: 280,
        size: 300,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <>
            {row.original.msmeStatus ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: MsmeColorMap[row.original.msmeStatus]?.color || "#000",
                    backgroundColor: MsmeColorMap[row.original.msmeStatus]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.msmeStatus}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Category",
        accessorFn: (row) => row.category?.[0]?.toLowerCase() || "",
        maxSize: 200,
        minSize: 200,
        size: 200,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
              loading={tempLoader}
            />
          </div>
        ),
      },
      {
        header: "MSME Segment",
        accessorFn: (row) => row.segment || "",
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.msmeStatus === MsmeConfirmationStatuses.NotMsme
              ? "Not MSME"
              : row.original.segment
              ? row.original.segment
              : "-"}
          </div>
        ),
        size: 220,
      },
      {
        header: "MSME/Udyam Registration Number",
        accessorFn: (row) => row.udyamRegistrationNumber || "",
        size: 340,
        filterVariant: "autocomplete",
      },
      {
        header: "MSME Confirmation document",
        accessorFn: (row) => row.msmeConfirmationDocument || false,
        size: 310,
        filterVariant: "checkbox",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.msmeConfirmationDocument ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() => viewUploadedPartnerPDF(row.original.businessPartnerId, "signedPdf")}
              >
                VIEW
              </Button>
            ) : row.original.msmeStatus === MsmeConfirmationStatuses.MsmeDocPending ? (
              "Pending"
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "MSME/Udyam Certificate",
        accessorFn: (row) => row.udyamCertificate || false,
        size: 280,
        filterVariant: "checkbox",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.udyamCertificate ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() => viewUploadedPartnerPDF(row.original.businessPartnerId, "udyamCertificate")}
              >
                VIEW
              </Button>
            ) : row.original.msmeStatus === MsmeConfirmationStatuses.MsmeDocPending ? (
              "Pending"
            ) : (
              "-"
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history]
  );

  const PartnerCommunicationContextValue: IMsmeContext = {
    rowsDataMsme,
    storeRowsDataMsme,
    setRowsDataMsme,
    setStoreRowsDataMsme,
    listAllBusinessPartnersWSR: () => ListAllBusinessPartnerForMsme(),
  };

  useEffect(() => {
    if (!actor.integration) {
      ListAllBusinessPartnerForMsme();
      // listAllBusinessPartnersForMsme();
      listUserEmailTemplateForMsme("");
      getListUserEmailSetting();
    }

    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  // Main ROWs API
  const ListAllBusinessPartnerForMsme = async () => {
    setLoader(true);
    useFetch<BusinessPartnersListForMsmeRes>("/api/partnerPortal/ListAllBusinessPartnerForMsme", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        // Extract the businessPartnerId values and store them in a new array
        // response.data = RowsData;
        storeMsmeData.current = response.data?.businessPartnersList || [];
        storeAllCategories.current = response.data?.allCategories || [];
        setRowsDataMsme(response.data.businessPartnersList || []);
        setStoreRowsDataMsme(response.data.businessPartnersList || []);
        setLoader(false);
        if (Object.keys(appliedFilters).length) setReFilter(true);
      },
    });
  };

  // User Email Templates for Mail Dialog
  const listUserEmailTemplateForMsme = (createTemplateName: string) => {
    useFetch<ListUserEmailTemplateResponse>("/api/partnerCommunication/ListUserEmailTemplate", "GET", {
      config: {
        params: {
          templateType: UseCaseType.msme,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { emailTemplates, lastEmailTemplateSelected } = response.data;
        setEmailTemplates(emailTemplates);

        if (lastEmailTemplateSelected) SetLastSelectedTemplateID(lastEmailTemplateSelected);
        if (createTemplateName.trim() !== "") {
          if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
            const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
              return item.templateName === createTemplateName;
            })[0];
            if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
          }
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          // setSelectedTemplate("");
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  // User Display Name and Email Id for Mail Dialog
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting?.display_name)) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (exists(response.data.userEmailSetting?.from_email)) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        if (exists(response.data.userEmailSetting?.signature?.image)) {
          setSignatureImgBase64(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (exists(response.data.userEmailSetting?.signature?.text)) {
          setSignature(response.data.userEmailSetting.signature.text);
        }
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (
    row: MRT_Row<BusinessPartnersListForMsme>,
    value: string[]
  ) => {
    useFetch("/api/UpdateOwnAndBusinessPartnerMappingCategories", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setTempLoader(true);
        updateCategory(row, value);
      },
    });
  };

  // update Rows Category
  const updateCategory = (row: MRT_Row<BusinessPartnersListForMsme>, value: string[]) => {
    storeMsmeData.current.find((v) => v.businessPartnerId === row.original.businessPartnerId).category = value;
    // setRowsDataMsme(storeMsmeData.current);
    // setStoreRowsDataMsme(storeMsmeData.current);

    setTempLoader(false);
  };

  // Company Select
  const companyNameSelect = (e: any, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        ListAllBusinessPartnerForMsme();
        listUserEmailTemplateForMsme("");
        getListUserEmailSetting();
        getMsmeBulkMailStatus();
        if (!intervalId) setIntervalMSME();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        ListAllBusinessPartnerForMsme();
        listUserEmailTemplateForMsme("");
        getListUserEmailSetting();
        getMsmeBulkMailStatus();
        if (!intervalId) setIntervalMSME();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
    setRowsDataMsme([]);
    setStoreRowsDataMsme([]);
  };

  // Get MSME Rows Report
  const getDownloadMsmeReport = () => {
    useFetch<{ workbookBase64: string }>("/api/partnerPortal/DownloadExcelReportForMsme", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setDownloadMsmeReportBase64(response.data?.workbookBase64);
        downloadMsmeReport(response.data?.workbookBase64);
      },
    });
  };

  // Download MSME Rows Report
  const downloadMsmeReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64 || downloadMsmeReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} MSME Report.xlsx`);
  };

  // Delete User Email Templates, View API Method Above Update Categories
  const deleteUserEmailTemplate = (templateId: number) => {
    setShowDeleteLoadingIcon(true);
    useFetch("/api/partnerCommunication/DeleteUserEmailTemplate", "DELETE", {
      config: {
        data: {
          templateId: templateId,
          useCaseType: UseCaseType.msme,
        },
      },
      thenCallBack: (_res) => {
        listUserEmailTemplateForMsme("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowDeleteLoadingIcon(false);
      },
      errorCallback: () => {
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  // View MSME Email Thread
  const viewEmail = (businessPartner: number) => {
    return new Promise<{ emails: MailThread[] }>((resolve, reject) => {
      useFetch<{ emails: MsmeMailThread[] }>("/api/partnerPortal/ViewEmailThread", "GET", {
        config: {
          params: {
            businessPartnerId: businessPartner,
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          if (response.data.emails) {
            const { emails } = response.data;
            const mailThreads: MailThread[] = emails.map((email) => ({
              emailBody: email.body,
              fromEmailId: email.from,
              timestamp: email.emailTime.toString(),
              attachments: [],
            }));

            resolve({ emails: mailThreads });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  // View Rows Updated Partner PDF
  const viewUploadedPartnerPDF = (_bpId: number, _fileType: UploadFileType) => {
    useFetch<{ signedUrl: string }>("/api/partnerPortal/ViewUploadedPartnerPdf", "GET", {
      config: {
        params: {
          businessPartnerId: _bpId,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          type: _fileType,
        },
      },
      thenCallBack: (_res) => {
        const link = document.createElement("a");
        link.href = _res.data.signedUrl;
        link.target = "_blank";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  const getMsmeBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<MsmeBulkMailStatusResponse>("/api/partnerPortal/GetMsmeBulkEmailStatus", "GET", {
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data.isCompleted) {
            if (res.data?.emailResponseMessage !== "") {
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }

            resolve({ isCompleted: true });

            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            dispatch(updateIsSendingBulkMailsMSME(false));

            if (res.data.listOfFailedUsers && res.data.listOfFailedUsers?.length) {
              if (openBulkMailReportDialogMSME) return;
              dispatch(updateOpenBulkMailReportDialogMSME(true));
              dispatch(updateEmailResponseMsgMSME(res.data.emailResponseMessage || ""));
              const listOfFailedUsers: BulkMailStatusResponse["listOfFailedUsers"] = [];
              res.data.listOfFailedUsers.forEach((failedUser) => {
                const found = listOfFailedUsers.find((user) => user.reason === failedUser.reason);
                if (found) {
                  found.businessPartnerNames.push(failedUser.businessPartnerName);
                } else
                  listOfFailedUsers.push({
                    reason: failedUser.reason,
                    businessPartnerNames: [failedUser.businessPartnerName],
                  });
              });
              dispatch(updateMailFailedUsersMSME(listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookMSME(res.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              listUserEmailTemplateForMsme("");
            }
          } else {
            dispatch(updateIsSendingBulkMailsMSME(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsMSME(false));
          reject();
        },
      });
    });
  };
  const setIntervalMSME = () => {
    intervalId = setInterval(async () => {
      const { isCompleted } = await getMsmeBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30000);
  };

  useEffect(() => {
    if (actor.integration === false) {
      getMsmeBulkMailStatus();
      if (!intervalId) setIntervalMSME();
    }
  }, []);
  const listAllBusinessPartnerRequest = () => {
    setLoadingPartnerRequests(true);
    useFetch<{ requestMap: Record<string, RaisedIssueBc[]> }>(
      "/api/partnerPortal/ListAllBusinessPartnerRequest",
      "GET",
      {
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (_res) => {
          setLoadingPartnerRequests(false);
          const { requestMap } = _res.data;
          if (Object.keys(requestMap)?.length > 0) {
            setRaisedIssues(
              Object.keys(requestMap)?.map((item) => {
                const { businessPartnerName, vendorCode } = JSON.parse(item);
                return {
                  businessPartnerName: businessPartnerName,
                  vendorCode: vendorCode,
                  issues: requestMap[item],
                };
              })
            );
          } else setRaisedIssues([]);
        },
        catchCallBack: () => {
          setLoadingPartnerRequests(false);
        },
      }
    );
  };

  const markIssueResolved = async (issueId: number) =>
    new Promise<{ success: boolean }>((resolve, reject) =>
      useFetch("/api/partnerPortal/MarkIssueAsResolved", "POST", {
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          issueId,
        },
        thenCallBack: (_res) => {
          listAllBusinessPartnerRequest();
          resolve({ success: true });
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );

  const GREEN = "#27B27C";

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <MsmeContext.Provider value={PartnerCommunicationContextValue}>
        {
          <Grid className="">
            <Grid position="relative">
              <Grid className="vertical_center_align mb_15" sx={{ gap: 2, flexWrap: "wrap" }}>
                {/* Left Side Dropdowns */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {!openMsmeSettings && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={null}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={null}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={null}
                          isDisabled={isSendingBulkMailsMSME}
                        />
                      )}
                    </>
                  )}
                </div>

                {/* Settings Back Button */}
                {openMsmeSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: GREEN, ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenMsmeSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {/* Right Side Buttons */}
                {!openMsmeSettings && (
                  <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{
                        borderRadius: "32px",
                        border: "1px solid #FFC135",
                        background: "#F8EDD4",
                      }}
                      startIcon={
                        loadingPartnerRequests ? (
                          <LoadingIcon loading={loadingPartnerRequests} />
                        ) : (
                          <svg className="icon" style={{ height: "30px", width: "30px", fill: "#333" }}>
                            <use xlinkHref="#icon-person-alert" />
                          </svg>
                        )
                      }
                      disabled={loadingPartnerRequests}
                      onClick={async () => {
                        await listAllBusinessPartnerRequest();
                        setOpenRaiseReq(true);
                      }}
                    >
                      Partner Requests
                    </Button>
                    <Tooltip title="Msme Confirmation Settings" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => setOpenMsmeSettings(true)}
                          color="inherit"
                          disabled={isSendingBulkMailsMSME}
                        >
                          <Settings />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title={"Filter"} arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_ClickedOnFilter, companyId.current, branchCode.current)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            onClick={() => setOpenMsmeFilter(true)}
                            color="inherit"
                            sx={{
                              pointerEvents: "auto !important",
                            }}
                          >
                            {isFilterAppliedMsme ? <FilterAlt /> : <FilterAltOff />}
                          </IconButton>
                        </span>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Bulk Email" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_clickedOnBulkEmail, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            setSendSoleOrBulkMail(EmailSendCount.Bulk);

                            setOpenSelectEmailTemplate(true);
                          }}
                          disabled={selectedRow.length < 2 ? true : isSendingBulkMailsMSME}
                          color="inherit"
                        >
                          {isSendingBulkMailsMSME ? (
                            <span
                              className="file-icon"
                              style={{
                                position: "absolute",
                                transform: "scale(2.5)",
                                left: 8,
                                color: "white",
                              }}
                            >
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fa-icon">
                            <i className="fas fa-envelope" />
                          </span>
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download MSME Confirmation Report" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_DownloadReport, companyId.current, branchCode.current)}
                      >
                        <IconButton
                          onClick={() => getDownloadMsmeReport()}
                          color="inherit"
                          disabled={isSendingBulkMailsMSME}
                        >
                          <Download />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </Grid>

              {/* Filter Applied Bar */}
              {!openMsmeSettings && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              <div
                className="bpDashboard"
                style={{
                  height: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openMsmeSettings ? "-50px" : "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                  }}
                >
                  {!openMsmeSettings && (
                    <ReactTable
                      columns={columnDefinitionMsme}
                      rows={rowsDataMsme}
                      setSelectedRow={setSelectedRow}
                      loading={loader}
                      enableGrouping={true}
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        // {/* Send Email Button */}
                        <MenuItem
                          disabled={isSendingBulkMailsMSME}
                          key={"sendMail"}
                          onClick={async () => {
                            // logic
                            setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                            setSendSoleOrBulkMail(EmailSendCount.Sole);
                            getListUserEmailSetting();

                            setOpenSelectEmailTemplate(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <MailOutline sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Send Email
                        </MenuItem>,
                        // {/* View Email Button */}
                        <MenuItem
                          disabled={isSendingBulkMailsMSME}
                          key={"viewEmail"}
                          onClick={async () => {
                            // logic
                            setMailLoading(true);
                            setOpenMailThread(true);
                            setMailThreads([]);
                            setBpName(row.original.businessPartnerName);
                            const { emails } = await viewEmail(row.original.businessPartnerId);
                            setMailLoading(false);
                            setMailThreads(emails || []);
                            uiLogger(uiLoggerName.ui_ViewEmailClicked, companyId.current, branchCode.current);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Visibility sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View Email
                        </MenuItem>,
                      ]}
                      renderAdditionalBottomToolbarCustomActions={() => {
                        return (
                          <PageSelectModeToggle pageSelectMode={pageSelectMode} setPageSelectMode={setPageSelectMode} />
                        );
                      }}
                      pageSelectMode={pageSelectMode}
                    />
                  )}
                  {openMsmeSettings && (
                    <MsmeConfirmationSettings
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      listUserEmailTemplate={listUserEmailTemplateForMsme}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
        <MsmeConfirmationFilter
          openMsmeFilter={openMsmeFilter}
          setOpenMsmeFilter={setOpenMsmeFilter}
          allCategories={storeAllCategories.current}
          rowsDataMsme={rowsDataMsme}
          setRowsDataMsme={setRowsDataMsme}
          storeRowsDataMsme={storeRowsDataMsme}
          setIsFilterAppliedMsme={setIsFilterAppliedMsme}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />

        {/* MSME Confirmation */}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
        <SendEmailTemplate
          companyId={companyId.current}
          branchCode={branchCode.current}
          base64Image={signatureImgBase64}
          signature={signature}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          listAllBusinessPartnerMapping={null}
          // after sending mail to update date
          listAllBusinessPartnersWSR={ListAllBusinessPartnerForMsme}
          // setLoader={setLoader}
          partnerCommunicationSelected={PartnerCommunicationSelected.msme}
          templateType={"MSME"}
          listUserEmailTemplate={listUserEmailTemplateForMsme}
          storeAllBusinessPartnerCheck={storeAllBusinessPartnerCheck}
          msmeConfirmation={true}
          setIntervalBulkMails={setIntervalMSME}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplate={listUserEmailTemplateForMsme}
          templateType={"MSME"}
          msmeConfirmation={true}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          width70Per={true}
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers as any}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        </Dialog>

        <MailThreadDialog
          open={openMailThread}
          setOpen={setOpenMailThread}
          mailThreads={mailThreads}
          s3Attachments={[]}
          businessPartnerName={bpName}
          loading={mailLoading}
          msme={true}
          companyId={companyId.current}
          branchCode={branchCode.current}
          useCaseType={null}
          emailSubject={null}
          listAllBusinessPartnersWSR={null}
        />
        <BulkMailReportDialog
          open={openBulkMailReportDialogMSME}
          setOpen={(value) => {
            dispatch(updateOpenBulkMailReportDialogMSME(value));
          }}
          emailResponseMsg={emailResponseMsgMSME}
          mailFailedUsers={mailFailedUsersMSME}
          workbook={mailFailedWorkbookMSME}
        />

        <PartnerRequests
          open={openRaiseReq}
          setOpen={setOpenRaiseReq}
          allIssues={raisedIssues}
          markIssueResolved={markIssueResolved}
        />
      </MsmeContext.Provider>
    </LoggedInSkeleton>
  );
};

export default Msme;

import { Button, Card, CardContent, Grid, TextField, Tooltip } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import useFetch from "../Common/useFetch";
import { AddUnpaidInvoicesInBulkResponse } from "./AdminInterfaces";

const UnpaidInvoice = () => {
  const [accountID, setAccountID] = useState<string>("");
  const [errAccountID, setErrAccountID] = useState<boolean>(false);
  const [filePayload, setFilePayload] = useState<File[]>(null);
  const [errFilePayload, setErrFilePayload] = useState<boolean>(false);
  // const [_unpaidInvoiceTemplateBase64, setUnpaidInvoiceTemplateBase64] = useState<string>(null);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);
  const [showLoadingIconSubmit, setShowLoadingIconSubmit] = useState<boolean>(false);

  useEffect(() => {
    // getUnpaidInvoiceTemplate();
  }, []);

  const uploadUnpaidInvoice = () => {
    if (accountID.trim() === "") {
      setErrAccountID(true);
    }
    if (filePayload === null) {
      setErrFilePayload(true);
    }
    if (accountID.trim() !== "" && filePayload !== null) {
      const reader = new FileReader();
      let data = null;
      reader.readAsDataURL(filePayload[0]);
      reader.onloadend = (event) => {
        data = event.target.result;
        setShowLoadingIconSubmit(true);
        useFetch<AddUnpaidInvoicesInBulkResponse>("/api/AddUnpaidInvoicesInBulk", "POST", {
          showSuccessToast: true,
          data: {
            accountId: isNaN(parseInt(accountID)) ? null : parseInt(accountID),
            unpaidInvoicesDataBase64: data
              .toString()
              .split("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,")[1],
          },
          thenCallBack: () => {
            setShowLoadingIconSubmit(false);
          },
          catchCallBack: () => {
            setShowLoadingIconSubmit(false);
          },
        });
      };
    }
  };

  const getUnpaidInvoiceTemplate = () => {
    useFetch("/api/GetUnpaidInvoicesBulkUploadExcelTemplate", "GET", {
      config: {
        params: {
          accountId: isNaN(parseInt(accountID)) ? null : parseInt(accountID),
        },
      },
      thenCallBack: (response) => {
        const excelData = Buffer.from(response.data.response, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, "Unpaid invoice template.xlsx");
      },
    });
  };

  // const downloadUnpaidInvoiceTemplate = () => {};

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Unpaid Invoice")}>
      <Grid className="center_align">
        <Card>
          <CardContent>
            <Grid className="vertical_center_align mt_30">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Account ID :</label>
              </Grid>
              <Grid>
                <TextField
                  variant="outlined"
                  required={true}
                  fullWidth={true}
                  label="Account ID"
                  name="accountID"
                  error={errAccountID}
                  helperText={errAccountID ? "please enter Account ID" : ""}
                  value={accountID}
                  onChange={(e) => {
                    setAccountID(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setErrAccountID(false);
                    } else {
                      setErrAccountID(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid className="flex_end mt_30">
              <Button
                onClick={() => {
                  getUnpaidInvoiceTemplate();
                }}
                className="theme_btn"
                sx={{ width: 200 }}
              >
                Download Format
              </Button>
            </Grid>
            <Grid className=" vertical_center_align mt_30">
              <Grid className="vertical_center_align p_10">
                <label className="addBpUser_label">Upload File :</label>
              </Grid>

              <Grid>
                <Button variant="contained" component="label" className="theme_btn" style={{ width: "200px" }}>
                  {showLoadingIconUpload ? null : (
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                  )}
                  {showLoadingIconUpload ? (
                    <span className="file-icon">
                      <i className="fas fa-circle-notch fa-spin" />
                    </span>
                  ) : null}
                  Upload File
                  <input
                    key={filePayload ? filePayload.length : 0} // Add key prop to trigger onChange event for the same file selection
                    hidden={true}
                    accept=".xlsx"
                    multiple={true}
                    required={true}
                    type="file"
                    onChange={(e) => {
                      setErrFilePayload(false);
                      setShowLoadingIconUpload(true);
                      const selectedFiles: any = Array.from(e.target.files);
                      // Clear input value to trigger onChange event for the same file selection
                      e.target.value = null;
                      // Clear previously selected files
                      setFilePayload([]);
                      // Assign the new files to the filePayload state variable
                      setFilePayload(selectedFiles);
                      setShowLoadingIconUpload(false);
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            {errFilePayload ? (
              <Grid className="center_align mt_10">
                <small style={{ color: "red" }}>please upload file</small>
              </Grid>
            ) : null}
            {filePayload ? (
              <Grid className="center_align mt_10">
                <Tooltip
                  title={filePayload?.map((fileItem: any, fileInd: number) => {
                    return <div key={`${fileInd}-file`}>{fileItem?.name}</div>;
                  })}
                >
                  <small>
                    File name:{" "}
                    {filePayload?.[0]?.name?.substring(0, 16) + (filePayload?.[0]?.name?.length > 15 ? "..." : "")}
                    {filePayload?.length > 1 && <span> + {filePayload?.length - 1}</span>}
                  </small>
                </Tooltip>
              </Grid>
            ) : null}
            <Grid className="center_align mt_30">
              <Button
                className="theme_outline_btn"
                style={{ width: "200px", border: "2px solid" }}
                onClick={() => {
                  uploadUnpaidInvoice();
                }}
              >
                {showLoadingIconSubmit ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                Submit
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </LoggedInSkeleton>
  );
};

export default UnpaidInvoice;

import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BusinessPartnerListForLrBeta,
  BusinessPartnersListForLrBetaHistory,
} from "src/entity/recon-entity/ReconInterfaces";
import { LedgerRequestBetaStatuses } from "src/Utils/PartnerCommunication";
import { TagsInputUniqueKeys } from "../../ReconMIS/ClientReporting/AutoCompletes";
import { CheckboxGroup } from "../../ReconMIS/utils";
import { DateFilter } from "../BalanceConfirmationBeta/BalanceConfirmationBetaFilter";
import "../LedgerRequestFilter.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type setAppliedFilters = StateDispatch<{
  [key: string]: string;
}>;

interface LedgerRequestFilterProps {
  allCategories: string[];
  storeRowsDataLedgerRequest: BusinessPartnerListForLrBeta[];
  rowsDataLedgerRequest: BusinessPartnerListForLrBeta[];
  setRowsDataLedgerRequest: StateDispatch<BusinessPartnerListForLrBeta[]>;
  setAppliedFilters: setAppliedFilters;
  setIsFilterAppliedLedgerRequest: StateDispatch<boolean>;
  openLedgerRequestFilter: boolean;
  setOpenLedgerRequestFilter: StateDispatch<boolean>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  historyConsumer?: boolean;
}

const LedgerRequestBetaFilter = (props: LedgerRequestFilterProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  //   const [fromDateAsOfLastStatusChange, setFromLedgerReceivedDate] = useState<string>(null);
  //   const [toDateAsOfLastStatusChange, setToLedgerReceivedDate] = useState<string>(null);
  const [fromDateOfMailInitiation, setFromDateOfMailInitiation] = useState<string>(null);
  const [toDateOfMailInitiation, setToDateOfMailInitiation] = useState<string>(null);

  const [fromLedgerReceivedDate, setFromLedgerReceivedDate] = useState<string>(null);
  const [toLedgerReceivedDate, setToLedgerReceivedDate] = useState<string>(null);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [downloadAvailable, setDownloadAvailable] = useState({
    yes: false,
    no: false,
  });

  const LedgerRequestStatus = Array.from(new Set(Object.values(LedgerRequestBetaStatuses))); // only unique values

  type keys = "partnerNames" | "category" | "status";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    props.setOpenLedgerRequestFilter(false);
  };

  const filterLedgerRequest = () => {
    let tempRowData = props.storeRowsDataLedgerRequest;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedLedgerRequest(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCategory?.every((val) => item.category?.includes(val));
      });
    }

    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) => item.ledgerRequestStatus?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (downloadAvailable.yes || downloadAvailable.no) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = tempRowData?.filter((item) => {
        if (downloadAvailable.yes && !downloadAvailable.no) return item.downloadLedgerKey;
        if (downloadAvailable.no && !downloadAvailable.yes) return !item.downloadLedgerKey;
        else if (downloadAvailable.no && downloadAvailable.yes) return true;
        else return false;
      });
    }

    if (props.historyConsumer && (fromDateOfMailInitiation || toDateOfMailInitiation)) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = DateFilter<BusinessPartnersListForLrBetaHistory>(
        fromDateOfMailInitiation,
        toDateOfMailInitiation,
        tempRowData,
        "mailInitiatingDate"
      );
    }

    if (fromLedgerReceivedDate || toLedgerReceivedDate) {
      props.setIsFilterAppliedLedgerRequest(true);
      tempRowData = DateFilter<BusinessPartnerListForLrBeta>(
        fromLedgerReceivedDate,
        toLedgerReceivedDate,
        tempRowData,
        "ledgerReceivedDate"
      );
    }

    setFilterObj({ partnerNames: partnerNames, category: selectedCategory, status: selectedStatus });

    props.setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "File Attached":
        downloadAvailable.yes && downloadAvailable.no
          ? "Yes, No"
          : downloadAvailable.yes
          ? "Yes"
          : downloadAvailable.no
          ? "No"
          : "",
      "Last Ledger Received Date":
        fromLedgerReceivedDate && toLedgerReceivedDate
          ? [fromLedgerReceivedDate, toLedgerReceivedDate].join(" - ")
          : fromLedgerReceivedDate
          ? `${fromLedgerReceivedDate} Onwards`
          : toLedgerReceivedDate
          ? `Till ${toLedgerReceivedDate}`
          : "",
    });

    props.setRowsDataLedgerRequest(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterLedgerRequest();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);
    setFromLedgerReceivedDate(null);
    setToLedgerReceivedDate(null);
    setPartnerNames([]);
    setDownloadAvailable({ yes: false, no: false });

    props.setAppliedFilters({});
    setFilterObj(null);

    handleClose();
    setTimeout(() => {
      props.setOpenLedgerRequestFilter(true);
      props.setRowsDataLedgerRequest(props.storeRowsDataLedgerRequest);
      props.setIsFilterAppliedLedgerRequest(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openLedgerRequestFilter}
        aria-labelledby="LedgerRequestFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setSelectedCategory(filterObj?.category || []);
              setSelectedStatus(filterObj?.status || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Names:</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <TagsInputUniqueKeys
                  tags={partnerNames}
                  setTags={setPartnerNames}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Names"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={props.storeRowsDataLedgerRequest}
                  accessor={"businessPartnerName"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={LedgerRequestStatus || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">File attached by Partner:</div>
              <div className="w_400 category_textFeild_width_390">
                <CheckboxGroup row={true} onChange={(_e, v) => setDownloadAvailable({ yes: v.yes, no: v.no })}>
                  <FormControlLabel control={<Checkbox checked={downloadAvailable.yes} name="yes" />} label="Yes" />
                  <FormControlLabel control={<Checkbox checked={downloadAvailable.no} name="no" />} label="No" />
                </CheckboxGroup>
              </div>
            </div>

            {props.historyConsumer && (
              <div className="d_flex m_15">
                <div className="w_300 vertical_center_align fw_600">Date of Email Initiation :</div>
                <div className="w_400 d_flex textFeild_width_185">
                  <TextField
                    className="mr_20"
                    label="From"
                    InputLabelProps={{ shrink: true }}
                    id="outlined-size-small"
                    size="small"
                    type="date"
                    value={fromDateOfMailInitiation || ""}
                    onChange={(e) => {
                      setFromDateOfMailInitiation(e.target.value);
                    }}
                  />
                  <TextField
                    label="To"
                    InputLabelProps={{ shrink: true }}
                    id="outlined-size-small"
                    size="small"
                    type="date"
                    value={toDateOfMailInitiation || ""}
                    onChange={(e) => {
                      setToDateOfMailInitiation(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Ledger Received Date:</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromLedgerReceivedDate || ""}
                  onChange={(e) => {
                    setFromLedgerReceivedDate(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toLedgerReceivedDate || ""}
                  onChange={(e) => {
                    setToLedgerReceivedDate(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              id="filterLedgerRequest_button"
              className="theme_btn"
              onClick={() => {
                filterLedgerRequest();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LedgerRequestBetaFilter;

import React, { useEffect, useState } from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { clarity } from "react-microsoft-clarity";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import packageJson from "../package.json";
import PartnerCommunicationAnalytics from "./Components/Admin/Analytics/PartnerCommunicationAnalytics";
import ReconAnalytics from "./Components/Admin/Analytics/ReconAnalytics";
import TaskManagementAnalytics from "./Components/Admin/Analytics/TaskManagementAnalytics";
import CBUpdate from "./Components/Admin/CBUpdate";
import ConfigTemplates from "./Components/Admin/ConfigTemplates";
import LedgerData from "./Components/Admin/LedgerData";
import PaymentDetails from "./Components/Admin/PaymentDetails";
import Register from "./Components/Admin/Register";
import SapData from "./Components/Admin/SapData";
import TallyLedger from "./Components/Admin/TallyLedger";
import TallyVoucher from "./Components/Admin/TallyVoucher";
import UnpaidInvoice from "./Components/Admin/UnpaidInvoice";
import Userlist from "./Components/Admin/Userlist";
import "./Components/Common/Common.scss";
import SideBar from "./Components/Common/SideBar";
import useFetch from "./Components/Common/useFetch";
import { userContext } from "./Components/Contexts/userContext";
import Login from "./Components/Login/Login";
import Faq from "./Components/Recon360/Faq/Faq";
import Ledger from "./Components/Recon360/LedgerAnalytics/Ledger";
import BalanceConfirmation from "./Components/Recon360/PartnerCommunication/BalanceConfirmation";
import BalanceConfirmationBeta from "./Components/Recon360/PartnerCommunication/BalanceConfirmationBeta/BalanceConfirmationBeta";
import BalanceConfirmationPortal from "./Components/Recon360/PartnerCommunication/BalanceConfirmationPortal/BalanceConfirmationPortal";
import BalanceConfirmationPortalContext from "./Components/Recon360/PartnerCommunication/BalanceConfirmationPortal/BalanceConfirmationPortalContext";
import CommonReport from "./Components/Recon360/PartnerCommunication/Common/CommonReport";
import ConfirmBalanceFromMail from "./Components/Recon360/PartnerCommunication/ConfirmBalanceFromMail/ConfirmBalanceFromMail";
import LedgerRequest from "./Components/Recon360/PartnerCommunication/LedgerRequest";
import LedgerRequestBeta from "./Components/Recon360/PartnerCommunication/LedgerRequestBeta/LedgerRequestBeta";
import LedgerRequestPortal from "./Components/Recon360/PartnerCommunication/LedgerRequestPortal/LedgerRequestPortal";
import MsmeConfirmation from "./Components/Recon360/PartnerCommunication/MsmeConfirmation/MsmeConfirmation";
import PartnerCommunicationContext from "./Components/Recon360/PartnerCommunication/PartnerCommunicationContext";
import PartnerPortal from "./Components/Recon360/PartnerCommunication/PartnerPortal/";
import PartnerMaster from "./Components/Recon360/PartnerMaster/PartnerMaster";
import AnnotationPage from "./Components/Recon360/PDFAnnotate";
import Recon360 from "./Components/Recon360/Recon360";
import APReconInsights from "./Components/Recon360/ReconMIS/APReconInsights/APReconInsights";
import ARReconInsights from "./Components/Recon360/ReconMIS/ARReconInsights/ARReconInsights";
import ClientReporting from "./Components/Recon360/ReconMIS/ClientReporting/ClientReporting";
import ReconMIS from "./Components/Recon360/ReconMIS/ReconMIS";
import ReconSettings from "./Components/Recon360/ReconSettings/ReconSettings";
import SupplierChainDashboard from "./Components/Recon360/SupplierChainDashboard/SupplierChainDashboard";
import ChangePassword from "./Components/ResetPassword/ChangePassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import { FrontendActor, UserTypes } from "./entity/models/FrontendActor";
import { UseCaseType } from "./entity/recon-entity/ReconInterfaces";
import { ENV_NAME } from "./Utils/Recon/ENV/Constants";
import { RECON360_SUB_TAB } from "./Utils/Recon/Recon360/Constants";

const NON_APP_PATHS = [
  { path: "/", exact: true },
  { path: "/reset", exact: false },
  { path: "confirmBalanceFromMail", exact: false },
  { path: "/annotate", exact: true },
  { path: "/partnerPortal", exact: false },
  { path: "/balanceConfirmationPortal", exact: false },
  { path: "/balanceConfirmReport", exact: false },
  { path: "/ledgerRequestPortal", exact: false },
  { path: "/ledgerRequestReport", exact: false },
];

export const isShownSideBar = (path: string) => {
  let isNonAppPath = false;
  NON_APP_PATHS.forEach((p) => {
    if (p.exact) {
      if (path === p.path) isNonAppPath = true;
    } else if (!p.exact) {
      if (path.includes(p.path)) isNonAppPath = true;
    }
  });
  // if non App path don't show side bar
  return isNonAppPath ? false : true;
};

const App = () => {
  const [actor, setActor] = useState<FrontendActor>(() => {
    const localData = localStorage.getItem("actor");
    try {
      return localData ? JSON.parse(localData) : null;
    } catch (e) {
      return null;
    }
  });

  if (
    actor &&
    actor?.userType !== UserTypes.Admin &&
    actor?.userType !== UserTypes.Sales &&
    actor?.zsMonitorAccount &&
    import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production
  ) {
    console.log("Tracking enabled for user");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      zapscale.init("660a5c46e4b3e5c5362401d9", {
        product_name: "ARP",
        organization_id:  "${actor?.clientId}",
        organization_name: "${actor?.actorName}",
        unique_organization_id: "${actor?.crmId ? actor?.crmId : ""}",
        user_id: "${actor?.userId}",
        user_name: "${actor?.userName}",
        user_email:  "${actor?.userEmail}",
        role_id: "${actor?.userType === UserTypes.CustomerSuccess ? "internal user" : "external user"}", 
        role_name:  "${actor?.userType === UserTypes.CustomerSuccess ? "internal user" : "external user"}", 
      }, {
        development: false
      });
    `;

    document.body.appendChild(script);
  }
  useEffect(() => {
    // Function to fetch the backend version
    const fetchBackendVersion = () => {
      useFetch("/api/version", "GET", {
        thenCallBack: (response) => {
          const backendVersion = response.data.version;
          // Access the version property from package.json
          const frontendVersion = packageJson.version;
          // Compare frontend and backend versions
          if (backendVersion && frontendVersion !== backendVersion) {
            // Perform a hard refresh
            window.location.reload();
          }
        },
      });
    };

    // Polling interval (every 1 minutes in this example)
    const pollingInterval = 1 * 60 * 1000; // 1 minutes in milliseconds

    // Initial fetch
    fetchBackendVersion();

    // Set up interval for periodic polling
    const intervalId = setInterval(fetchBackendVersion, pollingInterval);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const AdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (actor && actor.actorType === "Admin") {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    );
  };

  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  return (
    <BrowserRouter>
      <userContext.Provider value={{ actor, setActor }}>
        <SideBar />
        <Switch>
          <Route exact={true} path="/">
            <Login />
          </Route>
          <Route exact={true} path="/reset/:token/:accountType">
            <ChangePassword />
          </Route>
          <Route exact={true} path="/annotate">
            <AnnotationPage />
          </Route>
          <Route exact={true} path="/reset">
            <ResetPassword />
          </Route>
          <Route exact={true} path="/confirmBalanceFromMail">
            <ConfirmBalanceFromMail />
          </Route>
          <Route exact={true} path="/partnerPortal">
            <PartnerPortal />
          </Route>
          <Route exact={false} path="/balanceConfirmationPortal">
            <BalanceConfirmationPortalContext>
              <BalanceConfirmationPortal />
            </BalanceConfirmationPortalContext>
          </Route>
          <Route exact={false} path="/balanceConfirmReport">
            <CommonReport useCaseType={UseCaseType.balanceConfirmationBeta} />
          </Route>
          <Route exact={true} path="/ledgerRequestPortal">
            <LedgerRequestPortal />
          </Route>
          <Route exact={false} path="/ledgerRequestReport">
            <CommonReport useCaseType={UseCaseType.ledgerRequestBeta} />
          </Route>
          <Route exact={true} path="/faq">
            <Faq />
          </Route>

          {/* Recon360 paths */}
          {import.meta.env.VITE_APP_NAKAD_ENV === "test" ? (
            <Route exact={true} path="/:actorName/recon360/:pathParam?/PaymentAdvice">
              <Recon360 subTab={RECON360_SUB_TAB.PAYMENT_ADVICE} />
            </Route>
          ) : null}
          <Route exact={true} path="/:actorName/recon360/:pathParam?/Ledger">
            <Recon360 subTab={RECON360_SUB_TAB.LEDGER} />
          </Route>
          {actor?.integration && (
            <Route exact={true} path="/:actorName/LedgerAnalytics/Ledger">
              <Ledger />
            </Route>
          )}
          {import.meta.env.VITE_APP_NAKAD_ENV !== "production" ? (
            <Route exact={true} path="/:actorName/supplierchaindashboard">
              <SupplierChainDashboard />
            </Route>
          ) : null}
          <Route exact={true} path="/:actorName/reconSettings">
            <ReconSettings />
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/ledgerRequest">
            <PartnerCommunicationContext>
              <LedgerRequest />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/balanceConfirmation">
            <PartnerCommunicationContext>
              <BalanceConfirmation />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/msmeConfirmation">
            <PartnerCommunicationContext>
              <MsmeConfirmation />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/balanceConfirmBeta">
            <PartnerCommunicationContext>
              <BalanceConfirmationBeta />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/ledgerReqBeta">
            <PartnerCommunicationContext>
              <LedgerRequestBeta />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerMaster">
            <PartnerMaster />
          </Route>
          <Route exact={true} path="/:actorName/ReconMIS/ProcessManagement">
            <ReconMIS />
          </Route>
          {import.meta.env.VITE_APP_NAKAD_ENV === "test" ? (
            <Route exact={true} path="/:actorName/ReconMIS/APReconInsights">
              <APReconInsights />
            </Route>
          ) : null}
          {import.meta.env.VITE_APP_NAKAD_ENV === "test" ? (
            <Route exact={true} path="/:actorName/ReconMIS/ARReconInsights">
              <ARReconInsights />
            </Route>
          ) : null}
          <Route exact={true} path="/:actorName/ReconMIS/ClientReporting">
            <ClientReporting />
          </Route>

          {/* AdminRoute for admin paths */}
          <AdminRoute exact={true} path="/admin/userlist" component={Userlist} />
          <AdminRoute exact={true} path="/admin/sapdata" component={SapData} />
          <AdminRoute exact={true} path="/admin/ledgerdata" component={LedgerData} />
          <AdminRoute exact={true} path="/admin/configtemplates" component={ConfigTemplates} />
          <AdminRoute exact={true} path="/admin/tallyvoucher" component={TallyVoucher} />
          <AdminRoute exact={true} path="/admin/tallyledger" component={TallyLedger} />
          <AdminRoute exact={true} path="/admin/CBUpdate" component={CBUpdate} />
          <AdminRoute exact={true} path="/admin/UnpaidInvoice" component={UnpaidInvoice} />
          <AdminRoute exact={true} path="/admin/PaymentDetails" component={PaymentDetails} />
          <AdminRoute exact={true} path="/admin/Register" component={Register} />
          <AdminRoute exact={true} path="/admin/reconAnalytics" component={ReconAnalytics} />
          <AdminRoute
            exact={true}
            path="/admin/partnerCommunicationAnalytics"
            component={PartnerCommunicationAnalytics}
          />
          <AdminRoute exact={true} path="/admin/taskManagement" component={TaskManagementAnalytics} />
          <Redirect to="/" />
        </Switch>
      </userContext.Provider>
      <ToastContainer autoClose={5000} icon={false} closeButton={false} draggable limit={6} stacked />
    </BrowserRouter>
  );
};

export default App;

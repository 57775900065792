import { Button, Grid } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";

const AlgoCustomizationUpload = () => {
  const [showLoadingIconDownload, setShowLoadingIconDownload] = useState(false);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState(false);
  // Ref to file input element
  const fileInputRef = useRef(null);

  const downloadCurrentCustomization = () => {
    setShowLoadingIconDownload(true);
    useFetch("/api/Recon/GetReconSettings ", "POST", {
      data: {
        fetchJSON: true,
      },
      thenCallBack: (response) => {
        const jsonData = JSON.parse(Buffer.from(response.data.fileBase64, "base64").toString("utf-8"));
        // Convert JSON data to a Blob
        const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], { type: "application/json" });

        // Save the JSON Blob as a file
        saveAs(jsonBlob, "Current customization.json");

        toast.success(<CustomToast message="Downloaded successfully" />);
        setShowLoadingIconDownload(false);
      },
      catchCallBack: () => {
        setShowLoadingIconDownload(false);
      },
    });
  };

  const uploadCurrentCustomization = (filePayload) => {
    setShowLoadingIconUpload(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", filePayload);
    // Clear the value of the file input element
    fileInputRef.current.value = null;
    useFetch("/api/Recon/UploadReconSettings ", "POST", {
      showSuccessToast: true,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      thenCallBack: () => {
        setShowLoadingIconUpload(false);
      },
      catchCallBack: () => {
        setShowLoadingIconUpload(false);
      },
    });
  };

  return (
    <>
      <>
        <Grid>
          <Grid className="center_align_ver_horiz">
            <Button
              startIcon={<LoadingIcon loading={showLoadingIconDownload} />}
              className="purple_btn"
              style={{ width: "370px" }}
              onClick={() => {
                downloadCurrentCustomization();
              }}
              disabled={showLoadingIconDownload || showLoadingIconUpload}
            >
              DOWNLOAD CURRENT CUSTOMIZATION
            </Button>
            <Button
              className="green_btn ml_30"
              style={{ width: "315px" }}
              disabled={showLoadingIconUpload || showLoadingIconDownload}
              startIcon={<LoadingIcon loading={showLoadingIconUpload} />}
              component="label"
            >
              UPLOAD CUSTOMIZATION FILE
              <input
                ref={fileInputRef} // Assign the ref to the file input element
                hidden={true}
                accept=".json"
                required={true}
                type="file"
                onChange={(e) => {
                  uploadCurrentCustomization(e.target.files[0]);
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default AlgoCustomizationUpload;

import { Box, Divider, FormControlLabel, Stack } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useHistory, useLocation } from "react-router-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { uiLoggerLrPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import LendingTopBanner from "../Common/LendingTopBanner";
import PortalAccessDialog from "../Common/PortalAccessDialog";
import RaiseIssueSection from "../Common/RaiseIssueSection";
import { NdButton, NdCheckbox } from "../PartnerPortal/CommonComponents";
import ErrorPage from "../PartnerPortal/ErrorPage";
import { Header, LoadingPage, NavBar } from "../PartnerPortal/PartnerPortal.common";
import $ from "../PartnerPortal/PartnerPortal.module.scss";
import { StateDispatch } from "./CommonComponents";
import { fetchData } from "./CommonComponents";
import FinalPage, { RaiseRequestDialog } from "./FinalPage";
import { CommonUploaderBox, MailBox, MailBoxProps } from "./LedgerRequestPortalCommon";

type PageStatus = "NotFilled" | "FinalPage";

const LedgerRequestPortal: React.FC = () => {
  const [nameAndLogo, setNameAndLogo] = useState({
    logo: "",
    name: "",
  });

  const location = useLocation<any>();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const initialRaiseIssueDialogState = params.get("raiseIssue") === "1" ? true : false;

  const [submittingRequest, setSubmittingRequest] = useState(false);

  const [openDialog, setOpenDialog] = useState(initialRaiseIssueDialogState);

  // const [partnerName, setPartnerName] = useState<string>("");
  const [pageStatus, setPageStatus] = useState<PageStatus>("NotFilled");

  const [openPortalAccessDialog, setOpenPortalAccessDialog] = useState(true);

  const GetPartnerNameAndLogo = () => {
    useFetch<{ name: string; logo: string }>("/api/ledgerRequestPortal/GetPartnerNameAndLogo", "GET", {
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        setNameAndLogo({ name: res.data.name, logo: res.data.logo });
        clarity.setTag("LedgerRequestPortal", `Start`);
        clarity.setTag("RU", `${res.data.name}`);
        clarity.setTag("RU-BP", `${res.data.name}-${res.data.businessPartnerName}`);
      },
    });
  };

  const getLedgerRequestDetailsStatus = () =>
    fetchData<{ status: PageStatus }>("/api/ledgerRequestPortal/GetLedgerRequestDetailsStatus");
  const promisedLedgerRequestDetailsStatus = getLedgerRequestDetailsStatus();

  const getEmailDetails = () => fetchData<{ emailData: MailBoxProps }>("/api/ledgerRequestPortal/GetEmailDetails");
  const promisedEmailDetails = getEmailDetails();

  const submitLedgerRequest = () => {
    setSubmittingRequest(true);
    useFetch<{ signedUrl: string }>("/api/ledgerRequestPortal/SubmitLedgerRequest", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
      },
      thenCallBack: (_res) => {
        setSubmittingRequest(false);
        setPageStatus("FinalPage");
        clarity.setTag("LedgerRequestPortal", `End`);

        // clear edit response param if first time submitting
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("editResponse");

        history.push({
          pathname: pathname,
          search: searchParams.toString(),
        });
      },
      catchCallBack: () => {
        setSubmittingRequest(false);
      },
    });
  };

  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  useEffect(() => {
    GetPartnerNameAndLogo();
  }, []);

  return (
    <>
      <ErrorPage>
        <Suspense fallback={<LoadingPage />}>
          {pageStatus === "FinalPage" && <LendingTopBanner />}
          <NavBar companyName={nameAndLogo.name} companyLogo={nameAndLogo.logo} />
          <Divider className={$.borderColor} />
          <Header headerText="Ledger Request" />
          <Divider className={$.borderColor} />
          {
            pageStatus === "NotFilled" ? (
              <InitialPage
                promisedEmailDetails={promisedEmailDetails}
                promisedLedgerRequestDetailsStatus={promisedLedgerRequestDetailsStatus}
                setPageStatus={setPageStatus}
                submitLedgerRequest={submitLedgerRequest}
                submittingRequest={submittingRequest}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                openPortalAccessDialog={openPortalAccessDialog}
                setOpenPortalAccessDialog={setOpenPortalAccessDialog}
              />
            ) : pageStatus === "FinalPage" ? (
              <FinalPage partnerName={nameAndLogo.name} openDialog={openDialog} setOpenDialog={setOpenDialog} />
            ) : null // <FinalPage partnerName={partnerName} />
          }
        </Suspense>
      </ErrorPage>
    </>
  );
};

interface InitialPageProps {
  promisedEmailDetails: {
    read: () => {
      emailData: MailBoxProps;
    };
  };
  promisedLedgerRequestDetailsStatus: {
    read: () => {
      // partnerName: string;
      status: PageStatus;
    };
  };
  setPageStatus: StateDispatch<PageStatus>;
  submitLedgerRequest: () => void;
  submittingRequest: boolean;
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}

const InitialPage = ({
  promisedEmailDetails,
  promisedLedgerRequestDetailsStatus,
  setPageStatus,
  submitLedgerRequest,
  submittingRequest,
  openDialog,
  setOpenDialog,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: InitialPageProps) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const EmailDetails = promisedEmailDetails.read();
  const LedgerRequestDetailsStatus = promisedLedgerRequestDetailsStatus.read();

  const [emailData, setEmailData] = useState<MailBoxProps>({} as MailBoxProps);
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);

  useEffect(() => {
    setEmailData(EmailDetails?.emailData);
    const { status } = LedgerRequestDetailsStatus;
    // setPartnerName(partnerName || "");
    setPageStatus(status || "NotFilled");
  }, []);

  return (
    <>
      <Box className="main_container">
        <Stack className="fade_in details_box" py={6} gap={5} maxWidth={900} mx={"auto"}>
          <MailBox
            mailSubject={emailData.mailSubject}
            mailBody={emailData.mailBody}
            mailFrom={emailData.mailFrom}
            mailDate={emailData.mailDate}
          />
          <CommonUploaderBox setFileNames={setFileNames} />
          <FormControlLabel
            control={
              <NdCheckbox
                checked={fileNames.length > 0 && confirmCheck}
                onChange={(e) => setConfirmCheck(e.target.checked)}
                disabled={fileNames.length < 1}
              />
            }
            label="I confirm that above uploaded file is ledger file"
            sx={{ ml: 0, userSelect: "none" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NdButton
              className={$.BR_fix}
              variant="contained"
              disabled={!confirmCheck || submittingRequest}
              onClick={() => {
                submitLedgerRequest();
                uiLoggerPortal(uiLoggerLrPortal.ui_PpLrSubmitClick, encryptedData);
              }}
            >
              <LoadingIcon loading={submittingRequest} />
              submit
            </NdButton>
            <RaiseIssueSection setOpenDialog={setOpenDialog} />
          </Box>
        </Stack>
      </Box>
      <RaiseRequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <PortalAccessDialog
        openPortalAccessDialog={openPortalAccessDialog}
        setOpenPortalAccessDialog={setOpenPortalAccessDialog}
      />
    </>
  );
};

export default LedgerRequestPortal;

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import "./ReconOutput.scss";
import "./ReconOutput.scss";

const columnConfig = [
  { label: "Own Columns", stateKey: "ownColumn" },
  { label: "Partner Columns", stateKey: "partnerColumn" },
  { label: "NAKAD Columns", stateKey: "nakadColumn" },
];

export const defaultResultFile = [
  { label: "Reconciliation Summary + Annexures", stateKey: "reconciliationSummary" },
  { label: "Internal Summary + Own Vs Partner", stateKey: "internalSummary" },
  // { label: "Full Ledgers ->", stateKey: "fullLedgers" },
  { label: "Own Ledger", stateKey: "ownLedger" },
  { label: "Partner Ledger", stateKey: "partnerLedger" },
  { label: "Help", stateKey: "help" },
  { label: "Ledger Summary", stateKey: "ledgerSummary" },
  // { label: "QC (CS Users)", stateKey: "qcCsUsers" },
];

// Options for column types and names
const columnTypes = ["Own", "BP", "NAKAD", "Custom"];
const columnNames = {
  Own: [
    "Reconciliation Status",
    "Entry Number",
    "Entry Type",
    "Document Date",
    "Document Type",
    "Reference Number",
    "Amount",
    "Amount Difference",
    "Amount Difference (%)",
    "Match ID",
    "Default Input",
    "User Input",
    "Matching Logs",
    "Review Required",
  ],
  BP: [
    "Reconciliation Status",
    "Entry Number",
    "Entry Type",
    "Document Date",
    "Document Type",
    "Reference Number",
    "Amount",
    "Amount Difference",
    "Amount Difference (%)",
    "Match ID",
    "Default Input",
    "User Input",
    "Matching Logs",
    "Review Required",
  ],
  NAKAD: [
    "Reconciliation Status",
    "Entry Number",
    "Entry Type",
    "Document Date",
    "Document Type",
    "Reference Number",
    "Amount",
    "Amount Difference",
    "Amount Difference (%)",
    "Match ID",
    "Default Input",
    "User Input",
    "Matching Logs",
    "Review Required",
  ],
};

// Utility function to generate Excel-like column names starting from 'W'
const getExcelColumnName = (index) => {
  let columnName = "";
  index += 22; // Adjusting the index to start from 'W'

  while (index >= 0) {
    columnName = String.fromCharCode((index % 26) + 65) + columnName;
    index = Math.floor(index / 26) - 1;
  }

  return columnName;
};

// Reusable Component for each section
const SectionTable = ({ sectionName, rows, setRows, columnTypes, columnNames }) => {
  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([...rows, { columnNo: getExcelColumnName(rows.length), columnType: "", columnName: "", formula: "" }]);
  };

  const clearRow = (index) => {
    const updatedRows = [...rows];
    updatedRows[index] = { columnNo: updatedRows[index].columnNo, columnType: "", columnName: "", formula: "" };
    setRows(updatedRows);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{ pl: 2, pr: 2, fontWeight: 600, mt: 2, background: "#F3F3F3", pt: 1, pb: 1 }}
        className="col_header_name"
      >
        {sectionName}
      </Grid>
      <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
        <Grid
          container
          spacing={0}
          sx={{ pl: 2, pr: 2, fontWeight: 600, mb: 1, background: "#F3F3F3", pt: "4px", pb: "4px" }}
        >
          {/* Header Row */}
          <Grid item xs={1.5} sx={{ textAlign: "center", pr: 2 }}>
            Column No.
          </Grid>
          <Grid item xs={2} sx={{ pr: 2 }}>
            Column Type
          </Grid>
          <Grid item xs={3} sx={{ pr: 2 }}>
            Column Name
          </Grid>
          <Grid item xs={4.5}>
            Formula
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            Action
          </Grid>
        </Grid>

        {rows.map((row, index) => (
          <Grid container alignItems="center" key={index} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
            <Grid item xs={1.5} sx={{ textAlign: "center", pr: 2 }}>
              {row.columnNo}
            </Grid>
            <Grid item xs={2} sx={{ pr: 2 }}>
              <Select
                fullWidth
                size="small"
                value={row.columnType}
                onChange={(e) => handleRowChange(index, "columnType", e.target.value)}
                displayEmpty
                style={{ borderRadius: "4px" }}
              >
                <MenuItem value="" disabled>
                  Select Column Type
                </MenuItem>
                {columnTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ pr: 2 }}>
              {row.columnType === "Custom" ? (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={row.columnName}
                  onChange={(e) => handleRowChange(index, "columnName", e.target.value)}
                  className="update-custom-border-radius"
                />
              ) : (
                <Select
                  fullWidth
                  size="small"
                  value={row.columnName}
                  onChange={(e) => handleRowChange(index, "columnName", e.target.value)}
                  displayEmpty
                  style={{ borderRadius: "4px" }}
                >
                  <MenuItem value="" disabled>
                    Select Column Name
                  </MenuItem>
                  {(columnNames[row.columnType] || []).map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
            <Grid item xs={4.5}>
              {row.columnType === "Custom" ? (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={row.formula}
                  onChange={(e) => handleRowChange(index, "formula", e.target.value)}
                  className="update-custom-border-radius"
                />
              ) : (
                <Typography className="NA_text">Not Applicable</Typography>
              )}
            </Grid>
            <Grid item xs={1} sx={{ textAlign: "center" }}>
              <IconButton onClick={() => clearRow(index)}>
                <DeleteIcon sx={{ color: "#D32F2F" }} />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid sx={{ p: 2 }}>
          <Button sx={{ mt: 2 }} onClick={addRow} startIcon={<AddIcon />}>
            Add New Column
          </Button>
        </Grid>
      </Box>
    </>
  );
};

const ReconOutput = () => {
  const [showLoadingIconUpdateColumnsColor, setShowLoadingIconUpdateColumnsColor] = useState(false);
  const [showLoadingIconSetCustomResult, setShowLoadingIconSetCustomResult] = useState(false);
  // const [showLoadingIconAddColumnsInOwnVsBpSheet, setShowLoadingIconAddColumnsInOwnVsBpSheet] = useState(false);
  const [showLoadingIconAddColumnsInReconciliationSummary, setShowLoadingIconAddColumnsInReconciliationSummary] =
    useState(false);

  const [columns, setColumns] = useState({
    ownColumn: "",
    partnerColumn: "",
    nakadColumn: "",
  });

  // State to manage all sections in defaultResultFile dynamically
  const [defaultResultFileState, setDefaultResultFileState] = useState({
    reconciliationSummary: "",
    internalSummary: "",
    // fullLedgers: "",
    ownLedger: "",
    partnerLedger: "",
    help: "",
    ledgerSummary: "",
    qcCsUsers: "Show",
  });

  // State for dynamically added table rows, initially empty
  // const [rowsOwnVsPartner, setRowsOwnVsPartner] = useState([]);

  // Reconciliation Summary
  const [reconciliationRows, setReconciliationRows] = useState([]);

  // Annexures section
  // Dynamic state management for each section
  const [annexuresSections, setAnnexuresSections] = useState({
    invoiceAmountMismatch: [],
    invoiceNotPresentPartnerLedger: [],
    invoiceNotPresentOwnLedger: [],
    dnCnNotPresentOwnLedger: [],
    suggestedTdsGap: [],
    adjustmentMiscellaneousEntries: [],
  });

  // Helper function to update rows for each section
  const updateRows = (section, newRows) => {
    setAnnexuresSections((prevSections) => ({
      ...prevSections,
      [section]: newRows,
    }));
  };

  // Function to add a new row with default values
  // const addRow = () => {
  //   setRowsOwnVsPartner([
  //     ...rowsOwnVsPartner,
  //     {
  //       columnNo: getExcelColumnName(rowsOwnVsPartner.length), // Generate column names starting from 'W'
  //       columnType: "",
  //       columnName: "",
  //       formula: "",
  //     },
  //   ]);
  // };

  // Handler to update row values based on input changes
  // const handleRowChange = (index, key, value) => {
  //   setRowsOwnVsPartner((prevRows) => {
  //     const updatedRows = [...prevRows];
  //     updatedRows[index][key] = value;

  //     // Update the formula and reset column name when column type changes
  //     if (key === "columnType") {
  //       updatedRows[index].formula = value === "Custom" ? "" : "Not Applicable";
  //       updatedRows[index].columnName = "";
  //     }

  //     return updatedRows;
  //   });
  // };

  // Handler to clear row values without deleting the row itself
  // const clearRow = (index) => {
  //   setRowsOwnVsPartner((prevRows) => {
  //     const updatedRows = [...prevRows];
  //     updatedRows[index] = { columnNo: updatedRows[index].columnNo, columnType: "", columnName: "", formula: "" };
  //     return updatedRows;
  //   });
  // };

  const updateColumnsColor = () => {
    setShowLoadingIconUpdateColumnsColor(true);
    useFetch("/api/recon/UpdateColumnsColor", "POST", {
      showSuccessToast: true,
      data: {
        OwnColumnsColorCode: columns.ownColumn,
        businessPartnerColumnsColorCode: columns.partnerColumn,
        nakadColumnsColorCode: columns.nakadColumn,
      },
      thenCallBack: () => {
        setShowLoadingIconUpdateColumnsColor(false);
      },
      catchCallBack: () => {
        setShowLoadingIconUpdateColumnsColor(false);
      },
    });
  };

  const setCustomResult = () => {
    // Check if all fields are either "Hide" or "Remove"
    const { ...allHiddenOrRemovedWithoutQCSheet } = defaultResultFileState;
    delete allHiddenOrRemovedWithoutQCSheet.qcCsUsers;
    const allHiddenOrRemoved = Object.values(allHiddenOrRemovedWithoutQCSheet).every(
      (value) => value === "Hide" || value === "Remove"
    );
    if (allHiddenOrRemoved) {
      toast.error(<CustomToast message={"No sheets selected"} />);
    } else {
      setShowLoadingIconSetCustomResult(true);
      useFetch("/api/recon/SetDefaultDownloadSettings", "POST", {
        showSuccessToast: true,
        data: {
          ReconciliationSummaryAndAnnexures: defaultResultFileState.reconciliationSummary,
          InternalSummaryAndOwnVsPartner: defaultResultFileState.internalSummary,
          // FullLedgers: defaultResultFileState.fullLedgers,
          OwnLedger: defaultResultFileState.ownLedger,
          PartnerLedger: defaultResultFileState.partnerLedger,
          Help: defaultResultFileState.help,
          LedgerSummary: defaultResultFileState.ledgerSummary,
          QcSheets: defaultResultFileState.qcCsUsers,
        },
        thenCallBack: () => {
          setShowLoadingIconSetCustomResult(false);
        },
        catchCallBack: () => {
          setShowLoadingIconSetCustomResult(false);
        },
      });
    }
  };

  // const addColumnsInOwnVsBpSheet = () => {
  //   setShowLoadingIconAddColumnsInOwnVsBpSheet(true);
  //   useFetch("/api/recon/AddColumnsInOwnVsBpSheet", "POST", {
  //     showSuccessToast: true,
  //     data: { extraColumnsInOwnVsBpSheet: rowsOwnVsPartner },
  //     thenCallBack: () => {
  //       setShowLoadingIconAddColumnsInOwnVsBpSheet(false);
  //     },
  //     catchCallBack: () => {
  //       setShowLoadingIconAddColumnsInOwnVsBpSheet(false);
  //     },
  //   });
  // };
  const addColumnsInReconciliationSummary = () => {
    setShowLoadingIconAddColumnsInReconciliationSummary(true);
    useFetch("/api/recon/AddColumnsInRecociliationSummary", "POST", {
      showSuccessToast: true,
      data: { extraColumnsInReconciliationSummary: reconciliationRows },
      thenCallBack: () => {
        setShowLoadingIconAddColumnsInReconciliationSummary(false);
      },
      catchCallBack: () => {
        setShowLoadingIconAddColumnsInReconciliationSummary(false);
      },
    });
  };

  useEffect(() => {
    getColumnsColor();
    getExtraColumnsInExcelSheet();
    getDefaultDownloadSettings();
  }, []);

  const getColumnsColor = () => {
    useFetch("/api/recon/GetColumnsColor", "GET", {
      thenCallBack: (response) => {
        setColumns({
          ownColumn: response.data.data.ownColumnsColor || "",
          partnerColumn: response.data.data.businessPartnerColumnsColor || "",
          nakadColumn: response.data.data.nakadColumnsColor || "",
        });
      },
    });
  };
  const getDefaultDownloadSettings = () => {
    useFetch("/api/recon/GetDefaultDownloadSettings", "GET", {
      thenCallBack: (response) => {
        setDefaultResultFileState({
          reconciliationSummary: response.data.data.ReconciliationSummaryAndAnnexures || "",
          internalSummary: response.data.data.InternalSummaryAndOwnVsPartner || "",
          // fullLedgers: response.data.data.FullLedgers || "",
          ownLedger: response.data.data.OwnLedger || "",
          partnerLedger: response.data.data.PartnerLedger || "",
          help: response.data.data.Help || "",
          ledgerSummary: response.data.data.LedgerSummary || "",
          qcCsUsers: response.data.data.QcSheets || "Show",
        });
      },
    });
  };

  const getExtraColumnsInExcelSheet = () => {
    useFetch("/api/recon/GetExtraColumnsInExcelSheet", "GET", {
      thenCallBack: (response) => {
        // setRowsOwnVsPartner(response.data.data?.ownVsBpSheet || []);
        setReconciliationRows(response.data.data?.reconciliationSummarySheet || []);
      },
    });
  };

  const handleInputChange = (event, stateKey) => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      [stateKey]: event.target.value,
    }));
  };

  // Handle changes for defaultResultFile dynamically
  const handleDropdownChange = (event, stateKey) => {
    setDefaultResultFileState((prevState) => ({
      ...prevState,
      [stateKey]: event.target.value,
    }));
  };

  //  Reconciliation Summary

  const addReconciliationRow = () => {
    setReconciliationRows([...reconciliationRows, { columnName: "" }]);
  };

  const deleteReconciliationRow = (index) => {
    const updatedRows = reconciliationRows.filter((_, idx) => idx !== index);
    setReconciliationRows(updatedRows);
  };

  return (
    <div>
      {/* Customise Column Header Colors */}

      <Grid>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Customise Column Header Colors
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid container spacing={2}>
              {columnConfig.map(({ label, stateKey }) => (
                <Grid item container spacing={1} alignItems="center" key={stateKey}>
                  <Grid item style={{ width: "260px" }}>
                    <ListItem className="col_header_name">{label}</ListItem>
                  </Grid>
                  <Grid item style={{ width: "160px" }}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      className="update-custom-border-radius"
                      value={columns[stateKey]}
                      onChange={(e) => handleInputChange(e, stateKey)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconUpdateColumnsColor ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={updateColumnsColor}
              startIcon={<LoadingIcon loading={showLoadingIconUpdateColumnsColor} />}
              disabled={showLoadingIconUpdateColumnsColor}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>

      {/*   Default Result File */}

      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Default Result File
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid container>
              <Grid item container alignItems="center" sx={{ mb: 2, background: "#F3F3F3" }}>
                <Grid item xs={10.5}>
                  <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                    Sheet Name
                  </ListItem>
                </Grid>
                <Grid item xs={1.5}>
                  <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                    Action
                  </ListItem>
                </Grid>
              </Grid>
              {defaultResultFile.map(({ label, stateKey }) => (
                <Grid item container alignItems="center" key={stateKey} sx={{ pb: 1 }}>
                  <Grid item xs={10.5}>
                    <ListItem className="col_header_name">{label}</ListItem>
                  </Grid>
                  <Grid item xs={1.5}>
                    <FormControl fullWidth className="update-custom-border-radius" sx={{ pr: 2 }}>
                      <Select
                        fullWidth
                        size="small"
                        value={defaultResultFileState[stateKey]}
                        onChange={(e) => handleDropdownChange(e, stateKey)}
                      >
                        <MenuItem value="Show">Show</MenuItem>
                        <MenuItem value="Hide">Hide</MenuItem>
                        <MenuItem value="Remove">Remove</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconSetCustomResult ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={setCustomResult}
              startIcon={<LoadingIcon loading={showLoadingIconSetCustomResult} />}
              disabled={showLoadingIconSetCustomResult}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>

      {/*    Own Vs Partner */}

      {/* <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Own Vs Partner
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid
              container
              spacing={0}
              sx={{ pl: 2, pr: 2, fontWeight: 600, mb: 1, background: "#F3F3F3", pt: "4px", pb: "4px" }}
              className="col_header_name"
            >
              Header Row
              <Grid item xs={1.5} sx={{ textAlign: "center", pr: 2 }}>
                Column No.
              </Grid>
              <Grid item xs={2} sx={{ pr: 2 }}>
                Column Type
              </Grid>
              <Grid item xs={3} sx={{ pr: 2 }}>
                Column Name
              </Grid>
              <Grid item xs={4.5}>
                Formula
              </Grid>
              <Grid item xs={1} sx={{ textAlign: "center" }}>
                Action
              </Grid>
            </Grid>

            Data Rows
            {rowsOwnVsPartner.map((row, index) => (
              <Grid
                container
                alignItems="center"
                key={index}
                sx={{
                  // mt: 1,
                  pt: 1,
                  pb: 1,
                  pl: 2,
                  pr: 2,
                  // backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'transparent',
                }}
              >
                <Grid item xs={1.5} sx={{ textAlign: "center", pr: 2 }}>
                  {row.columnNo}
                </Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                  <Select
                    fullWidth
                    size="small"
                    value={row.columnType}
                    onChange={(e) => handleRowChange(index, "columnType", e.target.value)}
                    displayEmpty
                    style={{ borderRadius: "4px" }}
                  >
                    <MenuItem value="" disabled>
                      Select Column Type
                    </MenuItem>
                    {columnTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3} sx={{ pr: 2 }}>
                  {row.columnType === "Custom" ? (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.columnName}
                      onChange={(e) => handleRowChange(index, "columnName", e.target.value)}
                      className="update-custom-border-radius"
                    />
                  ) : (
                    <Select
                      fullWidth
                      size="small"
                      value={row.columnName}
                      onChange={(e) => handleRowChange(index, "columnName", e.target.value)}
                      displayEmpty
                      style={{ borderRadius: "4px" }}
                    >
                      <MenuItem value="" disabled>
                        Select Column Name
                      </MenuItem>
                      {(columnNames[row.columnType] || []).map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
                <Grid item xs={4.5}>
                  {row.columnType === "Custom" ? (
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.formula}
                      onChange={(e) => handleRowChange(index, "formula", e.target.value)}
                      className="update-custom-border-radius"
                    />
                  ) : (
                    <Typography className="NA_text">Not Applicable</Typography>
                  )}
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                  <IconButton onClick={() => clearRow(index)}>
                    <DeleteIcon sx={{ color: "#D32F2F" }} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid sx={{ p: 2 }}>
              <Button sx={{ mt: 2 }} onClick={addRow} startIcon={<AddIcon />}>
                Add New Column
              </Button>
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconAddColumnsInOwnVsBpSheet ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={addColumnsInOwnVsBpSheet}
              startIcon={<LoadingIcon loading={showLoadingIconAddColumnsInOwnVsBpSheet} />}
              disabled={showLoadingIconAddColumnsInOwnVsBpSheet}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid> */}

      {/*   Annexures */}
      {/* design not finalized yet */}
      {false && (
        <Grid sx={{ mt: 2 }}>
          <Paper>
            <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
              Annexures
            </Typography>
            <SectionTable
              sectionName="Invoice Amount Mismatch"
              rows={annexuresSections.invoiceAmountMismatch}
              setRows={(newRows) => updateRows("invoiceAmountMismatch", newRows)}
              columnTypes={columnTypes}
              columnNames={columnNames}
            />
            <SectionTable
              sectionName="Invoice not present in Partner ledger"
              rows={annexuresSections.invoiceNotPresentPartnerLedger}
              setRows={(newRows) => updateRows("invoiceNotPresentPartnerLedger", newRows)}
              columnTypes={columnTypes}
              columnNames={columnNames}
            />
            <SectionTable
              sectionName="Invoice not present in Own ledger"
              rows={annexuresSections.invoiceNotPresentOwnLedger}
              setRows={(newRows) => updateRows("invoiceNotPresentOwnLedger", newRows)}
              columnTypes={columnTypes}
              columnNames={columnNames}
            />
            <SectionTable
              sectionName="DN/CN not present in Own Ledger"
              rows={annexuresSections.dnCnNotPresentOwnLedger}
              setRows={(newRows) => updateRows("dnCnNotPresentOwnLedger", newRows)}
              columnTypes={columnTypes}
              columnNames={columnNames}
            />
            <SectionTable
              sectionName="Suggested TDS gap in invoice/payment/DN/CN value"
              rows={annexuresSections.suggestedTdsGap}
              setRows={(newRows) => updateRows("suggestedTdsGap", newRows)}
              columnTypes={columnTypes}
              columnNames={columnNames}
            />
            <SectionTable
              sectionName="Adjustment Of Miscellaneous Entries in Partner Ledger"
              rows={annexuresSections.adjustmentMiscellaneousEntries}
              setRows={(newRows) => updateRows("adjustmentMiscellaneousEntries", newRows)}
              columnTypes={columnTypes}
              columnNames={columnNames}
            />
            <Grid sx={{ p: 2 }} className="right_align">
              <Button className="green_btn" sx={{ width: "140px" }} onClick={() => console.log("Save logic")}>
                Save
              </Button>
            </Grid>
          </Paper>
        </Grid>
      )}

      {/*  Reconciliation Summary */}

      <Grid sx={{ mt: 2 }}>
        <Paper>
          <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
            Reconciliation Summary
          </Typography>
          <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
            <Grid container>
              <Grid item container alignItems="center" sx={{ mb: 2, background: "#F3F3F3" }}>
                <Grid item xs={10.5}>
                  <ListItem className="col_header_name" sx={{ pt: "4px", pb: "4px", fontWeight: 600 }}>
                    Column Name
                  </ListItem>
                </Grid>
                <Grid item xs={1.5}>
                  <ListItem
                    className="col_header_name"
                    sx={{ pt: "4px", pb: "4px", fontWeight: 600, justifyContent: "center" }}
                  >
                    Action
                  </ListItem>
                </Grid>
              </Grid>
              {reconciliationRows.map((row, index) => (
                <Grid item container alignItems="center" key={index} sx={{ pb: 1 }}>
                  <Grid item xs={10.5}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={row.columnName}
                      onChange={(e) => {
                        const updatedRows = [...reconciliationRows];
                        updatedRows[index].columnName = e.target.value;
                        setReconciliationRows(updatedRows);
                      }}
                      sx={{ maxWidth: "400px", pl: 2 }}
                      className="update-custom-border-radius"
                    />
                  </Grid>
                  <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => deleteReconciliationRow(index)}>
                      <DeleteIcon sx={{ color: "#D32F2F" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid sx={{ p: 2 }}>
              <Button sx={{ mt: 2 }} onClick={addReconciliationRow} startIcon={<AddIcon />}>
                Add New Column
              </Button>
            </Grid>
          </Box>
          <Grid sx={{ p: 2 }} className="right_align">
            <Button
              className={showLoadingIconAddColumnsInReconciliationSummary ? "grey_btn" : "green_btn"}
              sx={{ width: "140px" }}
              onClick={addColumnsInReconciliationSummary}
              startIcon={<LoadingIcon loading={showLoadingIconAddColumnsInReconciliationSummary} />}
              disabled={showLoadingIconAddColumnsInReconciliationSummary}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default ReconOutput;

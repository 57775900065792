import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  createFilterOptions,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";

const options: any[] = [];
const filter = createFilterOptions<any>();

const UserEmailSetting = (props: any) => {
  const [filePayload, setFilePayload] = useState<any>(undefined);
  const [errDisplayName, setErrDisplayName] = useState<boolean>(false);
  const [errReplyToEmailAddress, setErrReplyToEmailAddress] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [disableReplyTo, setDisableReplyTo] = useState<boolean>(true);
  const [clientOtherEmailIdsOrig, setClientOtherEmailIdsOrig] = useState<string[]>([]);
  const [clientOtherEmailIds, setClientOtherEmailIds] = useState<string[]>([]);

  useEffect(() => {
    getClientOtherEmails();
  }, []);

  const uploadSignatureImage = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append("image", filePayload);
    await useFetch("/api/UploadSignatureImage", "POST", {
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      thenCallBack: () => {
        updateUserEmailSetting();
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };
  const updateUserEmailSetting = async () => {
    await useFetch("/api/UpdateUserEmailSetting", "PUT", {
      showSuccessToast: true,
      data: {
        name: props.emailDisplayName,
        email: props.fromEmailAddress,
        appPassword: props.password,
        replyToEmailAddress: props.replyToEmailAddress,
        signature: props.signatureName,
        emailCredentialsProvided: props.emailCredentialsProvided,
        smtpHost: props.smtpHost,
        smtpHostName: props.emailSMTPHostUrl,
      },
      thenCallBack: () => {
        setShowLoadingIcon(false);
        handleClose();
        props.setSmtpHost(null);
        props.setEmailSMTPHostUrl(null);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  const saveUserEmailSetting = async () => {
    if (props.emailDisplayName.trim() === "") {
      setErrDisplayName(true);
    }
    if (props.replyToEmailAddress.length === 0) {
      setErrReplyToEmailAddress(true);
    }

    if (props.emailDisplayName.trim() !== "" && props.replyToEmailAddress.length > 0) {
      setShowLoadingIcon(true);
      uploadSignatureImage();
    }
  };

  const handleClose = () => {
    props.setUserEmailID("");
    props.setPassword("");
  };

  const getClientOtherEmails = () => {
    useFetch<{ clientOtherEmailIds: string[] }>("/api/partnerCommunication/GetClientOtherEmailIds", "GET", {
      thenCallBack: (_res) => {
        setClientOtherEmailIds(_res.data.clientOtherEmailIds || []);
        setClientOtherEmailIdsOrig(_res.data.clientOtherEmailIds || []);
      },
    });
  };

  const addClientOtherEmails = () => {
    if (
      clientOtherEmailIds.every((item) => clientOtherEmailIdsOrig.includes(item)) &&
      clientOtherEmailIdsOrig.every((item) => clientOtherEmailIds.includes(item))
    )
      return;
    useFetch("/api/partnerCommunication/AddClientOtherEmailIds", "POST", {
      showSuccessToast: true,
      data: {
        clientOtherEmailIds: clientOtherEmailIds,
      },
      thenCallBack: (_res) => {},
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 5 }}>
        <Card style={{ minWidth: "700px" }}>
          <CardContent>
            <Grid className="m_15 mb_40">
              <p className="fs_24 fw_600 text_center">User E-Mail Setting</p>
            </Grid>
            <Grid>
              <Grid className="d_flex mt_15">
                <Grid className="width_250 vertical_center_align p_10">
                  <label className="addBpUser_label">Email Display Name :</label>
                </Grid>
                <Grid className="w_300 vertical_center_align">
                  <TextField
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    label="Email Display Name"
                    name="emailDisplayName"
                    error={errDisplayName}
                    helperText={errDisplayName ? "please enter name" : ""}
                    value={props.emailDisplayName}
                    onChange={(e) => {
                      props.setEmailDisplayName(e.target.value);
                      if (e.target.value.trim() !== "") {
                        setErrDisplayName(false);
                      } else {
                        setErrDisplayName(true);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className="d_flex mt_15">
                <Grid className="width_250 vertical_center_align p_10">
                  <label className="addBpUser_label">"From" Email address :</label>
                </Grid>
                <Grid className="w_300 vertical_center_align">
                  <TextField
                    disabled={true}
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    label="From Email address"
                    name="fromEmailAddress"
                    value={props.fromEmailAddress}
                    onChange={(e) => {
                      props.setFromEmailAddress(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid className="d_flex mt_15">
                <Grid className="width_250 vertical_center_align p_10">
                  <label className="addBpUser_label">"Reply to" email address :</label>
                </Grid>
                <Grid className="vertical_center_align replyToEmailAddress">
                  <span className="w_300">
                    <Autocomplete
                      fullWidth={true}
                      disabled={disableReplyTo}
                      value={props.replyToEmailAddress}
                      onChange={(_, value) => {
                        if (value.length > 0) {
                          if (value[value.length - 1].includes("Add => ")) {
                            value[value.length - 1] = value[value.length - 1].replace('Add => "', "");
                            value[value.length - 1] = value[value.length - 1].replace('"', "");
                            const removeEmptyVal = value.filter((el) => {
                              return el !== "";
                            });
                            const uniqArr: any = [...new Set(removeEmptyVal)];
                            value = uniqArr;
                          }
                        }

                        const validEmails = value.filter((email) =>
                          email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
                        );
                        props.setReplyToEmailAddress(validEmails);
                        value.forEach((email) => {
                          if (!email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
                            toast.error(<CustomToast message={`${email} is not a valid email`} />);
                          }
                        });
                      }}
                      noOptionsText={"Add a new email"}
                      multiple={true}
                      id="tags-outlined"
                      options={options}
                      getOptionLabel={(option) => option}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => {
                          return (
                            <Chip
                              key={index}
                              label={option}
                              {...getTagProps({ index })}
                              disabled={option === props.fromEmailAddress && props.emailCredentialsProvided}
                            />
                          );
                        });
                      }}
                      filterSelectedOptions={true}
                      filterOptions={(_, params) => {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          setErrReplyToEmailAddress(false);
                          filtered.push(`Add => "${params.inputValue.trim()}"`);
                        }
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reply to email address"
                          placeholder="Enter email"
                          required={true}
                          error={errReplyToEmailAddress}
                          helperText={errReplyToEmailAddress ? "please enter valid email" : ""}
                          onKeyDown={(event: any) => {
                            if (event.key === "Backspace") {
                              event.stopPropagation();
                            }
                          }}
                        />
                      )}
                    />
                  </span>
                  <Button
                    className="theme_btn"
                    onClick={() => {
                      setDisableReplyTo(false);
                    }}
                    style={{ minWidth: "40px", height: "25px", marginLeft: "10px" }}
                  >
                    +
                  </Button>
                </Grid>
              </Grid>
              <Grid className="d_flex mt_15">
                <Grid className="width_250  p_10">
                  <label className="addBpUser_label">Signature :</label>
                </Grid>
                <Grid className="w_300 ">
                  <div>
                    <div className="mt_15">
                      <TextareaAutosize
                        minRows={2}
                        maxRows={4}
                        aria-label="maximum height"
                        placeholder="Signature Name"
                        style={{ width: "280px", padding: "10px" }}
                        value={props.signatureName}
                        onChange={(e) => {
                          props.setSignatureName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mt_15">
                      <Button variant="contained" component="label" className="theme_btn">
                        Upload Your Signature
                        <input
                          hidden={true}
                          accept="image/png, image/jpeg, image/jpg"
                          multiple={true}
                          type="file"
                          onChange={(e) => {
                            setFilePayload(e.target.files[0]);
                            props.setFile(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                      </Button>
                    </div>
                    <div className="mt_15">
                      {props.file && <img src={props.file} style={{ width: "180px", height: "80px" }} />}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="space_between mt_30">
              <div>
                {props.emailCredentialsProvided ? (
                  <>
                    <Button disabled={true} className="theme_outline_btn">
                      Official email is authenticated
                    </Button>
                    <Button
                      className="theme_btn ml_10"
                      onClick={() => {
                        props.setOpenSelectEmailDomain(true);
                      }}
                    >
                      Reset Password
                    </Button>
                  </>
                ) : (
                  <Button
                    className="theme_outline_btn"
                    onClick={() => {
                      props.setOpenSelectEmailDomain(true);
                    }}
                  >
                    Send email from official email ID
                  </Button>
                )}
              </div>
              <div>
                <Button
                  className="theme_btn"
                  onClick={() => {
                    saveUserEmailSetting();
                  }}
                  disabled={showLoadingIcon}
                >
                  {<LoadingIcon loading={showLoadingIcon} />}
                  Save
                </Button>
              </div>
            </Grid>
          </CardContent>
        </Card>
        {/* Client's Other Emails */}
        <Card>
          <CardContent>
            <Grid className="m_15 mb_40">
              <p className="fs_24 fw_600 text_center">Add Company's Side Emails</p>
              <Typography variant="subtitle2">
                Add Your company's side Emails here, to distinguish it from partner Emails.
              </Typography>
            </Grid>
            <Grid>
              <Autocomplete
                onChange={(_event, value) => {
                  if (value.length > 0) {
                    if (value[value.length - 1].includes("Add a new Email => ")) {
                      value[value.length - 1] = value[value.length - 1].replace('Add a new Email => "', "");
                      value[value.length - 1] = value[value.length - 1].replace('"', "");
                      const removeEmptyVal = value.filter((el) => {
                        return el !== "";
                      });
                      value = removeEmptyVal;
                    }
                  }
                  const validEmails = value.filter((email) =>
                    email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
                  );
                  value.forEach((email) => {
                    if (!email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
                      toast.error(<CustomToast message={`${email} is not a valid email`} />);
                    }
                  });

                  setClientOtherEmailIds(validEmails);

                  addClientOtherEmails();
                }}
                value={clientOtherEmailIds}
                onBlur={addClientOtherEmails}
                noOptionsText={"Add a new Email"}
                multiple={true}
                id="email-tags-outlined"
                options={clientOtherEmailIds}
                getOptionLabel={(option) => option}
                // defaultValue={[options[13]]}
                filterSelectedOptions={true}
                filterOptions={(_optionsFilter, params) => {
                  const filtered = filter(clientOtherEmailIds, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push(`Add a new Email => "${params.inputValue.trim()}"`);
                  }
                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Enter Email" placeholder="Enter Email" autoFocus={true} />
                )}
                sx={{
                  "& span": {
                    fontSize: "inherit",
                  },
                }}
              />
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default UserEmailSetting;

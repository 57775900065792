import { Alert, Box, Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useHistory, useLocation } from "react-router";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import useFetch from "src/Components/Common/useFetch";
import SubmitContactDetails, { ContactDetailsData } from "../Common/SubmitContactDetailsDialog";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import { NdAsyncButton, NdCheckbox } from "../PartnerPortal/CommonComponents";
import $ from "../PartnerPortal/PartnerPortal.module.scss";
import { StateDispatch } from "../ReplyEmail";
import {
  BcCustomisationDetails,
  BcPortalDetails,
  CommonUploaderBox,
  ConfirmationRequiredDialog,
} from "./BalanceConfirmationPortalCommon";
import { BcPortalContext } from "./BalanceConfirmationPortalContext";
import { RaiseRequestDialog } from "./FinalPage";

interface ConfirmBalanceDialogProps {
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  cbDate: string;
  ruNameAndCompany: string;
  ruName: string;
  bpName: string;
  customization: BcCustomisationDetails;
  UpdateBalanceConfirmationStatus: (confirmed: boolean) => Promise<boolean>;
}

export const ConfirmBalanceDialog = ({
  openDialog,
  setOpenDialog,
  cbDate,
  ruNameAndCompany,
  ruName,
  bpName,
  customization,
  ...props
}: ConfirmBalanceDialogProps) => {
  const location = useLocation<any>();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const { confirmStatus, setPageStatus } = useContext(BcPortalContext);

  const [closingBalanceFromData, setClosingBalanceFromData] = useState<number>(null);
  const [closingBalance, setClosingBalance] = useState<number>(null);
  const [currency, setCurrency] = useState<string>("INR");
  const [closingBalanceRu, setClosingBalanceRu] = useState<number>(null);
  const [accountingSign, setAccountingSign] = useState<"R" | "P">(null);
  const [balance, setBalance] = useState<number>((closingBalance as number) || 0);
  const setClosingBalanceRef = useRef<number>(null);
  const [cbError, setCbError] = useState<boolean>(false);

  const [completed, setCompleted] = useState({
    signedPdf: true,
    ledgerFile: customization.isLedgerRequired ? false : true,
  });

  const [step1Completed, setStep1Completed] = useState<0 | 1>(0);
  const [step2Checked, setStep2Checked] = useState(false);

  const [formDetails, setFormDetails] = useState<ContactDetailsData>({} as ContactDetailsData);
  const [openSubmitDetailsDialog, setOpenSubmitDetailsDialog] = useState<boolean>(false);
  const [openConfirmationRequiredDialog, setOpenConfirmationRequiredDialog] = useState<boolean>(false);
  const [issues, setIssues] = useState<RaisedIssueBc[]>([]);
  const [openRaiseRequestDialog, setOpenRaiseRequestDialog] = useState<boolean>(false);

  useEffect(() => {
    if (
      (completed.signedPdf || !customization?.isPdfRequired) &&
      (completed.ledgerFile || !customization?.isLedgerRequired)
    ) {
      setStep1Completed(1);
    } else setStep1Completed(0);
  }, [completed, closingBalance, confirmStatus]);

  useEffect(() => {
    getDetailsForUploadingPage();
    if (openDialog) {
      setClosingBalance(typeof closingBalance === "number" && confirmStatus ? closingBalanceFromData : null);
      setCbError(!confirmStatus && !Number.isFinite(closingBalance));
      setCompleted({
        signedPdf: true,
        ledgerFile: customization.isLedgerRequired ? false : true,
      });
      if (customization?.isPdfRequired) {
        setPageStatus("DetailsFilled");
        setOpenDialog(false);
      }
    }
  }, [openDialog, confirmStatus, customization]);

  useEffect(() => {
    let bal = Number(closingBalance) || 0;

    bal = bal >= 0 ? bal : -1 * bal;

    if (closingBalance === null) setBalance(null);
    else setBalance(bal);

    handleClosingBalanceChange(closingBalanceFromData, closingBalance);
  }, [closingBalance]);

  useEffect(() => {
    if (closingBalance !== null) setAccountingSign(closingBalance > 0 ? "R" : "P");
    else setAccountingSign(closingBalanceRu >= 0 ? "P" : "R");
  }, [closingBalance, closingBalanceRu]);

  const getDetailsForUploadingPage = () =>
    useFetch<{ details: BcPortalDetails }>("/api/BalanceConfirmationPortal/GetDetailsForUploadingPage", "GET", {
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const { details } = res.data;
        {
          setCurrency(details.currency ? details.currency : "INR");
          setClosingBalance(typeof details.closingBalanceBp === "number" ? details.closingBalanceBp : null);
          setClosingBalanceFromData(details.closingBalanceBp);
          setClosingBalanceRu(typeof details.closingBalanceOwn === "number" ? details.closingBalanceOwn : null);
        }
      },
    });

  const handleClosingBalanceChange = (oldCbValue: number, newCbValue: number) => {
    if (oldCbValue === null && newCbValue === null) return;

    setCbError(!Number.isFinite(newCbValue));

    if (!Number.isFinite(newCbValue)) return;
    if (oldCbValue === newCbValue) return;

    useFetch("/api/BalanceConfirmationPortal/updateBusinessPartnerClosingBalance", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        closingBalance: newCbValue,
      },
      thenCallBack: () => {
        setClosingBalance(newCbValue);
        setClosingBalanceFromData(newCbValue);
      },
      catchCallBack: () => {
        setClosingBalance(oldCbValue);
      },
      errorCallback: () => {
        setClosingBalance(oldCbValue);
      },
    });
  };

  const afterSubmitDetails = async (_formDetails: ContactDetailsData) => {
    setFormDetails(_formDetails);
    handleFinalSubmit(_formDetails || formDetails);
  };

  const handleFinalSubmit = (_formDetails: ContactDetailsData) => {
    useFetch("/api/BalanceConfirmationPortal/ConfirmFinalSubmission", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        isBalanceConfirmed: confirmStatus,
        personName: _formDetails.personName,
        designation: _formDetails.designation,
        email: _formDetails.email,
        phoneNumber: _formDetails.phoneNumber,
      },
      thenCallBack: () => {
        setPageStatus("FinalPage");
        clarity.setTag("BalanceConfirmationPortal", `End`);
        window.clarity("event", "BalanceConfirmationPortal-End");

        // clear edit response param if first time submitting
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("editResponse");

        // useEffect(() => {
        history.push({
          pathname: pathname,
          search: searchParams.toString(),
        });
        // }, []);
      },
    });
  };

  const getAllPreviouslyRaisedIssues = async () => {
    return new Promise<RaisedIssueBc[]>((resolve, reject) =>
      useFetch<{ previousRequest: RaisedIssueBc[] }>("/api/BalanceConfirmationPortal/GetPreviouslyRaisedIssue", "GET", {
        config: {
          params: {
            data: encryptedData,
          },
        },
        thenCallBack: (response) => {
          const { previousRequest } = response.data;
          if (previousRequest) {
            setIssues(previousRequest);
            resolve(previousRequest);
          }
          resolve([]);
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`Confirmation of balance as on ${cbDate} for ${ruNameAndCompany}`}</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3, gap: 2 }}>
          <Stack className="cb_box" gap={2}>
            <Typography py={2}>
              Closing Balance in {bpName} ledger, as on {cbDate} for {ruName}
            </Typography>
            <Box className="d_flex" gap={2}>
              <TextField
                className={$.BR_fix}
                sx={{
                  width: 186,
                  "& *.MuiInputBase-root": {
                    borderRadius: "4px !important",
                  },
                }}
                disabled={true}
              />
              <MonetaryInput
                fullWidth
                key={"closingBalance"}
                className={$.BR_fix}
                placeholder="Enter Closing Balance"
                label="Enter Closing Balance"
                returnNull={true}
                value={balance}
                allowNegative={false}
                setValue={(value: number) => {
                  if (value === null) setClosingBalance(null);
                  else setClosingBalance(accountingSign === "R" ? value : -1 * value);
                  setClosingBalanceRef.current = accountingSign === "R" ? value : -1 * value;
                }}
                // onBlur={(_e, value) => {
                //   handleClosingBalanceChange(closingBalanceFromData, value);
                // }}
                disabled={confirmStatus}
                focused={false}
                autoFocus={true}
                // inputRef={(input) => input && input.focus()}
                error={cbError}
                helperText={cbError ? "Please enter Closing Balance" : ""}
                currency={"INR"}
                sx={{
                  "input::placeholder": {
                    fontSize: "14px",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px !important",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <>
                      <TextField
                        select
                        className={$.BR_fix}
                        value={accountingSign}
                        onChange={(e) => {
                          const validBalance = closingBalance;

                          setAccountingSign(e.target.value as any);

                          if (e.target.value === "R") {
                            setClosingBalance(validBalance >= 0 ? validBalance : -1 * validBalance);
                            setClosingBalanceRef.current = validBalance >= 0 ? validBalance : -1 * validBalance;
                          } else {
                            setClosingBalance(validBalance <= 0 ? validBalance : -1 * validBalance);
                            setClosingBalanceRef.current = validBalance <= 0 ? validBalance : -1 * validBalance;
                          }
                        }}
                        sx={{
                          minWidth: 150,
                          position: "absolute",
                          left: -166,
                          "& *.MuiInputBase-root": {
                            borderRadius: "4px !important",
                          },
                        }}
                        disabled={confirmStatus}
                      >
                        <MenuItem value={"R"}>Receivable</MenuItem>
                        <MenuItem value={"P"}>Payable</MenuItem>
                      </TextField>
                      <Typography sx={{ marginRight: 2 }}>{currency}</Typography>
                    </>
                  ),
                }}
              />
            </Box>
            {(Number(closingBalanceRu) > 0 && accountingSign === "R") ||
            (Number(closingBalanceRu) < 0 && accountingSign === "P") ? (
              <Alert className={$.BR_fix} severity="warning" sx={{ "& *": { fontFamily: "inherit" } }}>
                You have changed the sign of Closing Balance amount, please make sure if it is correct
              </Alert>
            ) : (
              <></>
            )}
            {!confirmStatus && Math.abs(Math.abs(closingBalance) - Math.abs(closingBalanceRu)) < 5 && (
              <Alert
                className={$.BR_fix}
                severity="error"
                sx={{ "& *": { fontFamily: "inherit" } }}
                action={
                  <Button
                    className={$.BR_fix}
                    variant="outlined"
                    color="error"
                    sx={{ minWidth: 175 }}
                    onClick={() => {
                      props.UpdateBalanceConfirmationStatus(true);
                      if (customization?.isPdfRequired) setPageStatus("DetailsFilled");
                      else setOpenDialog(true);
                    }}
                  >
                    CONFIRM BALANCE
                  </Button>
                }
              >
                You have entered the exact same Closing Balance. Please click <b>‘Confirm Balance’</b> button if your
                Closing Balance is matching.
              </Alert>
            )}
          </Stack>
          <Stack className="uploader_box" gap={2}>
            <Typography variant="subtitle1">Upload the following documents </Typography>
            <CommonUploaderBox fileType="ledgerFile" setCompleted={setCompleted} customization={customization} />
          </Stack>
          <Stack className="checkbox_area" gap={2} pt={2}>
            <Alert className={$.BR_fix} severity="warning">
              <Typography variant="body2">Note: You cannot change your response once submitted</Typography>
            </Alert>
            <FormControlLabel
              control={
                <NdCheckbox
                  checked={step2Checked && Boolean(step1Completed) && Number.isFinite(closingBalance)}
                  onChange={() => setStep2Checked(!step2Checked)}
                  disabled={Boolean(!step1Completed) || !Number.isFinite(closingBalance)}
                />
              }
              label="I confirm that given Closing Balance and Date matches as per my books and the uploaded ledgers/files are correct and complete"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdAsyncButton
            autoFocus
            variant="contained"
            onClick={async () => {
              if (confirmStatus) {
                const _issues = (await getAllPreviouslyRaisedIssues()) || issues;
                if (_issues?.length > 0) setOpenConfirmationRequiredDialog(true);
                else setOpenSubmitDetailsDialog(true);
              } else setOpenSubmitDetailsDialog(true);
            }}
            disabled={
              Boolean(!step2Checked) ||
              !Number.isFinite(closingBalance) ||
              (!confirmStatus && Math.abs(Math.abs(closingBalance) - Math.abs(closingBalanceRu)) < 5)
            }
          >
            Submit
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
      <SubmitContactDetails
        openDialog={openSubmitDetailsDialog}
        setOpenDialog={setOpenSubmitDetailsDialog}
        afterSubmitAction={afterSubmitDetails}
      />
      <ConfirmationRequiredDialog
        openDialog={openConfirmationRequiredDialog}
        setOpenDialog={setOpenConfirmationRequiredDialog}
        nextStepAction={() => setOpenSubmitDetailsDialog(true)}
        viewQueryAction={async () => setOpenRaiseRequestDialog(true)}
      />
      <RaiseRequestDialog openDialog={openRaiseRequestDialog} setOpenDialog={setOpenRaiseRequestDialog} />
    </>
  );
};

import { Cached, FilterAlt, FilterAltOff, RestartAlt, Restore, Settings } from "@mui/icons-material";
import { Download, MailOutline, TaskAlt, Visibility } from "@mui/icons-material";
import { Autocomplete, Avatar, Box, Button, Grid, InputAdornment, ListItemIcon, MenuItem } from "@mui/material";
import { CircularProgress, createFilterOptions, IconButton, TextField, Tooltip } from "@mui/material";
import { Buffer } from "buffer";
import { Currency } from "dinero.js";
import { saveAs } from "file-saver";
import { MRT_Column, MRT_Row } from "material-react-table";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { Dialog } from "src/Components/Dialog/Dialog";
// import BpDashboardTable from "src/Components/ReactTable/BpDashboardTable";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomToast from "src/Components/CustomToast";
import {
  BulkMailStatusResponse,
  //   BcBetaMailThread,
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaRes,
  EmailSendCount,
  EmailTemplate,
  ListOwnTallyCompanies,
  ListPDFTemplatesResponse,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  MailThread,
  MsmeMailThread,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import {
  updateEmailResponseMsgBCB,
  updateIsSendingBulkMailsBCB,
  updateMailFailedUsersBCB,
  updateMailFailedWorkbookBCB,
  updateOpenBulkMailReportDialogBCB,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { Currencies } from "src/Utils/Common/Constants";
import { exists } from "src/Utils/Common/Validators";
import { filterDateBetweenStartAndEndDate } from "src/Utils/DateUtils";
import { DefaultCurrency, formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { BalanceConfirmationBetaColorMap, BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import ListBusinessPartnerUsers from "../../PartnerMaster/ListBusinessPartnerUsers";
import { TallyClosingBalanceForAllBpResponse } from "../BalanceConfirmation";
import BulkMailReportDialog from "../BulkMailReportDialog";
import { PageSelectModeToggle } from "../Common/CommonComponents";
import { ApproveResponseDialog, ResetStatusDialog } from "../Common/CommonDialogs";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
} from "../CommonComponents";
import CreateEmailTemplate from "../CreateEmailTemplate";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import MailThreadDialog from "../MailThreadDialog";
import "../PartnerCommunication.scss";
import { PartnerCommsContext } from "../PartnerCommunicationContext";
import SelectEmailTemplate from "../SelectEmailTemplate";
import SendEmailTemplateBeta from "../SendEmailTemplateBeta";
import BalanceConfirmationBetaFilter from "./BalanceConfirmationBetaFilter";
import BalanceConfirmationBetaSettings from "./BalanceConfirmationBetaSettings";
import BalanceConfirmationHistory from "./BalanceConfirmationHistory";
import PartnerRequests from "./PartnerRequests";
// import BcBetaConfirmationFilter from "./BcBetaConfirmationFilter";

let intervalId: ReturnType<typeof setTimeout>;
export enum BcBetaFileType {
  confirmationDocument = "confirmationDocument",
  ledgers = "ledgers",
}

interface IBcBetaContext {
  rowsDataBcBeta: BusinessPartnerListForBcBeta[];
  storeRowsDataBcBeta: BusinessPartnerListForBcBeta[];
  setRowsDataBcBeta: StateDispatch<BusinessPartnerListForBcBeta[]>;
  setStoreRowsDataBcBeta: StateDispatch<BusinessPartnerListForBcBeta[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const BcBetaContext = createContext<IBcBetaContext>(null);
const filter = createFilterOptions<any>();

export const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: ` `,
      businessPartnerId: 0,
      category: [],
      status:
        BalanceConfirmationBetaStatuses[
          Object.keys(BalanceConfirmationBetaStatuses)[
            Math.floor(Math.random() * Object.keys(BalanceConfirmationBetaStatuses).length)
          ]
        ],
      ledgers: false,
      signedPdf: false,
      vendorCode: " ",
      location: " ",
      currency: "INR",
      ownClosingBalance: null,
      businessPartnerClosingBalance: null,
      closingBalanceDifference: null,
      closingBalanceDate: undefined,
      statusOverview: " ",
      balanceConfirmationStatusWithReminderCount: "1st Reminder Sent",
    } as BusinessPartnerListForBcBeta);
  }
  return dataArray;
};

const MuiSliderFilterSteps = (column: MRT_Column<BusinessPartnerListForBcBeta>) => {
  const minMaxValues = column.getFacetedMinMaxValues();

  let step = 1000;
  if (minMaxValues?.length > 0) {
    const maxVal = minMaxValues[1];
    const minVal = minMaxValues[0];
    const calculatedStep = Math.floor((maxVal - minVal) / 20);
    step = calculatedStep ? calculatedStep : step;
  }

  return {
    //no need to specify min/max/step if using faceted values
    marks: true,
    step: step,
  };
};

const BalanceConfirmationBeta = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [rowsDataBcBeta, setRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>(getBlankData());
  const [storeRowsDataBcBeta, setStoreRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [openBcBetaSettings, setOpenBcBetaSettings] = useState<boolean>(false);
  const [openBcBetaFilter, setOpenBcBetaFilter] = useState<boolean>(false);
  const [openBcBetaHistory, setOpenBcBetaHistory] = useState<boolean>(false);
  const storeAllCategories = useRef<string[]>([]);
  const storeBcBetaData = useRef<BusinessPartnerListForBcBeta[]>([]);
  const [isFilterAppliedBcBeta, setIsFilterAppliedBcBeta] = useState<boolean>(false);
  const [tempLoader, setTempLoader] = useState<boolean>(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  // ----//

  const [signatureImgBase64, setSignatureImgBase64] = useState<string>(null);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [lastSelectedTemplateID, SetLastSelectedTemplateID] = useState(0);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [mailLoading, setMailLoading] = useState(false);
  const storeClosingBalanceValueFlag = useRef<boolean>(false);
  const storeClosingBalanceDateFlag = useRef<boolean>(false);
  const storeClosingBalanceRowID = useRef<number>(null);
  const [storeClosingBalanceDateValue, setStoreClosingBalanceDateValue] = useState<any>(null);

  // Tally CB async
  const [bulkTallyCbDate, setBulkTallyCbDate] = useState<string>("");
  const [defaultTallyCbDate, setDefaultTallyCbDate] = useState<string>("");
  const [updatingTallyCbDate, setUpdatingTallyCbDate] = useState(false);
  const [loadingTallyCbAll, setLoadingTallyCbAll] = useState(false);
  const lastTallyInterval = useRef(null);

  const [openRaiseReq, setOpenRaiseReq] = useState<boolean>(false);
  const [raisedIssues, setRaisedIssues] = useState<
    { businessPartnerName: string; vendorCode: string; issues: RaisedIssueBc[] }[]
  >([]);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [approveSelectedRow, setApproveSelectedRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [openResetStatusDialog, setOpenResetStatusDialog] = useState(false);

  const [loadingPartnerRequests, setLoadingPartnerRequests] = useState(false);
  const [withoutTemplateCheck, setWithoutTemplateCheck] = useState(false);
  const [pageSelectMode, setPageSelectMode] = useState(false);

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
    setPdfTemplates,
  } = useContext(PartnerCommsContext);

  const {
    openBulkMailReportDialogBCB,
    isSendingBulkMailsBCB,
    emailResponseMsgBCB,
    mailFailedUsersBCB,
    mailFailedWorkbookBCB,
  } = useSelector((state: any) => state.bulkMailsStatus);

  const dispatch = useDispatch();

  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);
  // Email States END

  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(null);

  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(null);

  // ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<MRT_Row<BusinessPartnerListForBcBeta>>(null);

  // reset status
  const [downloadBcBetaReportBase64, setDownloadBcBetaReportBase64] = useState<string>(null);

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  const columnDefinitionBcBeta = useMemo(
    (): ColDef<BusinessPartnerListForBcBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        filterVariant: "autocomplete",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase() || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
              title={row.original.businessPartnerName}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.vendorCode || "",
        enableSorting: true,
        size: 200,
        filterVariant: "autocomplete",
      },
      {
        header: "Balance Confirmation Status",
        accessorFn: (row) => row?.status || "",
        size: 340,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <>
            {row.original.status ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: BalanceConfirmationBetaColorMap[row.original.status]?.color || "#000",
                    backgroundColor: BalanceConfirmationBetaColorMap[row.original.status]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.status === BalanceConfirmationBetaStatuses.ReminderSent
                    ? row.original.balanceConfirmationStatusWithReminderCount || row.original.status
                    : row.original.status}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Category",
        accessorFn: (row) => row.category?.join(", ") || "",
        size: 200,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
              loading={tempLoader}
            />
          </div>
        ),
      },
      {
        id: "closingBalanceDate",
        header: "Closing Balance date",
        Header: () => (
          <div style={{ font: "inherit" }}>
            Closing Balance date
            {actor.integration && (
              <div className="textField_height">
                <TextField
                  key="bulkCBDate"
                  type="date"
                  size="small"
                  disabled={updatingTallyCbDate}
                  defaultValue={bulkTallyCbDate || defaultTallyCbDate}
                  // onChange={(_e) => setBulkTallyCbDate(_e.target.value)}
                  onBlur={(_e) => {
                    setBulkTallyCbDate(_e.target.value);
                    if (_e.target.value && bulkTallyCbDate !== _e.target.value)
                      UpdateErpClosingBalanceForAllBp(_e.target.value);
                  }}
                  sx={{
                    div: { borderColor: "white !important", borderRadius: 16 },
                    input: { background: "white", p: "1px 8px", borderRadius: 16 },
                  }}
                  onClick={(_e) => _e.stopPropagation()}
                />
                {(updatingTallyCbDate || loadingTallyCbAll) && (
                  <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
                )}
              </div>
            )}
          </div>
        ),
        accessorFn: (row) => new Date(row.closingBalanceDate || null),
        enableSorting: true,
        minSize: 280,
        size: 350,
        maxSize: 360,
        filterVariant: "date-range",
        // filterFn: "betweenInclusive",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.closingBalanceDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original?.closingBalanceDate?.toString()}
              variant="outlined"
              disabled={
                isSendingBulkMailsBCB ||
                (actor.integration
                  ? loadingTallyCbAll
                  : row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation)
              }
              // placeholder="yyyy-mm-dd"
              type="date"
              size="small"
              defaultValue={
                storeClosingBalanceDateFlag.current &&
                storeClosingBalanceRowID.current === row.original.businessPartnerId
                  ? storeClosingBalanceDateValue !== null
                    ? moment(storeClosingBalanceDateValue)?.format("YYYY-MM-DD")
                    : storeClosingBalanceDateValue
                  : row.original.closingBalanceDate !== null
                  ? moment(row.original.closingBalanceDate)?.format("YYYY-MM-DD")
                  : row.original.closingBalanceDate
              }
              onClick={() => {
                storeClosingBalanceDateFlag.current = true;
                storeClosingBalanceRowID.current = row.original.businessPartnerId;
                setStoreClosingBalanceDateValue(row.original.closingBalanceDate);
              }}
              onChange={(e) => {
                setStoreClosingBalanceDateValue(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (actor.integration === true) {
                    // updateClosingBalanceByClosingBalanceDate(row, e.target.value);
                    UpdateErpClosingBalance(row, e.target.value);
                    setBulkTallyCbDate("");
                  } else UpdateClosingBalanceDate(row, e.target.value);
                }
              }}
            />
          </div>
        ),
      },
      {
        id: "ownClosingBalance",
        header: "Closing Balance Own",
        Header: () => (
          <div style={{ font: "inherit" }}>
            Closing Balance Own
            {(updatingTallyCbDate || loadingTallyCbAll) && (
              <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
            )}
          </div>
        ),
        accessorFn: (row) => Number(row.ownClosingBalance),
        enableSorting: true,
        size: 250,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="textField_height">
            <MonetaryInput
              key={row.original.ownClosingBalance}
              returnNull={true}
              value={
                !isNaN(parseFloat(row.original.ownClosingBalance?.toString()))
                  ? parseFloat(row.original.ownClosingBalance?.toString())
                  : row.original.ownClosingBalance
              }
              // placeholder={row.original.ownClosingBalance as string}
              setValue={(value) => {
                if (row.original.ownClosingBalance === null && value === null) return;
                UpdateClosingBalance(row, value);
              }}
              disabled={
                isSendingBulkMailsBCB ||
                actor.integration ||
                row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ "&>*": { fontSize: "13px !important", pt: "2px" } }}>
                    {Currencies.find((cur) => cur.code === row.original.currency)?.code || DefaultCurrency.INR}
                  </InputAdornment>
                ),
              }}
              sx={{
                "input::placeholder": {
                  fontSize: "14px",
                },
              }}
              currency={(row.original.currency as Currency) || "INR"}
            />
          </div>
        ),
      },
      {
        header: "CB Partner",
        accessorFn: (row) => Number(row.businessPartnerClosingBalance),
        enableSorting: true,
        size: 200,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.businessPartnerClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      row.original.businessPartnerClosingBalance,
                      (row.original.currency as Currency) || "INR"
                    )
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "CB difference",
        accessorFn: (row) => Number(row.closingBalanceDifference),
        enableSorting: true,
        size: 200,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.closingBalanceDifference === null
                ? null
                : formatMoney(
                    ToDineroObj(row.original.closingBalanceDifference, (row.original.currency as Currency) || "INR")
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "Balance Confirmed",
        accessorFn: (row) => row.statusOverview || "",
        size: 240,
        filterVariant: "select",
      },
      {
        header: "Balance Confirm document",
        id: "signedPdf",
        accessorFn: (row) => row.signedPdf || false,
        size: 320,
        filterVariant: "checkbox",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.signedPdf ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() =>
                  downloadUploadedFile(row.original.businessPartnerId, BcBetaFileType.confirmationDocument)
                }
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "Partner Ledger",
        id: "ledgers",
        accessorFn: (row) => row.ledgers || false,
        size: 220,
        filterVariant: "checkbox",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.ledgers ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() => downloadUploadedFile(row.original.businessPartnerId, BcBetaFileType.ledgers)}
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "Response Approved",
        accessorFn: (row) => row.approvedStatus || "",
        size: 240,
        filterVariant: "select",
        Cell: ({ row }) => <div className="textOverflow_hidden">{row.original.approvedStatus}</div>,
      },
      {
        header: "Approved Date",
        accessorFn: (row) => row.approvedAt || "",
        size: 240,
        filterVariant: "select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.approvedAt ? moment(row.original.approvedAt)?.format("YYYY-MM-DD") : "-"}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history, updatingTallyCbDate, bulkTallyCbDate, loadingTallyCbAll, isSendingBulkMailsBCB]
  );

  const PartnerCommunicationContextValue: IBcBetaContext = {
    rowsDataBcBeta,
    storeRowsDataBcBeta,
    setRowsDataBcBeta,
    setStoreRowsDataBcBeta,
    listAllBusinessPartnersWSR: () => ListAllBusinessPartnerForBcBeta(),
  };

  useEffect(() => {
    if (!actor.integration) {
      ListAllBusinessPartnerForBcBeta();
      // listAllBusinessPartnersForBcBeta();
      listUserEmailTemplateForBcBeta("");
      listPDFTemplates();
      getListUserEmailSetting();
    }

    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actor.branchLevelReconcilation ? lastCompany && lastBranch : lastCompany) {
      PollErpClosingBalanceStatus();
    }
    // eslint-disable-next-line
  }, [actor, lastCompany, lastBranch]);

  // Main ROWs API
  const ListAllBusinessPartnerForBcBeta = async () => {
    setLoader(true);
    useFetch<BusinessPartnersListForBcBetaRes>("/api/BalanceConfirmationPortal/ListAllBusinessPartner", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        // Extract the businessPartnerId values and store them in a new array
        if (!response.data?.businessPartnerList)
          response.data = { businessPartnerList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
        storeBcBetaData.current = response.data?.businessPartnerList || [];
        storeAllCategories.current = response.data?.allCategories || [];
        setRowsDataBcBeta(response.data.businessPartnerList || []);
        setStoreRowsDataBcBeta(response.data.businessPartnerList || []);
        setLoader(false);
        if (Object.keys(appliedFilters).length) setReFilter(true);
        if (rowsDataBcBeta && defaultTallyCbDate) GetErpClosingBalanceForAllBp(response.data.businessPartnersList);
      },
    });
  };

  // User Email Templates for Mail Dialog
  const listUserEmailTemplateForBcBeta = (createTemplateName: string) => {
    useFetch<ListUserEmailTemplateResponse>("/api/partnerCommunication/ListUserEmailTemplate", "GET", {
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmationBeta,
          companyId: companyId.current,
        },
      },
      thenCallBack: (response) => {
        const { emailTemplates, lastEmailTemplateSelected } = response.data;
        setEmailTemplates(emailTemplates);
        listPDFTemplates();

        if (lastEmailTemplateSelected) SetLastSelectedTemplateID(lastEmailTemplateSelected);
        if (createTemplateName.trim() !== "") {
          if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
            const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
              return item.templateName === createTemplateName;
            })[0];
            if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
          }
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          // setSelectedTemplate("");
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  const listPDFTemplates = (createTemplateName = "") => {
    useFetch<ListPDFTemplatesResponse>("/api/partnerCommunication/ListPDFTemplates", "GET", {
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmation,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
        if (createTemplateName.trim() !== "") {
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  // User Display Name and Email Id for Mail Dialog
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting?.display_name)) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (exists(response.data.userEmailSetting?.from_email)) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        if (exists(response.data.userEmailSetting?.signature?.image)) {
          setSignatureImgBase64(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (exists(response.data.userEmailSetting?.signature?.text)) {
          setSignature(response.data.userEmailSetting.signature.text);
        }
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (
    row: MRT_Row<BusinessPartnerListForBcBeta>,
    value: string[]
  ) => {
    useFetch("/api/UpdateOwnAndBusinessPartnerMappingCategories", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setTempLoader(true);
        updateCategory(row, value);
      },
    });
  };

  // update Rows Category
  const updateCategory = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: string[]) => {
    storeBcBetaData.current.find((v) => v.businessPartnerId === row.original.businessPartnerId).category = value;
    // setRowsDataBcBeta(storeBcBetaData.current);
    // setStoreRowsDataBcBeta(storeBcBetaData.current);

    setTempLoader(false);
  };

  // Company Select
  const companyNameSelect = (e: Record<string, any>, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        ListAllBusinessPartnerForBcBeta(); // Rows API
        listUserEmailTemplateForBcBeta("");
        getListUserEmailSetting();
        getBulkMailStatus();

        if (!intervalId) setIntervalBCBeta();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: Record<string, any>, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        ListAllBusinessPartnerForBcBeta(); // Rows API
        listUserEmailTemplateForBcBeta("");
        getListUserEmailSetting();
        getBulkMailStatus();
        if (!intervalId) setIntervalBCBeta();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
    setRowsDataBcBeta([]);
    setStoreRowsDataBcBeta([]);
  };

  const getDownloadBcBetaReport = () => {
    useFetch<{ base64string: string }>("/api/BalanceConfirmationPortal/GetBalanceConfirmationReport", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setDownloadBcBetaReportBase64(response.data?.base64string);
        downloadBcBetaReport(response.data?.base64string);
      },
    });
  };

  // Download BcBeta Rows Report
  const downloadBcBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64 || downloadBcBetaReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Balance Confirmation Report.xlsx`);
  };

  // Delete User Email Templates, View API Method Above Update Categories
  const deleteUserEmailTemplate = (templateId: number) => {
    setShowDeleteLoadingIcon(true);
    useFetch("/api/partnerCommunication/DeleteUserEmailTemplate", "DELETE", {
      config: {
        data: {
          templateId: templateId,
          useCaseType: UseCaseType.balanceConfirmationBeta,
        },
      },
      thenCallBack: (_res) => {
        listUserEmailTemplateForBcBeta("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowDeleteLoadingIcon(false);
      },
      errorCallback: () => {
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  // View Email Thread
  const viewEmail = (businessPartner: number) => {
    return new Promise<{ emails: MailThread[] }>((resolve, reject) => {
      useFetch<{ emailThreads: MsmeMailThread[] }>("/api/BalanceConfirmationPortal/ViewEmailThread", "GET", {
        config: {
          params: {
            businessPartnerId: businessPartner,
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          if (response.data.emailThreads) {
            const { emailThreads } = response.data;
            const mailThreads: MailThread[] = emailThreads.map((email) => ({
              emailBody: email.body,
              fromEmailId: email.from,
              timestamp: email.emailTime?.toString(),
              attachments: [],
            }));

            resolve({ emails: mailThreads });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  // View Rows Partner Uploaded Files
  const downloadUploadedFile = (_bpId: number, _fileType: BcBetaFileType) => {
    useFetch<{ signedUrls: string[] }>("/api/BalanceConfirmationPortal/DownloadUploadedFile", "GET", {
      config: {
        params: {
          businessPartnerId: _bpId,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          type: _fileType,
        },
      },
      thenCallBack: (_res) => {
        if (_res.data.signedUrls?.length > 0) {
          _res.data.signedUrls.forEach((url) => {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      },
    });
  };
  const getBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{
        emailResponseMessage: string;
        isCompleted: boolean;
        listOfFailedUsers: { reason: string; businessPartnerName: string }[];
        failedEmailsWorkbook: string;
      }>("/api/BalanceConfirmationPortal/GetBulkEmailStatus", "GET", {
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data.isCompleted) {
            if (res.data?.emailResponseMessage !== "") {
              ListAllBusinessPartnerForBcBeta();
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            dispatch(updateIsSendingBulkMailsBCB(false));
            // setIsSendingBulkMailsBCB(false);

            if (res.data.listOfFailedUsers && res.data.listOfFailedUsers?.length) {
              if (openBulkMailReportDialogBCB) return;
              dispatch(updateOpenBulkMailReportDialogBCB(true));
              dispatch(updateEmailResponseMsgBCB(res.data.emailResponseMessage || ""));
              const listOfFailedUsers: BulkMailStatusResponse["listOfFailedUsers"] = [];
              res.data.listOfFailedUsers.forEach((failedUser) => {
                const found = listOfFailedUsers.find((user) => user.reason === failedUser.reason);
                if (found) {
                  found.businessPartnerNames.push(failedUser.businessPartnerName);
                } else
                  listOfFailedUsers.push({
                    reason: failedUser.reason,
                    businessPartnerNames: [failedUser.businessPartnerName],
                  });
              });
              dispatch(updateMailFailedUsersBCB(listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookBCB(res.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              listUserEmailTemplateForBcBeta("");
            }
          } else {
            dispatch(updateIsSendingBulkMailsBCB(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsBCB(false));
          reject();
        },
      });
    });
  };

  const UpdateClosingBalance = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: number) => {
    useFetch<{ message: string }>("/api/BalanceConfirmationPortal/UpdateClosingBalance", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerId: row.original.businessPartnerId,
        closingBalance: value,
      },
      thenCallBack: (_res) => {
        row.original.ownClosingBalance = value;
      },
    });
  };

  const UpdateClosingBalanceDate = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: string) => {
    useFetch<{ message: string }>("/api/BalanceConfirmationPortal/UpdateClosingBalanceDate", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerId: row.original.businessPartnerId,
        closingBalanceDate: value,
      },
      thenCallBack: (_res) => {
        row.original.closingBalanceDate = value as any;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  const listAllBusinessPartnerRequest = () => {
    setLoadingPartnerRequests(true);
    useFetch<{ requestMap: Record<string, RaisedIssueBc[]> }>(
      "/api/BalanceConfirmationPortal/ListAllBusinessPartnerRequest",
      "GET",
      {
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (_res) => {
          setLoadingPartnerRequests(false);
          const { requestMap } = _res.data;
          if (Object.keys(requestMap)?.length > 0) {
            setRaisedIssues(
              Object.keys(requestMap)?.map((item) => {
                const { businessPartnerName, vendorCode } = JSON.parse(item);
                return {
                  businessPartnerName: businessPartnerName,
                  vendorCode: vendorCode,
                  issues: requestMap[item],
                };
              })
            );
          } else setRaisedIssues([]);
        },
        catchCallBack: () => {
          setLoadingPartnerRequests(false);
        },
      }
    );
  };

  const markIssueResolved = async (issueId: number) =>
    new Promise<{ success: boolean }>((resolve, reject) =>
      useFetch("/api/BalanceConfirmationPortal/MarkIssueAsResolved", "POST", {
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          issueId,
        },
        thenCallBack: (_res) => {
          listAllBusinessPartnerRequest();
          resolve({ success: true });
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );

  const approveResponse = async (businessPartnerIds: number[]) => {
    useFetch<{ message: string }>("/api/BalanceConfirmationPortal/ApproveStatus", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerIds,
      },
      thenCallBack: (_res) => {
        ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  const resetStatus = async (businessPartnerIds: number[]) => {
    return await useFetch<{ message: string }>(
      "/api/BalanceConfirmationPortal/ResetStatesOnPartnerLevelForBalanceConfirmation",
      "POST",
      {
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          businessPartnerId: businessPartnerIds,
        },
        thenCallBack: (_res) => {
          ListAllBusinessPartnerForBcBeta();
        },
      }
    );
  };

  const setIntervalBCBeta = () => {
    intervalId = setInterval(async () => {
      const { isCompleted } = await getBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30000);
  };

  useEffect(() => {
    if (actor.integration === false) {
      getBulkMailStatus();
      if (!intervalId) setIntervalBCBeta();
    }
  }, []);

  useEffect(() => {
    if (rowsDataBcBeta && defaultTallyCbDate) GetErpClosingBalanceForAllBp(rowsDataBcBeta);

    // eslint-disable-next-line
  }, [defaultTallyCbDate]);

  useEffect(() => {
    if (updatingTallyCbDate) {
      const intervalsInMs = [10_000, 20_000, 30_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            PollErpClosingBalanceStatus();
          } finally {
            if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastTallyInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastTallyInterval.current);
    }

    return () => window.clearInterval(lastTallyInterval.current);
    // eslint-disable-next-line
  }, [updatingTallyCbDate]);

  const PollErpClosingBalanceStatus = async () => {
    interface Response {
      isClosingBalanceUpdating: boolean;
      error: string;
      closingBalanceDate: string;
    }

    useFetch<Response>("/api/GetERPClosingBalanceStatus", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          service: "BalanceConfirmation",
        },
      },
      thenCallBack: (res) => {
        const { isClosingBalanceUpdating, closingBalanceDate } = res.data;
        let TallyCbDate = "";
        if (!isClosingBalanceUpdating) {
          if (closingBalanceDate) TallyCbDate = moment(closingBalanceDate).format("YYYY-MM-DD");
          else TallyCbDate = moment().format("YYYY-MM-DD");

          setDefaultTallyCbDate(TallyCbDate);
          setUpdatingTallyCbDate(false);

          if (closingBalanceDate) setBulkTallyCbDate(TallyCbDate);
        } else if (isClosingBalanceUpdating) {
          setUpdatingTallyCbDate(true);
          if (closingBalanceDate && !bulkTallyCbDate)
            setBulkTallyCbDate(moment(closingBalanceDate).format("YYYY-MM-DD"));
          else if (!closingBalanceDate) setDefaultTallyCbDate(moment().format("YYYY-MM-DD"));
        }
      },
    });
  };

  const GetErpClosingBalanceForAllBp = (rowsBalanceConfirmation: BusinessPartnerListForBcBeta[]) => {
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/GetERPClosingBalanceForAllBp", "GET", {
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          closingBalanceDate: moment(defaultTallyCbDate).format("YYYY-MM-DD"),
          service: "BalanceConfirmation",
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceOfAllBp } = res.data;
        if (closingBalanceOfAllBp?.length > 0) {
          rowsBalanceConfirmation?.forEach((row) => {
            const closingBalObj = closingBalanceOfAllBp.find((cb) => cb.businessPartnerId === row.businessPartnerId);
            if (closingBalObj) {
              row.ownClosingBalance = closingBalObj.closingBalance as number;
              row.closingBalanceDate = closingBalObj.closingBalanceDate as any;
            }
          });
          if (rowsBalanceConfirmation && rowsBalanceConfirmation[0]?.businessPartnerId !== 0)
            setRowsDataBcBeta([...rowsBalanceConfirmation]);

          // find highest count of balance confirmation date
          // const count: { [k: string]: number } = {};
          const firstRowCBDate = closingBalanceOfAllBp[0].closingBalanceDate;
          const allRowCBDateSame = closingBalanceOfAllBp.every((row) => row.closingBalanceDate === firstRowCBDate);
          // closingBalanceOfAllBp.forEach((e) => {
          //   if (count.hasOwnProperty(e.closingBalanceDate)) count[e.closingBalanceDate] += 1;
          //   else count[e.closingBalanceDate] = 1;
          // });
          // const arr = Object.entries(count)?.sort((a, b) => b[1] - a[1]);
          if (allRowCBDateSame) setBulkTallyCbDate(firstRowCBDate);
          else setBulkTallyCbDate(null);
        }
        setLoadingTallyCbAll(false);
      },
    });
  };

  const UpdateErpClosingBalanceForAllBp = (date: string) => {
    setUpdatingTallyCbDate(true);
    setLoadingTallyCbAll(true);
    const momentDate = moment(date).isValid() ? moment(date) : moment();
    useFetch<TallyClosingBalanceForAllBpResponse>("/api/UpdateERPClosingBalanceForAllBp", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        closingBalanceDate: momentDate.format("YYYY-MM-DD"),
        service: "BalanceConfirmation",
      },
    });
  };

  const UpdateErpClosingBalance = (row: MRT_Row<BusinessPartnerListForBcBeta>, date: string) => {
    const Response = {
      businessPartnerId: 0,
      closingBalance: 0,
      closingBalanceDate: "",
      openingBalance: 0,
    };

    useFetch<typeof Response>("/api/UpdateERPClosingBalance", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        closingBalanceDate: moment(date).format("YYYY-MM-DD"),
        service: "BalanceConfirmation",
      },
      thenCallBack: (res) => {
        if (res.data.businessPartnerId === row.original.businessPartnerId)
          row.original.ownClosingBalance = res.data.closingBalance;
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  const GREEN = "#27B27C";

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <BcBetaContext.Provider value={PartnerCommunicationContextValue}>
        {
          <Grid className="">
            <Grid position="relative">
              <Grid className="vertical_center_align mb_15" sx={{ gap: 2, flexWrap: "wrap" }}>
                {/* Left Side Dropdowns */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {!openBcBetaSettings && !openBcBetaHistory && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={null}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={null}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={null}
                          isDisabled={isSendingBulkMailsBCB}
                        />
                      )}
                    </>
                  )}
                </div>

                {/* Settings Back Button */}
                {openBcBetaSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: GREEN, ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenBcBetaSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {/* Right Side Buttons */}
                {!openBcBetaSettings && !openBcBetaHistory && (
                  <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{
                        borderRadius: "32px",
                        border: "1px solid #FFC135",
                        background: "#F8EDD4",
                      }}
                      startIcon={
                        loadingPartnerRequests ? (
                          <LoadingIcon loading={loadingPartnerRequests} />
                        ) : (
                          <svg className="icon" style={{ height: "30px", width: "30px", fill: "#333" }}>
                            <use xlinkHref="#icon-person-alert" />
                          </svg>
                        )
                      }
                      disabled={loadingPartnerRequests}
                      onClick={async () => {
                        await listAllBusinessPartnerRequest();
                        setOpenRaiseReq(true);
                      }}
                    >
                      Partner Requests
                    </Button>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{
                        borderRadius: "32px",
                        border: "1px solid #111",
                        background: "#FFF",
                      }}
                      startIcon={<Restore sx={{ fontSize: "24px !important" }} />}
                      onClick={async () => {
                        setOpenBcBetaHistory(true);
                      }}
                    >
                      Past Communications
                    </Button>
                    <Tooltip title="Bulk Approve" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => {
                            setApproveSelectedRow(selectedRow);
                            setOpenApproveDialog(true);
                          }}
                          color="inherit"
                          disabled={selectedRow.length < 2}
                        >
                          <TaskAlt />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Bulk Reset Status" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => {
                            setApproveSelectedRow(selectedRow);
                            setOpenResetStatusDialog(true);
                          }}
                          color="inherit"
                          disabled={selectedRow.length < 1}
                        >
                          <RestartAlt />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Balance Confirmation Settings" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => setOpenBcBetaSettings(true)}
                          color="inherit"
                          disabled={isSendingBulkMailsBCB}
                        >
                          <Settings />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title={"Filter"} arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_ClickedOnFilter, companyId.current, branchCode.current)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            onClick={() => setOpenBcBetaFilter(true)}
                            color="inherit"
                            sx={{
                              pointerEvents: "auto !important",
                            }}
                          >
                            {isFilterAppliedBcBeta ? <FilterAlt /> : <FilterAltOff />}
                          </IconButton>
                        </span>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Bulk Email" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_clickedOnBulkEmail, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            setSendSoleOrBulkMail(EmailSendCount.Bulk);

                            setOpenSelectEmailTemplate(true);
                          }}
                          disabled={selectedRow.length < 2 ? true : isSendingBulkMailsBCB}
                          color="inherit"
                        >
                          {isSendingBulkMailsBCB ? (
                            <span
                              className="file-icon"
                              style={{
                                position: "absolute",
                                transform: "scale(2.5)",
                                left: 8,
                                color: "white",
                              }}
                            >
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fa-icon">
                            <i className="fas fa-envelope" />
                          </span>
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download Balance Confirmation Report" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_DownloadReport, companyId.current, branchCode.current)}
                      >
                        <IconButton
                          onClick={() => getDownloadBcBetaReport()}
                          color="inherit"
                          disabled={isSendingBulkMailsBCB}
                        >
                          <Download />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </Grid>

              {/* Filter Applied Bar */}
              {!openBcBetaSettings && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              <div
                className="bpDashboard"
                style={{
                  height: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openBcBetaSettings ? "-50px" : "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                  }}
                >
                  {!openBcBetaSettings && !openBcBetaHistory && (
                    <ReactTable
                      columns={columnDefinitionBcBeta}
                      rows={rowsDataBcBeta}
                      setSelectedRow={setSelectedRow}
                      loading={loader}
                      enableGrouping={true}
                      positionToolbarAlertBanner={"top"}
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        // {/* Send Email Button */}
                        <MenuItem
                          disabled={isSendingBulkMailsBCB}
                          key={"sendMail"}
                          onClick={async () => {
                            // logic
                            setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                            setSendSoleOrBulkMail(EmailSendCount.Sole);
                            getListUserEmailSetting();

                            setOpenSelectEmailTemplate(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <MailOutline sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Send Email
                        </MenuItem>,
                        // {/* View Email Button */}
                        <MenuItem
                          disabled={isSendingBulkMailsBCB}
                          key={"viewEmail"}
                          onClick={async () => {
                            // logic
                            setMailLoading(true);
                            setOpenMailThread(true);
                            setMailThreads([]);
                            setBpName(row.original.businessPartnerName);
                            const { emails } = await viewEmail(row.original.businessPartnerId);
                            setMailLoading(false);
                            setMailThreads(emails || []);
                            uiLogger(uiLoggerName.ui_ViewEmailClicked, companyId.current, branchCode.current);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Visibility sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View Email
                        </MenuItem>,
                        // {/* View Recon */}
                        <MenuItem
                          disabled={isSendingBulkMailsBCB}
                          key={"viewRecon"}
                          onClick={async () => {
                            // logic
                            const { businessPartnerId, businessPartnerName } = row.original;
                            const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
                            const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                            window.open(uri, "_blank").focus();
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Cached sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View Recon
                        </MenuItem>,
                        // {/* Approve Response */}
                        <MenuItem
                          disabled={isSendingBulkMailsBCB}
                          key={"approveResponse"}
                          onClick={async () => {
                            // logic
                            // const { businessPartnerId } = row.original;
                            setApproveSelectedRow([row.original]);
                            setOpenApproveDialog(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <TaskAlt sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Approve Response
                        </MenuItem>,
                      ]}
                      renderAdditionalBottomToolbarCustomActions={() => {
                        return (
                          <PageSelectModeToggle pageSelectMode={pageSelectMode} setPageSelectMode={setPageSelectMode} />
                        );
                      }}
                      pageSelectMode={pageSelectMode}
                    />
                  )}

                  {openBcBetaSettings && !openBcBetaHistory && (
                    <BalanceConfirmationBetaSettings
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      listUserEmailTemplate={listUserEmailTemplateForBcBeta}
                    />
                  )}

                  {openBcBetaHistory && !openBcBetaSettings && (
                    <BalanceConfirmationHistory
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      setOpen={setOpenBcBetaHistory}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
        <BalanceConfirmationBetaFilter
          openBalanceConfirmationFilter={openBcBetaFilter}
          setOpenBalanceConfirmationFilter={setOpenBcBetaFilter}
          allCategories={storeAllCategories.current}
          rowsDataBalanceConfirmation={rowsDataBcBeta}
          setRowsDataBalanceConfirmation={setRowsDataBcBeta}
          storeRowsDataBalanceConfirmation={storeRowsDataBcBeta}
          setIsFilterAppliedBalanceConfirmation={setIsFilterAppliedBcBeta}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
        />

        {/* Balance Confirmation Beta*/}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
        <SendEmailTemplateBeta
          companyId={companyId.current}
          branchCode={branchCode.current} // wot
          base64Image={signatureImgBase64}
          signature={signature}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          // after sending mail to update date
          listAllBusinessPartnersWSR={ListAllBusinessPartnerForBcBeta}
          // setLoader={setLoader}
          templateType={"BCB"}
          setIntervalBulkMails={setIntervalBCBeta}
          listUserEmailTemplate={listUserEmailTemplateForBcBeta}
          withoutTemplateCheck={withoutTemplateCheck}
          setWithoutTemplateCheck={setWithoutTemplateCheck}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplate={listUserEmailTemplateForBcBeta}
          templateType={"BCB"}
          beta={true}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          width70Per={true}
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers as any}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        </Dialog>

        <MailThreadDialog
          open={openMailThread}
          setOpen={setOpenMailThread}
          mailThreads={mailThreads}
          s3Attachments={[]}
          businessPartnerName={bpName}
          loading={mailLoading}
          msme={true}
          companyId={companyId.current}
          branchCode={branchCode.current}
          useCaseType={null}
          emailSubject={null}
          listAllBusinessPartnersWSR={null}
        />

        <PartnerRequests
          open={openRaiseReq}
          setOpen={setOpenRaiseReq}
          allIssues={raisedIssues}
          markIssueResolved={markIssueResolved}
          setSendSoleOrBulkMail={setSendSoleOrBulkMail}
          setWithoutTemplateCheck={setWithoutTemplateCheck}
        />
        <BulkMailReportDialog
          open={openBulkMailReportDialogBCB}
          setOpen={(value: boolean) => dispatch(updateOpenBulkMailReportDialogBCB(value))}
          emailResponseMsg={emailResponseMsgBCB}
          mailFailedUsers={mailFailedUsersBCB}
          workbook={mailFailedWorkbookBCB}
        />
        <ApproveResponseDialog
          open={openApproveDialog}
          setOpen={setOpenApproveDialog}
          partnerCount={approveSelectedRow.length}
          approveAction={() => approveResponse(approveSelectedRow.map((row) => row.businessPartnerId))}
        />
        <ResetStatusDialog
          open={openResetStatusDialog}
          setOpen={setOpenResetStatusDialog}
          partnerCount={approveSelectedRow.length}
          partnerName={approveSelectedRow.length === 1 ? approveSelectedRow[0].businessPartnerName : ""}
          resetAction={async () => await resetStatus(approveSelectedRow.map((row) => row.businessPartnerId))}
        />
      </BcBetaContext.Provider>
    </LoggedInSkeleton>
  );
};

export default BalanceConfirmationBeta;

import { Box, Button, Card, CardContent, FormControl, FormControlLabel } from "@mui/material";
import { Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "../CommonComponents";
import CreateEmailTemplate from "../CreateEmailTemplate";
import { PartnerCommsContext } from "../PartnerCommunicationContext";

interface TabProps {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  listUserEmailTemplate: (name: string) => void;
  useCaseType: UseCaseType.balanceConfirmationBeta | UseCaseType.ledgerRequestBeta | UseCaseType.msme;
}
const SendReminderTab = ({ companyId, branchCode, listUserEmailTemplate, useCaseType }: TabProps) => {
  const [reminderAuto, setReminderAuto] = useState(false);
  const [reminderDays, setReminderDays] = useState<number>(0);
  const [reminderCount, setReminderCount] = useState<number>(0);
  const [templateID, setTemplateID] = useState(0);
  const [templateName, setTemplateName] = useState<string>("");
  const [valueChanged, setValueChanged] = useState(false);
  const { emailTemplates, setOpenCreateEmailTemplate, setHideSendEmailTemplate } = useContext(PartnerCommsContext);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [formStateValid, setFormStateValid] = useState(false);

  const useCaseAndEndPointMap = {
    [UseCaseType.balanceConfirmationBeta]: "/api/BalanceConfirmationPortal",
    [UseCaseType.ledgerRequestBeta]: "/api/ledgerRequestPortal",
    [UseCaseType.msme]: "/api/partnerPortal",
  };

  const endPointPrefix = useCaseAndEndPointMap[useCaseType] || "";

  useEffect(() => {
    ListEmailAutoSendReminderSetting();
    listUserEmailTemplate("");
    ListEmailReminderSettings();
    ListAutoSendReminderTemplate();
    // eslint-disable-next-line
  }, []);

  // ListEmailAutoSendReminderSetting
  const ListEmailAutoSendReminderSetting = async () =>
    useFetch<{ autoSend: boolean; response: string }>(`${endPointPrefix}/ListEmailAutoSendReminderSetting`, "GET", {
      thenCallBack: (response) => setReminderAuto(response.data.autoSend || false),
      config: {
        params: {
          ownCompanyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
          useCaseType: useCaseType,
        },
      },
    });

  const UpdateAutoReminderSettings = async () => {
    setShowLoadingIcon(true);
    useFetch<{ response: string }>(`${endPointPrefix}/UpdateAutoReminderSettings`, "POST", {
      showSuccessToast: true,
      data: {
        autoSendStatus: reminderAuto,
        numberOfReminders: reminderCount,
        reminderDays,
        templateId: templateID,
        templateName,
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        useCaseType: useCaseType,
      },
      thenCallBack: () => {
        setShowLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
      errorCallback: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  const ListEmailReminderSettings = async () => {
    useFetch<{ reminderSettings: { reminderDays: number; numberOfReminders: number } }>(
      `${endPointPrefix}/ListEmailReminderSettings`,
      "GET",
      {
        thenCallBack: ({ data: { reminderSettings } }) => {
          setReminderDays(reminderSettings?.reminderDays);
          setReminderCount(reminderSettings?.numberOfReminders);
        },
        config: {
          params: {
            ownCompanyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
            useCaseType: useCaseType,
          },
        },
      }
    );
  };

  const ListAutoSendReminderTemplate = async () =>
    useFetch<{ templateName: string; templateId: number }>(`${endPointPrefix}/ListAutoSendReminderTemplate`, "GET", {
      thenCallBack: (res) => {
        // setTemplateID(res.data.templateId);
        const template = emailTemplates?.find((_template) => _template?.templateId === res.data?.templateId);

        setTemplateID(template?.templateId);
        setTemplateName(template?.templateName);
      },
      config: {
        params: {
          ownCompanyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
          useCaseType: useCaseType,
        },
      },
    });

  const checkFormStateValid = () => {
    if (reminderAuto) {
      if (reminderCount && reminderDays && templateName) setFormStateValid(true);
      else setFormStateValid(false);
    } else setFormStateValid(true);
  };

  useEffect(() => {
    checkFormStateValid();
  }, [reminderAuto, reminderCount, reminderDays, templateName]);

  return (
    <Grid className="mb_15">
      <Card sx={{ maxWidth: "1040px", mx: "auto" }}>
        <CardContent sx={{ p: "16px !important" }}>
          <div className="d_flex">
            <div style={{ width: "90%" }}>
              <div className="vertical_center_align space_between">
                <div className="fs_20"> Send Reminder automatically: </div>
                <div className="ml_60">
                  <FormControl>
                    <RadioGroup
                      row={true}
                      aria-labelledby="row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={reminderAuto}
                      onChange={(e) => {
                        if (e.target.value === "true") {
                          setReminderAuto(true);
                          // UpdateEmailAutoSendReminderSetting(true);
                        } else {
                          setReminderAuto(false);
                          // UpdateEmailAutoSendReminderSetting(false);
                        }
                      }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      {reminderAuto && (
        <Card sx={{ maxWidth: "1040px", mx: "auto", mt: 2 }}>
          <CardContent sx={{ p: "16px !important" }}>
            <div className="d_flex">
              <div style={{ width: "90%" }}>
                <div className="fs_20 fw_600">If the partner is not responding:</div>
                <div className="vertical_center_align space_between mt_10">
                  <div className="fs_20"> After how many days the reminder should go?: </div>
                  <div className="ml_60">
                    <FormControl>
                      <TextField
                        label="Number of Days"
                        type="number"
                        size="small"
                        value={reminderDays ?? ""}
                        onChange={(e) => {
                          const days = parseInt(e.target.value as string, 10);
                          if (reminderCount !== days) setValueChanged(true);
                          setReminderDays(days);
                        }}
                        onBlur={() => {
                          if (isNaN(reminderDays)) setReminderDays(0);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="vertical_center_align space_between mt_10">
                  <div className="fs_20"> How many times the reminders should go?: </div>
                  <div className="ml_60">
                    <FormControl>
                      <TextField
                        label="Number of Reminders"
                        type="number"
                        size="small"
                        value={reminderCount ?? ""}
                        onChange={(e) => {
                          const count = parseInt(e.target.value as string, 10);
                          if (reminderCount !== count) setValueChanged(true);
                          setReminderCount(count);
                        }}
                        onBlur={() => {
                          if (isNaN(reminderCount)) setReminderCount(0);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {reminderAuto && (
        <Card sx={{ maxWidth: "1040px", mx: "auto", mt: 2 }}>
          <CardContent sx={{ p: "16px !important" }}>
            <div className="d_flex">
              <div style={{ width: "90%" }}>
                <div className="vertical_center_align space_between">
                  <div className="fs_20"> Select reminder template: </div>
                  <div className="ml_60">
                    <FormControl sx={{ minWidth: 195 }} size="small">
                      <InputLabel id="select-helper-label">Select Template</InputLabel>
                      <Select
                        labelId="select-helper-label"
                        label="Select Template"
                        value={templateID}
                        onChange={(e) => {
                          if (templateName !== e.target.value && e.target.value) setValueChanged(true);
                          if (e.target.value) {
                            const _templateID = e.target.value;
                            const template = emailTemplates.find(
                              (_template) => _template.templateId.toString() === _templateID.toString()
                            );

                            setTemplateID(template?.templateId);
                            setTemplateName(template?.templateName);
                          }
                        }}
                        onBlur={() => {
                          if (valueChanged) {
                            // if (templateID) UpdateAutoSendReminderTemplate();
                            setValueChanged(false);
                          }
                        }}
                        displayEmpty={true}
                      >
                        {emailTemplates.map((template) => (
                          <MenuItem key={template.templateId} value={template.templateId} title="">
                            {template.templateName}
                          </MenuItem>
                        ))}
                        <MenuItem
                          value={0}
                          onClick={() => {
                            setOpenCreateEmailTemplate(true);
                            setHideSendEmailTemplate(true);
                          }}
                        >
                          <em>Add New Template</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      <Box position={"relative"} top={40} left={"50%"}>
        <Button
          className="theme_btn"
          variant="contained"
          size="small"
          startIcon={!showLoadingIcon && <i className="fas fa-save" />}
          sx={{
            px: 3,
            py: 0.25,
          }}
          onClick={() => {
            UpdateAutoReminderSettings();
          }}
          disabled={!formStateValid || showLoadingIcon}
        >
          {showLoadingIcon ? <LoadingIcon loading={showLoadingIcon} /> : null}
          Save
        </Button>
      </Box>
      <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
        companyId={companyId}
        branchCode={branchCode}
        listUserEmailTemplate={listUserEmailTemplate}
        templateType={useCaseType}
      />
    </Grid>
  );
};

export default SendReminderTab;

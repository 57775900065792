import { ChevronRight } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Alert, Badge, Box, CircularProgress, Collapse, Fab, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {
  ActorTypesForRecon,
  AllTickets,
  amountEntryType,
  ColumnsToSanitize,
  ConfigTemplateType,
  ConfigUseCaseType,
  DateRange,
  EmailTemplate,
  FetchDataFromERPResponse,
  FileData,
  FileWiseDocTypeAndColumnHeader,
  GetAllTicketsResponse,
  GetReconPeriodResponse,
  GetReconRelatedDetailsOfBpRes,
  InsertLedgerConfig,
  InsertLedgerResponse,
  LastReconciliationStatusRes,
  LedgerConfigTemplate,
  LedgerConfiguration,
  ListAllBusinessPartnersRes,
  ListAllLedgerConfigTemplateResponse,
  ListBusinessPartnerLedgerConfigResponse,
  ListOwnLedgerConfigResponse,
  ListOwnTallyCompanies,
  ListReconSummaryAccountingSettingResponse,
  ListSanitizationRulesResponse,
  ListUserEmailTemplateResponse,
  ManualReconciledEntriesTrack,
  ObjectEle,
  OutputUISummary,
  OwnBusinessPartnerMappingUpdateClosingBalances,
  ReconRequestStatus,
  ReconResponseJSON,
  SyncFromSAPResponse,
  TicketStatus,
  TicketUser,
  UnIdentifiedDoctype,
  UploadPDFFileResponse,
  UploadStatus,
  WhichActionRequiredStatus,
} from "src/entity/recon-entity/ReconInterfaces";
import {
  BUSINESS_PARTNER_LEDGER,
  columnHeaderNameOrder,
  OWN_LEDGER,
  RECON360_SUB_TAB,
  reconciliationStatus,
  // section1ColumnHeaderArr,
  // section2ColumnHeaderArr,
  section3ColumnHeaderArr,
  summaryTypeSelectedStatus,
  UploadLedgerSide,
  UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY,
} from "src/Utils/Recon/Recon360/Constants";

import { ToDineroObj } from "../../Utils/MoneyUtils";
import { DefaultCurrency, formatMoney } from "../../Utils/MoneyUtils";
import {
  fileTypeLogger,
  uiLoggerName,
  uiLoggerNamesChecklistAndNotes,
  uiLoggerNamesRecon,
} from "../../Utils/Recon/UiLogger/Constants";

import LinkIcon from "@mui/icons-material/Link";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../Common/TopNavBar";
import useFetch from "../Common/useFetch";
import { userContext } from "../Contexts/userContext";

import ChecklistIcon from "@mui/icons-material/Checklist";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Currency } from "dinero.js";
import { toast } from "react-toastify";
import { uiLogger as utilUiLogger } from "src/Utils/UiLogger";
import reconcile_ledger from "../../Graphics/AutomatedRecon/reconcile_ledger.svg";
import LoadingIcon from "../Common/LoadingIcon";
import RestrictiveLoader from "../Common/RestrictiveLoader";
import CustomToast from "../CustomToast";
import {
  AmountMismatchColDef,
  DocNotPresentInLedgerColDef,
  reconSummaryDetailColDef,
} from "./AutomatedRecon/ColumnDefinitions/ReconSummaryDetailsColDefs";
import InsightsSection from "./AutomatedRecon/InsightsSection/InsightsSection";
import PartnerSelectSection from "./AutomatedRecon/PartnerSelectSection/PartnerSelectSection";
import ReconciliationStatement from "./AutomatedRecon/ReconciliationStatement/ReconciliationStatement";
import ReconSummaryDetails from "./AutomatedRecon/ReconSummaryDetails/ReconSummaryDetails";
import SignOffStatement from "./AutomatedRecon/SignOffStatement/SignOffStatement";
import CollapseSubRow from "./CollapseSubRow";
import LedgerUpload from "./LedgerUpload";
import ManualRecon from "./ManualRecon/ManualRecon";
import { NdButton } from "./PartnerCommunication/PartnerPortal/CommonComponents";
import AddChecklist from "./Recon360FileModal/Checklist/AddChecklist";
import Checklist from "./Recon360FileModal/Checklist/Checklist";
import ColumnHeaderModal from "./Recon360FileModal/ColumnHeaderModal";
import DocumentTypeModal from "./Recon360FileModal/DocumentTypeModal";
import ErrorInUploadModal from "./Recon360FileModal/ErrorInUploadModal";
import LedgerUploadStatusModal from "./Recon360FileModal/LedgerUploadStatusModal";
import LinkOldReco from "./Recon360FileModal/LinkOldReco/LinkOldReco";
import UnlinkOldReco from "./Recon360FileModal/LinkOldReco/UnlinkOldReco";
import ManualChangesConfirmModal from "./Recon360FileModal/ManualChangesConfirmModal";
import ManualMatchDialog, { ManualReconChanges } from "./Recon360FileModal/ManualMatchDialog";
import AddNotesModal from "./Recon360FileModal/Notes/AddNotesModal";
import NotesModal from "./Recon360FileModal/Notes/NotesModal";
import PdfOptionConfirmModal from "./Recon360FileModal/PdfOptionConfirmModal";
import RaiseDisputeModal from "./Recon360FileModal/RaiseDisputeModal";
import ReuseOldFilesModal from "./Recon360FileModal/ReuseOldFilesModal";
import { SendMail } from "./Recon360FileModal/SendMail";
import SheetSelectionModal from "./Recon360FileModal/SheetSelectionModal";
import SuggestedTdsGstDialog from "./Recon360FileModal/SuggestedTdsGstDialog";
import UnassignedDoctypeAdvancedModal from "./Recon360FileModal/UnassignedDoctypeAdvancedModal";
import UploadedSummary from "./Recon360FileModal/UploadedSummary";
import UploadPdfErrorModal from "./Recon360FileModal/UploadPdfErrorModal";
import UseDateFilter from "./Recon360FileModal/UseDateFilter";
import ReconHome, { ConfigTemplateNames, ConfigTemplateRes, Template } from "./ReconHome";
import { CreateTicketModal } from "./Tickets/CreateTicket";
import { CurrentTicket } from "./Tickets/CurrentTicket";
import { ViewTickets } from "./Tickets/ViewTickets";

/**
 * @Component
 * Own Component for Own Entry Adjustment tab. It contains two sub tabs - pending and completed.
 *
 * 1.Pending - All the own invoices(BusinessPartnerInvoices) which were matched against a Tier2Invoice. The loan for the mapping has been sanctioned and disbursed. This tab represents the invoices which are due to be adjusted in Own's ERP.
 *
 * 2.Completed - All the own invoices(BusinessPartnerInvoices) which are adjusted in ERP.
 *
 * This component uses Ag grid library to demonstrate the data in tabular form.
 */

export type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export type OptionObj = { name: string; id: number; firstLetter?: string; currency?: string; vendorCode?: string };

type DateSyncFromSAP = { lastTimeToSync: string; latestEntry: string; oldestEntry: string; overallEnteries: number };

export interface IRecon360Context {
  businessPartnerSelected: number;
  businessPartnerSelectedRef: React.MutableRefObject<string>;
  businessPartnerSelectedCompanyIdRef: React.MutableRefObject<string>;
  companyId: React.MutableRefObject<string>;
  amountTolerance: number;
  totalAmount: number;
  currency: React.MutableRefObject<string>;
  assignBusinessPartnerNameRef: React.MutableRefObject<string>; // current selected business partner name

  checkboxDataManualRecon: ManualReconciledEntriesTrack[];
  hasManuallyReconciled: boolean;
  setOpenManualRecon: StateDispatch<boolean>;
  getManuallyChangedEntries: () => Promise<void>;
  downloadExcelFromApi: (from: string, defaultResultFileState?: any) => Promise<void>;

  showDownloadResultsLoadingIcon: boolean;
  fileKeysForPdfNotCorrectMark;
  uiLogger: (functionName: string, fileType?: string, optionSelected?: string) => void;
  openManualChangesConfirmModal: boolean;
  setOpenManualChangesConfirmModal: StateDispatch<boolean>;
  setCheckboxDataManualRecon: StateDispatch<ManualReconciledEntriesTrack[]>;

  setIsUsedAsTemplateOwn: StateDispatch<boolean>;
  setIsUsedAsTemplateBp: StateDispatch<boolean>;
  // sheets selection
  sheetsArrRef: React.MutableRefObject<FileData[]>;
  selectedSheets: FileData[];
  setSelectedSheets: StateDispatch<FileData[]>;
  openSheetSelectionModal: boolean;
  setOpenSheetSelectionModal: StateDispatch<boolean>;
  uploadSelectedSheets: () => void;
  // ErrorInUploadModal
  openErrorInUploadModal: boolean;
  setOpenErrorInUploadModal: StateDispatch<boolean>;
  uploadErrorData: any[];
  setUploadErrorData: StateDispatch<any[]>;
  getLedgerConfigList: () => Promise<void>;

  getSummaryForReconciliationCommonFunction: () => void;
  branchCode: React.MutableRefObject<string>;
  postReconPeriod: (startDateVal: any, endDateVal: any) => void;
  loadingReconSavePeriod: boolean;
  configList: Template[];

  defTemplateId: number;
  setDefTemplateId: StateDispatch<number>;

  bpSelectedOptionRef: React.MutableRefObject<OptionObj>;
  currentEditableBpId: React.MutableRefObject<string>;
  businessPartnerSelect: (e: any, option: OptionObj) => Promise<void>;
  ListAllConfigTemplateNamesRu: () => Promise<void>;
  subTab: string;
  setWhichActionRequired: StateDispatch<string>;
  setDisableUploadBothSide: StateDispatch<boolean>;
  //ReuseOldFilesModal
  openReuseOldFilesModal: boolean;
  setOpenReuseOldFilesModal: StateDispatch<boolean>;
  disableUploadBothSide: boolean;
  ledgerFiles: React.MutableRefObject<File[]>;
  fileDetails: any;
  isSheetSelectionFinishRef: React.MutableRefObject<boolean>;
  isFileReuploaded: React.MutableRefObject<boolean>;
  excelFileUploadOnChange: any;
  excelFileUploadOnChangeFileName: typeof OWN_LEDGER | typeof BUSINESS_PARTNER_LEDGER | "";
  uploadSideRef: React.MutableRefObject<any>;
  reUploadLedger: any;

  //ColumnHeaderModal
  openColumnHeaderDialog: boolean;
  setOpenColumnHeaderDialog: StateDispatch<boolean>;
  fetchDataFromERP: boolean;
  fileNameForUnidentified: string;
  sheetNameForUnidentified: string;
  ignoreDoctypesList: React.MutableRefObject<string[]>;
  newMappingOwn: boolean;
  configUserType: string;
  columnHeaderArr: any;
  setColumnHeaderArr: StateDispatch<any>;
  newMappingBusiness: boolean;
  configUserName: string;
  isOpenSelectTemplateUploadConfirmRefOwn: React.MutableRefObject<boolean>;
  isOpenSelectTemplateUploadConfirmRefBp: React.MutableRefObject<boolean>;
  highlightedHeader: any;
  setHighlightedHeader: StateDispatch<any>;
  listColumnHeaderEntries: any;
  headerNameBp: any;
  headerName: any;
  saveRuleApi: any;
  sanitizeColBusiness: any;
  businessPartnerIntegrationWithERP: boolean;
  compareLedgersCheckEndDate: any;
  documentTypesArr: UnIdentifiedDoctype[];
  setOpenDocumentTypeDialog: StateDispatch<boolean>;
  excelFileUploadOnChangeEvent: File[];
  saveRuleApiOwn: any;
  sanitizeColOwn: any;
  cancelUploadRequest: any;

  //DocumentTypeModal
  openDocumentTypeDialog: boolean;
  groupedDocTypesArr: React.MutableRefObject<{ [k: string]: UnIdentifiedDoctype[] }>;
  isChangedDocTypeOnScreenSuggestionMap: React.MutableRefObject<boolean>;
  setSelectedDocumentTypeInUnassignedModal: StateDispatch<string>;
  changeDocumentTypeConfig: any;
  documentTypes: any;
  setItemForUnassignedDoctypeAdvancedDialog: StateDispatch<any>;
  setDuplicateTagName: any;
  finaldocumentTypesObj: React.MutableRefObject<any>;
  templateNameBp: string;
  templateName: string;
  createdocumentTypeTagsForMappedTags: any;
  setDocumentTypeRow: StateDispatch<any>;
  extractTextBeforeTilde: any;
  extractTextAfterTilde: any;
  //UnassignedDoctypesAdvancedModal
  openUnassignedDoctypeAdvancedDialog: boolean;
  setUnassignedDoctypeAdvancedDialog: StateDispatch<boolean>;
  duplicateTagName: any;
  documentTypeRow: any;
  setHeaderNameOwn: StateDispatch<any>;
  setHeaderNameBusiness: StateDispatch<any>;
  itemForUnassignedDoctypeAdvancedDialog: any;
  documentTypesTags: any;
  selectedDocumentTypeInUnassignedModal: string;
  //RaiseDisputeModal
  openMsgDialog: boolean;
  setOpenMsgDialog: StateDispatch<boolean>;
  detailsHeadingName: React.MutableRefObject<any>;
  setColumnDefinition: StateDispatch<any>;
  setPaymentGroupBy: StateDispatch<any>;
  summaryDetailsData: React.MutableRefObject<any>;
  setExpanded: StateDispatch<any>;
  setOpendetailsJSON: StateDispatch<any>;
  invoiceAmountMismatchDispute: any;
  invoiceAmountMismatchDisputeValue: any;
  invoiceAmountMismatchColDef: any;
  invoiceNotPresentInPartnerLedgerColDef: any;
  invoiceNotPresentInOwnLedgerColDef: any;
  paymentAmountMismatchColDef: any;
  paymentNotPresentInPartnerLedgerColDef: any;
  dnCnNotPresentInOwnLedgerColDef: any;
  tdsAmountMismatchColDef: any;
  dnCnNotPresentInPartnerLedgerColDef: any;
  tdsNotPresentInPartnerLedgerColDef: any;
  tdsNotPresentInOwnLedgerColDef: any;
  dnCnAmountMismatchColDef: any;
  paymentNotPresentInOwnLedgerColDef: any;

  //LedgerUploadStatusModal
  openLedgerUploadStatusModal: boolean;
  setOpenLedgerUploadStatusModal: StateDispatch<boolean>;
  fileStatus: any;
  whichActionRequired: string;
  cancelPollingRequest: () => void;

  //PartnerSelectSection
  listCompaniesForRecon: any;
  listBranchesForRecon: any;
  selectedCompany: any;
  selectedBranch: any;
  branchNameSelect: any;
  companyNameSelect: any;
  companiesLoaded: boolean;
  branchesLoaded: boolean;
  ownBusinessPartnersLoaded: boolean;
  selectedBusinessPartnerName: string;
  options: OptionObj[];
  selectedBusinessPartnerVendorCode: string;
  listAllOwnBusinessPartnerMapping: any;

  //signoff statement
  showExpandedSignOffStatement: boolean;
  isExpandedSignOffStatement: boolean;
  setIsExpandedSignOffStatement: StateDispatch<boolean>;
  dateOfLastSummary: any;
  setDateOfLastSummary: any;
  setShowDownloadGapSummary: any;
  setManualReconChanges: StateDispatch<ManualReconChanges[]>;
  setOpenManualMatches: StateDispatch<boolean>;
  ownClosingbalanceRef: React.MutableRefObject<any>;
  businessPartnerClosingbalanceRef: React.MutableRefObject<any>;
  assignBusinessPartnerName: any;
  showDownloadGapSummary: boolean;
  setReconEmailFiles: StateDispatch<{
    originalOwnLedger: File[];
    originalBPLedger: File[];
    summaryOutput: File;
  }>;
  base64ToFile: any;
  setEmailTemplateContent: StateDispatch<any>;
  emailTemplateContent: EmailTemplate;
  templateBody: string;
  setOpenSendMailDialog: StateDispatch<boolean>;

  //Insights section
  showExpandedInsights: boolean;
  isExpandedInsights: boolean;
  setIsExpandedInsights: StateDispatch<boolean>;
  rowsDataOverviewInsights: any;
  setDetailsHeading: StateDispatch<any>;
  configMappedOwn: StateDispatch<any>;
  configMappedBp: StateDispatch<any>;
  loadingConfigBP: boolean;
  loadingConfigOwn: boolean;
  configListOwn: LedgerConfigTemplate[];

  setDocumentTypesArr: StateDispatch<UnIdentifiedDoctype[]>;
  documentTypeSuggestionMap: Record<string, any>;
  setFetchDataFromERP: StateDispatch<boolean>;
  setConfigUserType: StateDispatch<string>;
  //Save and discard ticket modal
  showSaveTicketModal: boolean;
  setShowSaveTicketModal: StateDispatch<boolean>;
  showDiscardTicketModal: boolean;
  setShowDiscardTicketModal: StateDispatch<boolean>;

  updateClosingBalance: (updateFor) => void;
  openUnassignedDoctypeDialogFromView: boolean;
  setOpenUnassignedDoctypedDialogFromView: StateDispatch<boolean>;
  resetChecklist: () => void;
  openLinkOldReco: boolean;
  setOpenLinkOldReco: StateDispatch<boolean>;
  linkOldRecoTask: any;
  unlinkOldRecoTask: any;
  selectedTaskId: number;
  setSelectedTaskId: StateDispatch<number>;
  openUnlinkOldReco: boolean;
  setOpenUnlinkOldReco: StateDispatch<boolean>;
  linkReconTicket: () => void;
  deleteCurrentLink: () => void;
}

export const Recon360Context = createContext<IRecon360Context>(null);

const Recon360 = (props: { subTab: RECON360_SUB_TAB }) => {
  const location = useLocation<any>();
  const [prevLocation, setPrevLocation] = useState(null);
  const { actor } = useContext(userContext);
  const history = useHistory();
  const { pathParam } = useParams<{ pathParam?: string }>();
  const DEFAULT_ACTIVE_TAB = "Summary";
  const pathWay = "/" + actor.name + "/recon360";
  const [ledgerComparisonSummaryData, setLedgerComparisonSummaryData] = useState<any[]>(null);
  const [openDetailsJSON, setOpendetailsJSON] = useState<any[]>(null);
  const [columnDefinition, setColumnDefinition] = useState<any>(null);
  const [detailsHeading, setDetailsHeading] = useState<any>(null);
  const detailsHeadingName = useRef(null);
  const itemNuminvoiceAmountMismatchDispute = 0;
  const allinvoiceAmountMismatchDisputeValue = 0;
  const [ownClosingbalance, setOwnClosingBalance] = useState<number>(null);
  const ownClosingbalanceRef = useRef<number>(null);
  const [businessPartnerClosingbalance, setBusinessPartnerClosingBalance] = useState<number>(null);
  const businessPartnerClosingbalanceRef = useRef<number>(null);
  const [businessPartnerSelected, setSelectedBusinessPartner] = useState<number>(null);
  const businessPartnerSelectedRef = useRef(null);
  const [businessPartnerSelectedCompanyId, setSelectedBusinessPartnerCompanyId] = useState<string>(null);
  const businessPartnerSelectedCompanyIdRef = useRef<string>(null);
  const [ownBusinessPartners, setOwnBusinessPartners] = useState(null);
  const [headerName, setHeaderNameOwn] = useState<any>({} as any);
  const [sanitizeColOwn, setSanitizeColOwn] = useState([]);
  const [sanitizeColBusiness, setSanitizeColBusiness] = useState([]);
  const [headerNameBp, setHeaderNameBusiness] = useState<any>({} as any);
  const [templateName, setTemplateNameOwn] = useState<string>("");
  const [templateNameBp, setTemplateNameBusiness] = useState<string>("");
  const [newMappingOwn, setNewMappingOwn] = useState<boolean>(false);
  const [newMappingBusiness, setNewMappingBusiness] = useState<boolean>(false);
  const [displayTemplateNameOwn, setDisplayTemplateNameOwn] = useState<string>(null);
  const [displayTemplateNameBusiness, setDisplayTemplateNameBusiness] = useState<string>(null);
  const summaryUIRef = useRef<OutputUISummary>(null);
  const [showLoadingIconOwn, setShowLoadingIconOwn] = useState<boolean>(false);
  const [showLoadingIconBusinessPartner, setShowLoadingIconBusinessPartner] = useState<boolean>(false);
  const [uploadedFileNameOwn, setUploadedFileNameOwn] = useState<string[]>(null);
  const [uploadedFileNameBusinessPartner, setUploadedFileNameBusinessPartner] = useState<string[]>(null);
  const [uploadedLastModifiedDateOwn, setUploadedLastModifiedDateOwn] = useState(null);
  const [uploadedLastModifiedDateBusinessPartner, setUploadedLastModifiedDateBusinessPartner] = useState(null);
  // const [showUploadWarningAlert, setShowUploadWarningAlert] = useState<boolean>(false);
  const [rulesArray, setRulesArray] = useState([]);
  const [showReconcileLoadingIcon, setShowReconcileLoadingIcon] = useState<boolean>(false);
  const [waitingForOutputFile, setWaitingForOutputFile] = useState<boolean>(false);
  const [openOwnPdfDialog, setOpenOwnPdfDialog] = useState<boolean>(false);
  const [openBusinessPartnerPdfDialog, setOpenBusinessPartnerPdfDialog] = useState<boolean>(false);
  const [openPdfDialogErrorMessage, setOpenPdfDialogErrorMessage] = useState<boolean>(false);
  const [pdfDialogErrorMessage, setPdfDialogErrorMessage] = useState<any>("");
  const [ownPdfTableData, setOwnPdfTableData] = useState(null);
  const [businessPartnerPdfTableData, setBusinessPartnerPdfTableData] = useState(null);
  const [paymentGroupBy, setPaymentGroupBy] = useState([""]);
  const [expanded, setExpanded] = useState(null);
  const [selectedBusinessPartnerName, setSelectedBusinessPartnerName] = useState<string>("");
  const [selectedBusinessPartnerVendorCode, setSelectedBusinessPartnerVendorCode] = useState<string>("");
  const [ownDateSyncFromSAP, setOwnDateSyncFromSAP] = useState<DateSyncFromSAP>({} as DateSyncFromSAP);
  const [businessPartnerDateSyncFromSAP, setbusinessPartnerDateSyncFromSAP] = useState<DateSyncFromSAP>(null);
  const [businessPartnerIntegrationWithERP, setBusinessPartnerIntegrationWithERP] = useState<boolean>(false);
  const [businessPartnerAccountingSoftware, setbusinessPartnerAccountingSoftware] = useState<string>(null);
  const [assignBusinessPartnerName, setAssignBusinessPartnerName] = useState(null);
  const assignBusinessPartnerNameRef = useRef(null);
  const [ownSpecificConfig, setOwnSpecificConfig] = useState(false);
  const [disableOwnSpecificConfigCheckbox, setDisableOwnSpecificConfigCheckbox] = useState(true);
  const [configListOwn, setConfigListOwn] = useState<LedgerConfigTemplate[]>([]);
  const [configListBp, setConfigListBp] = useState<LedgerConfigTemplate[]>([]);
  const [configList, setConfigList] = useState<Template[]>([]);
  const [defTemplateId, setDefTemplateId] = useState<number>(null); // RU default id
  const [openColumnHeaderDialog, setOpenColumnHeaderDialog] = useState<boolean>(false);
  const [openDocumentTypeDialog, setOpenDocumentTypeDialog] = useState<boolean>(false);
  const [columnHeaderArr, setColumnHeaderArr] = useState<any>([]);
  const [highlightedHeader, setHighlightedHeader] = useState(null);
  const [listColumnHeaderEntries, setListColumnHeaderEntries] = useState([]);
  const [documentTypesArr, setDocumentTypesArr] = useState<UnIdentifiedDoctype[]>([]);
  const groupedDocTypesArr = useRef<{ [k: string]: UnIdentifiedDoctype[] }>({});
  const [configUserType, setConfigUserType] = useState<string>("");
  const [configUserName, setConfigUserName] = useState<string>("");
  const finaldocumentTypesObj = useRef<any>({});
  const ignoreDoctypesList = useRef<string[]>([]);
  const [currentInProgressTicket, setCurrentInProgressTicket] = useState<any>(undefined);
  const currentInProgressTicketRef = useRef<any>(undefined);
  const [createNewTicket, setCreateNewTicket] = useState<boolean>(false);
  const [ticketUsers, setTicketUsers] = useState<TicketUser[]>([]);
  const [ticketStatuses, setTicketStatuses] = useState<TicketStatus[]>([]);
  const [allTickets, setAllTickets] = useState<AllTickets>(null);
  const [whichActionRequired, setWhichActionRequired] = useState<string>(null);
  const [whichActionRequiredDisable, setWhichActionRequiredDisable] = useState(false);
  const [fetchReconStatusStart, setFetchReconStatusStart] = useState(false);
  const [initiatedReconOn, setInitiatedReconOn] = useState(null);
  const [openLedgerUploadStatusModal, setOpenLedgerUploadStatusModal] = useState(false);
  const [fileStatus, setFileStatus] = useState(null);
  const [disableUploadBothSide, setDisableUploadBothSide] = useState(false);
  const [errorMsgUploadFail, setErrorMsgUploadFail] = useState("");
  const uploadSideRef = useRef(null);
  const [configMappedOwn, setConfigMappedOwn] = useState(null);
  const [configMappedBp, setConfigMappedBp] = useState(null);

  const documentTypes =
    props.subTab === "Payment Advice"
      ? [
          "Invoice",
          "Discount/Rebate",
          "DN/CN",
          "Reversal",
          "TDS",
          "Inter Document",
          "Ignore this time",
          "Never ask again",
        ]
      : ["Invoice", "Payment", "DN/CN", "Reversal", "TDS", "Inter Document", "Ignore this time", "Never ask again"];

  const documentTypeSuggestionMap: Record<string, any> = {
    Invoice: "Invoice",
    Payments: "Payment",
    DNCN: "DN/CN",
    Reversal: "Reversal",
    InternalDoc: "Inter Document",
    TDS: "TDS",
    Ask: "Ignore this time",
    NeverAskAgain: "Never ask again",
  };

  const [excelFileUploadOnChangeEvent, setExcelFileUploadOnChangeEvent] = useState<File[]>([]);
  const storeFileAfterUploadRef = useRef<File[]>([]);
  const [excelFileUploadOnChangeFileName, setexcelFileUploadOnChangeFileName] = useState<
    typeof OWN_LEDGER | typeof BUSINESS_PARTNER_LEDGER | ""
  >("");
  const [listCompaniesForRecon, setListCompaniesForRecon] = useState([]);
  const [listBranchesForRecon, setListBranchesForRecon] = useState([]);
  const companyId = useRef<string>(null);
  const branchCode = useRef<string>(null);
  const [summaryTypeSelected, setSummaryTypeSelected] = useState<string>(null);
  const summaryTypeSelectedRef = useRef<string>(null);
  const [showUploadedSummary, setShowUploadedSummary] = useState<boolean>(false);
  const [showUploadedSummaryObject, setShowUploadedSummaryObject] = useState<any>(null);
  const [showConfigMapped, setShowConfigMapped] = useState<boolean>(false);
  const [showConfigMappedData, setShowConfigMappedData] = useState<FileWiseDocTypeAndColumnHeader>(null);
  const selectedPdfConverter = useRef<string>("PDFLedgerParser");
  const [disablePdfTableOptionForMultiplePdf, setDisablePdfTableOptionForMultiplePdf] = useState<boolean>(false);
  const [pdfConversionLoader, setPdfConversionLoader] = useState<boolean>(true);
  const totalNumberOfUploadedFiles = useRef<number>(null);
  const currentFileNumber = useRef<number>(0);
  const [debitAmountSignReverseFlagOwn, setDebitAmountSignReverseFlagOwn] = useState<boolean>(false);
  const [debitAmountSignReverseFlagBP, setDebitAmountSignReverseFlagBP] = useState<boolean>(false);
  const [creditAmountSignReverseFlagOwn, setCreditAmountSignReverseFlagOwn] = useState<boolean>(false);
  const [creditAmountSignReverseFlagBP, setCreditAmountSignReverseFlagBP] = useState<boolean>(false);
  const [fetchDataFromERP, setFetchDataFromERP] = useState<boolean>(false);
  const [companiesLoaded, setCompaniesLoaded] = useState<boolean>(false);
  const [branchesLoaded, setBranchesLoaded] = useState<boolean>(true);
  const [ownBusinessPartnersLoaded, setOwnBusinessPartnersLoaded] = useState<boolean>(true);

  const [rowsDataOverviewInsights, setRowsDataOverviewInsights] = useState<any>([]);
  const [isExpandedRunRecon, setIsExpandedRunRecon] = useState(false);
  const [isExpandedReconHome, setIsExpandedReconHome] = useState(false);
  const [isExpandedInsights, setIsExpandedInsights] = useState(false);
  const [isExpandedSummary, setIsExpandedSummary] = useState(false);
  const [isExpandedSignOffStatement, setIsExpandedSignOffStatement] = useState(false);
  const [showExpandedInsights, setShowExpandedInsights] = useState(false);
  const [showExpandedSummary, setShowExpandedSummary] = useState(false);
  const [showExpandedSignOffStatement, setShowExpandedSignOffStatement] = useState(false);
  const [accountingTerminology, setAccountingTerminology] = useState<amountEntryType>(null);

  // ---- UseDateFilter start ----//
  const [showUseDateFilter, setShowUseDateFilter] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(null);
  const startDateRef = useRef(null);
  const [endDate, setEndDate] = useState(null);
  const endDateRef = useRef(null);
  // const [openReconciliationPeriodModal, setOpenReconciliationPeriodModal] = useState<boolean>(false);
  const [dateRanges, setDateRanges] = useState<DateRange[]>([]);
  const [loadingReconPeriod, setLoadingReconPeriod] = useState<boolean>(false);
  const [loadingReconSavePeriod, setLoadingReconSavePeriod] = useState<boolean>(false);
  // ----UseDateFilter end ------//

  // ---- disable fields when ledger is not uploaded start----//
  const [ledgerUploadedOwn, setLedgerUploadedOwn] = useState<boolean>(false);
  const [ledgerUploadedBP, setLedgerUploadedBP] = useState<boolean>(false);
  // ---- disable fields when ledger is not uploaded end----//

  // -- UnassignedDoctypeAdvanced Modal start  --//
  const [openUnassignedDoctypeAdvancedDialog, setUnassignedDoctypeAdvancedDialog] = useState<boolean>(false);
  const [itemForUnassignedDoctypeAdvancedDialog, setItemForUnassignedDoctypeAdvancedDialog] = useState(null);
  const [selectedDocumentTypeInUnassignedModal, setSelectedDocumentTypeInUnassignedModal] = useState<string>(null);
  const isChangedDocTypeOnScreenSuggestionMap = useRef<boolean>(false);
  const [documentTypesTags, setDocumentTypesTags] = useState<any>([]);
  const [documentTypeRow, setDocumentTypeRow] = useState([]);
  const [duplicateTagName, setDuplicateTagName] = useState("");
  // -- UnassignedDoctypeAdvanced Modal end  --//

  const [loadingConfigBP, setLoadingConfigBP] = useState<boolean>(false);
  const [loadingConfigOwn, setLoadingConfigOwn] = useState<boolean>(false);

  // -----Dispute UI------
  const [openMsgDialog, setOpenMsgDialog] = useState<boolean>(false);
  const [invoiceAmountMismatchDispute, setInvoiceAmountMismatchDispute] = useState<any>();
  const [invoiceAmountMismatchDisputeValue, setInvoiceAmountMismatchDisputeValue] = useState<any>();

  // -----------------------------

  // ------Summary Details-----
  const summaryDetailsData = useRef([]);
  const [loader, setLoader] = useState<boolean>(false);
  // ------

  // --------open pdf option confirm------
  const [openConfirmOptionModal, setOpenConfirmOptionModal] = useState<boolean>(false);
  const isPdfOptionConfirm = useRef<boolean>(false);
  // --------
  // Manual Recon--------
  const [openManualRecon, setOpenManualRecon] = useState<boolean>(false);
  const [hasReconciled, setHasReconciled] = useState<boolean>(false);
  const [amountTolerance, setAmountTolerance] = useState<number>(null);
  const [checkboxDataManualRecon, setCheckboxDataManualRecon] = useState<ManualReconciledEntriesTrack[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [hasManuallyReconciled, setHasManuallyReconciled] = useState<boolean>(true);
  // ---------

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showDownloadResultsLoadingIcon, setShowDownloadResultsLoadingIcon] = useState<boolean>(false);

  // sign off statement
  const [dateOfLastRecon, setDateOfLastRecon] = useState(null);
  const [dateOfLastSummary, setDateOfLastSummary] = useState(null);
  const [showDownloadGapSummary, setShowDownloadGapSummary] = useState<boolean>(false);
  const EMAIL_TEMPLATE_NAME = "Share Recon with Partner";
  const [openSendMailDialog, setOpenSendMailDialog] = useState(false);
  const [emailTemplateContent, setEmailTemplateContent] = useState<EmailTemplate>(null);
  const [templateBody, setTemplateBody] = useState<string>("");
  const [reconEmailFiles, setReconEmailFiles] = useState<{
    originalOwnLedger: File[];
    originalBPLedger: File[];
    summaryOutput: File;
  }>({
    originalBPLedger: [],
    originalOwnLedger: [],
    summaryOutput: null,
  });
  const ledgerFiles = useRef<File[]>([]);
  const fileDetails = useRef({});
  const [openReuseOldFilesModal, setOpenReuseOldFilesModal] = useState(false);
  const isFileReuploaded = useRef(false);
  const bpSelectedOptionRef = useRef<OptionObj>(null);
  const currentEditableBpId = useRef<string>(null);

  const guidCommonTimestamp = useRef<string>("");
  const [fileKeysForPdfNotCorrectMark, setFileKeysForPdfNotCorrectMark] = useState(null);
  const [openManualMatches, setOpenManualMatches] = useState(false);
  const [manualReconChanges, setManualReconChanges] = useState<ManualReconChanges[]>([]);
  // const [defaultRuConfigId, setDefaultRuConfigId] = useState<number>(null); // also default RU template Id

  // probably tds gst modal
  const [openProbablyTdsGstModal, setOpenProbablyTdsGstModal] = useState<boolean>(false);
  const probablyTdsGstArr = useRef<{ TDS: number; GST: number; count: number; selectedOption: string }[]>([]);

  // select ERP Template
  const isOpenSelectTemplateUploadConfirmRefOwn = useRef(false);
  const isOpenSelectTemplateUploadConfirmRefBp = useRef(false);
  const isUploadFileAgainAfterSelectTempSaveRef = useRef(false);
  const [openSelectTemplateOwn, setOpenSelectTemplateOwn] = useState<boolean>(false);
  const [openSelectTemplateBp, setOpenSelectTemplateBp] = useState<boolean>(false);
  // Manual Changes Confirm Modal
  const [openManualChangesConfirmModal, setOpenManualChangesConfirmModal] = useState<boolean>(false);
  const currency = useRef<string>(DefaultCurrency.INR);

  // reuse config
  const [isUsedAsTemplateOwn, setIsUsedAsTemplateOwn] = useState<boolean>(false);
  const [isUsedAsTemplateBp, setIsUsedAsTemplateBp] = useState<boolean>(false);

  // file sheets array
  const [openSheetSelectionModal, setOpenSheetSelectionModal] = useState(false);
  const sheetsArrRef = useRef<FileData[]>([]);
  const [selectedSheets, setSelectedSheets] = useState<FileData[]>([]);
  const isSheetSelectionFinishRef = useRef(false);
  const [sheetNameForUnidentified, setSheetNameForUnidentified] = useState<string>(null);
  const [fileNameForUnidentified, setFileNameForUnidentified] = useState<string>(null);

  // ErrorInUploadModal
  const [openErrorInUploadModal, setOpenErrorInUploadModal] = useState(false);
  const [uploadErrorData, setUploadErrorData] = useState([]);
  // polling api
  const startInterval = useRef(null);
  const [generatingSummary, setGeneratingSummary] = useState(false);

  // RestrictiveLoader
  const [openRestrictiveLoader, setOpenRestrictiveLoader] = useState(false);
  const [restrictiveLoaderMsg, setRestrictiveLoaderMsg] = useState("");
  let pollingCounterUploadLedgerStatus = 0;
  let pollingCounterFetchReconStatus = 0;

  //discard and save ticket modals
  const [showSaveTicketModal, setShowSaveTicketModal] = useState<boolean>(false);
  const [showDiscardTicketModal, setShowDiscardTicketModal] = useState<boolean>(false);

  const [openUnassignedDoctypeDialogFromView, setOpenUnassignedDoctypedDialogFromView] = useState(false);

  // LinkOldReco
  const [openLinkOldReco, setOpenLinkOldReco] = useState(false);
  const [openUnlinkOldReco, setOpenUnlinkOldReco] = useState(false);
  const [linkOldRecoTask, setLinkOldRecoTask] = useState([]);
  const [unlinkOldRecoTask, setUnlinkOldRecoTask] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);

  // checklist and notes
  const [showChecklistAndNotesIcon, setShowChecklistAndNotesIcon] = useState(false);
  const [openChecklistModal, setOpenChecklistModal] = useState(false);
  const [openAddChecklistModal, setOpenAddChecklistModal] = useState(false);
  const [checklistTitle, setChecklistTitle] = useState("");
  const [checklistCategory, setChecklistCategory] = useState("");
  const [checklistDynamicContent, setChecklistDynamicContent] = useState<string>("");
  const isViewChecklistRef = useRef(false);
  const [listOfChecklist, setListOfChecklist] = useState([]);
  const selectedChecklistIDRef = useRef(null);
  const [addChecklistLoader, setAddChecklistLoader] = useState(false);
  const [markChecklistLoader, setMarkChecklistLoader] = useState(false);

  // notes
  const [openNotesModal, setOpenNotesModal] = useState(false);
  // State to manage selected tags
  const tagsList = ["Own Side", "Partner Side", "Invoice", "Payment", "DNCN", "TDS", "Reversal", "Internal Doc"];
  const [selectedNotesTags, setSelectedNotesTags] = useState([]);
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const isViewNotesListRef = useRef(false);
  const [notesTitle, setNotesTitle] = useState("");
  const [selectedNotesTagsForAdd, setSelectedNotesTagsForAdd] = useState(["Partner Side"]);
  const [notesDynamicContent, setNotesDynamicContent] = useState("");
  const [listOfNotes, setListOfNotes] = useState([]);
  const selectedNotesRef = useRef(null);
  const [addNotesLoader, setAddNotesLoader] = useState(false);

  /**
   * react hook
   * runs first time only when dependency array is empty
   * if empty array not given than it runs everytime on the change dependency array
   * dependency array defines variable on which use effect is used e.g: api call on first load of page
   * @public
   */
  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  useEffect(() => {
    setPrevLocation(location);
  }, [location, prevLocation]);

  useEffect(() => {
    if (!pathParam) {
      // history.push(`/${pathWay}/${DEFAULT_ACTIVE_TAB}`);
      history.push(`/${pathWay}/${DEFAULT_ACTIVE_TAB}/${props.subTab}`);
    }
    getLedgerConfigList();
    listReconSummaryAccountingSetting();
    fetchTickets();
    if (actor.integration === true) {
      listCompanies();
    } else {
      listAllOwnBusinessPartnerMapping();
    }
    useFetch<ListSanitizationRulesResponse>("/api/listSanitizeRule", "GET", {
      thenCallBack: (response) => {
        setRulesArray(response.data.sanitizationRules);
      },
    });
    clearAllStates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subTab]);

  const UpdateTallyClosingBalance = () => {
    const Response = {
      businessPartnerId: 0,
      closingBalance: 0,
      closingBalanceDate: "",
      openingBalance: 0,
    };

    useFetch<typeof Response>("/api/UpdateClosingBalanceForRecon", "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        startDate: startDateRef.current ? moment(startDateRef.current)?.format("YYYY-MM-DD") : null,
        endDate: endDateRef.current ? moment(endDateRef.current)?.format("YYYY-MM-DD") : null,
      },
      thenCallBack: (res) => {
        if (res.data.businessPartnerId === businessPartnerSelected) setOwnClosingBalance(res.data.closingBalance);
      },
    });
  };

  const fetchTickets = () => {
    useFetch<GetAllTicketsResponse>("/api/tickets/FetchTickets", "GET", {
      thenCallBack: (response) => {
        setAllTickets(response.data.allTickets);
        setTicketUsers(response.data.users ? response.data.users : []);
        setTicketStatuses(response.data.statuses ? response.data.statuses : []);
      },
    });
  };

  const listReconSummaryAccountingSetting = () => {
    useFetch<ListReconSummaryAccountingSettingResponse>("/api/ListReconSummaryAccountingSetting", "GET", {
      thenCallBack: (response) => {
        setSummaryTypeSelected(response.data.reconSummaryAccountingSetting.summaryTypeSelected);
        summaryTypeSelectedRef.current = response.data.reconSummaryAccountingSetting.summaryTypeSelected;
        setAccountingTerminology(response.data.amountEntryType);
      },
    });
  };

  /* eslint-disable react-hooks/rules-of-hooks */

  const ListAllConfigTemplateNames = async () => {
    return new Promise<ConfigTemplateNames>((resolve, reject) => {
      useFetch<ConfigTemplateNames>("/api/ListRuConfigTemplates", "GET", {
        thenCallBack: (response) => {
          if (response.data?.defaultTemplateId) setDefTemplateId(response.data.defaultTemplateId);
          else setDefTemplateId(null);

          resolve(response.data);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const ListPartnerConfigTemplates = async () => {
    return new Promise<LedgerConfigTemplate[]>((resolve, reject) => {
      useFetch<ListAllLedgerConfigTemplateResponse>("/api/ListAllLedgerConfigTemplate", "GET", {
        config: {
          params: {
            templateType: ConfigTemplateType.Partner,
          },
        },
        thenCallBack: (response) => {
          resolve(response.data.ledgerConfigTemplates);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const getLedgerConfigList = async () => {
    const RUConfigTemplates: Template[] & { IsRuConfigTemplate: boolean }[] = [];
    const PartnerConfigTemplates: Template[] & { IsPartnerConfigTemplate: boolean }[] = [];

    const data = await ListAllConfigTemplateNames(); // get RU Config Templates List and add to normal Ledger Config Templates List
    if (data.templates.length > -1) {
      RUConfigTemplates.push(...data.templates.map((x) => ({ ...x, IsRuConfigTemplate: true })));
    }

    setConfigList(
      data.templates
        .map((template) => ({ ...template, IsRuConfigTemplate: true }))
        .sort((a, b) => {
          return a.templateName.localeCompare(b.templateName, undefined, { sensitivity: "base" });
        })
    );

    const dataPartner = await ListPartnerConfigTemplates(); // get PArtner Config Templates List and add to normal Ledger Config Templates List
    if (dataPartner.length > -1) {
      PartnerConfigTemplates.push(...dataPartner.map((x) => ({ ...x, IsPartnerConfigTemplate: true })));
    }
    await useFetch<ListAllLedgerConfigTemplateResponse>("/api/ListAllLedgerConfigTemplate", "GET", {
      config: {
        params: {
          templateType: ConfigTemplateType.ERP,
        },
      },
      thenCallBack: (response) => {
        if (response?.data?.ledgerConfigTemplates?.length > 0) {
          const ERPConfigTemplates: Template[] & { IsERPConfigTemplate: boolean }[] = [];
          const { ledgerConfigTemplates } = response.data;

          ERPConfigTemplates.push(...ledgerConfigTemplates.map((x) => ({ ...x, IsERPConfigTemplate: true })));
          const finalData = [...ERPConfigTemplates];
          const finalDataBp = [...ERPConfigTemplates];
          if (RUConfigTemplates.length > 0) finalData.push(...(RUConfigTemplates as any[]));
          if (PartnerConfigTemplates.length > 0) finalDataBp.push(...(PartnerConfigTemplates as any[]));

          setConfigListOwn(
            finalData.sort((a, b) => {
              return a.templateName.localeCompare(b.templateName, undefined, { sensitivity: "base" });
            })
          );
          setConfigListBp(
            finalDataBp.sort((a, b) => {
              return a.templateName.localeCompare(b.templateName, undefined, { sensitivity: "base" });
            })
          );
        }
      },
    });
  };

  const listCompanies = async () => {
    await useFetch("/api/ListCompanies", "GET", {
      thenCallBack: (response) => {
        setListCompaniesForRecon(response?.data?.companies);
        setCompaniesLoaded(true);
        const lastCompID = response?.data?.lastSelectedCompanyId;
        if (lastCompID) {
          const foundCompany = response.data.companies.find((c) => c.companyId === lastCompID);
          const foundLastCompany = {
            firstLetter: foundCompany?.companyName[0].toUpperCase(),
            ...foundCompany,
          };
          setSelectedCompany(foundLastCompany);
          companyNameSelect({}, foundLastCompany);
        }
      },
    });
  };
  const listBranches = async (companyId) => {
    setBranchesLoaded(false);
    await useFetch("/api/ListBranches", "GET", {
      config: {
        params: {
          companyId,
        },
      },
      thenCallBack: (response) => {
        setListBranchesForRecon(response?.data?.branches);
        setBranchesLoaded(true);
        const lastBranchID = response?.data?.lastSelectedbranch?.branchCode;
        if (lastBranchID) {
          const foundBranch = response.data.branches.find((c) => c.branchCode === lastBranchID);
          const foundLastBranch = {
            firstLetter: foundBranch?.branchName[0].toUpperCase(),
            ...foundBranch,
          };
          setSelectedBranch(foundLastBranch);
          branchNameSelect({}, foundLastBranch);
        }
      },
    });
  };

  const listAllOwnBusinessPartnerMapping = async () => {
    setOwnBusinessPartnersLoaded(false);
    await useFetch<ListAllBusinessPartnersRes>("/api/ListAllBusinessPartners", "GET", {
      config: {
        params: {
          ownId: actor.id,
          companyId: companyId.current,
          branchCode: branchCode.current,
        },
      },
      thenCallBack: (response) => {
        setOwnBusinessPartners(response.data.partnerList);
        setOwnBusinessPartnersLoaded(true);
        listUserEmailTemplates();

        const params = new URLSearchParams(location.search);
        const bpName = atob(params.get("bpName"));

        const foundBpId = response.data.partnerList?.find(
          (value) => value.businessPartnerName === bpName
        )?.businessPartnerId;

        const bpId = Number(params.get("bpId")) || foundBpId;
        if (bpId && bpId !== undefined) {
          businessPartnerSelect(
            {},
            {
              name: bpName,
              id: bpId,
            }
          );
        }
      },
    });
  };

  const getOwnLedgerConfigResponse = async (
    businessPartnerId: number,
    afterConfigInsert: boolean,
    reupload = false
  ) => {
    const data = await ListAllConfigTemplateNames();

    const defaultRuTemplate = data.defaultTemplateId;

    // GET RU Config Templates to show in list of regular Templates while config editing,
    const GetRuConfigTemplate = async (_templateId: number) => {
      return new Promise((resolve: (value: ConfigTemplateRes) => void, reject) => {
        useFetch<ConfigTemplateRes>("/api/GetRuConfigTemplate", "GET", {
          config: {
            params: {
              templateId: _templateId,
            },
          },
          thenCallBack: (response) => {
            if (response.data.template) {
              resolve(response.data);
              // setConfigTemplate(response.data.template.config);
              // setTemplateName(response.data.template.templateName);
            } else reject("template not found " + JSON.stringify(response.data));
          },
          catchCallBack: reject,
          errorCallback: reject,
        });
      });
    };

    // GET recon meta data, to check if recon is run yet or not,

    await useFetch<ListOwnLedgerConfigResponse>("/api/ListOwnLedgerConfig", "GET", {
      config: {
        params: {
          businessPartnerId,
          companyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerName: assignBusinessPartnerNameRef.current,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        },
      },
      thenCallBack: async (response) => {
        let data: ConfigTemplateRes;

        if (
          (props.subTab === RECON360_SUB_TAB.LEDGER && response.data.ledgerConfig.reconTemplateName) ||
          (props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE && response.data.ledgerConfig.paymentAdviceTemplateName)
        ) {
          setNewMappingOwn(true);
          if (defaultRuTemplate && !afterConfigInsert) data = await GetRuConfigTemplate(defaultRuTemplate);
        } else if (defaultRuTemplate && (!afterConfigInsert || response.data.ledgerConfig.reconTemplateName === null)) {
          data = await GetRuConfigTemplate(defaultRuTemplate);

          setNewMappingOwn(true);
        } else {
          setNewMappingOwn(false);
          isOpenSelectTemplateUploadConfirmRefOwn.current = false;
        }

        if (props.subTab === "Ledger") {
          // if own config not available or --if metadata empty (recon is not run)-- , set own config as default RU config
          if (!response.data.ledgerConfig.reconTemplateName && data) {
            setDisplayTemplateNameOwn(data.template.templateName);
            setTemplateNameOwn(data.template.templateName);
            setHeaderNameOwn(data.template.config);
            setDebitAmountSignReverseFlagOwn(data.template.config.debitAmountSignReverseFlag);
            setCreditAmountSignReverseFlagOwn(data.template.config.creditAmountSignReverseFlag);
            setSanitizeColOwn(data.template.columnsToSanitize);
            // setConfigMappedOwn(data?.template?.configMapped || {})
          } else {
            // own
            setDisplayTemplateNameOwn(response.data.ledgerConfig.reconTemplateName);
            setTemplateNameOwn(response.data.ledgerConfig.reconTemplateName);
            setHeaderNameOwn(response.data.ledgerConfig?.reconConfig ? response.data.ledgerConfig?.reconConfig : {});
            setDebitAmountSignReverseFlagOwn(response?.data?.ledgerConfig?.reconConfig?.debitAmountSignReverseFlag);
            setCreditAmountSignReverseFlagOwn(response?.data?.ledgerConfig?.reconConfig?.creditAmountSignReverseFlag);
            setSanitizeColOwn(response.data.ledgerConfig.reconColumnsToSanitize);
            setIsUsedAsTemplateOwn(response.data.ledgerConfig?.isUsedAsTemplate);
            setConfigMappedOwn(response.data?.configMapped || {});
          }
        } else if (props.subTab === "Payment Advice") {
          // own
          setDisplayTemplateNameOwn(response.data.ledgerConfig.paymentAdviceTemplateName);
          setTemplateNameOwn(response.data.ledgerConfig.paymentAdviceTemplateName);
          setHeaderNameOwn(
            response.data.ledgerConfig?.paymentAdviceConfig ? response.data.ledgerConfig?.paymentAdviceConfig : {}
          );
          setDebitAmountSignReverseFlagOwn(
            response?.data?.ledgerConfig?.paymentAdviceConfig?.debitAmountSignReverseFlag
          );
          setCreditAmountSignReverseFlagOwn(
            response?.data?.ledgerConfig?.paymentAdviceConfig?.creditAmountSignReverseFlag
          );
          setSanitizeColOwn(response.data.ledgerConfig.paymentAdviceColumnsToSanitize);
          setConfigMappedOwn(response.data?.configMapped || {});
        }
        setLoadingConfigOwn(false);
        if (excelFileUploadOnChangeFileName === OWN_LEDGER && !reupload) {
          uploadFileAgainAfterSelectTempSave();
        }
      },
      catchCallBack: () => {
        setNewMappingBusiness(false);
        setLoadingConfigOwn(false);
      },
    });
  };
  const getBusinessPartnerLedgerConfigResponse = async (
    businessPartnerId: number,
    afterConfigInsert = false,
    reupload = false
  ) => {
    getOwnLedgerConfigResponse(businessPartnerId, afterConfigInsert, reupload);
    await useFetch<ListBusinessPartnerLedgerConfigResponse>("/api/ListBusinessPartnerLedgerConfig", "GET", {
      config: {
        params: {
          businessPartnerId,
          companyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerName: assignBusinessPartnerNameRef.current,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        },
      },
      thenCallBack: (response) => {
        if (
          (props.subTab === RECON360_SUB_TAB.LEDGER && response.data.ledgerConfig.reconTemplateName) ||
          (props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE && response.data.ledgerConfig.paymentAdviceTemplateName)
        ) {
          setNewMappingBusiness(true);
        } else {
          setNewMappingBusiness(false);
          isOpenSelectTemplateUploadConfirmRefBp.current = false;
        }

        if (props.subTab === "Ledger") {
          // bp
          setDisplayTemplateNameBusiness(response.data.ledgerConfig.reconTemplateName);
          setTemplateNameBusiness(response.data.ledgerConfig.reconTemplateName);
          setHeaderNameBusiness(response.data.ledgerConfig?.reconConfig ? response.data.ledgerConfig?.reconConfig : {});
          setDebitAmountSignReverseFlagBP(response?.data?.ledgerConfig?.reconConfig?.debitAmountSignReverseFlag);
          setCreditAmountSignReverseFlagBP(response?.data?.ledgerConfig?.reconConfig?.creditAmountSignReverseFlag);
          setSanitizeColBusiness(response.data.ledgerConfig.reconColumnsToSanitize);
          setIsUsedAsTemplateBp(response.data.ledgerConfig?.isUsedAsTemplate);
          setConfigMappedBp(response.data?.configMapped || {});
        } else if (props.subTab === "Payment Advice") {
          // bp
          setDisplayTemplateNameBusiness(response.data.ledgerConfig.paymentAdviceTemplateName);
          setTemplateNameBusiness(response.data.ledgerConfig.paymentAdviceTemplateName);
          setHeaderNameBusiness(
            response.data.ledgerConfig?.paymentAdviceConfig ? response.data.ledgerConfig?.paymentAdviceConfig : {}
          );
          setDebitAmountSignReverseFlagBP(
            response?.data?.ledgerConfig?.paymentAdviceConfig?.debitAmountSignReverseFlag
          );
          setCreditAmountSignReverseFlagBP(
            response?.data?.ledgerConfig?.paymentAdviceConfig?.creditAmountSignReverseFlag
          );
          setSanitizeColBusiness(response.data.ledgerConfig.paymentAdviceColumnsToSanitize);
          setConfigMappedBp(response.data?.configMapped || {});
        }
        setLoadingConfigBP(false);
        if (excelFileUploadOnChangeFileName === BUSINESS_PARTNER_LEDGER && !reupload) {
          // if not from reupload and from bp select then go here
          uploadFileAgainAfterSelectTempSave();
        }
      },
      catchCallBack: () => {
        setNewMappingBusiness(false);
        setDisplayTemplateNameBusiness("");
        setTemplateNameBusiness("");
        setHeaderNameBusiness({});
        setLoadingConfigBP(false);
      },
    });
  };

  /** This is a column definition of the grid on Own Entry Adjustment page - Pending sub-tab
   * @public
   */

  const reconSummaryDetailColDefImported = reconSummaryDetailColDef();
  /** This is a column definition of the grid on Own Entry Adjustment page - Completed sub-tab
   * @public
   */

  const invoiceAmountMismatchColDef = AmountMismatchColDef(
    adjustEntry,
    raiseDisputeHandler,
    "own_invoice_match",
    "doc_own"
  );
  const paymentAmountMismatchColDef = AmountMismatchColDef(
    adjustEntry,
    raiseDisputeHandler,
    "own_payment_match",
    "doc_own"
  );
  const tdsAmountMismatchColDef = AmountMismatchColDef(
    adjustEntry,
    raiseDisputeHandler,
    "own_invoice_match",
    "doc_date_qtr"
  );
  const dnCnAmountMismatchColDef = AmountMismatchColDef(
    adjustEntry,
    raiseDisputeHandler,
    "own_dncn_match",
    "doc_date_qtr"
  );

  const invoiceNotPresentInPartnerLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Own",
    "doc_own"
  );
  const tdsNotPresentInPartnerLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Own",
    "doc_date_qtr"
  );
  const paymentNotPresentInPartnerLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Own",
    "doc_date_qtr"
  );
  const dnCnNotPresentInPartnerLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Own",
    "doc_date_qtr"
  );

  const invoiceNotPresentInOwnLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Business Partner",
    "doc_businessPartner"
  );
  const tdsNotPresentInOwnLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Business Partner",
    "doc_date_qtr_sup"
  );
  const paymentNotPresentInOwnLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Business Partner",
    "doc_date_qtr_sup"
  );
  const dnCnNotPresentInOwnLedgerColDef = DocNotPresentInLedgerColDef(
    adjustEntry,
    raiseDisputeHandler,
    "Business Partner",
    "doc_date_qtr_sup"
  );

  function adjustEntry(e: any) {
    //
    if (detailsHeadingName.current === reconciliationStatus.invoiceAmountMismatch) {
      const tempInvoicePaymentsDNCNSummary = summaryDetailsData.current;
      for (const [i, value] of tempInvoicePaymentsDNCNSummary.entries()) {
        if (e === value.doc_own) {
          tempInvoicePaymentsDNCNSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(invoiceAmountMismatchColDef);
      setPaymentGroupBy(["own_invoice_match"]);

      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(tempInvoicePaymentsDNCNSummary);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInPartnerLedger) {
      const tempInvoicePaymentsDNCNSummary = summaryDetailsData.current;
      for (const [i, value] of tempInvoicePaymentsDNCNSummary.entries()) {
        if (e === value.doc_own) {
          tempInvoicePaymentsDNCNSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(invoiceNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInOwnLedger) {
      const tempInvoicePaymentsDNCNSummary = summaryDetailsData.current;
      for (const [i, value] of tempInvoicePaymentsDNCNSummary.entries()) {
        if (e === value.doc_businessPartner) {
          tempInvoicePaymentsDNCNSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(invoiceNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentAmountMismatch) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_own) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }

      setColumnDefinition(paymentAmountMismatchColDef);
      setPaymentGroupBy(["own_payment_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_payment_match.length > 0) {
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInPartnerLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(paymentNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInOwnLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(paymentNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }

      setColumnDefinition(dnCnAmountMismatchColDef);
      setPaymentGroupBy(["own_dncn_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_dncn_match.length > 0) {
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(dnCnNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInOwnLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(tdsNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(tdsNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsAmountMismatch) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(tdsAmountMismatchColDef);
      setPaymentGroupBy(["own_invoice_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
        if (item.own_payment_match.length > 0) {
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
        if (item.own_dncn_match.length > 0) {
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA) {
      const tempSummary = summaryDetailsData.current;
      for (const [i, value] of tempSummary.entries()) {
        if (e === value.doc_date_qtr_sup) {
          tempSummary[i].entry_adjusted = "Entry Adjusted";
        }
      }
      setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    }
    //
  }

  function raiseDisputeHandler(doc: string, value: number) {
    setOpenMsgDialog(true);
    setInvoiceAmountMismatchDispute(doc);
    setInvoiceAmountMismatchDisputeValue(value);
  }

  const createTicket = async (data) => {
    data.businessPartnerId = businessPartnerSelected;
    data.companyId = companyId.current;
    data.branchCode = branchCode.current;
    useFetch("/api/tickets/CreateTicket", "POST", {
      showSuccessToast: true,
      data: data,
      thenCallBack: (response) => {
        setCurrentInProgressTicket(response.data.ticketDetails);
        currentInProgressTicketRef.current = response.data.ticketDetails;
        getReconLinkInfo();
        fetchTickets();

        if (dateOfLastRecon) {
          getSummaryForUI();
        }
        fetchReconStatus();
      },
    });
  };

  // -------- upload new flow --------

  const startUploadLoadingIcon = (uploadSide: string) => {
    if (uploadSide === OWN_LEDGER) {
      setShowLoadingIconOwn(true);
    } else if (uploadSide === BUSINESS_PARTNER_LEDGER) {
      setShowLoadingIconBusinessPartner(true);
    }
  };
  const stopUploadLoadingIcon = (uploadSide: string) => {
    if (uploadSide === OWN_LEDGER) {
      setShowLoadingIconOwn(false);
    } else if (uploadSide === BUSINESS_PARTNER_LEDGER) {
      setShowLoadingIconBusinessPartner(false);
    }
  };

  //  this api after sheet selection
  const uploadLedgerAfterSheetSelection = (uploadSide: string, fileMap) => {
    uploadSideRef.current = uploadSide;
    setWhichActionRequired(WhichActionRequiredStatus.UploadInProgress);
    setDisableUploadBothSide(true);
    const bodyFormData = new FormData();
    for (const item of storeFileAfterUploadRef.current) {
      bodyFormData.append("Files", item);
    }
    bodyFormData.append("guid", guidCommonTimestamp.current);
    bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
    bodyFormData.append("companyId", companyId.current);
    bodyFormData.append("branchCode", branchCode.current);
    bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
    bodyFormData.append("isFileSheetSelected", "true");
    bodyFormData.append("useCase", uploadSide === OWN_LEDGER ? "OWN" : "BP");
    bodyFormData.append("fileMap", JSON.stringify(fileMap));
    bodyFormData.append(
      "configUseCaseType",
      props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
    );
    startUploadLoadingIcon(uploadSide);

    useFetch("/api/manualUpload/v2/uploadLedger", "POST", {
      data: bodyFormData,
      config: {
        headers: { "Content-Type": "multipart/form-data" },
      },
      thenCallBack: () => {
        uploadLedgerStatus();
      },
      catchCallBack: () => {
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
        stopUploadLoadingIcon(uploadSide);
      },
    });
  };

  const reUploadLedger = (isFileReuploaded) => {
    setWhichActionRequired(WhichActionRequiredStatus.UploadInProgress);
    setDisableUploadBothSide(true);
    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
    bodyFormData.append("companyId", companyId.current);
    bodyFormData.append("branchCode", branchCode.current);
    bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
    bodyFormData.append("isFileReuploaded", isFileReuploaded);
    bodyFormData.append("useCase", uploadSideRef.current === OWN_LEDGER ? "OWN" : "BP");
    bodyFormData.append(
      "configUseCaseType",
      props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
    );
    startUploadLoadingIcon(uploadSideRef.current);

    useFetch("/api/manualUpload/v2/reuploadLedger", "POST", {
      data: bodyFormData,
      config: {
        headers: { "Content-Type": "multipart/form-data" },
      },
      thenCallBack: () => {
        uploadLedgerStatus();
      },
      catchCallBack: () => {
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
        stopUploadLoadingIcon(uploadSideRef.current);
      },
    });
  };

  const cancelUploadRequest = async () => {
    await useFetch("/api/manualUpload/v2/cancelUploadRequest", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        useCase: uploadSideRef.current === OWN_LEDGER ? "OWN" : "BP",
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
      },
      thenCallBack: () => {
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
      },
    });
  };
  const cancelReconRequest = async () => {
    setWhichActionRequiredDisable(true);
    await useFetch("/api/ProcessRecon/CancelRecon", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyId,
      },
      thenCallBack: () => {
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
        setShowReconcileLoadingIcon(false);
        setWhichActionRequiredDisable(false);
      },
      catchCallBack: () => {
        setWhichActionRequiredDisable(false);
      },
    });
  };

  const cancelPollingRequest = async () => {
    await useFetch("/api/manualUpload/v2/cancelPollingRequest", "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        useCase: uploadSideRef.current === OWN_LEDGER ? "OWN" : "BP",
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
      },
    });
  };

  const uploadLedgerStatus = () => {
    useFetch("/api/manualUpload/v2/uploadStatus", "GET", {
      config: {
        params: {
          companyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerId: businessPartnerSelectedRef.current,
        },
      },
      thenCallBack: (response) => {
        uploadSideRef.current =
          response.data.useCase === "OWN"
            ? OWN_LEDGER
            : response.data.useCase === "BP"
            ? BUSINESS_PARTNER_LEDGER
            : null;
        if (response.data.status === UploadStatus.InProgress) {
          // If status is 'In progress', call uploadLedger again after 10 seconds
          startUploadLoadingIcon(uploadSideRef.current);
          setWhichActionRequired(WhichActionRequiredStatus.UploadInProgress);
          setDisableUploadBothSide(true);
          const timeoutDuration = pollingCounterUploadLedgerStatus < 5 ? 5000 : 10000; // 5 seconds for first 4 times, then 10 seconds
          setTimeout(() => {
            if (businessPartnerSelectedRef.current) {
              pollingCounterUploadLedgerStatus++; // Increment the retry counter
              uploadLedgerStatus();
            }
          }, timeoutDuration);
        } else if (response.data.status === UploadStatus.ActionRequired) {
          pollingCounterUploadLedgerStatus = 0; // Reset the retry counter if the status is not 'In progress'
          stopUploadLoadingIcon(uploadSideRef.current);
          setDisableUploadBothSide(true);
          // Do something when status is not 'In progress'
          if (uploadSideRef.current === OWN_LEDGER) {
            clarity.setTag("Own Ledger Upload", `Excel`);
            uiLogger(uiLoggerName.ui_UploadOwnLedger, fileTypeLogger.excel);
            setShowLoadingIconOwn(false);
            setSheetNameForUnidentified(response?.data?.output?.result?.sheetname);
            setFileNameForUnidentified(response?.data?.output?.filename);
            setFileStatus(response.data.fileStatus);

            if (response.data?.output?.result.response === "warning-unidentified") {
              setWhichActionRequired(WhichActionRequiredStatus.UnIdentifiedColumnHeaders);
            } else if (response?.data?.output?.result?.response === "warning-nonEmptyDocTypes") {
              setWhichActionRequired(WhichActionRequiredStatus.PotentiallyMissedDocumentTypes);
            }

            if (response?.data?.output?.result?.unIdentifiedColumns.length > 0) {
              const columnHeaderArrwithErrorFlag = response?.data?.output?.result?.unIdentifiedColumns.map(
                (object: any) => {
                  return {
                    ...object,
                    errorFlag: false,
                    columnHeaderConfigValue: object.columnHeaders[object.suggestedHeaderIndex],
                    unassignedColumnHeadersFound: true,
                  };
                }
              );

              const alreadyMappedColumnHeaders =
                Object.keys(response.data.output.result.mappedColumnHeaderList).length > 0
                  ? [response.data.output.result.mappedColumnHeaderList]
                  : [];

              alreadyMappedColumnHeaders.forEach((headerGroup) => {
                Object.keys(headerGroup).forEach((key) => {
                  columnHeaderArrwithErrorFlag.push({
                    columnHeaders: columnHeaderArrwithErrorFlag[0]?.columnHeaders,
                    columnHeaderName: key,
                    suggestedHeaderIndex: 0,
                    errorFlag: false,
                    columnHeaderConfigValue: headerGroup[key],
                    alreadyMappedColumnHeaders: true,
                  });
                });
              });

              if (response?.data?.output?.result?.defaultConfig)
                setHeaderNameOwn(response?.data?.output?.result?.defaultConfig);
              updateColumnHeadersArrForOptional(columnHeaderArrwithErrorFlag);

              // Create a map of the enum order for quick lookup
              const enumOrderMap: { [key: string]: number } = {};
              columnHeaderNameOrder.forEach((key, index) => {
                enumOrderMap[key] = index;
              });

              // Sort the columnHeaderArrwithErrorFlag based on the enum order
              columnHeaderArrwithErrorFlag.sort((a, b) => {
                return enumOrderMap[a.columnHeaderName] - enumOrderMap[b.columnHeaderName];
              });
              setColumnHeaderArr(columnHeaderArrwithErrorFlag);
              setHighlightedHeader({
                columnHeaderName: columnHeaderArrwithErrorFlag?.[0]?.columnHeaderName,
                columnHeaderConfigValue: columnHeaderArrwithErrorFlag?.[0]?.columnHeaderConfigValue,
              });
              setListColumnHeaderEntries(response?.data?.output?.result?.columnHeadersWithData);
              setFetchDataFromERP(false);
              // setOpenColumnHeaderDialog(true);
              uiLogger(uiLoggerName.ui_OwnAutoConfigOpen);
            }
            if (response?.data?.output?.result?.nonEmptyDocTypes?.length > 0) {
              setDocumentTypesArr(response?.data?.output?.result?.nonEmptyDocTypes);
              finaldocumentTypesObj.current = Object.fromEntries(
                response?.data?.output?.result?.nonEmptyDocTypes.map((item) => [
                  item.unidentifiedText,
                  {
                    documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                    transactionType: 0,
                  },
                ])
              );

              setFetchDataFromERP(false);
              changeDocumentTypeConfig(
                response?.data?.output?.result?.nonEmptyDocTypes[0].unidentifiedText,
                documentTypeSuggestionMap[response?.data?.output?.result?.nonEmptyDocTypes[0].suggestedDoctype]
              );
            } else {
              setDocumentTypesArr([]);
            }

            setConfigUserType(OWN_LEDGER);
            setConfigUserName(actor?.name);
          } else if (uploadSideRef.current === BUSINESS_PARTNER_LEDGER) {
            clarity.setTag("BP Ledger Upload", `Excel`);
            uiLogger(uiLoggerName.ui_UploadPartnerLedger, fileTypeLogger.excel);
            setShowLoadingIconBusinessPartner(false);
            setSheetNameForUnidentified(response?.data?.output?.result?.sheetname);
            setFileNameForUnidentified(response?.data?.output?.filename);
            setFileStatus(response.data.fileStatus);

            if (response?.data?.output?.result?.response === "warning-unidentified") {
              setWhichActionRequired(WhichActionRequiredStatus.UnIdentifiedColumnHeaders);
            } else if (response?.data?.output?.result?.response === "warning-nonEmptyDocTypes") {
              setWhichActionRequired(WhichActionRequiredStatus.PotentiallyMissedDocumentTypes);
            }

            // setEndDate(moment(new Date())?.format("YYYY-MM-DD"));
            // endDateRef.current = moment(new Date())?.format("YYYY-MM-DD");
            if (response?.data?.output?.result?.unIdentifiedColumns.length > 0) {
              const columnHeaderArrwithErrorFlag = response?.data?.output?.result?.unIdentifiedColumns.map(
                (object: any) => {
                  return {
                    ...object,
                    errorFlag: false,
                    columnHeaderConfigValue: object.columnHeaders[object.suggestedHeaderIndex],
                    unassignedColumnHeadersFound: true,
                  };
                }
              );
              // Convert to array of objects or return an empty array if the object is empty
              const alreadyMappedColumnHeaders =
                Object.keys(response.data.output.result.mappedColumnHeaderList).length > 0
                  ? [response.data.output.result.mappedColumnHeaderList]
                  : [];

              alreadyMappedColumnHeaders.forEach((headerGroup) => {
                Object.keys(headerGroup).forEach((key) => {
                  columnHeaderArrwithErrorFlag.push({
                    columnHeaders: columnHeaderArrwithErrorFlag[0]?.columnHeaders,
                    columnHeaderName: key,
                    suggestedHeaderIndex: 0,
                    errorFlag: false,
                    columnHeaderConfigValue: headerGroup[key],
                    alreadyMappedColumnHeaders: true,
                  });
                });
              });

              if (response?.data?.output?.result?.defaultConfig)
                setHeaderNameBusiness(response?.data?.output?.result?.defaultConfig);
              updateColumnHeadersArrForOptional(columnHeaderArrwithErrorFlag);

              // Create a map of the enum order for quick lookup
              const enumOrderMap: { [key: string]: number } = {};
              columnHeaderNameOrder.forEach((key, index) => {
                enumOrderMap[key] = index;
              });

              // Sort the columnHeaderArrwithErrorFlag based on the enum order
              columnHeaderArrwithErrorFlag.sort((a, b) => {
                return enumOrderMap[a.columnHeaderName] - enumOrderMap[b.columnHeaderName];
              });

              setColumnHeaderArr(columnHeaderArrwithErrorFlag);
              setHighlightedHeader({
                columnHeaderName: columnHeaderArrwithErrorFlag?.[0]?.columnHeaderName,
                columnHeaderConfigValue: columnHeaderArrwithErrorFlag?.[0]?.columnHeaderConfigValue,
              });
              // check here section first entries like that
              // setHighlightedHeader({columnHeaderName:"documentType" , columnHeaderConfigValue:"Type"})
              setListColumnHeaderEntries(response?.data?.output?.result?.columnHeadersWithData);
              setFetchDataFromERP(false);

              // setOpenColumnHeaderDialog(true);
              uiLogger(uiLoggerName.ui_BpAutoConfigOpen);
            }

            if (response?.data?.output?.result?.nonEmptyDocTypes?.length > 0) {
              setDocumentTypesArr(response?.data?.output?.result?.nonEmptyDocTypes);
              finaldocumentTypesObj.current = Object.fromEntries(
                response?.data?.output?.result?.nonEmptyDocTypes.map((item) => [
                  item.unidentifiedText,
                  {
                    documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                    transactionType: 0,
                  },
                ])
              );

              setFetchDataFromERP(false);
              changeDocumentTypeConfig(
                response?.data?.output?.result?.nonEmptyDocTypes[0].unidentifiedText,
                documentTypeSuggestionMap[response?.data?.output?.result?.nonEmptyDocTypes[0].suggestedDoctype]
              );
              // if (response?.data?.output?.result?.unIdentifiedColumns.length > 0) {
              //   setOpenDocumentTypeDialog(false);
              // } else {
              //   // setOpenDocumentTypeDialog(true);
              //   uiLogger(uiLoggerName.ui_BpAutoConfigOpen);
              // }
            } else {
              setDocumentTypesArr([]);
            }

            // if (
            //   response?.data?.output?.result?.unIdentifiedColumns.length === 0 &&
            //   response?.data?.output?.result?.nonEmptyDocTypes.length === 0
            // ) {
            //   currentFileNumber.current = currentFileNumber.current + 1;
            //   if (fileArr.length > 1) {
            //     if (currentFileNumber.current < totalNumberOfUploadedFiles.current) {
            //       excelFileUploadOnChange(fileArr, fileName);
            //     } else {
            //       ignoreDoctypesList.current = [];
            //     }
            //   } else {
            //     ignoreDoctypesList.current = [];
            //   }
            // }

            // ------

            setConfigUserType(BUSINESS_PARTNER_LEDGER);
            setConfigUserName(assignBusinessPartnerNameRef.current);
          }
        } else if (response.data.status === UploadStatus.Success) {
          setDisableUploadBothSide(false);
          stopUploadLoadingIcon(uploadSideRef.current);
          if (response.data?.useCase === null && currentInProgressTicketRef.current) {
            fetchReconStatus();
          }

          getBusinessPartnerLedgerConfigResponse(businessPartnerSelectedRef.current, false, false);
          pollingCounterUploadLedgerStatus = 0; // Reset the retry counter if the status is not 'In progress'
          // check for other success and failure cases

          // own
          if (uploadSideRef.current === OWN_LEDGER) {
            getReconMetaData("own");

            // set headers from success response after file successfully uploaded and including configUsedInMappingFile key
            if (props.subTab === "Ledger") {
              setHeaderNameBusiness(response?.data?.output?.result?.config?.reconConfig || {});
            } else if (props.subTab === "Payment Advice") {
              setHeaderNameBusiness(response?.data?.output?.result?.config?.paymentAdviceConfig || {});
            }

            setUploadedLastModifiedDateOwn(
              new Date().getDate() +
                "/" +
                (new Date().getMonth() + 1) +
                "  " +
                new Date().toLocaleTimeString("en-US", {
                  // en-US can be set to 'default' to use user's browser settings
                  hour: "2-digit",
                  minute: "2-digit",
                })
            );

            isOpenSelectTemplateUploadConfirmRefBp.current = false;

            if (response?.data?.output?.result?.uploadSummary) {
              {
                businessPartnerSelectedRef.current && cancelPollingRequest();
              }
              setShowUploadedSummaryObject(response?.data?.output?.result?.uploadSummary);
              setShowConfigMappedData(response?.data?.output?.result?.configMapped);

              // setShowUploadedSummary(true);
              setWhichActionRequired(UploadStatus.Success);

              const summaryObj = response?.data?.output?.result?.uploadSummary;

              setUploadedFileNameOwn(() => {
                const fileNames = summaryObj
                  ? Object.keys(summaryObj).filter((k) => k.toLowerCase() !== UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY)
                  : [];
                return fileNames;
              });
            }
          } else if (uploadSideRef.current === BUSINESS_PARTNER_LEDGER) {
            // business partner
            getReconMetaData("businessPartner");
            //  set headers from success response after file successfully uploaded and including configUsedInMappingFile key
            if (props.subTab === "Ledger") {
              setHeaderNameBusiness(response?.data?.output?.result?.config?.reconConfig || {});
            } else if (props.subTab === "Payment Advice") {
              setHeaderNameBusiness(response?.data?.output?.result?.config?.paymentAdviceConfig || {});
            }

            setUploadedLastModifiedDateBusinessPartner(
              new Date().getDate() +
                "/" +
                (new Date().getMonth() + 1) +
                "  " +
                new Date().toLocaleTimeString("en-US", {
                  // en-US can be set to 'default' to use user's browser settings
                  hour: "2-digit",
                  minute: "2-digit",
                })
            );
            isOpenSelectTemplateUploadConfirmRefBp.current = false;

            if (response?.data?.output?.result?.uploadSummary) {
              {
                businessPartnerSelectedRef.current && cancelPollingRequest();
              }
              setShowUploadedSummaryObject(response?.data?.output?.result?.uploadSummary);
              setShowConfigMappedData(response?.data?.output?.result?.configMapped);

              // setShowUploadedSummary(true);
              setWhichActionRequired(UploadStatus.Success);

              const summaryObj = response?.data?.output?.result?.uploadSummary;

              setUploadedFileNameBusinessPartner(() => {
                const fileNames = Object.keys(summaryObj).filter(
                  (k) => k.toLowerCase() !== UPLOAD_SUMMARY_CONSTANT_TOTAL_KEY
                );
                return fileNames;
              });
            }
          }
        } else if (response.data.status === UploadStatus.Failure) {
          pollingCounterUploadLedgerStatus = 0; // Reset the retry counter if the status is not 'In progress'
          if (response.data.output.message) {
            setErrorMsgUploadFail(response.data.output.message);
          }
          setWhichActionRequired(UploadStatus.Failure);
          setDisableUploadBothSide(false);
          stopUploadLoadingIcon(uploadSideRef.current);
          // cancelPollingRequest();
          // cancelUploadRequest();
        }
      },
      catchCallBack: () => {
        pollingCounterUploadLedgerStatus = 0; // Reset the retry counter if the status is not 'In progress'
        // stopUploadLoadingIcon(uploadSide);
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
        cancelPollingRequest();
      },
    });
  };

  const excelFileUploadOnChange = async (file: File[], fileName: UploadLedgerSide) => {
    const fileArr: File[] = [];
    const firstType = file[0].type;
    const allSameType = Object.keys(file).every((key) => {
      if (firstType.includes("spreadsheet") || firstType.includes("excel")) {
        return file[key].type.includes("spreadsheet") || file[key].type.includes("excel");
      } else {
        return file[key].type === firstType;
      }
    });
    if (file !== undefined && file !== null && file.length > 0) {
      for (const item of file) {
        fileArr.push(item);
      }
      totalNumberOfUploadedFiles.current = fileArr.length;
      storeFileAfterUploadRef.current = fileArr;
      setExcelFileUploadOnChangeEvent(fileArr);
      setexcelFileUploadOnChangeFileName(fileName);

      if (fileArr.length > 1) {
        setDisablePdfTableOptionForMultiplePdf(true);
      } else {
        setDisablePdfTableOptionForMultiplePdf(false);
      }
    }

    if (allSameType) {
      if (firstType === "application/pdf") {
        isOpenSelectTemplateUploadConfirmRefOwn.current = false;
        isOpenSelectTemplateUploadConfirmRefBp.current = false;
        if (!isPdfOptionConfirm.current) {
          setOpenConfirmOptionModal(true);
        } else {
          isPdfOptionConfirm.current = false;
          if (fileName === OWN_LEDGER) {
            setShowLoadingIconOwn(true);
            setDisableUploadBothSide(true);

            const bodyFormData = new FormData();
            bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
            bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
            // bodyFormData.append("user", "own");
            bodyFormData.append("ownId", actor.id);
            bodyFormData.append("pdfConversionMethod", selectedPdfConverter.current);
            bodyFormData.append("isOwnConfig", "true");
            bodyFormData.append(
              "configUseCaseType",
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
            );
            for (const item of file) {
              bodyFormData.append("data", item);
            }
            bodyFormData.append("companyId", companyId.current);
            bodyFormData.append("branchCode", branchCode.current);
            useFetch<UploadPDFFileResponse>("/api/UploadPDFAsExcel", "POST", {
              data: bodyFormData,
              config: { headers: { "Content-Type": "multipart/form-data" } },
              thenCallBack: (response) => {
                setDisableUploadBothSide(false);
                clarity.setTag("Own Ledger Upload", `PDF`);
                setFileKeysForPdfNotCorrectMark(response?.data?.result?.fileKeys);
                setPdfConversionLoader(false);
                setShowLoadingIconOwn(false);

                setOwnPdfTableData({
                  JSON: response.data.result.data.JSON,
                  businessPartnerId: businessPartnerSelected,
                  own: true,
                  data: response.data.result.data.data,
                  fileName: file[0].name, // sending first file name, need to change later
                  workbook: response?.data?.result?.data?.workbook,
                  highlightedColumns: response?.data?.result?.data?.highlightedColumns,
                  errorMessage: response?.data?.result?.data?.errorMessage,
                });
                setPdfDialogErrorMessage(response?.data?.result?.data?.errorMessage);
                response.data.result.data.JSON.length === 0
                  ? setOpenPdfDialogErrorMessage(true)
                  : setOpenOwnPdfDialog(true);

                uiLogger(uiLoggerName.ui_UploadOwnLedger, fileTypeLogger.pdf, selectedPdfConverter.current);
              },
              catchCallBack: () => {
                setDisableUploadBothSide(false);
                setPdfConversionLoader(false);
                setShowLoadingIconOwn(false);
              },
            });
            // };
          } else if (fileName === BUSINESS_PARTNER_LEDGER) {
            setShowLoadingIconBusinessPartner(true);
            setDisableUploadBothSide(true);

            const bodyFormData = new FormData();
            bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
            bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
            // bodyFormData.append("user", "bp");
            bodyFormData.append("ownId", actor.id);
            bodyFormData.append("pdfConversionMethod", selectedPdfConverter.current);
            bodyFormData.append("isOwnConfig", "false");
            bodyFormData.append(
              "configUseCaseType",
              props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
            );
            for (const item of file) {
              bodyFormData.append("data", item);
            }

            useFetch<UploadPDFFileResponse>("/api/UploadPDFAsExcel", "POST", {
              data: bodyFormData,
              config: {
                headers: { "Content-Type": "multipart/form-data" },
              },
              thenCallBack: (response) => {
                clarity.setTag("BP Ledger Upload", `PDF`);
                setDisableUploadBothSide(false);
                setFileKeysForPdfNotCorrectMark(response?.data?.result?.fileKeys);
                setPdfConversionLoader(false);
                setShowLoadingIconBusinessPartner(false);
                setBusinessPartnerPdfTableData({
                  JSON: response.data.result.data.JSON,
                  businessPartnerId: businessPartnerSelected,
                  own: false,
                  data: response.data.result.data.data,
                  fileName: file[0].name, // sending first file name, need to change later
                  workbook: response?.data?.result?.data?.workbook,
                  highlightedColumns: response?.data?.result?.data?.highlightedColumns,
                  errorMessage: response?.data?.result?.data?.errorMessage,
                });
                setPdfDialogErrorMessage(response?.data?.result?.data?.errorMessage);
                response.data.result.data.JSON.length === 0
                  ? setOpenPdfDialogErrorMessage(true)
                  : setOpenBusinessPartnerPdfDialog(true);

                uiLogger(uiLoggerName.ui_UploadPartnerLedger, fileTypeLogger.pdf, selectedPdfConverter.current);
              },
              catchCallBack: () => {
                setDisableUploadBothSide(false);
                setPdfConversionLoader(false);
                setShowLoadingIconBusinessPartner(false);
              },
            });
          }
        }
      } else {
        if (
          (!newMappingOwn && fileName === OWN_LEDGER && !isOpenSelectTemplateUploadConfirmRefOwn.current) ||
          (!newMappingBusiness &&
            fileName === BUSINESS_PARTNER_LEDGER &&
            !isOpenSelectTemplateUploadConfirmRefBp.current)
        ) {
          isUploadFileAgainAfterSelectTempSaveRef.current = true;
          if (fileName === OWN_LEDGER) {
            setOpenSelectTemplateOwn(true);
          } else if (fileName === BUSINESS_PARTNER_LEDGER) {
            setOpenSelectTemplateBp(true);
          }
        } else {
          // isOpenSelectTemplateUploadConfirmRef.current = false;
          isUploadFileAgainAfterSelectTempSaveRef.current = false;
          // Check if any file is larger than 50MB
          const isFileSizeValid = file.every((item) => item.size <= 50 * 1024 * 1024); // 50MB in bytes

          if (!isFileSizeValid) {
            toast.error(<CustomToast message="Error: All files must be 50MB or smaller" />);
            return;
          }
          // new flow ---------
          uploadSideRef.current = fileName;
          setWhichActionRequired(WhichActionRequiredStatus.UploadInProgress);
          setDisableUploadBothSide(true);
          setRestrictiveLoaderMsg(
            `${fileName === OWN_LEDGER ? "Uploading Own Ledger..." : "Uploading Partner Ledger..."}`
          );
          setOpenRestrictiveLoader(true);

          const bodyFormData = new FormData();
          for (const item of file) {
            bodyFormData.append("Files", item);
          }
          bodyFormData.append("guid", guidCommonTimestamp.current);
          bodyFormData.append("businessPartnerName", assignBusinessPartnerNameRef.current);
          bodyFormData.append("companyId", companyId.current);
          bodyFormData.append("branchCode", branchCode.current);
          bodyFormData.append("businessPartnerId", businessPartnerSelected?.toString());
          bodyFormData.append("isFileSheetSelected", "false");
          bodyFormData.append("useCase", fileName === OWN_LEDGER ? "OWN" : "BP");
          bodyFormData.append("fileMap", null);
          bodyFormData.append(
            "configUseCaseType",
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon
          );

          startUploadLoadingIcon(fileName);
          useFetch("/api/manualUpload/v2/uploadLedger", "POST", {
            data: bodyFormData,
            config: { headers: { "Content-Type": "multipart/form-data" } },
            thenCallBack: (response) => {
              const transformedData = Object.entries(response.data.fileMap).map(([filename, sheets]: any) => ({
                filename,
                sheets,
              }));
              sheetsArrRef.current = transformedData;
              const selectedSheetsVal = sheetsArrRef.current?.map((sheetRef) => ({
                filename: sheetRef.filename,
                sheets: [sheetRef.sheets[0]], // Extracting only the first sheet as an array
              }));

              setSelectedSheets(selectedSheetsVal);
              setOpenRestrictiveLoader(false);

              if (sheetsArrRef.current?.every((file) => file.sheets.length === 1)) {
                // direct proceed upload api
                uploadLedgerAfterSheetSelection(fileName, response.data.fileMap);
              } else {
                stopUploadLoadingIcon(fileName);
                setOpenSheetSelectionModal(true);
              }
            },
            catchCallBack: () => {
              setWhichActionRequired(null);
              setDisableUploadBothSide(false);
              stopUploadLoadingIcon(fileName);
              setOpenRestrictiveLoader(false);
              setRestrictiveLoaderMsg("");
            },
          });
        }
        //
      }
    } else {
      toast.error(<CustomToast message="Error: All files must have the same type" />);
    }
  };

  const fetchDataFromERPHandler = async (type: string, isFreshUpload = false) => {
    await useFetch<FetchDataFromERPResponse>("/api/FetchDataFromERP", "POST", {
      showSuccessToast: true,
      data: {
        companyId: companyId.current,
        branchCode: branchCode.current,
        startDate: startDate,
        endDate: endDate,
        businessPartnerId: businessPartnerSelected,
        actorTypeForRecon: type,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        isFreshUpload,
      },
      thenCallBack: (response) => {
        if (response.data?.nonEmptyDocTypes === null) {
          if (actor.integration && businessPartnerIntegrationWithERP && type === ActorTypesForRecon.ReconUser) {
            fetchDataFromERPHandler(ActorTypesForRecon.Partner);
          } else {
            compareLedgers();
          }
        } else {
          if (response.data?.unIdentifiedColumns?.length > 0) {
            const columnHeaderArrwithErrorFlag = response.data.unIdentifiedColumns?.map((object) => {
              return { ...object, errorFlag: false, columnHeaderConfigValue: "" };
            });
            const alreadyMappedColumnHeaders =
              response?.data?.mappedColumnHeaderList && Object.keys(response?.data?.mappedColumnHeaderList)?.length > 0
                ? [response?.data?.mappedColumnHeaderList]
                : [];

            alreadyMappedColumnHeaders.forEach((headerGroup) => {
              Object.keys(headerGroup).forEach((key) => {
                columnHeaderArrwithErrorFlag.push({
                  columnHeaders: columnHeaderArrwithErrorFlag[0]?.columnHeaders,
                  columnHeaderName: key,
                  suggestedHeaderIndex: 0,
                  errorFlag: false,
                  columnHeaderConfigValue: headerGroup[key],
                  alreadyMappedColumnHeaders: true,
                });
              });
            });

            updateColumnHeadersArrForOptional(columnHeaderArrwithErrorFlag);
            // Create a map of the enum order for quick lookup
            const enumOrderMap: { [key: string]: number } = {};
            columnHeaderNameOrder.forEach((key, index) => {
              enumOrderMap[key] = index;
            });
            // Sort the columnHeaderArrwithErrorFlag based on the enum order
            columnHeaderArrwithErrorFlag.sort((a, b) => {
              return enumOrderMap[a.columnHeaderName] - enumOrderMap[b.columnHeaderName];
            });
            setColumnHeaderArr(columnHeaderArrwithErrorFlag);
            setHighlightedHeader({
              columnHeaderName: columnHeaderArrwithErrorFlag?.[0]?.columnHeaderName,
              columnHeaderConfigValue: columnHeaderArrwithErrorFlag?.[0]?.columnHeaderConfigValue,
            });
            setListColumnHeaderEntries(response?.data?.columnHeadersWithData);
            setConfigUserType(response.data?.configUserType);
            if (response.data?.configUserType === OWN_LEDGER) {
              setConfigUserName(actor?.name);
            } else if (response.data?.configUserType === BUSINESS_PARTNER_LEDGER) {
              setConfigUserName(assignBusinessPartnerName);
            }
            setFetchDataFromERP(true);
            setOpenColumnHeaderDialog(true);
          }

          if (response.data?.nonEmptyDocTypes?.length > 0) {
            setDocumentTypesArr(response.data?.nonEmptyDocTypes);

            finaldocumentTypesObj.current = Object.fromEntries(
              response.data?.nonEmptyDocTypes.map((item) => [
                item.unidentifiedText,
                {
                  documentType: documentTypeSuggestionMap[item.suggestedDoctype],
                  transactionType: 0,
                },
              ])
            );

            setConfigUserType(response.data?.configUserType);
            changeDocumentTypeConfig(
              response.data.nonEmptyDocTypes[0].unidentifiedText,
              documentTypeSuggestionMap[response.data.nonEmptyDocTypes[0].suggestedDoctype]
            );
            if (response.data?.configUserType === OWN_LEDGER) {
              setConfigUserName(actor?.name);
            } else if (response.data?.configUserType === BUSINESS_PARTNER_LEDGER) {
              setConfigUserName(assignBusinessPartnerName);
            }
            setFetchDataFromERP(true);
            setOpenDocumentTypeDialog(true);
          } else {
            setDocumentTypesArr([]);
          }
        }
      },
    });
  };

  const compareLedgersCheckEndDate = (isFreshUpload: boolean) => {
    if (actor.integration || businessPartnerIntegrationWithERP) {
      if (actor.integration) {
        fetchDataFromERPHandler(ActorTypesForRecon.ReconUser, isFreshUpload);
      } else if (businessPartnerIntegrationWithERP) {
        fetchDataFromERPHandler(ActorTypesForRecon.Partner, isFreshUpload);
      }
    } else {
      compareLedgers();
    }
  };

  const getSummaryForReconciliationCommonFunction = () => {
    if (
      summaryTypeSelectedRef.current === summaryTypeSelectedStatus.legacy &&
      props.subTab === RECON360_SUB_TAB.LEDGER
    ) {
      getSummaryForReconciliation();
    } else {
      setGeneratingSummary(false);
      setShowDownloadResultsLoadingIcon(false);
    }
  };

  const compareLedgers = async () => {
    if (pollingCounterFetchReconStatus === 0) {
      setFetchReconStatusStart(true);
    }

    setWhichActionRequired(WhichActionRequiredStatus.ReconInProgress);
    setDisableUploadBothSide(true);
    setShowReconcileLoadingIcon(true);
    await useFetch("/api/ProcessRecon/InitiateRecon", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerName: assignBusinessPartnerNameRef.current,
        ownStartDate: startDate,
        ownEndDate: endDate,
        ownClosingBalance: ownClosingbalance,
        businessPartnerStartDate: startDate,
        businessPartnerEndDate: endDate,
        businessPartnerClosingBalance: businessPartnerClosingbalance,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyId,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        ignoreTdsPercentage: calculateCombinationsOfTdsGst("Ignore this time"),
      },
      thenCallBack: () => {
        fetchReconStatus();
      },
      catchCallBack: () => {
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
        setShowReconcileLoadingIcon(false);
      },
    });
  };
  const fetchReconStatus = () => {
    if (pollingCounterFetchReconStatus === 0) {
      setFetchReconStatusStart(true);
    }

    setWhichActionRequired(WhichActionRequiredStatus.ReconInProgress);
    setDisableUploadBothSide(true);
    setShowReconcileLoadingIcon(true);

    useFetch("/api/ProcessRecon/FetchReconStatus", "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        businessPartnerName: assignBusinessPartnerNameRef.current,
        ownStartDate: startDateRef.current,
        ownEndDate: endDateRef.current,
        ownClosingBalance: ownClosingbalanceRef.current,
        businessPartnerStartDate: startDateRef.current,
        businessPartnerEndDate: endDateRef.current,
        businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        ignoreTdsPercentage: calculateCombinationsOfTdsGst("Ignore this time"),
      },
      thenCallBack: async (response) => {
        setInitiatedReconOn(response.data?.reconInfoMessage);
        setFetchReconStatusStart(false);
        if (response.data.reconRequestStatus === ReconRequestStatus.reconInProgress) {
          setShowReconcileLoadingIcon(true);
          setWhichActionRequired(WhichActionRequiredStatus.ReconInProgress);
          setDisableUploadBothSide(true);

          const timeoutDuration = pollingCounterFetchReconStatus < 5 ? 5000 : 10000; // 5 seconds for first 4 times, then 10 seconds
          setTimeout(() => {
            if (businessPartnerSelectedRef.current) {
              pollingCounterFetchReconStatus++; // Increment the retry counter
              fetchReconStatus();
            }
          }, timeoutDuration);
        } else if (response.data.reconRequestStatus === ReconRequestStatus.reconDone) {
          setWhichActionRequired(null);
          setDisableUploadBothSide(false);
          setShowReconcileLoadingIcon(false);
          probablyTdsGstArr.current = [];
          useFetch<LastReconciliationStatusRes>("/api/GetLastReconciliationStatus", "GET", {
            config: {
              params: {
                businessPartnerId: businessPartnerSelectedRef.current,
                businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
                ownCompanyId: companyId.current,
              },
            },
            thenCallBack: async (responseStatus) => {
              setHasReconciled(responseStatus.data.hasReconciled);
              setAmountTolerance(responseStatus.data.amountTolerance);
              setDateOfLastRecon(responseStatus.data?.dateOfLastRecon);
              setDateOfLastSummary(responseStatus.data?.dateOfLastSummary);
              if (responseStatus.data?.dateOfLastSummary) {
                setShowDownloadGapSummary(true);
              } else {
                setShowDownloadGapSummary(false);
              }
              if (responseStatus.data.hasReconciled) {
                setWaitingForOutputFile(false);
                if (props.subTab === "Ledger") {
                  setIsExpandedRunRecon(true);
                  setShowExpandedInsights(true);
                  setIsExpandedInsights(false);

                  if (summaryTypeSelectedRef.current === "legacy") {
                    setShowExpandedSummary(true);
                    setIsExpandedSummary(true);
                    setShowExpandedSignOffStatement(true);
                    setIsExpandedSignOffStatement(true);
                  }
                }
              }
              if (responseStatus.data?.dateOfLastRecon) {
                setShowReconcileLoadingIcon(false);
                if (props.subTab === "Ledger") {
                  setIsExpandedRunRecon(true);
                  setShowExpandedInsights(true);
                  setIsExpandedInsights(false);
                  if (summaryTypeSelected === "legacy") {
                    setShowExpandedSummary(true);
                    setIsExpandedSummary(true);
                    setShowExpandedSignOffStatement(true);
                    setIsExpandedSignOffStatement(true);
                  }
                }
              }
            },
            catchCallBack: () => {
              setWaitingForOutputFile(false);
              setGeneratingSummary(false);
              setShowDownloadResultsLoadingIcon(false);
            },
          });

          if (response.data.result.suggestedTds?.length > 0) {
            probablyTdsGstArr.current = response.data.result.suggestedTds;
            setOpenProbablyTdsGstModal(true);
          }
          if (response.data.result.manualChangesData?.length > 0) {
            setCheckboxDataManualRecon(response.data.result.manualChangesData);
            if (response.data.result.suggestedTds?.length < 1) {
              setOpenManualChangesConfirmModal(true);
            }
          }
          if (response.data.result.suggestedTds?.length < 1 && response.data.result.manualChangesData?.length < 1) {
            getSummaryForReconciliationCommonFunction();
          } else {
            // how this needs to show warning tds manual chnages
            //           setShowReconcileLoadingIcon(false);
            // setWhichActionRequired(WhichActionRequiredStatus.Success);
            //           setDisableUploadBothSide(false);
          }
        } else if (response.data.reconRequestStatus === ReconRequestStatus.reconFailed) {
          setWhichActionRequired(null);
          setDisableUploadBothSide(false);
          setShowReconcileLoadingIcon(false);
          toast.error(<CustomToast message={response.data.message} />);
        } else {
          // other condition
          // if (whichActionRequired === WhichActionRequiredStatus.ReconInProgress || whichActionRequired === null) {
          setWhichActionRequired(null);
          setDisableUploadBothSide(false);
          setShowReconcileLoadingIcon(false);
          // }
        }
      },
      catchCallBack: () => {
        setWhichActionRequired(null);
        setDisableUploadBothSide(false);
        setShowReconcileLoadingIcon(false);
      },
    });
  };

  const getReconMetaData = async (getMetaDataFor: string) => {
    await useFetch<SyncFromSAPResponse>("/api/GetReconMetaData", "GET", {
      config: {
        params: {
          getMetaDataFor,
          businessPartnerId: businessPartnerSelectedRef.current,
          configUseCaseType:
            props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE
              ? ConfigUseCaseType.paymentAdvice
              : ConfigUseCaseType.recon,
          companyId: companyId.current,
          branchCode: branchCode.current,
        },
      },
      thenCallBack: (response) => {
        if (getMetaDataFor === "own") {
          setOwnDateSyncFromSAP(response.data);
          if (
            response.data.lastTimeToSync &&
            response.data.latestEntry &&
            response.data.oldestEntry &&
            response.data.overallEnteries
          ) {
            setLedgerUploadedOwn(true);
          } else {
            setLedgerUploadedOwn(false);
          }
          if (
            !response.data?.lastTimeToSync &&
            !response.data?.latestEntry &&
            !response.data?.oldestEntry &&
            !response.data?.overallEnteries
          ) {
            setUploadedLastModifiedDateOwn(null);
            setUploadedFileNameOwn(null);
          }
        } else if (getMetaDataFor === "businessPartner") {
          setbusinessPartnerDateSyncFromSAP(response.data);
          if (
            response.data?.lastTimeToSync &&
            response.data?.latestEntry &&
            response.data?.oldestEntry &&
            response.data?.overallEnteries
          ) {
            setLedgerUploadedBP(true);
          } else {
            setLedgerUploadedBP(false);
          }
          if (
            !response.data?.lastTimeToSync &&
            !response.data?.latestEntry &&
            !response.data?.oldestEntry &&
            !response.data?.overallEnteries
          ) {
            setUploadedLastModifiedDateBusinessPartner(null);
            setUploadedFileNameBusinessPartner(null);
          }
        }
      },
    });
  };

  const businessPartnerSelect = async (e: any, option: OptionObj) => {
    if (location.search) {
      const newUrl = location.pathname;
      history.replace(newUrl);
    }

    bpSelectedOptionRef.current = option;
    setWhichActionRequired(null);
    setDisableUploadBothSide(false);
    setShowLoadingIconOwn(false);
    setShowLoadingIconBusinessPartner(false);

    if (option !== null) {
      clarity.setTag("RU-BP", `${actor.name}-${option.name}`);
      if (e && e?.persist) e?.persist();
      isOpenSelectTemplateUploadConfirmRefOwn.current = false;
      isOpenSelectTemplateUploadConfirmRefBp.current = false;

      await useFetch<GetReconRelatedDetailsOfBpRes>("/api/GetReconRelatedDetailsOfBusinessPartner", "GET", {
        config: {
          params: {
            businessPartnerId: option.id,
            branchCode: branchCode.current,
            companyId: companyId.current,
            configUseCaseType:
              props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE
                ? ConfigUseCaseType.paymentAdvice
                : ConfigUseCaseType.recon,
          },
        },
        thenCallBack: (response) => {
          setCurrentInProgressTicket(
            response.data.currentInProgressTicket ? response.data.currentInProgressTicket : null
          );
          currentInProgressTicketRef.current = response.data.currentInProgressTicket
            ? response.data.currentInProgressTicket
            : null;

          if (response.data.partnerDetails.ownBusinessPartnerMapping.currency)
            currency.current = response.data.partnerDetails.ownBusinessPartnerMapping.currency;
          setSelectedBusinessPartnerName(option.name);
          setSelectedBusinessPartnerVendorCode(option.vendorCode);
          setAssignBusinessPartnerName(option.name);
          assignBusinessPartnerNameRef.current = option.name;
          setbusinessPartnerAccountingSoftware(response.data.partnerDetails.accountingSoftware);
          setBusinessPartnerIntegrationWithERP(response.data.partnerDetails?.intraCompanyPartner);
          setSelectedBusinessPartner(option.id);
          businessPartnerSelectedRef.current = option.id;
          getReconMetaData("own");
          getReconMetaData("businessPartner");
          getBusinessPartnerLedgerConfigResponse(option.id, false, true);
          setOwnClosingBalance(
            response.data.partnerDetails.ownBusinessPartnerMapping.ownClosingBalance === null
              ? parseInt("", 10)
              : response.data.partnerDetails.ownBusinessPartnerMapping.ownClosingBalance
          );
          ownClosingbalanceRef.current =
            response.data.partnerDetails.ownBusinessPartnerMapping.ownClosingBalance === null
              ? parseInt("", 10)
              : response.data.partnerDetails.ownBusinessPartnerMapping.ownClosingBalance;
          setBusinessPartnerClosingBalance(
            response.data.partnerDetails.ownBusinessPartnerMapping.businessPartnerClosingBalance === null
              ? parseInt("", 10)
              : response.data.partnerDetails.ownBusinessPartnerMapping.businessPartnerClosingBalance
          );
          businessPartnerClosingbalanceRef.current =
            response.data.partnerDetails.ownBusinessPartnerMapping.businessPartnerClosingBalance === null
              ? parseInt("", 10)
              : response.data.partnerDetails.ownBusinessPartnerMapping.businessPartnerClosingBalance;
          if (response.data.partnerDetails.reconPeriod?.startDate) {
            setStartDate(response.data.partnerDetails.reconPeriod.startDate);
            startDateRef.current = response.data.partnerDetails.reconPeriod.startDate;
          } else {
            setStartDate(null);
            startDateRef.current = null;
          }

          if (response.data.partnerDetails.reconPeriod?.endDate) {
            setEndDate(response.data.partnerDetails.reconPeriod.endDate);
            endDateRef.current = response.data.partnerDetails.reconPeriod.endDate;
          } else {
            setEndDate(null);
            endDateRef.current = null;
          }
          setLedgerComparisonSummaryData(null);
          setUploadedFileNameOwn(null);
          setUploadedFileNameBusinessPartner(null);
          setUploadedLastModifiedDateOwn(null);
          setUploadedLastModifiedDateBusinessPartner(null);
          if (props.subTab === RECON360_SUB_TAB.LEDGER) {
            setOwnSpecificConfig(response.data?.partnerDetails.ownBusinessPartnerMapping?.ownReconSpecificConfig);
            setDisableOwnSpecificConfigCheckbox(
              response.data?.partnerDetails.ownBusinessPartnerMapping?.ownReconSpecificConfig
            );

            // on BP select call getReconMisInsights function to get last recon run results
            // getReconMisInsights(option.id, null);
          } else if (props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE) {
            setOwnSpecificConfig(
              response.data?.partnerDetails.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig
            );
            setDisableOwnSpecificConfigCheckbox(
              response.data?.partnerDetails.ownBusinessPartnerMapping?.ownPaymentAdviceSpecificConfig
            );
          }

          getLastReconciliationStatus(option.id, response.data.currentInProgressTicket);
          if (response.data.currentInProgressTicket) {
            getReconLinkInfo();
          }

          //
          setShowExpandedInsights(false);
          setShowExpandedSummary(false);
          setShowExpandedSignOffStatement(false);
          setIsExpandedInsights(false);
          setIsExpandedSummary(false);
          setIsExpandedSignOffStatement(false);
          setIsExpandedRunRecon(true);
          setIsExpandedReconHome(false);

          if (actor.integration) {
            UpdateTallyClosingBalance();
          }

          //  check diff integration condition for uploadLedgerStatus
          // check condition of both integration and call uploadLedgerStatus
          uploadLedgerStatus();
          // if (response.data.currentInProgressTicket) {
          //   fetchReconStatus();
          // }

          listChecklist();
          listNotes();
          setShowChecklistAndNotesIcon(true);

          uiLogger(uiLoggerName.ui_BpSelected);
        },
      });
    } else if (option === null) {
      clearAllStates();
      setShowChecklistAndNotesIcon(false);
      setListOfChecklist([]);
      setListOfNotes([]);
    }
  };

  const clearAllStates = () => {
    setOwnDateSyncFromSAP({} as DateSyncFromSAP);
    setbusinessPartnerDateSyncFromSAP(null);
    setSelectedBusinessPartnerName("");
    setSelectedBusinessPartnerVendorCode("");
    setAssignBusinessPartnerName(null);
    assignBusinessPartnerNameRef.current = null;
    setbusinessPartnerAccountingSoftware(null);
    setBusinessPartnerIntegrationWithERP(false);
    setSelectedBusinessPartner(null);
    setCurrentInProgressTicket(null);
    currentInProgressTicketRef.current = null;
    setCreateNewTicket(false);
    businessPartnerSelectedRef.current = null;
    setSelectedBusinessPartnerCompanyId(null);
    setOwnClosingBalance(parseInt("", 10));
    ownClosingbalanceRef.current = parseInt("", 10);
    setBusinessPartnerClosingBalance(parseInt("", 10));
    businessPartnerClosingbalanceRef.current = parseInt("", 10);
    setStartDate(null);
    startDateRef.current = null;
    setEndDate(null);
    endDateRef.current = null;
    setLedgerComparisonSummaryData(null);
    setUploadedFileNameOwn(null);
    setUploadedFileNameBusinessPartner(null);
    setUploadedLastModifiedDateOwn(null);
    setUploadedLastModifiedDateBusinessPartner(null);
    setNewMappingBusiness(false);
    setNewMappingOwn(false);
    setDisplayTemplateNameBusiness(null);
    setTemplateNameBusiness("");
    setHeaderNameOwn({} as LedgerConfiguration);
    setHeaderNameBusiness({} as LedgerConfiguration);
    setSanitizeColBusiness([]);
    setOwnSpecificConfig(false);
    setDisableOwnSpecificConfigCheckbox(true);
    setShowExpandedInsights(false);
    setShowExpandedSummary(false);
    setShowExpandedSignOffStatement(false);
    setIsExpandedInsights(false);
    setIsExpandedSummary(false);
    setIsExpandedSignOffStatement(false);
    setIsExpandedRunRecon(false);
    setLedgerUploadedOwn(false);
    setLedgerUploadedBP(false);
    setIsExpandedReconHome(false);
    setHasReconciled(false);
    setAmountTolerance(null);
    setCheckboxDataManualRecon([]);
    setTotalAmount(null);
    setHasManuallyReconciled(true);
    setWhichActionRequired(null);
    // link old reco
    setLinkOldRecoTask([]);
    setUnlinkOldRecoTask([]);
    setSelectedTaskId(null);
  };

  const saveRuleApi = async (
    header: LedgerConfiguration,
    template: string,
    data: ColumnsToSanitize[],
    noReupload = false
  ) => {
    setLoadingConfigBP(true);

    Object.keys(header).map((k) => {
      if (
        k !== "amount" &&
        k !== "closingBalance" &&
        k !== "nonRelevantDocTypeFlag" &&
        k !== "debitAmountSignReverseFlag" &&
        k !== "creditAmountSignReverseFlag" &&
        k !== "configUsedInMappingFile" &&
        k !== "ignoreDocTypeList"
      ) {
        header[k] =
          header[k] !== null
            ? header[k].map((item: any) => {
                if (typeof item === "string") {
                  return item?.trim();
                } else if (typeof item === "object") {
                  // item !== null || item !== undefined
                  return {
                    code: item?.code?.trim(),
                    sign: item.sign,
                    addParticulars: item.addParticulars,
                    excludeParticulars: item.excludeParticulars,
                    group: item.group,
                    useReference: item.useReference ? item.useReference : false,
                  };
                }
                return header;
              })
            : header[k];
      } else if (k === "debitAmountSignReverseFlag") {
        header.debitAmountSignReverseFlag = debitAmountSignReverseFlagBP;
      } else if (k === "creditAmountSignReverseFlag") {
        header.creditAmountSignReverseFlag = creditAmountSignReverseFlagBP;
      }
      return header;
    });

    if (!Object.prototype.hasOwnProperty.call(header, "debitAmountSignReverseFlag")) {
      header.debitAmountSignReverseFlag = debitAmountSignReverseFlagBP;
    }
    if (!Object.prototype.hasOwnProperty.call(header, "creditAmountSignReverseFlag")) {
      header.creditAmountSignReverseFlag = creditAmountSignReverseFlagBP;
    }
    if (!Object.prototype.hasOwnProperty.call(header, "ignoreDocTypeList")) {
      header.ignoreDocTypeList = {
        neverAskAgain: [],
        ignoreThisTime: [],
      };
    }

    const uniqueValues = {};

    // Iterate over the columnHeaderNameOrder array
    columnHeaderNameOrder.forEach((key) => {
      if (header[key]) {
        // Use a Set to track lowercase versions of the values for uniqueness
        const lowerCaseSet = new Set();
        uniqueValues[key] = header[key].filter((value) => {
          const lowerCaseValue = value.toLowerCase();
          if (!lowerCaseSet.has(lowerCaseValue)) {
            lowerCaseSet.add(lowerCaseValue);
            return true;
          }
          return false;
        });
      }
    });

    // Include all other keys from the original header object
    Object.keys(header).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(uniqueValues, key)) {
        uniqueValues[key] = header[key];
      }
    });

    const ledgerConfig: InsertLedgerConfig = {
      templateName: template,
      config: uniqueValues as LedgerConfiguration,
      actorId: businessPartnerSelected,
      columnsToSanitize: data,
      createdAt: undefined,
      updatedAt: undefined,
    };
    await useFetch<InsertLedgerResponse>("/api/InsertBusinessPartnerLedgerConfig", "POST", {
      showSuccessToast: true,
      data: {
        ledgerConfig,
        specificConfig: false,
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
      },
      thenCallBack: (response) => {
        clarity.setTag("BP Config", `Save`);
        setLoadingConfigBP(false);
        setNewMappingBusiness(true);
        setDisplayTemplateNameBusiness(templateNameBp);
        listAllOwnBusinessPartnerMapping();
        getBusinessPartnerLedgerConfigResponse(
          businessPartnerSelected,
          false,
          response.data.lastUploadedFiles?.length > 0 ? true : false
        );
        uiLogger(uiLoggerName.ui_BpConfigClose);
        if (response.data.lastUploadedFiles?.length && !noReupload && documentTypesArr?.length <= 25) {
          fileDetails.current = response.data?.fileDetails;
          const files = response.data.lastUploadedFiles;
          const fileArr: File[] = [];
          files.forEach((_f) => {
            const output = base64ToFile(_f.content, _f.fileName);
            if (output) fileArr.push(output);
          });
          setexcelFileUploadOnChangeFileName(BUSINESS_PARTNER_LEDGER);
          ledgerFiles.current = fileArr;
          currentFileNumber.current = 0;
          setOpenReuseOldFilesModal(true);

          guidCommonTimestamp.current = `${Date.now()}`;
        }
        // auto upload, after config insert, if too many doc types ( > 25) dialog was shown,
        if (openDocumentTypeDialog && documentTypesArr?.length > 25) {
          setOpenDocumentTypeDialog(false);
          reUploadLedger("false");
        }
        setOpenUnassignedDoctypedDialogFromView(false);
      },
      catchCallBack: () => {
        setOpenUnassignedDoctypedDialogFromView(false);
        getBusinessPartnerLedgerConfigResponse(businessPartnerSelected, false, false);
        setLoadingConfigBP(false);
        if (displayTemplateNameBusiness) {
          setNewMappingBusiness(true);
        }
      },
    });
  };

  const saveRuleApiOwn = async (
    header: LedgerConfiguration,
    template: string,
    data: ColumnsToSanitize[],
    noReupload = false
  ) => {
    setLoadingConfigOwn(true);

    Object.keys(header).map((k) => {
      if (
        k !== "amount" &&
        k !== "closingBalance" &&
        k !== "nonRelevantDocTypeFlag" &&
        k !== "debitAmountSignReverseFlag" &&
        k !== "creditAmountSignReverseFlag" &&
        k !== "configUsedInMappingFile" &&
        k !== "ignoreDocTypeList"
      ) {
        header[k] =
          header[k] !== null
            ? header[k].map((item: any) => {
                if (typeof item === "string") {
                  return item?.trim();
                } else if (typeof item === "object" && item !== null && item.code !== null && item.sign !== null) {
                  // item !== null || item !== undefined
                  return {
                    code: item?.code?.trim(),
                    sign: item.sign,
                    addParticulars: item.addParticulars,
                    excludeParticulars: item.excludeParticulars,
                    group: item.group,
                    useReference: item.useReference ? item.useReference : false,
                  };
                }
                return header;
              })
            : header[k];
      } else if (k === "debitAmountSignReverseFlag") {
        header.debitAmountSignReverseFlag = debitAmountSignReverseFlagOwn;
      } else if (k === "creditAmountSignReverseFlag") {
        header.creditAmountSignReverseFlag = creditAmountSignReverseFlagOwn;
      }
      return header;
    });

    if (!Object.prototype.hasOwnProperty.call(header, "debitAmountSignReverseFlag")) {
      header.debitAmountSignReverseFlag = debitAmountSignReverseFlagOwn;
    }
    if (!Object.prototype.hasOwnProperty.call(header, "creditAmountSignReverseFlag")) {
      header.creditAmountSignReverseFlag = creditAmountSignReverseFlagOwn;
    }
    if (!Object.prototype.hasOwnProperty.call(header, "ignoreDocTypeList")) {
      header.ignoreDocTypeList = {
        neverAskAgain: [],
        ignoreThisTime: [],
      };
    }

    const uniqueValues = {};

    // Iterate over the columnHeaderNameOrder array
    columnHeaderNameOrder.forEach((key) => {
      if (header[key]) {
        // Use a Set to track lowercase versions of the values for uniqueness
        const lowerCaseSet = new Set();
        uniqueValues[key] = header[key].filter((value) => {
          const lowerCaseValue = value.toLowerCase();
          if (!lowerCaseSet.has(lowerCaseValue)) {
            lowerCaseSet.add(lowerCaseValue);
            return true;
          }
          return false;
        });
      }
    });

    // Include all other keys from the original header object
    Object.keys(header).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(uniqueValues, key)) {
        uniqueValues[key] = header[key];
      }
    });

    const ledgerConfig: InsertLedgerConfig = {
      templateName: template,
      config: uniqueValues as LedgerConfiguration,
      actorId: actor.id,
      columnsToSanitize: data,
      createdAt: undefined,
      updatedAt: undefined,
    };
    await useFetch<InsertLedgerResponse>("/api/InsertOwnLedgerConfig", "POST", {
      showSuccessToast: true,
      data: {
        ledgerConfig,
        specificConfig: ownSpecificConfig,
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
      },
      thenCallBack: (response) => {
        clarity.setTag("Own Config", `Save`);
        setLoadingConfigOwn(false);
        setNewMappingOwn(true);
        setDisplayTemplateNameOwn(templateName);
        listAllOwnBusinessPartnerMapping();
        getBusinessPartnerLedgerConfigResponse(
          businessPartnerSelected,
          true,
          response.data.lastUploadedFiles?.length > 0 ? true : false
        );
        uiLogger(uiLoggerName.ui_OwnConfigClose);
        if (response.data.lastUploadedFiles?.length && !noReupload && documentTypesArr?.length <= 25) {
          fileDetails.current = response.data?.fileDetails;
          const files = response.data.lastUploadedFiles;
          const fileArr: File[] = [];
          files.forEach((_f) => {
            const output = base64ToFile(_f.content, _f.fileName);
            if (output) fileArr.push(output);
          });
          setexcelFileUploadOnChangeFileName(OWN_LEDGER);
          ledgerFiles.current = fileArr;
          currentFileNumber.current = 0;
          setOpenReuseOldFilesModal(true);

          guidCommonTimestamp.current = `${Date.now()}`;
        }

        // auto upload, after config insert, if too many doc types ( > 25) dialog was shown,
        if (openDocumentTypeDialog && documentTypesArr?.length > 25) {
          setOpenDocumentTypeDialog(false);
          reUploadLedger("false");
        }
        setOpenUnassignedDoctypedDialogFromView(false);
      },
      catchCallBack: () => {
        setOpenUnassignedDoctypedDialogFromView(false);
        getBusinessPartnerLedgerConfigResponse(businessPartnerSelected, false, false);
        setLoadingConfigOwn(false);
        if (displayTemplateNameOwn) {
          setNewMappingOwn(true);
        }
      },
    });
  };

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <CollapseSubRow
          // tdsPresent={tdsPresent}
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          invoiceAmountMismatchOthers={summaryUIRef.current.invoice.amountMismatch}
          paymentAmountMismatchOthers={summaryUIRef.current.payment.amountMismatch}
          debitNoteCreditNoteAmountMismatchOthers={summaryUIRef.current.dncn.amountMismatch}
          invoiceUnaccountedInBusinessPartner={summaryUIRef.current.invoice.invoiceUnaccountedInBusinessPartner}
          paymentNotRecognisedInBusinessPartner={summaryUIRef.current.payment.paymentNotRecognisedInBusinessPartner}
          dncnNotRecognisedInBusinessPartner={summaryUIRef.current.dncn.dncnNotRecognisedInBusinessPartner}
          invoiceUnaccountedInOwn={summaryUIRef.current.invoice.invoiceUnaccountedInOwn}
          extraUnmatchedPaymentInBusinessPartner={summaryUIRef.current.payment.extraUnmatchedPaymentInBusinessPartner}
          extraUnmatchedDNCNInBusinessPartner={summaryUIRef.current.dncn.extraUnmatchedDNCNInBusinessPartner}
          tdsNotRecognisedInBusinessPartner={summaryUIRef.current.tds.tdsNotRecognisedInBusinessPartner}
          extraUnmatchedTDSInBusinessPartner={summaryUIRef.current.tds.extraUnmatchedTDSInBusinessPartner}
          suggestedTDSGap={summaryUIRef.current.tds.suggestedTDSGap}
          miscellaneousOwnSOA={summaryUIRef.current.miscellaneous.inOwnSOA}
          miscellaneousBusinessPartnerSOA={summaryUIRef.current.miscellaneous.inBusinessPartnerSOA}
          openingBalanceOwnSOA={summaryUIRef.current.openingBalance.ownSOA}
          openingBalanceBusinessPartnerSOA={summaryUIRef.current.openingBalance.businessPartnerSOA}
          openDetailsMismatched={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.invoiceAmountMismatch,
              invoiceAmountMismatchColDef,
              "both",
              "invoice"
            );
          }}
          openDetailsExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.invoiceNotPresentInPartnerLedger,
              invoiceNotPresentInPartnerLedgerColDef,
              "own",
              "invoice"
            );
          }}
          openDetailsMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.invoiceNotPresentInOwnLedger,
              invoiceNotPresentInOwnLedgerColDef,
              "businessPartner",
              "invoice"
            );
          }}
          openPaymentAmountMismatch={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.paymentAmountMismatch,
              paymentAmountMismatchColDef,
              "both",
              "payments"
            );
          }}
          openPaymentAmountExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.paymentNotPresentInPartnerLedger,
              paymentNotPresentInPartnerLedgerColDef,
              "own",
              "payments"
            );
          }}
          openTDSAmountExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.tdsTcsNotPresentInPartnerLedger,
              tdsNotPresentInPartnerLedgerColDef,
              "own",
              "tds"
            );
          }}
          openPaymentAmountMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.paymentNotPresentInOwnLedger,
              paymentNotPresentInOwnLedgerColDef,
              "businessPartner",
              "payments"
            );
          }}
          openTDSAmountMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.tdsTcsNotPresentInOwnLedger,
              tdsNotPresentInOwnLedgerColDef,
              "businessPartner",
              "tds"
            );
          }}
          openDNCNMismatch={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.debitNoteCreditNoteAmountMismatch,
              dnCnAmountMismatchColDef,
              "both",
              "dncn"
            );
          }}
          openDNCNExtra={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.dnCnNotPresentInPartnerLedger,
              dnCnNotPresentInPartnerLedgerColDef,
              "own",
              "dncn"
            );
          }}
          openDNCNMissed={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.dnCnNotPresentInOwnLedger,
              dnCnNotPresentInOwnLedgerColDef,
              "businessPartner",
              "dncn"
            );
          }}
          openTDSMismatched={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.tdsAmountMismatch,
              tdsAmountMismatchColDef,
              "both",
              "tds"
            );
          }}
          openOwnMiscellaneous={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.miscellaneousEntriesInOwnSOA,
              dnCnNotPresentInPartnerLedgerColDef,
              "own",
              "miscellaneous"
            );
          }}
          openBusinessPartnerMiscellaneous={() => {
            getUnaccountedAndMisMatchEntries(
              reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA,
              dnCnNotPresentInOwnLedgerColDef,
              "businessPartner",
              "miscellaneous"
            );
          }}
          assignBusinessPartnerName={assignBusinessPartnerName}
        />
      );
    },
    // eslint-disable-next-line
    [assignBusinessPartnerName]
  );

  const businessPartners: any[] = [];
  ownBusinessPartners?.forEach((value) => {
    businessPartners.push({
      name: value.businessPartnerName,
      id: value.businessPartnerId,
      vendorCode: value.businessPartnerVendorCode,
      // currency: value.ownBusinessPartnerMapping.currency,
    });
  });

  const options: OptionObj[] = businessPartners?.map((option) => {
    const firstLetter = option.name[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const tempGrouped = {};
  documentTypesArr.forEach((docType) => {
    const firstLetter = docType.unidentifiedText[0];
    if (tempGrouped[firstLetter]) tempGrouped[firstLetter]?.push(docType);
    else tempGrouped[firstLetter] = [docType];
  });
  groupedDocTypesArr.current = { ...tempGrouped };

  const changeDocumentTypeConfig = (item: string, value: string) => {
    value = value === "Discount/Rebate" ? "Payment" : value;
    const oldIgnoreDoctypes: { [k: string]: any } = {};
    for (const docType of ignoreDoctypesList.current) oldIgnoreDoctypes[docType] = true;

    if (item in finaldocumentTypesObj.current)
      finaldocumentTypesObj.current[item].documentType = value === "Never ask again" ? "garbageDoctype" : value;
    Object.keys(finaldocumentTypesObj.current).forEach(
      (key) => (oldIgnoreDoctypes[key] = finaldocumentTypesObj.current[key].documentType)
    );

    // Filter out items that exist in ignoreDoctypesList and their value is not "Ignore this time"
    ignoreDoctypesList.current = ignoreDoctypesList.current.filter((k) =>
      ["Ignore this time", true].includes(oldIgnoreDoctypes[k])
    );

    // append the ignored document types from first file in the upload call into the second file,
    // so not to ask ignored doc types again in the current multi upload loop.
    ignoreDoctypesList.current.push(
      ...Object.keys(finaldocumentTypesObj.current).filter((k) =>
        ["Ignore this time", true].includes(oldIgnoreDoctypes[k])
      )
    );

    // Remove duplicates
    ignoreDoctypesList.current = [...new Set(ignoreDoctypesList.current)];

    // Additional logic to remove the item from 'garbageDoctype' and 'neverAskAgain' if its value changes
    if (value !== "Never ask again") {
      if (configUserType === OWN_LEDGER) {
        headerName.garbageDoctype = headerName?.garbageDoctype?.filter((doc) => doc.code !== item);
        headerName.ignoreDocTypeList.neverAskAgain = headerName?.ignoreDocTypeList?.neverAskAgain?.filter(
          (doc) => doc.code !== item
        );
      } else if (configUserType === BUSINESS_PARTNER_LEDGER) {
        headerNameBp.garbageDoctype = headerNameBp?.garbageDoctype?.filter((doc) => doc.code !== item);
        headerNameBp.ignoreDocTypeList.neverAskAgain = headerNameBp?.ignoreDocTypeList?.neverAskAgain?.filter(
          (doc) => doc.code !== item
        );
      }
    }
  };

  const companyNameSelect = (e: any, option: ListOwnTallyCompanies) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      setSelectedCompany(option);
      companyId.current = option.companyId;

      // if company change then remove all other selected
      branchCode.current = null;
      setSelectedBranch(null);
      setSelectedBusinessPartner(null);
      businessPartnerSelectedRef.current = null;
      setSelectedBusinessPartnerName("");
      setSelectedBusinessPartnerVendorCode("");
      setAssignBusinessPartnerName(null);
      assignBusinessPartnerNameRef.current = null;
      setOwnBusinessPartners([]);
      setListBranchesForRecon([]);

      if (actor.branchLevelReconcilation) {
        listBranches(option.companyId);
      } else {
        listAllOwnBusinessPartnerMapping();
      }
    } else if (option === null) {
      setOwnBusinessPartners([]);
      setListBranchesForRecon([]);
      setSelectedBusinessPartner(null);
      businessPartnerSelectedRef.current = null;
      setSelectedBusinessPartnerName("");
      setSelectedBusinessPartnerVendorCode("");
      setAssignBusinessPartnerName(null);
      assignBusinessPartnerNameRef.current = null;
      companyId.current = null;
      branchCode.current = null;
      setSelectedBranch(null);
      setSelectedCompany(null);
    }
    setShowExpandedInsights(false);
    setShowExpandedSummary(false);
    setShowExpandedSignOffStatement(false);
    setIsExpandedInsights(false);
    setIsExpandedSummary(false);
    setIsExpandedSignOffStatement(false);
    setIsExpandedRunRecon(false);
    setIsExpandedReconHome(false);
  };
  const branchNameSelect = (e: any, option) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      setSelectedBranch(option);
      branchCode.current = option.branchCode;
      setSelectedBusinessPartner(null);
      businessPartnerSelectedRef.current = null;
      setSelectedBusinessPartnerName("");
      setSelectedBusinessPartnerVendorCode("");
      setAssignBusinessPartnerName(null);
      assignBusinessPartnerNameRef.current = null;
      setOwnBusinessPartners([]);
      listAllOwnBusinessPartnerMapping();
    } else if (option === null) {
      setOwnBusinessPartners([]);
      setSelectedBusinessPartner(null);
      businessPartnerSelectedRef.current = null;
      setSelectedBusinessPartnerName("");
      setSelectedBusinessPartnerVendorCode("");
      setAssignBusinessPartnerName(null);
      assignBusinessPartnerNameRef.current = null;
      branchCode.current = null;
      setSelectedBranch(null);
    }
    setShowExpandedInsights(false);
    setShowExpandedSummary(false);
    setShowExpandedSignOffStatement(false);
    setIsExpandedInsights(false);
    setIsExpandedSummary(false);
    setIsExpandedSignOffStatement(false);
    setIsExpandedRunRecon(false);
    setIsExpandedReconHome(false);
  };

  const toggleExpandRunRecon = () => {
    setIsExpandedRunRecon(!isExpandedRunRecon);
  };

  const toggleExpandSummary = () => {
    setIsExpandedSummary(!isExpandedSummary);
  };

  const createdocumentTypeTagsForMappedTags = (newtemp: any) => {
    const obj = newtemp.map((item: any) => ({
      name: item.name,
      allTransaction: item.codes.filter((val: any) => val.sign === 0),
      debit: item.codes.filter((val: any) => val.sign === 1),
      credit: item.codes.filter((val: any) => val.sign === 2),
    }));
    setDocumentTypesTags(obj);
  };

  const getUnaccountedAndMisMatchEntries = async (
    reconciliationStatusName: string,
    detailsColDef,
    forTypeForDetailsTable,
    entryTypeForDetailsTable
  ) => {
    setLoader(true);
    await useFetch("/api/GetUnaccountedAndMisMatchEntries", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelectedRef.current,
          for: forTypeForDetailsTable,
          entryType: entryTypeForDetailsTable,
          ownCompanyId: companyId.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        },
      },
      thenCallBack: (response) => {
        setDetailsHeading(reconciliationStatusName);
        detailsHeadingName.current = reconciliationStatusName;
        setColumnDefinition(detailsColDef);

        summaryDetailsData.current = response.data.responseData;

        if (reconciliationStatusName === reconciliationStatus.invoiceAmountMismatch) {
          setPaymentGroupBy(["own_invoice_match"]);
          const setExpandedValue: ObjectEle = {};
          summaryDetailsData.current?.forEach((item: any) => {
            if (item.own_invoice_match.length > 0) {
              setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
            }
          });
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus?.invoiceNotPresentInPartnerLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.invoiceNotPresentInOwnLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.paymentAmountMismatch) {
          setPaymentGroupBy(["own_payment_match"]);

          const setExpandedValue: ObjectEle = {};
          summaryDetailsData.current?.forEach((item: any) => {
            if (item.own_payment_match.length > 0) {
              setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
            }
          });
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.paymentNotPresentInPartnerLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.paymentNotPresentInOwnLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
          setPaymentGroupBy(["own_dncn_match"]);
          const setExpandedValue: ObjectEle = {};
          summaryDetailsData.current?.forEach((item: any) => {
            if (item.own_dncn_match.length > 0) {
              setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
            }
          });
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.dnCnNotPresentInOwnLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.tdsAmountMismatch) {
          setPaymentGroupBy(["own_invoice_match"]);
          const setExpandedValue: ObjectEle = {};
          summaryDetailsData.current?.forEach((item: any) => {
            if (item.own_invoice_match.length > 0) {
              setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
            }
            if (item.own_payment_match.length > 0) {
              setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
            }
            if (item.own_dncn_match.length > 0) {
              setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
            }
          });
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        } else if (reconciliationStatusName === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA) {
          const setExpandedValue: ObjectEle = {};
          setExpanded(setExpandedValue);
        }
        setOpendetailsJSON(summaryDetailsData.current);

        const summaryEle = document.getElementById("summary_page");
        const detailsEle = document.getElementById("open_details_page");
        summaryEle.style.display = "none";
        detailsEle.style.display = "block";

        setLoader(false);
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const onPdfOptionConfirm = () => {
    isPdfOptionConfirm.current = true;
    setOpenConfirmOptionModal(false);
    excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
  };

  const getLastReconciliationStatus = async (bpid, ticketPresent) => {
    await useFetch<LastReconciliationStatusRes>("/api/GetLastReconciliationStatus", "GET", {
      config: {
        params: {
          businessPartnerId: bpid,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
        },
      },
      thenCallBack: (response) => {
        setHasReconciled(response.data.hasReconciled);
        setAmountTolerance(response.data.amountTolerance);
        setDateOfLastRecon(response.data?.dateOfLastRecon);
        setDateOfLastSummary(response.data?.dateOfLastSummary);
        if (response.data?.dateOfLastSummary) {
          setShowDownloadGapSummary(true);
        } else {
          setShowDownloadGapSummary(false);
        }
        if (response.data.hasReconciled) {
          if (props.subTab === "Ledger") {
            // setWaitingForOutputFile(true);
            setIsExpandedRunRecon(true);
            setShowExpandedInsights(true);
            setIsExpandedInsights(false);

            if (summaryTypeSelectedRef.current === "legacy") {
              setShowExpandedSummary(true);
              setIsExpandedSummary(true);
              setShowExpandedSignOffStatement(true);
              setIsExpandedSignOffStatement(true);
            }
          }
        }
        if (response.data?.dateOfLastRecon && ticketPresent) {
          getSummaryForUI();
        }
      },
    });
  };

  const getManuallyChangedEntries = async () => {
    await useFetch("/api/GetChangesDoneManually", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
        },
      },
      thenCallBack: (response) => {
        setCheckboxDataManualRecon(response.data.data);
        setTotalAmount(response.data.total);
        setHasManuallyReconciled(response.data.hasManuallyReconciled);
        setOpenManualRecon(true);
        uiLogger(uiLoggerName.ui_ManualReconOpen);
      },
    });
  };

  const getSummaryForUI = () => {
    useFetch("/api/GetSummaryForUI", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelectedRef.current,
          ownCompanyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        },
      },
      thenCallBack: (response) => {
        if (
          summaryTypeSelectedRef.current === summaryTypeSelectedStatus.legacy &&
          props.subTab === RECON360_SUB_TAB.LEDGER &&
          response.data.summary
        ) {
          showSummaryTable(response.data.summary);
        }
      },
    });
  };

  const getExcelWorkbook = async () => {
    await useFetch("/api/GetExcelWorkbook", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelectedRef.current,
          ownCompanyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        },
      },
      thenCallBack: async (response) => {
        if (response.data.workBookBase64 === null && response.data.error === null) {
          if (startInterval.current && Date.now() - startInterval.current < 720000) {
            setTimeout(() => {
              getExcelWorkbook();
            }, 10000); // 10000 milliseconds = 10 seconds
          } else {
            setShowDownloadResultsLoadingIcon(false);
            toast.error(<CustomToast message="Time exceeded" />);
          }
        } else {
          if (response.data.workBookBase64) {
            const excelData = Buffer.from(response.data.workBookBase64, "base64");
            const fileType = "application/vnd.ms-excel.sheet.macroEnabled.12";
            const blob = new Blob([excelData], { type: fileType });
            saveAs(blob, actor?.name + "-" + assignBusinessPartnerName + " Reconciled Output.xlsm");
            uiLogger(uiLoggerName.ui_DownloadResultsButtonClicked);
          } else if (response.data.error) {
            toast.error(<CustomToast message={response.data.error} />);
          }
          setShowDownloadResultsLoadingIcon(false);
        }
      },
      catchCallBack: () => {
        setShowDownloadResultsLoadingIcon(false);
      },
    });
  };

  const getSummaryForReconciliation = async () => {
    setGeneratingSummary(true);
    setShowDownloadResultsLoadingIcon(true);
    await useFetch<ReconResponseJSON>("/api/GetSummaryForReconciliation", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: businessPartnerSelectedRef.current,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        ownClosingBalance: ownClosingbalanceRef.current,
        businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
        sanitizationWarning: null,
        startDate: startDateRef.current,
        endDate: endDateRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
      },
      thenCallBack: (response) => {
        setGeneratingSummary(false);
        setShowDownloadResultsLoadingIcon(false);

        const saveOwnBusinessPartnerMapping: OwnBusinessPartnerMappingUpdateClosingBalances = {
          ownId: actor.id,
          businessPartnerId: businessPartnerSelectedRef.current,
          ownClosingBalance: ownClosingbalanceRef.current,
          businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
          ownStartDate: startDateRef.current,
          ownEndDate: endDateRef.current,
          businessPartnerStartDate: startDateRef.current,
          businessPartnerEndDate: endDateRef.current,
          createdAt: undefined,
          updatedAt: undefined,
          closingDifference: response.data.result.closingDifference,
          ownClosingBalanceDate: new Date(),
          businessPartnerClosingBalanceDate: new Date(),
          ownClosingBalanceDateForDashboard: new Date(),
          businessPartnerClosingBalanceDateForDashboard: new Date(),
          businessPartnerClosingBalanceForDashboard: businessPartnerClosingbalanceRef.current,
          ownClosingBalanceForDashboard: ownClosingbalanceRef.current,
          closingDifferenceForDashboard: response.data.result.closingDifference,
          lastReconOn: new Date(),
        };

        useFetch("/api/UpdateClosingBalancesOnReconRun", "POST", {
          data: {
            ownBusinessPartnerMapping: saveOwnBusinessPartnerMapping,
          },
          thenCallBack: () => {
            setNewMappingOwn(true);
            setDisplayTemplateNameOwn(templateName);
          },
        });

        if (props.subTab === "Ledger") {
          setRowsDataOverviewInsights(response.data.result?.reconMetrics || null);
        }

        if (
          summaryTypeSelectedRef.current === summaryTypeSelectedStatus.legacy &&
          props.subTab === RECON360_SUB_TAB.LEDGER
        ) {
          showSummaryTable(response.data.result.summary);
        }
      },
      catchCallBack: () => {
        setShowReconcileLoadingIcon(false);
        setShowDownloadResultsLoadingIcon(false);
        setGeneratingSummary(false);
      },
    });
  };

  const showSummaryTable = (summary) => {
    summaryUIRef.current = summary;

    const tempLedgerComparisonSummaryData = [
      {
        line_item: (
          <>
            <b>Closing balance as per {assignBusinessPartnerNameRef.current}'s ledger</b>
          </>
        ),
        sub_total: "",
        total: formatMoney(
          ToDineroObj(summaryUIRef.current.closingBalanceAsPerBusinessPartner, currency.current as Currency)
        ),
        num_of_item: "",
        value_of_item: "",
      },
      {
        line_item: <p>Invoice value gap</p>,
        sub_total: "",
        total: formatMoney(ToDineroObj(summaryUIRef.current.invoice.valueGap, currency.current as Currency)),
        num_of_item: <p>{itemNuminvoiceAmountMismatchDispute}</p>,
        value_of_item: <p>{allinvoiceAmountMismatchDisputeValue}</p>,
      },
      {
        line_item: <p>Payment value gap</p>,
        sub_total: "",
        total: formatMoney(ToDineroObj(summaryUIRef.current.payment.valueGap, currency.current as Currency)),
        num_of_item: "4",
        value_of_item: "12454",
      },

      {
        line_item: <p>Debit/credit note value gap</p>,
        sub_total: "",
        total: formatMoney(ToDineroObj(summaryUIRef.current.dncn.valueGap, currency.current as Currency)),
        num_of_item: "4",
        value_of_item: "",
      },

      {
        line_item: <p>TDS Value Gap</p>,
        sub_total: "",
        total: formatMoney(ToDineroObj(summaryUIRef.current.tds.valueGap, currency.current as Currency)),
        num_of_item: "1",
        value_of_item: "",
      },
      {
        line_item: <p>Adjustment Of Miscellaneous Entries</p>,
        sub_total: "",
        total: formatMoney(ToDineroObj(summaryUIRef.current.miscellaneous.valueGap, currency.current as Currency)),
        num_of_item: "1",
        value_of_item: "",
      },
      {
        line_item: (
          <>
            <b>Reconciled closing balance</b>
          </>
        ),
        sub_total: "",
        total: formatMoney(ToDineroObj(summaryUIRef.current.reconciledClosingBalance, currency.current as Currency)),
        num_of_item: "",
        value_of_item: "",
      },
      {
        line_item: (
          <>
            <b>Closing balance as per {actor.name} ledger</b>
          </>
        ),
        total: formatMoney(ToDineroObj(summaryUIRef.current.closingBalanceAsPerOwn, currency.current as Currency)),
      },
      {
        line_item: <p>Difference in opening balance</p>,
        total: formatMoney(ToDineroObj(summaryUIRef.current.openingBalance.valueGap, currency.current as Currency)),
      },
      {
        line_item: (
          <>
            <b>Residual difference</b>
          </>
        ),

        // total:'12411392'
        total: formatMoney(ToDineroObj(summaryUIRef.current.residualDifference, currency.current as Currency)),
      },
    ];

    setLedgerComparisonSummaryData(tempLedgerComparisonSummaryData);
  };
  const downloadExcelFromApi = async (from: string, defaultResultFileState: any = {}) => {
    console.log(from);
    setShowDownloadResultsLoadingIcon(true);
    await useFetch("/api/GenerateSummaryExcel", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: businessPartnerSelectedRef.current,
        configUseCaseType:
          props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        isCustomResultDownload: from === "CustomResultDownload" ? true : false,
        customResultDownload: {
          ReconciliationSummaryAndAnnexures: defaultResultFileState?.reconciliationSummary,
          InternalSummaryAndOwnVsPartner: defaultResultFileState.internalSummary,
          FullLedgers: defaultResultFileState.fullLedgers,
          OwnLedger: defaultResultFileState.ownLedger,
          PartnerLedger: defaultResultFileState.partnerLedger,
          Help: defaultResultFileState.help,
          LedgerSummary: defaultResultFileState.ledgerSummary,
          QcSheets: defaultResultFileState.qcCsUsers,
        },
      },
      thenCallBack: () => {
        startInterval.current = Date.now();
        getExcelWorkbook();
      },
      catchCallBack: () => {
        setShowDownloadResultsLoadingIcon(false);
      },
    });
  };

  const uiLogger = (functionName: string, fileType?: string, optionSelected?: string) => {
    useFetch("/api/uiLogger", "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        functionName,
        fileType,
        optionSelected,
      },
    });
  };
  /* eslint-disable react-hooks/rules-of-hooks */

  const listUserEmailTemplates = () => {
    useFetch<ListUserEmailTemplateResponse>("/api/partnerCommunication/ListUserEmailTemplate", "GET", {
      config: {
        params: {
          templateType: "LR",
          companyId: companyId.current,
          branchCode: branchCode.current,
        },
      },
      thenCallBack: (res) => {
        const { emailTemplates } = res.data;
        const foundTemplate = emailTemplates.find((template) => template.templateName === EMAIL_TEMPLATE_NAME);
        setEmailTemplateContent(foundTemplate);
        setTemplateBody(foundTemplate?.emailBody || " ");
      },
    });
  };

  const base64ToFile = (base64str: string, fileName: string) => {
    const excelData = Buffer.from(base64str, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    return new File([blob], fileName);
  };

  const calculateCombinationsOfTdsGst = (selectedOption: string) => {
    const combinations = [];

    for (const item of probablyTdsGstArr.current) {
      // by default send as Ignore this time
      if (item.selectedOption === undefined) {
        item.selectedOption = "Ignore this time";
      }

      if (item.selectedOption === selectedOption) {
        const TDS = item.TDS;
        const GST = item.GST;
        const combinationValue = (TDS / (1 + GST / 100))?.toFixed(2);
        combinations.push(combinationValue);
      }
    }

    return combinations;
  };
  const uploadFileAgainAfterSelectTempSave = () => {
    if (isUploadFileAgainAfterSelectTempSaveRef.current) {
      excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
    }
  };

  const updateColumnHeadersArrForOptional = (rearrangedColumnHeaderArr) => {
    // Create a set of existing "columnHeaderName" values
    const existingColumnHeaderNames = new Set(rearrangedColumnHeaderArr.map((item) => item.columnHeaderName));

    // Iterate through section3ColumnHeaderArr
    section3ColumnHeaderArr.forEach((colHeaderName) => {
      // Check if the headerName is not in existingColumnHeaderNames
      if (!existingColumnHeaderNames.has(colHeaderName)) {
        // Add a new configuration for missing column header
        rearrangedColumnHeaderArr.push({
          columnHeaderName: colHeaderName,
          columnHeaders: ["Never ask again", ...rearrangedColumnHeaderArr[0].columnHeaders],
          suggestedHeaderIndex: -1,
          errorFlag: false,
          columnHeaderConfigValue: "",
        });
      }
    });
  };

  const extractTextBeforeTilde = (unidentifiedText: string) => {
    // Check if "~~~" exists in the text
    if (unidentifiedText?.includes("~~~")) {
      // Split the text using "~~~" as the delimiter and get the first part
      const parts = unidentifiedText.split("~~~");
      const extractedText = parts[0];

      // Return the extracted text or a default value
      return extractedText || "";
    } else {
      // Return the original text if "~~~" is not found
      return unidentifiedText;
    }
  };
  const extractTextAfterTilde = (unidentifiedText: string) => {
    // Check if "~~~" exists in the text
    if (unidentifiedText?.includes("~~~")) {
      // Split the text using "~~~" as the delimiter and get the second part
      const parts = unidentifiedText.split("~~~");
      const extractedText = parts[1];

      // Return the extracted text or a default value
      return extractedText || "";
    }
  };

  const clearSheetSelectionStates = () => {
    isSheetSelectionFinishRef.current = false;
    sheetsArrRef.current = [];
    setSelectedSheets([]);
    setOpenSheetSelectionModal(false);

    setOpenColumnHeaderDialog(false);
    setOpenDocumentTypeDialog(false);
    isFileReuploaded.current = false;
  };

  const uploadSelectedSheets = () => {
    isSheetSelectionFinishRef.current = true;
    setOpenSheetSelectionModal(false);
    // excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
    const fileMap = {};

    selectedSheets.forEach(({ filename, sheets }) => {
      fileMap[filename] = sheets;
    });
    uploadLedgerAfterSheetSelection(excelFileUploadOnChangeFileName, fileMap);
  };
  const getReconPeriod = () => {
    setLoadingReconPeriod(true);
    useFetch<GetReconPeriodResponse>("/api/GetReconPeriod", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelected,
          companyId: companyId.current,
          branchCode: branchCode.current,
          ownClosingBalance: ownClosingbalance,
          businessPartnerClosingBalance: businessPartnerClosingbalance,
          configUseCaseType:
            props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE
              ? ConfigUseCaseType.paymentAdvice
              : ConfigUseCaseType.recon,
        },
      },
      thenCallBack: (response) => {
        setLoadingReconPeriod(false);
        setDateRanges(
          response.data.periods.map((period) => {
            return {
              startDate: new Date(period.startDate),
              endDate: new Date(period.endDate),
            };
          })
        );
        setShowUseDateFilter(true);
      },
      catchCallBack: () => {
        setLoadingReconPeriod(false);
      },
    });
  };

  const postReconPeriod = (startDateVal, endDateVal) => {
    setLoadingReconSavePeriod(true);
    useFetch("/api/PostReconPeriod", "POST", {
      showSuccessToast: true,
      data: {
        startDate: startDateVal,
        endDate: endDateVal,
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        configUseCaseType:
          props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
      },
      thenCallBack: () => {
        setStartDate(startDateVal);
        setEndDate(endDateVal);
        getReconLinkInfo();
        startDateRef.current = startDateVal;
        endDateRef.current = endDateVal;
        setLoadingReconSavePeriod(false);
        setShowUseDateFilter(false);
        if (actor.integration) {
          UpdateTallyClosingBalance();
        }
      },
      catchCallBack: () => {
        setLoadingReconSavePeriod(false);
      },
    });
  };

  const sortFilesByName = (fileList: File[]) => {
    return Array.from(fileList).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const ListAllConfigTemplateNamesRu = async () => {
    useFetch<ConfigTemplateNames>("/api/ListRuConfigTemplates", "GET", {
      thenCallBack: (response) => {
        if (response.data.templates.length > -1) {
          setConfigList(
            response.data.templates
              .map((template) => ({ ...template, IsRuConfigTemplate: true }))
              .sort((a, b) => {
                return a.templateName.localeCompare(b.templateName, undefined, { sensitivity: "base" });
              })
          );

          if (response.data?.defaultTemplateId) {
            setDefTemplateId(response.data.defaultTemplateId);
          } else setDefTemplateId(null);
        }
      },
    });
  };

  const updateClosingBalance = async (updateFor) => {
    await useFetch("/api/ProcessRecon/UpdateClosingBalance", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyId,
        updateFor,
        ownClosingBalance: ownClosingbalanceRef.current,
        businessPartnerClosingBalance: businessPartnerClosingbalanceRef.current,
      },
    });
  };

  const listChecklist = async (isFromReset = false) => {
    await useFetch("/api/checklist/list", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelectedRef.current || currentEditableBpId.current,
          companyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyId,
        },
      },
      thenCallBack: (response) => {
        setListOfChecklist(response.data.result);
        if (!isFromReset) {
          //  if  we call listChecklist after reset then do not open directly checklist
          // Check if there's at least one unchecked item
          if (response.data.result?.some((item) => !item.isMarked)) {
            setShowChecklistAndNotesIcon(false);
            setOpenChecklistModal(true);
          }
        }
      },
    });
  };
  const deleteChecklist = async () => {
    utilUiLogger(
      uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_DELETE_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message: uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_DELETE_CLICK.message,
        businessPartnerId: businessPartnerSelectedRef.current,
      }
    );
    await useFetch(`/api/checklist/delete/${checklistCategory}/${selectedChecklistIDRef.current}`, "DELETE", {
      showSuccessToast: true,
      thenCallBack: () => {
        listChecklist();
        setOpenAddChecklistModal(false);
        isViewChecklistRef.current = false;
        setOpenChecklistModal(true);
      },
    });
  };
  const addChecklist = async () => {
    setAddChecklistLoader(true);
    await useFetch("/api/checklist/add", "POST", {
      showSuccessToast: true,
      data: {
        type: checklistCategory,
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyId,
        title: checklistTitle,
        content: checklistDynamicContent === "" ? "<p> <br /></p>" : checklistDynamicContent,
      },
      thenCallBack: () => {
        listChecklist();
        setOpenChecklistModal(true);
        setOpenAddChecklistModal(false);
        isViewChecklistRef.current = false;
        setAddChecklistLoader(false);
      },
      catchCallBack: () => {
        setAddChecklistLoader(false);
      },
    });
  };

  const markChecklist = async (id, action, type) => {
    utilUiLogger(
      action
        ? uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_MARK_CLICK.functionName
        : uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_UNMARK_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message: action
          ? uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_MARK_CLICK.message
          : uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_UNMARK_CLICK.message,
        businessPartnerId: businessPartnerSelectedRef.current,
      }
    );
    setMarkChecklistLoader(true);
    await useFetch(`/api/checklist/edit/${type}/${id}`, "PUT", {
      showSuccessToast: true,
      data: {
        action: action ? "MARK" : "UNMARK",
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
      },
      thenCallBack: () => {
        listChecklist();
        setMarkChecklistLoader(false);
      },
      catchCallBack: () => {
        setMarkChecklistLoader(false);
      },
    });
  };

  const resetChecklist = async () => {
    await useFetch("/api/checklist/reset", "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current || currentEditableBpId.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
      },
      thenCallBack: () => {
        listChecklist(true);
      },
    });
  };

  const listNotes = async () => {
    await useFetch("/api/notes/list", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelectedRef.current,
          companyId: companyId.current,
          branchCode: branchCode.current,
          businessPartnerCompanyId: businessPartnerSelectedCompanyId,
        },
      },
      thenCallBack: (response) => {
        setListOfNotes(response.data.result);
      },
    });
  };

  const addNotes = async () => {
    setAddNotesLoader(true);
    await useFetch("/api/notes/add", "POST", {
      showSuccessToast: true,
      data: {
        ownId: actor.id,
        tags: selectedNotesTagsForAdd,
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerCompanyId: businessPartnerSelectedCompanyId,
        title: notesTitle,
        content: notesDynamicContent === "" ? "<p> <br /></p>" : notesDynamicContent,
      },
      thenCallBack: () => {
        listNotes();
        setOpenNotesModal(true);
        setOpenAddNoteModal(false);
        isViewNotesListRef.current = false;
        setAddNotesLoader(false);
      },
      catchCallBack: () => {
        setAddNotesLoader(false);
      },
    });
  };
  const deleteNote = async () => {
    const noteId = selectedNotesRef.current.id;
    const tags = selectedNotesRef.current.tags;

    await useFetch(`/api/notes/delete/${noteId}`, "DELETE", {
      showSuccessToast: true,
      config: { params: { tags: JSON.stringify(tags) } },
      thenCallBack: () => {
        listNotes();
        isViewNotesListRef.current = false;
        setOpenNotesModal(true);
      },
    });
  };

  const getReconLinkInfo = async () => {
    await useFetch("/api/ProcessRecon/GetReconLinkInfo", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelectedRef.current,
          companyId: companyId.current,
          branchCode: branchCode.current,
        },
      },
      thenCallBack: (response) => {
        setLinkOldRecoTask(response.data.possibleLinks);
        if (response.data.currentLink) {
          setUnlinkOldRecoTask([response.data.currentLink]);
          setSelectedTaskId(response.data.currentLink.ticketId);
        } else {
          setUnlinkOldRecoTask([]);
          setSelectedTaskId(null);
        }
      },
    });
  };
  const linkReconTicket = async () => {
    await useFetch("/api/ProcessRecon/LinkReconTicket", "POST", {
      showSuccessToast: true,
      data: {
        ticketId: selectedTaskId,
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
      },
      thenCallBack: () => {
        getReconLinkInfo();
      },
    });
  };
  const deleteCurrentLink = async () => {
    await useFetch("/api/ProcessRecon/DeleteCurrentLink", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
      },
      thenCallBack: () => {
        getReconLinkInfo();
      },
    });
  };

  // ----Recon360ContextValue------

  const Recon360ContextValue: IRecon360Context = {
    businessPartnerSelected,
    businessPartnerSelectedRef,
    businessPartnerSelectedCompanyIdRef,
    companyId,
    checkboxDataManualRecon,
    amountTolerance,
    totalAmount,
    hasManuallyReconciled,
    setOpenManualRecon,
    getManuallyChangedEntries,
    downloadExcelFromApi,
    showDownloadResultsLoadingIcon,
    fileKeysForPdfNotCorrectMark,
    uiLogger,
    openManualChangesConfirmModal,
    setOpenManualChangesConfirmModal,
    setCheckboxDataManualRecon,
    currency,
    setIsUsedAsTemplateOwn,
    setIsUsedAsTemplateBp,
    // sheets selection
    sheetsArrRef,
    selectedSheets,
    setSelectedSheets,
    openSheetSelectionModal,
    setOpenSheetSelectionModal,
    uploadSelectedSheets,
    // ErrorInUploadModal
    openErrorInUploadModal,
    setOpenErrorInUploadModal,
    uploadErrorData,
    setUploadErrorData,
    getLedgerConfigList,
    assignBusinessPartnerNameRef,
    getSummaryForReconciliationCommonFunction,
    branchCode,
    currentEditableBpId,
    bpSelectedOptionRef, // last selected bp option obj, can be used to re-select bp
    businessPartnerSelect, // main function called after business partner select
    postReconPeriod,
    loadingReconSavePeriod,
    configList,
    defTemplateId,
    setDefTemplateId,
    ListAllConfigTemplateNamesRu,
    subTab: props.subTab,
    setWhichActionRequired,
    setDisableUploadBothSide,

    //reuseOldFilesModal
    openReuseOldFilesModal,
    setOpenReuseOldFilesModal,
    disableUploadBothSide,
    ledgerFiles,
    fileDetails,
    isSheetSelectionFinishRef,
    isFileReuploaded,
    excelFileUploadOnChange,
    excelFileUploadOnChangeFileName,
    uploadSideRef,
    reUploadLedger,
    //columnHeaderModal
    openColumnHeaderDialog,
    setOpenColumnHeaderDialog,
    fetchDataFromERP,
    sheetNameForUnidentified,
    fileNameForUnidentified,
    ignoreDoctypesList,
    newMappingOwn,
    configUserType,
    columnHeaderArr,
    setColumnHeaderArr,
    newMappingBusiness,
    configUserName,
    isOpenSelectTemplateUploadConfirmRefOwn,
    isOpenSelectTemplateUploadConfirmRefBp,
    highlightedHeader,
    setHighlightedHeader,
    listColumnHeaderEntries,
    headerNameBp,
    headerName,
    saveRuleApi,
    sanitizeColBusiness,
    businessPartnerIntegrationWithERP,
    compareLedgersCheckEndDate,
    documentTypesArr,
    setOpenDocumentTypeDialog,
    excelFileUploadOnChangeEvent,
    saveRuleApiOwn,
    sanitizeColOwn,
    cancelUploadRequest,
    //DocumentTypeModal
    openDocumentTypeDialog,
    groupedDocTypesArr,
    isChangedDocTypeOnScreenSuggestionMap,
    setSelectedDocumentTypeInUnassignedModal,
    changeDocumentTypeConfig,
    documentTypes,
    setItemForUnassignedDoctypeAdvancedDialog,
    setDuplicateTagName,
    finaldocumentTypesObj,
    templateNameBp,
    templateName,
    createdocumentTypeTagsForMappedTags,
    setDocumentTypeRow,
    extractTextBeforeTilde,
    extractTextAfterTilde,
    //UnassignedDoctypesAdvancedModal
    openUnassignedDoctypeAdvancedDialog,
    setUnassignedDoctypeAdvancedDialog,
    documentTypeRow,
    duplicateTagName,
    setHeaderNameOwn,
    setHeaderNameBusiness,
    itemForUnassignedDoctypeAdvancedDialog,
    documentTypesTags,
    selectedDocumentTypeInUnassignedModal,
    //RaiseDisputeModal
    openMsgDialog,
    setOpenMsgDialog,
    detailsHeadingName,
    setColumnDefinition,
    setPaymentGroupBy,
    summaryDetailsData,
    setExpanded,
    setOpendetailsJSON,
    invoiceAmountMismatchDispute,
    invoiceAmountMismatchDisputeValue,
    invoiceAmountMismatchColDef,
    invoiceNotPresentInPartnerLedgerColDef,
    invoiceNotPresentInOwnLedgerColDef,
    paymentAmountMismatchColDef,
    paymentNotPresentInPartnerLedgerColDef,
    dnCnNotPresentInOwnLedgerColDef,
    tdsAmountMismatchColDef,
    dnCnNotPresentInPartnerLedgerColDef,
    tdsNotPresentInPartnerLedgerColDef,
    tdsNotPresentInOwnLedgerColDef,
    dnCnAmountMismatchColDef,
    paymentNotPresentInOwnLedgerColDef,

    openLedgerUploadStatusModal,
    setOpenLedgerUploadStatusModal,
    fileStatus,
    whichActionRequired,
    cancelPollingRequest,

    listCompaniesForRecon,
    listBranchesForRecon,
    selectedCompany,
    selectedBranch,
    branchNameSelect,
    companyNameSelect,
    companiesLoaded,
    branchesLoaded,
    ownBusinessPartnersLoaded,
    selectedBusinessPartnerName,
    options,
    selectedBusinessPartnerVendorCode,
    listAllOwnBusinessPartnerMapping,

    //signoff statement
    showExpandedSignOffStatement,
    isExpandedSignOffStatement,
    setIsExpandedSignOffStatement,
    dateOfLastSummary,
    setDateOfLastSummary,
    setShowDownloadGapSummary,
    setManualReconChanges,
    setOpenManualMatches,
    ownClosingbalanceRef,
    businessPartnerClosingbalanceRef,
    assignBusinessPartnerName,
    showDownloadGapSummary,
    setReconEmailFiles,
    base64ToFile,
    setEmailTemplateContent,
    emailTemplateContent,
    templateBody,
    setOpenSendMailDialog,

    //Insights section
    showExpandedInsights,
    isExpandedInsights,
    setIsExpandedInsights,
    rowsDataOverviewInsights,
    setDetailsHeading,
    configMappedOwn,
    configMappedBp,
    loadingConfigBP,
    loadingConfigOwn,

    //save and discard ticket modal
    showSaveTicketModal,
    setShowSaveTicketModal,
    showDiscardTicketModal,
    setShowDiscardTicketModal,

    // Recon Home
    configListOwn,

    // doctype view from config
    setDocumentTypesArr,
    documentTypeSuggestionMap,
    setFetchDataFromERP,
    setConfigUserType,

    updateClosingBalance,
    openUnassignedDoctypeDialogFromView,
    setOpenUnassignedDoctypedDialogFromView,

    // checklist
    resetChecklist,
    // LinkOldReco
    openLinkOldReco,
    setOpenLinkOldReco,
    linkOldRecoTask,
    selectedTaskId,
    setSelectedTaskId,
    openUnlinkOldReco,
    setOpenUnlinkOldReco,
    unlinkOldRecoTask,
    linkReconTicket,
    deleteCurrentLink,
  };

  // ----Recon360ContextValue------

  return (
    <>
      <LoggedInSkeleton
        // topBarButtons={getReconTopBarButtons("Recon360", actor.name, location?.state?.openCollapseObj)}
        topBarButtons={getReconTopBarButtons(props.subTab, actor.name, location?.state?.openCollapseObj, actor)}
      >
        <Recon360Context.Provider value={Recon360ContextValue}>
          <RestrictiveLoader
            openRestrictiveLoader={openRestrictiveLoader}
            restrictiveLoaderMsg={restrictiveLoaderMsg}
          />
          {!openManualRecon ? (
            <>
              <Grid container={true} id="summary_page" sx={{ p: 4, pt: 0, justifyContent: "center" }}>
                {/* Upper Drop down Selection */}
                <div className="recon_top_bar">
                  <PartnerSelectSection />
                  {props.subTab === RECON360_SUB_TAB.LEDGER && (
                    <div className="home_quick_action">
                      <span className="fw_500 fs_20">Quick Actions</span>
                      <div
                        className="home_quick_actions_btn"
                        onClick={() => {
                          setIsExpandedReconHome((prev) => !prev);
                          if (!isExpandedReconHome) {
                            utilUiLogger(
                              uiLoggerNamesRecon.UI_WF_DB_SHOW_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNamesRecon.UI_WF_DB_SHOW_CLICK.message,
                                businessPartnerId: businessPartnerSelectedRef.current,
                              }
                            );
                          }
                        }}
                      >
                        <span className="fw_500 fs_15">{isExpandedReconHome ? "HIDE" : "SHOW"}</span>
                      </div>
                    </div>
                  )}
                </div>

                {/* Recon Home Collapsible */}
                <Collapse
                  in={props.subTab === RECON360_SUB_TAB.LEDGER && isExpandedReconHome}
                  timeout="auto"
                  className="ReconHomeCollapse"
                >
                  <ReconHome
                    selectPartner={(partnerName) => {
                      const foundPartner = options.find((opt) => opt.name === partnerName);

                      if (foundPartner) businessPartnerSelect({}, foundPartner);
                    }}
                  />
                </Collapse>

                {businessPartnerSelected === null && allTickets !== null && actor.workflowFeatureFlag ? (
                  <ViewTickets
                    fetchTickets={fetchTickets}
                    allTickets={allTickets}
                    allStatuses={ticketStatuses.map((status) => status.status)}
                    allUsers={ticketUsers
                      .map((user) => user.name)
                      .filter((i, index, arr) => index === arr.lastIndexOf(i))}
                    allUsersWithIds={ticketUsers.map((user) => {
                      return {
                        id: user.id,
                        name: user.name,
                      };
                    })}
                  />
                ) : (
                  ""
                )}

                {currentInProgressTicket && actor.workflowFeatureFlag && (
                  <CurrentTicket
                    key={currentInProgressTicket.id}
                    ticket={currentInProgressTicket}
                    ticketStatuses={ticketStatuses}
                    setTicket={setCurrentInProgressTicket}
                    fetchTickets={fetchTickets}
                  />
                )}
                {currentInProgressTicket === null && businessPartnerSelected !== null && actor.workflowFeatureFlag ? (
                  <Box
                    className="space_between"
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      width: "100%",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                  >
                    <span style={{ alignItems: "center", display: "flex", fontSize: "20px", fontWeight: 500 }}>
                      New Recon Task
                    </span>
                    <NdButton variant="contained" onClick={() => setCreateNewTicket(true)}>
                      Create Task
                    </NdButton>
                  </Box>
                ) : (
                  <>
                    {/* Run Recon Segment Bar  */}
                    <Grid xs={12} hidden={!businessPartnerSelected && props.subTab === RECON360_SUB_TAB.LEDGER}>
                      <div onClick={toggleExpandRunRecon} className="vertical_center_align space_between recon_segment">
                        Run Reconciliation
                        <ChevronRight
                          className="up_down_arrow"
                          sx={{ rotate: isExpandedRunRecon ? "90deg" : "-90deg", transition: "all 0.2s ease" }}
                        />
                      </div>
                    </Grid>
                    {/* Run Recon Collpsible */}
                    {true && (
                      <Collapse
                        in={
                          isExpandedRunRecon && (!!businessPartnerSelected || props.subTab !== RECON360_SUB_TAB.LEDGER)
                        }
                        timeout="auto"
                      >
                        {whichActionRequired && (
                          //  && uploadSideRef.current
                          <Grid className="mt_30">
                            <Alert
                              severity={
                                whichActionRequired === WhichActionRequiredStatus.Failure
                                  ? "error"
                                  : whichActionRequired === WhichActionRequiredStatus.UploadInProgress ||
                                    whichActionRequired === WhichActionRequiredStatus.ReconInProgress
                                  ? "info"
                                  : whichActionRequired === WhichActionRequiredStatus.Success
                                  ? "success"
                                  : "warning"
                              }
                              icon={
                                whichActionRequired === WhichActionRequiredStatus.UploadInProgress ||
                                whichActionRequired === WhichActionRequiredStatus.ReconInProgress ? (
                                  <CircularProgress size={20} />
                                ) : whichActionRequired === WhichActionRequiredStatus.Failure ? (
                                  <ErrorIcon />
                                ) : whichActionRequired === WhichActionRequiredStatus.Success ? (
                                  <CheckCircleIcon />
                                ) : undefined
                              }
                              sx={{
                                border:
                                  whichActionRequired === WhichActionRequiredStatus.Failure
                                    ? "1px solid #f44336"
                                    : whichActionRequired === WhichActionRequiredStatus.UploadInProgress ||
                                      whichActionRequired === WhichActionRequiredStatus.ReconInProgress
                                    ? "1px solid #2196f3"
                                    : whichActionRequired === WhichActionRequiredStatus.Success
                                    ? "1px solid #4caf50"
                                    : "1px solid #ff9800",
                              }}
                              action={
                                (whichActionRequired === WhichActionRequiredStatus.UnIdentifiedColumnHeaders ||
                                  whichActionRequired === WhichActionRequiredStatus.PotentiallyMissedDocumentTypes ||
                                  whichActionRequired === WhichActionRequiredStatus.Success ||
                                  whichActionRequired === WhichActionRequiredStatus.Failure ||
                                  (whichActionRequired === WhichActionRequiredStatus.ReconInProgress &&
                                    fetchReconStatusStart === false)) && (
                                  <Button
                                    color="inherit"
                                    size="small"
                                    style={{
                                      backgroundColor:
                                        whichActionRequired === WhichActionRequiredStatus.UnIdentifiedColumnHeaders ||
                                        whichActionRequired === WhichActionRequiredStatus.PotentiallyMissedDocumentTypes
                                          ? "#EF6C00"
                                          : whichActionRequired === WhichActionRequiredStatus.Success
                                          ? "#4caf50"
                                          : whichActionRequired === WhichActionRequiredStatus.Failure
                                          ? "#f44336"
                                          : "#2196f3",
                                      color: "#fff",
                                    }}
                                    onClick={() => {
                                      if (
                                        whichActionRequired === WhichActionRequiredStatus.UnIdentifiedColumnHeaders ||
                                        whichActionRequired === WhichActionRequiredStatus.PotentiallyMissedDocumentTypes
                                      ) {
                                        setOpenLedgerUploadStatusModal(true);
                                      } else if (whichActionRequired === WhichActionRequiredStatus.Success) {
                                        setShowUploadedSummary(true);
                                      } else if (whichActionRequired === WhichActionRequiredStatus.Failure) {
                                        setWhichActionRequired(null);
                                        cancelUploadRequest();
                                      } else if (whichActionRequired === WhichActionRequiredStatus.ReconInProgress) {
                                        cancelReconRequest();
                                      }
                                    }}
                                    disabled={whichActionRequiredDisable}
                                  >
                                    {whichActionRequired === WhichActionRequiredStatus.Failure
                                      ? "Close"
                                      : whichActionRequired === WhichActionRequiredStatus.ReconInProgress
                                      ? "CANCEL"
                                      : "View"}
                                  </Button>
                                )
                              }
                            >
                              {whichActionRequired === WhichActionRequiredStatus.UnIdentifiedColumnHeaders ||
                              whichActionRequired === WhichActionRequiredStatus.PotentiallyMissedDocumentTypes
                                ? `${
                                    uploadSideRef.current === OWN_LEDGER ? "Own" : "Partner"
                                  } upload paused, User action required`
                                : whichActionRequired === WhichActionRequiredStatus.UploadInProgress
                                ? `${
                                    uploadSideRef.current === OWN_LEDGER ? "Own" : "Partner"
                                  } Ledger upload in progress`
                                : whichActionRequired === WhichActionRequiredStatus.Failure
                                ? "Error occurred" + `: ${errorMsgUploadFail}`
                                : whichActionRequired === WhichActionRequiredStatus.Success
                                ? `${uploadSideRef.current === OWN_LEDGER ? "Own" : "Partner"} Ledger upload completed`
                                : whichActionRequired === WhichActionRequiredStatus.ReconInProgress
                                ? fetchReconStatusStart
                                  ? "Please wait fetching recon status"
                                  : `Reconciliation is in progress ${initiatedReconOn ? `| ${initiatedReconOn}` : ""}`
                                : ""}
                            </Alert>
                          </Grid>
                        )}
                        <Grid
                          container={true}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          className="buttons max_width_1300"
                          sx={{ pt: 2 }}
                        >
                          <Grid item={true} xs={12} sm={12} md={6}>
                            <Card>
                              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                                <LedgerUpload
                                  name="Own Documents"
                                  cardTitle="Your Ledger"
                                  uploadSectionName={
                                    props.subTab === "Payment Advice" ? "Upload Open Items" : "UPLOAD OWN LEDGER"
                                  }
                                  saveRules={(header: any, template: any, value: any) =>
                                    saveRuleApiOwn(header, template, value)
                                  }
                                  newMapping={newMappingOwn}
                                  newMappingOwn={newMappingOwn}
                                  setNewMapping={setNewMappingOwn}
                                  templateName={templateName}
                                  setTemplateName={setTemplateNameOwn}
                                  sanitizeCol={sanitizeColOwn}
                                  setSanitizeCol={setSanitizeColOwn}
                                  headerName={headerName}
                                  setHeaderName={setHeaderNameOwn}
                                  displayTemplateName={displayTemplateNameOwn}
                                  excelUpload={(e) => {
                                    totalNumberOfUploadedFiles.current = 0;
                                    currentFileNumber.current = 0;
                                    ledgerFiles.current = Array.from(e.target.files);
                                    excelFileUploadOnChange(sortFilesByName(Array.from(e.target.files)), OWN_LEDGER);
                                  }}
                                  closingBalance={ownClosingbalance}
                                  setClosingBalance={setOwnClosingBalance}
                                  setClosingBalanceRef={ownClosingbalanceRef}
                                  startDate={startDate}
                                  setStartDate={setStartDate}
                                  endDate={endDate}
                                  setEndDate={setEndDate}
                                  setEndDateOwn={setEndDate}
                                  setStartDateBusinessPartner={setStartDate}
                                  setEndDateBusinessPartner={setEndDate}
                                  showLoadingIcon={showLoadingIconOwn}
                                  uploadedLastModifiedDate={uploadedLastModifiedDateOwn}
                                  uploadedFileName={uploadedFileNameOwn}
                                  rulesArray={rulesArray}
                                  openPdfDialog={openOwnPdfDialog}
                                  setOpenPdfDialog={setOpenOwnPdfDialog}
                                  pdfTableData={ownPdfTableData}
                                  setUploadedLastModifiedDate={setUploadedLastModifiedDateOwn}
                                  setUploadedFileName={setUploadedFileNameOwn}
                                  integration={actor.integration}
                                  businessPartnerSelected={businessPartnerSelected}
                                  dateSyncFromSAP={ownDateSyncFromSAP}
                                  getReconMetaData={getReconMetaData}
                                  specificConfig={ownSpecificConfig}
                                  setSpecificConfig={setOwnSpecificConfig}
                                  disableSpecificConfigCheckbox={disableOwnSpecificConfigCheckbox}
                                  configList={configListOwn}
                                  setColumnHeaderArr={setColumnHeaderArr}
                                  setConfigUserType={setConfigUserType}
                                  setConfigUserName={setConfigUserName}
                                  setOpenColumnHeaderDialog={setOpenColumnHeaderDialog}
                                  setDocumentTypesArr={setDocumentTypesArr}
                                  assignBusinessPartnerName={assignBusinessPartnerName}
                                  // setFinaldocumentTypesObj={setFinaldocumentTypesObj}
                                  finaldocumentTypesObj={finaldocumentTypesObj}
                                  setOpenDocumentTypeDialog={setOpenDocumentTypeDialog}
                                  setShowUploadedSummary={setShowUploadedSummary}
                                  setShowUploadedSummaryObject={setShowUploadedSummaryObject}
                                  selectedPdfConverter={selectedPdfConverter}
                                  isPdfOptionConfirm={isPdfOptionConfirm}
                                  onChangePdfConversionMethod={() =>
                                    excelFileUploadOnChange(
                                      excelFileUploadOnChangeEvent,
                                      excelFileUploadOnChangeFileName
                                    )
                                  }
                                  disablePdfTableOptionForMultiplePdf={disablePdfTableOptionForMultiplePdf}
                                  selectedBusinessPartnerName={selectedBusinessPartnerName}
                                  downloadUploadedPdfName="Own Ledger"
                                  pdfConversionLoader={pdfConversionLoader}
                                  setPdfConversionLoader={setPdfConversionLoader}
                                  debitAmountSignReverseFlag={debitAmountSignReverseFlagOwn}
                                  setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlagOwn}
                                  creditAmountSignReverseFlag={creditAmountSignReverseFlagOwn}
                                  setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlagOwn}
                                  subTab={props.subTab}
                                  ledgerUploadedOwn={ledgerUploadedOwn}
                                  ledgerUploadedBP={ledgerUploadedBP}
                                  accountingTerminology={accountingTerminology}
                                  getConfigResponse={getOwnLedgerConfigResponse}
                                  loadingConfig={loadingConfigOwn}
                                  uiLogger={(functionName: string) => uiLogger(functionName)}
                                  guidCommonTimestamp={guidCommonTimestamp}
                                  openSelectTemplate={openSelectTemplateOwn}
                                  setOpenSelectTemplate={setOpenSelectTemplateOwn}
                                  isOpenSelectTemplateUploadConfirmRef={isOpenSelectTemplateUploadConfirmRefOwn}
                                  uploadFileAgainAfterSelectTempSave={() => {
                                    uploadFileAgainAfterSelectTempSave();
                                  }}
                                  isUsedAsTemplate={isUsedAsTemplateOwn}
                                  clearSheetSelectionStates={clearSheetSelectionStates}
                                  companyId={companyId.current}
                                  branchCode={branchCode.current}
                                  disableUploadBothSide={disableUploadBothSide}
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item={true} xs={12} sm={12} md={6}>
                            <Card>
                              <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                                <LedgerUpload
                                  name="Business Partner Documents"
                                  cardTitle="Partner Ledger"
                                  uploadSectionName={
                                    props.subTab === "Payment Advice"
                                      ? "Upload Payment Advice"
                                      : "UPLOAD PARTNER LEDGER"
                                  }
                                  saveRules={(header: any, template: any, value: any) => {
                                    saveRuleApi(header, template, value);
                                  }}
                                  newMapping={newMappingBusiness}
                                  setNewMapping={setNewMappingBusiness}
                                  newMappingOwn={newMappingOwn}
                                  templateName={templateNameBp}
                                  setTemplateName={setTemplateNameBusiness}
                                  sanitizeCol={sanitizeColBusiness}
                                  setSanitizeCol={setSanitizeColBusiness}
                                  headerName={headerNameBp}
                                  setHeaderName={setHeaderNameBusiness}
                                  displayTemplateName={displayTemplateNameBusiness}
                                  excelUpload={(e) => {
                                    totalNumberOfUploadedFiles.current = 0;
                                    currentFileNumber.current = 0;
                                    ledgerFiles.current = Array.from(e.target.files);
                                    excelFileUploadOnChange(
                                      sortFilesByName(Array.from(e.target.files)),
                                      BUSINESS_PARTNER_LEDGER
                                    );
                                  }}
                                  closingBalance={businessPartnerClosingbalance}
                                  setClosingBalance={setBusinessPartnerClosingBalance}
                                  setClosingBalanceRef={businessPartnerClosingbalanceRef}
                                  startDate={startDate}
                                  setStartDate={setStartDate}
                                  endDate={endDate}
                                  setEndDate={setEndDate}
                                  setEndDateBusinessPartner={setEndDate}
                                  showLoadingIcon={showLoadingIconBusinessPartner}
                                  uploadedLastModifiedDate={uploadedLastModifiedDateBusinessPartner}
                                  uploadedFileName={uploadedFileNameBusinessPartner}
                                  rulesArray={rulesArray}
                                  openPdfDialog={openBusinessPartnerPdfDialog}
                                  setOpenPdfDialog={setOpenBusinessPartnerPdfDialog}
                                  pdfTableData={businessPartnerPdfTableData}
                                  setUploadedLastModifiedDate={setUploadedLastModifiedDateBusinessPartner}
                                  setUploadedFileName={setUploadedFileNameBusinessPartner}
                                  integration={businessPartnerIntegrationWithERP}
                                  businessPartnerAccountingSoftware={businessPartnerAccountingSoftware}
                                  businessPartnerSelectedCompanyId={businessPartnerSelectedCompanyId}
                                  dateSyncFromSAP={businessPartnerDateSyncFromSAP}
                                  businessPartnerSelected={businessPartnerSelected}
                                  getReconMetaData={getReconMetaData}
                                  // specificConfig={businessPartnerspecificConfig}
                                  // setSpecificConfig={setBusinessPartnerSpecificConfig}
                                  // disableSpecificConfigCheckbox={disableBusinessPartnerSpecificConfigCheckbox}
                                  configList={configListBp}
                                  setColumnHeaderArr={setColumnHeaderArr}
                                  setConfigUserType={setConfigUserType}
                                  setConfigUserName={setConfigUserName}
                                  setOpenColumnHeaderDialog={setOpenColumnHeaderDialog}
                                  setDocumentTypesArr={setDocumentTypesArr}
                                  assignBusinessPartnerName={assignBusinessPartnerName}
                                  // setFinaldocumentTypesObj={setFinaldocumentTypesObj}
                                  finaldocumentTypesObj={finaldocumentTypesObj}
                                  setOpenDocumentTypeDialog={setOpenDocumentTypeDialog}
                                  getConfigResponse={getBusinessPartnerLedgerConfigResponse}
                                  setShowUploadedSummary={setShowUploadedSummary}
                                  setShowUploadedSummaryObject={setShowUploadedSummaryObject}
                                  selectedPdfConverter={selectedPdfConverter}
                                  isPdfOptionConfirm={isPdfOptionConfirm}
                                  onChangePdfConversionMethod={() =>
                                    excelFileUploadOnChange(
                                      excelFileUploadOnChangeEvent,
                                      excelFileUploadOnChangeFileName
                                    )
                                  }
                                  disablePdfTableOptionForMultiplePdf={disablePdfTableOptionForMultiplePdf}
                                  selectedBusinessPartnerName={selectedBusinessPartnerName}
                                  downloadUploadedPdfName="Partner Ledger"
                                  pdfConversionLoader={pdfConversionLoader}
                                  setPdfConversionLoader={setPdfConversionLoader}
                                  debitAmountSignReverseFlag={debitAmountSignReverseFlagBP}
                                  setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlagBP}
                                  creditAmountSignReverseFlag={creditAmountSignReverseFlagBP}
                                  setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlagBP}
                                  subTab={props.subTab}
                                  ledgerUploadedOwn={ledgerUploadedOwn}
                                  ledgerUploadedBP={ledgerUploadedBP}
                                  accountingTerminology={accountingTerminology}
                                  loadingConfig={loadingConfigBP}
                                  uiLogger={(functionName: string) => uiLogger(functionName)}
                                  guidCommonTimestamp={guidCommonTimestamp}
                                  openSelectTemplate={openSelectTemplateBp}
                                  setOpenSelectTemplate={setOpenSelectTemplateBp}
                                  isOpenSelectTemplateUploadConfirmRef={isOpenSelectTemplateUploadConfirmRefBp}
                                  uploadFileAgainAfterSelectTempSave={() => {
                                    uploadFileAgainAfterSelectTempSave();
                                  }}
                                  isUsedAsTemplate={isUsedAsTemplateBp}
                                  clearSheetSelectionStates={clearSheetSelectionStates}
                                  companyId={companyId.current}
                                  branchCode={branchCode.current}
                                  disableUploadBothSide={disableUploadBothSide}
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>

                        <div style={{ width: "45%", margin: "auto" }}>
                          <Card>
                            <div className="recon_period_card_title">
                              <p className="fw_600 fs_20">Reconciliation Period</p>
                            </div>
                            <hr className="solid_divider" />
                            <div className="recon_period_card_content">
                              <p className="fw_500 fs_16">Reconciliation to be done for below Date Period</p>
                              <div className="recon_period_date">
                                <div className="recon_period_date_item">
                                  <p className="fw_500 fs_12">START DATE</p>
                                  <p className="fw_600 fs_16">
                                    {startDate && startDate !== "Invalid date"
                                      ? moment(startDate)?.format("DD-MM-YYYY")
                                      : "-"}
                                  </p>
                                </div>
                                <div className="dashed_line"></div>
                                <div className="recon_period_date_item">
                                  <p className="fw_500 fs_12">END DATE</p>
                                  <p className="fw_600 fs_16">
                                    {endDate && endDate !== "Invalid date"
                                      ? moment(endDate)?.format("DD-MM-YYYY")
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card>
                          <div className="recon_period_actions">
                            <Button
                              className="fs_16 fw_700"
                              variant="outlined"
                              startIcon={
                                loadingReconPeriod ? (
                                  <LoadingIcon loading={loadingReconPeriod} />
                                ) : (
                                  <CalendarMonthIcon />
                                )
                              }
                              sx={{
                                width: "45%",
                                background: "#fff",
                                padding: "16px 22px",
                                borderRadius: "10px !important",
                                color: "#541C4C",
                                borderColor: "#541C4C",
                                "&:hover, &.Mui-focusVisible": { borderColor: "#541C4C" },
                              }}
                              disabled={
                                (ledgerUploadedOwn && ledgerUploadedBP ? false : true) ||
                                loadingReconPeriod ||
                                disableUploadBothSide
                              }
                              onClick={() => {
                                getReconPeriod();
                                utilUiLogger(
                                  uiLoggerNamesRecon.UI_RL_SET_RECON_PERIOD_CLICK.functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message: uiLoggerNamesRecon.UI_RL_SET_RECON_PERIOD_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                              }}
                            >
                              SET RECON PERIOD
                            </Button>
                            <Button
                              className="fs_16 fw_700"
                              variant="contained"
                              startIcon={
                                showReconcileLoadingIcon ? (
                                  <LoadingIcon loading={showReconcileLoadingIcon} />
                                ) : (
                                  <img src={reconcile_ledger} alt="ledger" />
                                )
                              }
                              sx={{
                                width: "45%",
                                background: "#541C4C",
                                padding: "10px 22px",
                                borderRadius: "10px !important",
                                color: "#fff",
                                "&:hover, &.Mui-focusVisible": { background: "#541C4C" },
                              }}
                              disabled={
                                (ledgerUploadedOwn &&
                                ledgerUploadedBP &&
                                startDate &&
                                endDate &&
                                startDate !== "Invalid date" &&
                                endDate !== "Invalid date"
                                  ? false
                                  : true) ||
                                disableUploadBothSide ||
                                showReconcileLoadingIcon ||
                                showDownloadResultsLoadingIcon
                              }
                              value="Compare"
                              onClick={() => {
                                if (businessPartnerSelected !== null) {
                                  clarity.setTag("Reconciliation Button", `Click`);
                                  compareLedgersCheckEndDate(true);
                                  uiLogger(uiLoggerName.ui_ReconcileLedgerButton);
                                  utilUiLogger(
                                    uiLoggerNamesRecon.UI_RL_RECONCILE_LEDGERS_CLICK.functionName,
                                    companyId.current,
                                    branchCode.current,
                                    {
                                      message: uiLoggerNamesRecon.UI_RL_RECONCILE_LEDGERS_CLICK.message,
                                      businessPartnerId: businessPartnerSelectedRef.current,
                                    }
                                  );
                                } else {
                                  toast.error(<CustomToast message="please select business partner" />);
                                }
                              }}
                            >
                              {props.subTab === "Payment Advice" ? "Reconcile" : " RECONCILE LEDGER"}
                            </Button>
                            <Button
                              style={{
                                background: unlinkOldRecoTask.length > 0 ? "#2E7D32" : "#fff",
                                color: unlinkOldRecoTask.length > 0 ? "#FFFFFF" : "black",
                              }}
                              onClick={() => {
                                if (unlinkOldRecoTask.length > 0) {
                                  setOpenUnlinkOldReco(true);
                                } else if (linkOldRecoTask.length > 0) {
                                  setOpenLinkOldReco(true);
                                }
                              }}
                              disabled={unlinkOldRecoTask.length === 0 && linkOldRecoTask.length === 0} // Disable if both arrays are empty
                            >
                              <Tooltip
                                title={unlinkOldRecoTask.length > 0 ? "Unlink Reconciliation" : "Link Reconciliation"}
                                arrow
                                placement="right"
                              >
                                <LinkIcon />
                              </Tooltip>
                            </Button>
                          </div>
                        </div>
                      </Collapse>
                    )}
                    <ReconciliationStatement
                      showExpandedSummary={showExpandedSummary}
                      toggleExpandSummary={toggleExpandSummary}
                      dateOfLastRecon={dateOfLastRecon}
                      isExpandedSummary={isExpandedSummary}
                      renderRowSubComponent={renderRowSubComponent}
                      generatingSummary={generatingSummary}
                      reconSummaryDetailColDefImported={reconSummaryDetailColDefImported}
                      summaryTypeSelected={summaryTypeSelected}
                      waitingForOutputFile={waitingForOutputFile}
                      ledgerComparisonSummaryData={ledgerComparisonSummaryData}
                      hasReconciled={hasReconciled}
                    />
                    {/* added false to remove insight section for now */}
                    {false && <InsightsSection />}
                    <SignOffStatement />
                  </>
                )}
              </Grid>

              {currentInProgressTicket !== null && businessPartnerSelected !== null && actor.workflowFeatureFlag && (
                <ReconSummaryDetails
                  loader={loader}
                  detailsHeading={detailsHeading}
                  openDetailsJSON={openDetailsJSON}
                  columnDefinition={columnDefinition}
                  paymentGroupBy={paymentGroupBy}
                  expanded={expanded}
                />
              )}
            </>
          ) : (
            <ManualRecon />
          )}
          {/* // -----Dispute UI------ */}
          {openMsgDialog && <RaiseDisputeModal />}
          {createNewTicket && actor.workflowFeatureFlag && (
            <CreateTicketModal
              ticketUsers={ticketUsers}
              ticketStatuses={ticketStatuses}
              createNewTicket={createNewTicket}
              setCreateNewTicket={setCreateNewTicket}
              createTicket={createTicket}
            />
          )}
          {openPdfDialogErrorMessage && (
            <UploadPdfErrorModal
              openPdfDialogErrorMessage={openPdfDialogErrorMessage}
              pdfDialogErrorMessage={pdfDialogErrorMessage}
              setOpenPdfDialogErrorMessage={setOpenPdfDialogErrorMessage}
            />
          )}
          {openColumnHeaderDialog && <ColumnHeaderModal />}
          {openDocumentTypeDialog && <DocumentTypeModal />}
          {openUnassignedDoctypeAdvancedDialog && <UnassignedDoctypeAdvancedModal />}
          {showUploadedSummary && (
            <UploadedSummary
              showUploadedSummary={showUploadedSummary}
              setShowUploadedSummary={setShowUploadedSummary}
              showUploadedSummaryObject={showUploadedSummaryObject}
              subTab={props.subTab}
              showConfigMappedData={showConfigMappedData}
              showConfigMapped={showConfigMapped}
              setShowConfigMapped={setShowConfigMapped}
            />
          )}
          {showUseDateFilter && (
            <UseDateFilter
              showUseDateFilter={showUseDateFilter}
              setShowUseDateFilter={setShowUseDateFilter}
              startDate={new Date(startDate)}
              setStartDate={setStartDate}
              endDate={new Date(endDate)}
              setEndDate={setEndDate}
              dateRanges={dateRanges}
            />
          )}

          {openConfirmOptionModal && (
            <PdfOptionConfirmModal
              openConfirmOptionModal={openConfirmOptionModal}
              onPdfOptionConfirm={() => {
                onPdfOptionConfirm();
              }}
              selectedPdfConverter={selectedPdfConverter}
            />
          )}
          {/* ------------------- */}
          {openSendMailDialog && (
            <SendMail
              openSendMailDialog={openSendMailDialog}
              companyId={companyId.current}
              emailTemplate={emailTemplateContent}
              recieverId={businessPartnerSelected}
              setOpenSendMailDialog={setOpenSendMailDialog}
              files={
                reconEmailFiles.summaryOutput
                  ? [
                      ...reconEmailFiles.originalBPLedger,
                      ...reconEmailFiles.originalOwnLedger,
                      reconEmailFiles?.summaryOutput,
                    ]
                  : [...reconEmailFiles.originalBPLedger, ...reconEmailFiles.originalOwnLedger]
              }
            />
          )}
          {openProbablyTdsGstModal && (
            <SuggestedTdsGstDialog
              openProbablyTdsGstModal={openProbablyTdsGstModal}
              setOpenProbablyTdsGstModal={setOpenProbablyTdsGstModal}
              probablyTdsGstArr={probablyTdsGstArr}
              compareLedgers={compareLedgers}
              checkboxDataManualRecon={checkboxDataManualRecon}
              setOpenManualChangesConfirmModal={setOpenManualChangesConfirmModal}
            />
          )}
          {openReuseOldFilesModal && <ReuseOldFilesModal />}
          {openManualChangesConfirmModal && <ManualChangesConfirmModal />}
          {openSheetSelectionModal && <SheetSelectionModal />}
          {openErrorInUploadModal && <ErrorInUploadModal />}
          {manualReconChanges.length > 0 && openManualMatches && (
            <ManualMatchDialog
              manualReconChanges={manualReconChanges}
              open={openManualMatches}
              setOpen={setOpenManualMatches}
            />
          )}

          {/* openLedgerUploadStatusModal dialog */}
          {openLedgerUploadStatusModal && <LedgerUploadStatusModal />}
          {/* Link old reco */}
          {openLinkOldReco && <LinkOldReco />}
          {openUnlinkOldReco && <UnlinkOldReco />}
          {/* checklist and notes */}
          {/* <div style={{
       position: "absolute",}}> */}
          {/* Your main application content here */}

          {/* Buttons that are always at the bottom-right of the screen */}

          {/* </div> */}
        </Recon360Context.Provider>
      </LoggedInSkeleton>
      <div style={{ position: "absolute" }}>
        {showChecklistAndNotesIcon && (
          <span
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              zIndex: 9999,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: "20px",
              // height:"100vh"
            }}
          >
            <Badge
              badgeContent={
                listOfChecklist.filter((item) => !item.isMarked).length > 0
                  ? `${listOfChecklist.filter((item) => !item.isMarked).length}`
                  : null
              }
              color="warning"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiBadge-badge": {
                  right: 10,
                  zIndex: 1060,
                },
              }}
            >
              <Fab
                variant="extended"
                color="primary"
                onClick={() => {
                  setShowChecklistAndNotesIcon(false);
                  setOpenChecklistModal(true);
                  utilUiLogger(
                    uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_CLICK.message,
                      businessPartnerId: businessPartnerSelectedRef.current,
                    }
                  );
                }}
              >
                <ChecklistIcon sx={{ mr: 1 }} />
                Checklist
              </Fab>
            </Badge>

            <Fab
              size="medium"
              sx={{
                background: "#000000",
                borderRadius: "50%",
                marginLeft: "8px",
                "&:hover": {
                  background: "#000000", // Keeps the background color the same on hover
                },
              }}
              aria-label="add"
              onClick={() => {
                setOpenNotesModal(true);
                utilUiLogger(
                  uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_CLICK.message,
                    businessPartnerId: businessPartnerSelectedRef.current,
                  }
                );
              }}
            >
              <Tooltip title="Notes" arrow>
                <StickyNote2Icon sx={{ color: "#fff" }} />
              </Tooltip>
            </Fab>
          </span>
        )}
        {/* {openChecklistModal && */}
        <Checklist
          openChecklistModal={openChecklistModal}
          setOpenChecklistModal={setOpenChecklistModal}
          setOpenAddChecklistModal={setOpenAddChecklistModal}
          setShowChecklistAndNotesIcon={setShowChecklistAndNotesIcon}
          setChecklistTitle={setChecklistTitle}
          setChecklistCategory={setChecklistCategory}
          setChecklistDynamicContent={setChecklistDynamicContent}
          isViewChecklistRef={isViewChecklistRef}
          listOfChecklist={listOfChecklist}
          selectedChecklistIDRef={selectedChecklistIDRef}
          markChecklist={(id, action, type) => {
            markChecklist(id, action, type);
          }}
          markChecklistLoader={markChecklistLoader}
          companyId={companyId}
          branchCode={branchCode}
          businessPartnerSelectedRef={businessPartnerSelectedRef}
        />
        {/* } */}
        {openAddChecklistModal && (
          <AddChecklist
            openAddChecklistModal={openAddChecklistModal}
            setOpenAddChecklistModal={setOpenAddChecklistModal}
            setOpenChecklistModal={setOpenChecklistModal}
            selectedBusinessPartnerName={selectedBusinessPartnerName}
            checklistTitle={checklistTitle}
            setChecklistTitle={setChecklistTitle}
            checklistCategory={checklistCategory}
            setChecklistCategory={setChecklistCategory}
            checklistDynamicContent={checklistDynamicContent}
            setChecklistDynamicContent={setChecklistDynamicContent}
            isViewChecklistRef={isViewChecklistRef}
            addChecklist={addChecklist}
            deleteChecklist={deleteChecklist}
            addChecklistLoader={addChecklistLoader}
            companyId={companyId}
            branchCode={branchCode}
            businessPartnerSelectedRef={businessPartnerSelectedRef}
          />
        )}
        {openNotesModal && (
          <NotesModal
            openNotesModal={openNotesModal}
            setOpenNotesModal={setOpenNotesModal}
            selectedNotesTags={selectedNotesTags}
            setSelectedNotesTags={setSelectedNotesTags}
            setOpenAddNoteModal={setOpenAddNoteModal}
            tagsList={tagsList}
            listOfNotes={listOfNotes}
            setNotesTitle={setNotesTitle}
            setSelectedNotesTagsForAdd={setSelectedNotesTagsForAdd}
            setNotesDynamicContent={setNotesDynamicContent}
            isViewNotesListRef={isViewNotesListRef}
            deleteNote={deleteNote}
            selectedNotesRef={selectedNotesRef}
            companyId={companyId}
            branchCode={branchCode}
            businessPartnerSelectedRef={businessPartnerSelectedRef}
          />
        )}
        {openAddNoteModal && (
          <AddNotesModal
            openAddNoteModal={openAddNoteModal}
            setOpenAddNoteModal={setOpenAddNoteModal}
            isViewNotesListRef={isViewNotesListRef}
            notesTitle={notesTitle}
            setNotesTitle={setNotesTitle}
            tagsList={tagsList}
            selectedNotesTagsForAdd={selectedNotesTagsForAdd}
            setSelectedNotesTagsForAdd={setSelectedNotesTagsForAdd}
            notesDynamicContent={notesDynamicContent}
            setNotesDynamicContent={setNotesDynamicContent}
            addNotes={addNotes}
            setOpenNotesModal={setOpenNotesModal}
            addNotesLoader={addNotesLoader}
            companyId={companyId}
            branchCode={branchCode}
            businessPartnerSelectedRef={businessPartnerSelectedRef}
          />
        )}
      </div>
    </>
  );
};

export default Recon360;

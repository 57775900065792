import { ListAllActorData } from "src/Components/Admin/AdminInterfaces";
import useFetch from "src/Components/Common/useFetch";

export const getAllActorsList = async () => {
  return new Promise<ListAllActorData[]>((resolve, reject) => {
    useFetch<{ data: ListAllActorData[] }>("/api/listAllActorDetails", "GET", {
      thenCallBack: (response) => {
        return resolve(response.data.data);
      },
      catchCallBack: reject,
    });
  });
};

import { Box, SxProps, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { UserTypes } from "../../../entity/models/FrontendActor";
import { EmailSMTPHosts } from "../../../entity/recon-entity/ReconInterfaces";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import UserEmailSetting from "../BpContactMaster/ UserEmailSetting";
import "../BpContactMaster/BpContactMaster.scss";
import DesignationSetting from "../BpContactMaster/DesignationSetting";
import SelectEmailDomain from "../BpContactMaster/SelectEmailDomain";
import SendEmailFromOfficialEmail from "../BpContactMaster/SendEmailFromOfficialEmail";
// import AlgoCustomization from "./AlgoCustomization";
import { toast } from "react-toastify";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { getBaseURL } from "src/Utils/Recon/ENV/Constants";
import AccountSetting from "./AccountSetting";
import AlgoCustomization from "./AlgoCustomization";
import AlgoCustomizationUpload from "./AlgoCustomizationUpload";
import ReconOutput from "./ReconOutput";
import ReconRuleConfigurations from "./ReconRuleConfigurations";
import "./ReconSettings.scss";
import ReconSummaryCalculation from "./ReconSummaryCalculation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3, ...props.sx }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReconSettings = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [value, setValue] = useState<number>(0);
  const [emailDisplayName, setEmailDisplayName] = useState<string>("");
  const [fromEmailAddress, setFromEmailAddress] = useState<string>("");
  const [replyToEmailAddress, setReplyToEmailAddress] = useState<string[]>([]);
  const [file, setFile] = useState<any>(undefined);
  const [signatureName, setSignatureName] = useState<string>("");
  const [emailCredentialsProvided, setEmailCredentialsProvided] = useState<boolean>(false);
  const [openSendEmailFromOfficialEmail, setOpenSendEmailFromOfficialEmail] = useState<boolean>(false);
  const [openSelectEmailDomain, setOpenSelectEmailDomain] = useState<boolean>(false);
  const [userEmailID, setUserEmailID] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [smtpHost, setSmtpHost] = useState<string>(null);
  const [emailSMTPHostUrl, setEmailSMTPHostUrl] = useState<string>(null);
  const [openSendEmailFromOfficialEmailForOutlook, setOpenSendEmailFromOfficialEmailForOutlook] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForZimbra, setOpenSendEmailFromOfficialEmailForZimbra] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForGoDaddy, setOpenSendEmailFromOfficialEmailForGoDaddy] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForMicrosoftExchange, setOpenSendEmailFromOfficialEmailForMicrosoftExchange] =
    useState<boolean>(false);
  const [openSendEmailFromOfficialEmailForOther, setOpenSendEmailFromOfficialEmailForOther] = useState<boolean>(false);
  const [listAllDesignation, setListAllDesignation] = useState<any[]>([]);

  const handleChange = (_, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isSuccessAuthentication = params.get("isSuccess");
    if (isSuccessAuthentication === "true") {
      setValue(4);
      toast.success(<CustomToast message="Authentication Successful" />);
    } else if (isSuccessAuthentication === "false") {
      setValue(4);
      toast.error(<CustomToast message="Authentication Failed" />);
    }

    // Remove isSuccess from URL after handling
    params.delete("isSuccess");
    history.replace({ search: params.toString() });

    getListUserEmailSetting();
    getListAllDesignation();
    // eslint-disable-next-line
  }, []);

  const getListUserEmailSetting = async () => {
    await useFetch("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (
          response.data.userEmailSetting.display_name !== null &&
          response.data.userEmailSetting.display_name !== undefined &&
          response.data.userEmailSetting.display_name !== ""
        ) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (
          response.data.userEmailSetting.from_email !== null &&
          response.data.userEmailSetting.from_email !== undefined &&
          response.data.userEmailSetting.from_email !== ""
        ) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }
        if (
          response.data.userEmailSetting.reply_to !== null &&
          response.data.userEmailSetting.reply_to !== undefined &&
          response.data.userEmailSetting.reply_to !== ""
        ) {
          setReplyToEmailAddress(response.data.userEmailSetting.reply_to); // ----
        }
        if (
          response.data.userEmailSetting.signature.image !== null &&
          response.data.userEmailSetting.signature.image !== undefined &&
          response.data.userEmailSetting.signature.image !== ""
        ) {
          // setFile(response.data.userEmailSetting.signature.image);
          setFile(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (
          response.data.userEmailSetting.signature.text !== null &&
          response.data.userEmailSetting.signature.text !== undefined &&
          response.data.userEmailSetting.signature.text !== ""
        ) {
          setSignatureName(response.data.userEmailSetting.signature.text);
        }
        setEmailCredentialsProvided(response.data.userEmailSetting.emailCredentialsProvided);
        // setOpenUserEmailSetting(true);
      },
    });
  };

  const getListAllDesignation = async () => {
    await useFetch("/api/ListAllDesignation", "GET", {
      thenCallBack: (response) => {
        setListAllDesignation(response.data.designations);
      },
    });
  };

  const getAuthorisationCodeUrl = async () => {
    const accountName = actor?.name
      .toString()
      .toLowerCase()
      .replace(/[^a-z\d]+/gi, "");

    const urlEndPoint = getBaseURL();

    await useFetch("/api/authenticationRoute/GetAuthorisationCodeUrl", "GET", {
      config: {
        params: {
          returnEndpoint: `${urlEndPoint}${accountName}/reconSettings`,
        },
      },
      thenCallBack: (response) => {
        // Check if the response contains a redirect URL
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      },
    });
  };

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Recon Settings", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Account setting" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="Recon Output" {...a11yProps(1)} sx={{ textTransform: "none" }} />
              <Tab label="Recon Summary calculation" {...a11yProps(2)} sx={{ textTransform: "none" }} />
              {actor.userType === UserTypes.CustomerSuccess ? (
                <Tab label="Recon Rule Configurations" {...a11yProps(3)} sx={{ textTransform: "none" }} />
              ) : (
                <div />
              )}
              <Tab label="User email setting" {...a11yProps(4)} sx={{ textTransform: "none" }} />
              <Tab label="Designation setting" {...a11yProps(5)} sx={{ textTransform: "none" }} />
              {actor.userType === UserTypes.CustomerSuccess ? (
                <Tab label="Algo Customization" {...a11yProps(6)} sx={{ textTransform: "none" }} />
              ) : (
                <div />
              )}
              {actor.userType === UserTypes.CustomerSuccess && (
                <Tab label="Algo Customization Advance" {...a11yProps(7)} sx={{ textTransform: "none" }} />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AccountSetting />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReconOutput />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReconSummaryCalculation />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ReconRuleConfigurations />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UserEmailSetting
              emailDisplayName={emailDisplayName}
              setEmailDisplayName={setEmailDisplayName}
              fromEmailAddress={fromEmailAddress}
              setFromEmailAddress={setFromEmailAddress}
              replyToEmailAddress={replyToEmailAddress}
              setReplyToEmailAddress={setReplyToEmailAddress}
              file={file}
              setFile={setFile}
              getListUserEmailSetting={getListUserEmailSetting}
              signatureName={signatureName}
              setSignatureName={setSignatureName}
              setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmail}
              setOpenSelectEmailDomain={setOpenSelectEmailDomain}
              emailCredentialsProvided={emailCredentialsProvided}
              password={password}
              setUserEmailID={setUserEmailID}
              setPassword={setPassword}
              smtpHost={smtpHost}
              setSmtpHost={setSmtpHost}
              emailSMTPHostUrl={emailSMTPHostUrl}
              setEmailSMTPHostUrl={setEmailSMTPHostUrl}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <DesignationSetting
              from={"BpContactMaster"}
              listAllDesignation={listAllDesignation}
              setListAllDesignation={setListAllDesignation}
              // listAllBPContacts={listAllBPContacts}
              getListAllDesignation={getListAllDesignation}
            />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <AlgoCustomization />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <AlgoCustomizationUpload />
          </TabPanel>
        </Box>
      </div>
      <SelectEmailDomain
        openSelectEmailDomain={openSelectEmailDomain}
        setOpenSelectEmailDomain={setOpenSelectEmailDomain}
        setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmail}
        setOpenSendEmailFromOfficialEmailForOutlook={setOpenSendEmailFromOfficialEmailForOutlook}
        setOpenSendEmailFromOfficialEmailForZimbra={setOpenSendEmailFromOfficialEmailForZimbra}
        setOpenSendEmailFromOfficialEmailForGoDaddy={setOpenSendEmailFromOfficialEmailForGoDaddy}
        setOpenSendEmailFromOfficialEmailForMicrosoftExchange={setOpenSendEmailFromOfficialEmailForMicrosoftExchange}
        setOpenSendEmailFromOfficialEmailForOther={setOpenSendEmailFromOfficialEmailForOther}
        getAuthorisationCodeUrl={getAuthorisationCodeUrl}
      />
      <Dialog open={openSendEmailFromOfficialEmail} onClose={() => setOpenSendEmailFromOfficialEmail(true)}>
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmail}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.gmail}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForOutlook}
        onClose={() => setOpenSendEmailFromOfficialEmailForOutlook(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForOutlook}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.outlook}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForZimbra}
        onClose={() => setOpenSendEmailFromOfficialEmailForZimbra(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForZimbra}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.zimbra}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForGoDaddy}
        onClose={() => setOpenSendEmailFromOfficialEmailForGoDaddy(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForGoDaddy}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.goDaddy}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForMicrosoftExchange}
        onClose={() => setOpenSendEmailFromOfficialEmailForMicrosoftExchange(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForMicrosoftExchange}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.microsoftExchange}
        />
      </Dialog>
      <Dialog
        open={openSendEmailFromOfficialEmailForOther}
        onClose={() => setOpenSendEmailFromOfficialEmailForOther(true)}
      >
        <SendEmailFromOfficialEmail
          setOpenSendEmailFromOfficialEmail={setOpenSendEmailFromOfficialEmailForOther}
          userEmailID={userEmailID}
          setUserEmailID={setUserEmailID}
          password={password}
          setPassword={setPassword}
          setFromEmailAddress={setFromEmailAddress}
          replyToEmailAddress={replyToEmailAddress}
          setReplyToEmailAddress={setReplyToEmailAddress}
          setEmailCredentialsProvided={setEmailCredentialsProvided}
          setOpenSelectEmailDomain={setOpenSelectEmailDomain}
          setSmtpHost={setSmtpHost}
          smtpHostName={EmailSMTPHosts.other}
          setEmailSMTPHostUrl={setEmailSMTPHostUrl}
        />
      </Dialog>
      {/* <Dialog
        open={openDesignationSettingFromBpContactMaster}
        onClose={() => setOpenDesignationSettingFromBpContactMaster(true)}
        // width70Per={true}
      >
        <DesignationSetting
          setOpenDesignationSetting={setOpenDesignationSettingFromBpContactMaster}
          from={"BpContactMaster"}
          listAllDesignation={listAllDesignation}
          setListAllDesignation={setListAllDesignation}
          // listAllBPContacts={listAllBPContacts}
          getListAllDesignation={getListAllDesignation}
        />
      </Dialog> */}
    </LoggedInSkeleton>
  );
};

export default ReconSettings;

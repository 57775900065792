import useFetch from "src/Components/Common/useFetch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../Components/Recon360/PartnerCommunication/CommonComponents";

export const uiLogger = (eventName: string, companyId?: string, branchCode?: string, others?: Record<string, any>) => {
  useFetch("/api/uiLogger", "POST", {
    data: {
      functionName: eventName,
      companyId: companyId || DEFAULT_COMPANY_ID,
      branchCode: branchCode || DEFAULT_BRANCH_CODE,
      ...others,
    },
  });
};

export const uiLoggerPortal = (
  eventName: string,
  data: string,
  companyId?: string,
  branchCode?: string,
  others?: Record<string, any>
) => {
  useFetch("/api/UiPortalLogger", "POST", {
    data: {
      functionName: eventName,
      data,
      companyId: companyId || DEFAULT_COMPANY_ID,
      branchCode: branchCode || DEFAULT_BRANCH_CODE,
      ...others,
    },
  });
};

export default uiLogger;

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Button, IconButton, ListSubheader, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { TicketFollowUpType } from "src/entity/recon-entity/ReconInterfaces";
import { formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/PartnerPortal/CommonComponents";
import { Recon360Context } from "../Recon360";
import "./CurrentTicket.scss";
import DiscardTicketModal from "./DiscardTicketModal";
import SaveTicketModal from "./SaveTicketModal";
import TasksHistoryModal from "./TasksHistoryModal";

const TicketLifecycleState = {
  Ledger: "Ledger",
  Maker: "Maker",
  Checker: "Checker",
  Saved: "Saved",
  Discarded: "Discarded",
  Approver: "Approver",
  Miscllaneous: "Miscellaneous",
};
enum TicketStatuses {
  IssueInLedger = "Issue in Ledger",
  CheckerRejected = "Checker Rejected",
  ApproverRejected = "Approver Rejected",
  TechnicalIssue = "Technical Issue",
  Saved = "Saved",
  Discarded = "Discarded",
}
interface TicketHistoryInterface {
  id: number;
  user: string;
  field: string;
  previousValue: string;
  newValue: string;
  timestamp: Date;
}

const mappingForUiLogStatuses = {
  "Both Side Ledger Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_BOTH_SIDE_LEDGER_PENDING_CLICK,
  "Partner Ledger Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_PARTNER_LEDGER_PENDING_CLICK,
  "Own Ledger Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_OWN_LEDGER_PENDING_CLICK,
  "Issue in Ledger": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_ISSUE_IN_LEDGER_CLICK,
  "Yet to Start": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_YET_TO_START_CLICK,
  "Recon in Progress": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_RECON_IN_PROGRESS_CLICK,
  "Maker Passed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_MAKER_PASSED_CLICK,
  "Checker Passed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_CHECKER_PASSED_CLICK,
  "Checker Rejected": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_CHECKER_REJECTED_CLICK,
  "Sent to Approver": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_SENT_TO_APPROVER_CLICK,
  "Approver Passed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_APPROVER_PASSED_CLICK,
  "Approver Rejected": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_APPROVER_REJECTED_CLICK,
  "Technical Issue": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_TECHNICAL_ISSUE_CLICK,
  Dropped: uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_DROPPED_CLICK,
  "Saved Ticket": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_SAVE_TICKET_CLICK,
  "Discarded Ticket": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_DISCARD_TICKET_CLICK,
};
export const CurrentTicket = (props) => {
  const {
    companyId,
    branchCode,
    businessPartnerSelected,
    showSaveTicketModal,
    setShowSaveTicketModal,
    showDiscardTicketModal,
    setShowDiscardTicketModal,
  } = useContext(Recon360Context);

  const [currentStatus, setCurrentStatus] = useState<string>(props.ticket.status);
  const [remarks, setRemarks] = useState<string>(props.ticket.remarks);

  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const [loadingTicketHistory, setLoadingTicketHistory] = useState<boolean>(false);
  const [ticketHistoryList, setTicketHistoryList] = useState<TicketHistoryInterface[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorElDispute, setAnchorElDispute] = React.useState(null);
  const openDispute = Boolean(anchorElDispute);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickDispute(event) {
    setAnchorElDispute(event.currentTarget);
  }
  function handleCloseDispute() {
    setAnchorElDispute(null);
  }

  function handlefollowUpSelect(e) {
    handleClose();
    useFetch("/api/tickets/AddTicketFollowUp", "POST", {
      data: {
        ticketId: props.ticket.id,
        followUpType: e.target.value === 0 ? TicketFollowUpType.Mail : TicketFollowUpType.Conversation,
      },
      thenCallBack: (response) => {
        props.fetchTickets();
        props.setTicket(response.data.ticketDetails);
        e.target.value === 0
          ? toast.success(<CustomToast message="Successfully updated mail follow up" />)
          : toast.success(<CustomToast message="Successfully updated conversation follow up" />);
      },
      catchCallBack: () => {
        e.target.value === 0
          ? toast.error(<CustomToast message="Error in updating mail follow up" />)
          : toast.error(<CustomToast message="Error in updating conversation follow up" />);
      },
    });
  }

  const [saveRemarksDisabled, setSaveRemarksDisabled] = useState<boolean>(true);

  const [isSavingRemark, setIsSavingRemark] = useState(false);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const updateTicketRemarks = () => {
    setIsSavingRemark(true);
    useFetch("/api/tickets/UpdateTicketRemarks", "POST", {
      data: {
        ticketId: props.ticket.id,
        remarks: remarks,
      },
      thenCallBack: () => {
        setIsSavingRemark(false);
        toast.success(<CustomToast message="Remark Saved" />);
        props.fetchTickets();
        setSaveRemarksDisabled(true);
      },
      catchCallBack: () => {
        setIsSavingRemark(false);
        setRemarks(props.ticket.remarks ? props.ticket.remarks : "");
        toast.error(<CustomToast message="Unable to save remark" />);
      },
    });
  };
  const changeStatus = (status: string, ticketId: number) => {
    setIsStatusUpdating(true);
    uiLogger(mappingForUiLogStatuses[status].functionName, companyId.current, branchCode.current, {
      message: mappingForUiLogStatuses[status].message,
      businessPartnerId: businessPartnerSelected,
      ticketStatus: status,
      idOfTicket: ticketId,
    });
    useFetch("/api/tickets/UpdateTicketStatus", "POST", {
      data: {
        ticketId: ticketId,
        status: status,
      },
      thenCallBack: (response) => {
        setCurrentStatus(status);
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        setIsStatusUpdating(false);
        props.fetchTickets();
        props.setTicket(response.data.ticketDetails);
        toast.success(<CustomToast message="Successfully changed ticket status" />);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        toast.error(<CustomToast message={message} />);
        setShowSaveTicketModal(false);
        setIsStatusUpdating(false);
        setShowDiscardTicketModal(false);
      },
    });
  };
  const getTicketHistory = () => {
    setLoadingTicketHistory(true);
    useFetch<{ history: TicketHistoryInterface[] }>("/api/tickets/GetTicketHistory", "GET", {
      config: { params: { ticketId: props.ticket.id } },
      thenCallBack: (res) => {
        setTicketHistoryList(res.data.history || []);
        setLoadingTicketHistory(false);
        setShowTasksHistoryModal(true);
      },
      catchCallBack: () => {
        setLoadingTicketHistory(false);
      },
    });
  };

  const renderGroupedOptions = () => {
    return Object.values(TicketLifecycleState).map((state) => {
      if (state === TicketStatuses.Saved || state === TicketStatuses.Discarded) return null;
      return [
        <ListSubheader
          disableSticky
          key={state}
          sx={{
            borderTop: "1px solid rgba(0,0,0,.12)",
            fontWeight: 700,
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          {state}
        </ListSubheader>,
        ...props.ticketStatuses
          .filter((ticket) => ticket.category === state)
          .map((item) => (
            <MenuItem
              key={item.status}
              value={item.status}
              sx={{
                color:
                  (item.status === TicketStatuses.IssueInLedger && "#EF6C00") ||
                  (item.status === TicketStatuses.CheckerRejected && "#EF6C00") ||
                  (item.status === TicketStatuses.ApproverRejected && "#EF6C00") ||
                  (item.status === TicketStatuses.TechnicalIssue && "#EF6C00"),
              }}
            >
              {item.status}
            </MenuItem>
          )),
      ];
    });
  };
  return (
    <>
      {showSaveTicketModal && (
        <SaveTicketModal
          showSaveTicketModal={showSaveTicketModal}
          setShowSaveTicketModal={setShowSaveTicketModal}
          changeStatus={changeStatus}
          ticketId={props.ticket.id}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      {showDiscardTicketModal && (
        <DiscardTicketModal
          showDiscardTicketModal={showDiscardTicketModal}
          setShowDiscardTicketModal={setShowDiscardTicketModal}
          changeStatus={changeStatus}
          ticketId={props.ticket.id}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      {showTasksHistoryModal && (
        <TasksHistoryModal
          showTasksHistoryModal={showTasksHistoryModal}
          setShowTasksHistoryModal={setShowTasksHistoryModal}
          ticketHistoryList={ticketHistoryList}
        />
      )}
      <div className="current-ticket">
        <div className="space_between current-ticket-header">
          <div className="ticket_header_left" style={{ display: "flex", alignItems: "center", color: "#232323" }}>
            <span className="fs_24 fw_700" style={{ marginLeft: "10px" }}>
              Current Recon Task
            </span>
            <Button
              disabled={loadingTicketHistory}
              variant="outlined"
              className="view_history fs_13 fw_500"
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
                borderRadius: "4px !important",
                "&:hover, &.Mui-focusVisible": { borderColor: "rgba(0, 0, 0, 0.87)" },
              }}
              onClick={() => {
                getTicketHistory();
              }}
            >
              {loadingTicketHistory ? <LoadingIcon loading={true} /> : <HistoryIcon />}
              <span className="fw_600 fs_13">VIEW HISTORY</span>
            </Button>
          </div>
          <div style={{ display: "flex", margin: "10px" }}>
            <Box sx={{ display: "flex", alignItems: "center", fontWeight: 500, fontSize: "20px", marginX: "20px" }}>
              Add Follow Up
              <IconButton onClick={handleClick}>
                <AddCircleOutlinedIcon fontSize="large" sx={{ color: "#45173f" }} />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  value={0}
                  onClick={(e) => {
                    uiLogger(
                      uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_MAIL_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        message: uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_MAIL_CLICK.message,
                        businessPartnerId: businessPartnerSelected,
                        ticketStatus: currentStatus,
                        idOfTicket: props.ticket.id,
                      }
                    );
                    handlefollowUpSelect(e);
                  }}
                >
                  +1 time for Mail
                </MenuItem>
                <MenuItem
                  value={1}
                  onClick={(e) => {
                    uiLogger(
                      uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_CONVERSATION_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        businessPartnerId: businessPartnerSelected,
                        ticketStatus: currentStatus,
                        idOfTicket: props.ticket.id,
                        message: uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_CONVERSATION_CLICK.message,
                      }
                    );
                    handlefollowUpSelect(e);
                  }}
                >
                  +1 time for Conversation
                </MenuItem>
              </Menu>
            </Box>
            <div style={{ display: "flex", alignItems: "center", color: "#232323" }}>
              <span className="fs_20 fw_500" style={{ marginRight: "10px" }}>
                Recon Status:
              </span>
            </div>
            <Select
              style={{ width: "200px", backgroundColor: "whitesmoke", padding: 0 }}
              value={currentStatus}
              open={openDispute}
              onOpen={handleClickDispute}
              onClose={handleCloseDispute}
              onChange={(e) => {
                if (e.target.value === "Saved Ticket") {
                  setShowSaveTicketModal(true);
                } else if (e.target.value === "Discarded Ticket") {
                  setShowDiscardTicketModal(true);
                } else {
                  changeStatus(e.target.value, props.ticket.id);
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxHeight: 500,
                    },
                  },
                },
              }}
            >
              {renderGroupedOptions()}

              {/* <NestedMenuItem
                sx={{ "& p": { fontWeight: 700, fontSize: "16px" } }}
                label={"In Dispute Resolution"}
                parentMenuOpen={openDispute}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  transformOrigin: { vertical: "top", horizontal: "right" },
                }}
                rightIcon={<ArrowRightRounded />}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseDispute();
                  }}
                >
                  Status1
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseDispute();
                  }}
                >
                  Status1
                </MenuItem>
              </NestedMenuItem> */}
              <div
                style={{
                  position: "sticky",
                  bottom: "0",
                  backgroundColor: "#fff",
                  zIndex: 100,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "16px",
                  borderTop: "2px solid #e7e7e7",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  className="theme_btn fs_14 fw_500"
                  sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                  onClick={() => setShowSaveTicketModal(true)}
                >
                  SAVE/SIGN OFF TASK
                </Button>
                <Button
                  className="fs_14 fw_500"
                  variant="outlined"
                  color="error"
                  sx={{ borderRadius: "4px !important", padding: "6px 16px" }}
                  onClick={() => setShowDiscardTicketModal(true)}
                >
                  DISCARD TASK
                </Button>
              </div>
            </Select>
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", padding: "10px", background: "whitesmoke", borderRadius: "10px" }}
        >
          <table className="current-ticket-table">
            <tr>
              <th>Task ID</th>
              <th>Maker</th>
              <th>Checker</th>
              <th>Approver</th>
              <th style={{ backgroundColor: "white" }}>Last User</th>
              <th>Priority</th>
              <th>Due Date</th>
              <th>Creation Date</th>
              <th style={{ backgroundColor: "white" }}>Time of Last Status Change</th>
              <th>Mail Follow Ups</th>
              <th>Conversation Follow Ups</th>
            </tr>
            <tr>
              <td>{props.ticket.id ? props.ticket.id : "-"}</td>
              <td>{props.ticket.maker ? props.ticket.maker : "-"}</td>
              <td>{props.ticket.checker ? props.ticket.checker : "-"}</td>
              <td>{props.ticket.approver ? props.ticket.approver : "-"}</td>
              <td style={{ backgroundColor: "white" }}>{props.ticket.lastActiveUser}</td>
              <td>{props.ticket.priority}</td>
              <td>{props.ticket.dueDate ? formatDate(props.ticket.dueDate) : "-"}</td>
              <td>{props.ticket.createdAt ? formatDate(props.ticket.createdAt) : "-"}</td>
              <td style={{ backgroundColor: "white" }}>
                {props.ticket.lastStatusChangeTime ? formatDateTime24Hours(props.ticket.lastStatusChangeTime) : "-"}
              </td>
              <td>
                <Box fontSize={15} fontWeight={600}>
                  {(props.ticket.followUpCounts?.[TicketFollowUpType?.Mail] || "0") + " Times"}
                </Box>
                <Box>
                  {props.ticket.followUpCounts?.[TicketFollowUpType?.Mail] !== 0 &&
                    formatDate(props.ticket.followUpLastTime?.[TicketFollowUpType?.Mail])}
                </Box>
              </td>
              <td>
                <Box fontSize={15} fontWeight={600}>
                  {(props.ticket.followUpCounts?.[TicketFollowUpType?.Conversation] || "0") + " Times"}
                </Box>
                <Box>
                  {props.ticket.followUpCounts?.[TicketFollowUpType?.Conversation] !== 0 &&
                    formatDate(props.ticket.followUpLastTime?.[TicketFollowUpType?.Conversation])}
                </Box>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <Box sx={{ my: 2, width: "100%" }} className="vertical_center_align">
        <Typography m={1} variant="h6">
          Remarks
        </Typography>
        <TextField
          value={remarks}
          onChange={(e) => {
            setSaveRemarksDisabled(false);
            setRemarks(e.target.value);
          }}
          sx={{ backgroundColor: "#fff", width: "100%" }}
        />
        <NdButton
          disabled={saveRemarksDisabled || isSavingRemark}
          variant="contained"
          sx={{ minWidth: "170px", marginLeft: 2, minHeight: "45px" }}
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_IT_SAVE_REMARKS_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_IT_SAVE_REMARKS_CLICK.message,
                businessPartnerId: businessPartnerSelected,
                ticketStatus: currentStatus,
                idOfTicket: props.ticket.id,
              }
            );
            updateTicketRemarks();
          }}
        >
          <LoadingIcon loading={isSavingRemark} />
          Save Remark
        </NdButton>
      </Box>
    </>
  );
};

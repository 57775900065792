import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Nakad from "../../Graphics/NAKAD_FINAL (1).png";
import LoadingIcon from "../Common/LoadingIcon";
import useFetch from "../Common/useFetch";
import "../Login/Login.scss";
import { NdButton } from "../Recon360/PartnerCommunication/PartnerPortal/CommonComponents";
const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [showAccountSelect, setShowAccountSelect] = useState(false);
  const [currentSelectedAccountId, setCurrentSelectedAccountId] = useState(null);

  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const collectLoginCreds = async () => {
    setIsLoading(true);
    await useFetch("/api/FetchUserEmailAccounts", "POST", {
      data: { username: email },
      thenCallBack: (response) => {
        setIsLoading(false);
        setCompanyList(response.data.accounts);
        if (response.data.accounts.length === 1) {
          resetPasswordHandler(response.data.accounts[0].id);
        } else if (response.data.accounts.length === 0) {
          resetPasswordHandler(null);
        } else {
          setShowAccountSelect(true);
        }
      },
      catchCallBack: () => {
        setIsLoading(false);
      },
    });
  };
  const resetPasswordHandler = async (accountId: number) => {
    setIsLoading(true);
    await useFetch("/api/Reset", "POST", {
      data: {
        email,
        accountId,
      },
      thenCallBack: (response) => {
        setIsLoading(false);
        alert(response.data.message);
        setEmail("");
        history.push("/login");
      },
      catchCallBack: (error) => {
        alert(error.response.data.message);
        setEmail("");
        history.push("/reset");
        setIsLoading(false);
      },
    });
  };

  return (
    <div className="resetpass_wrapper">
      <div>
        <img src={Nakad} alt="" className="resetlogo" />
        <>
          <p style={{ marginTop: "125px" }} className="enter-info-text">
            {showAccountSelect
              ? "Please choose the company for which you want to reset the password"
              : "Please enter registered user email id"}
          </p>
          {showAccountSelect ? (
            <>
              <Autocomplete
                onChange={(_, value) => {
                  setCurrentSelectedAccountId(value.id);
                }}
                className="update-custom-border-radius"
                disableClearable
                options={companyList}
                getOptionLabel={(option) => option.name}
                sx={{ width: 350, background: "#fff", mt: "16px", mx: "auto" }}
                renderInput={(params) => <TextField {...params} label="Select Company" />}
              />
              <NdButton
                startIcon={<LoadingIcon loading={isLoading} />}
                disabled={isLoading || !currentSelectedAccountId}
                variant="contained"
                onClick={() => resetPasswordHandler(currentSelectedAccountId)}
                sx={{ padding: "8px 18px", marginTop: "20px" }}
              >
                Submit
              </NdButton>
            </>
          ) : (
            <>
              <div className="field">
                <div className="control">
                  <input
                    className="input input-box"
                    type="text"
                    name="username"
                    placeholder="Enter the registered user email id"
                    value={email}
                    onChange={update}
                    required={true}
                  />
                </div>
                <NdButton
                  startIcon={<LoadingIcon loading={isLoading} />}
                  disabled={isLoading}
                  variant="contained"
                  onClick={collectLoginCreds}
                  sx={{ padding: "8px 18px", marginTop: "20px" }}
                >
                  Confirm
                </NdButton>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default ResetPassword;

/* eslint-disable react-hooks/rules-of-hooks */
import { Delete } from "@mui/icons-material";
import { Alert, Autocomplete, Box, Button, Chip, Grid, TextField } from "@mui/material";
import { DialogActions, DialogContent, FormLabel } from "@mui/material";
import SendMailDialog from "@mui/material/Dialog";
import React, { useContext, useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { EmailTemplate, ListUserEmailSettingRes } from "../../../entity/recon-entity/ReconInterfaces";
import { dynamicContentOptions as dynamicOrig } from "../../../Utils/PartnerCommunication";
import useFetch from "../../Common/useFetch";
import { userContext } from "../../Contexts/userContext";
import Editor from "../PartnerCommunication/Editor";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface SendEmailTemplateProps {
  openSendMailDialog: boolean;
  setOpenSendMailDialog: StateDispatch<boolean>;
  recieverId: number;
  companyId: string;
  emailTemplate: EmailTemplate;
  preFetch?: () => void;
  postFetch?: (response: any) => void;
  mailTo?: string;
  files?: File[];
}

export const SendMail = (props: SendEmailTemplateProps) => {
  const { actor } = useContext(userContext);
  const [dynamicContent, setDynamicContent] = useState<string>("");
  const [files, setFiles] = useState<File[]>(props.files || []);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [errEmailSubject, setErrEmailSubject] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [emailDisplayName, setEmailDisplayName] = useState<string>("");
  const [fromEmailAddress, setFromEmailAddress] = useState<string>("");
  const [signImg, setSignImg] = useState<string>("");
  const [signTxt, setSignTxt] = useState<string>("");

  const dynamicContentOptions = [...dynamicOrig];

  useEffect(() => {
    getListUserEmailSetting();
    ListDynamicContent();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.emailTemplate) setEmailSubject(props.emailTemplate.emailSubject);
    if (props.files) setFiles(props.files);

    // eslint-disable-next-line
  }, [props.emailTemplate, props.files]);

  const handleClose = () => {
    props.setOpenSendMailDialog(false);
    setSelectedTemplateName("");
    setFiles([]);
  };

  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (res) => {
        const { display_name, from_email, signature } = res.data.userEmailSetting;

        if (display_name) setEmailDisplayName(display_name);
        if (from_email) setFromEmailAddress(from_email);
        if (signature) setSignTxt(signature.text);
        if (signature) setSignImg(signature.image);
      },
    });
  };

  const sendEmail = () => {
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", props.recieverId?.toString());
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("senderCompanyId", props.companyId);
    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (props.preFetch) props.preFetch();

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      useFetch("/api/SendReconResultThroughEmail", "POST", {
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: (response) => {
          setShowLoadingIcon(false);
          handleClose();
          if (props.postFetch) props.postFetch(response);
        },
        catchCallBack: () => {
          setShowLoadingIcon(false);
        },
      });
    }
  };

  const getShortName = (name: string) => {
    const splittedName = name.split(".");
    const extension = splittedName[splittedName.length - 1];
    splittedName.splice(splittedName.length - 1);
    let fileName = splittedName.join("");
    if (fileName.length > 12) {
      fileName = fileName.substring(0, 6) + "..." + fileName.substring(fileName.length - 6);
    }
    return fileName + "." + extension;
  };

  const ListDynamicContent = () => {
    useFetch<{ dynamicContents: string[] }>("/api/ListDynamicContent", "GET", {
      thenCallBack: (res) => {
        dynamicContentOptions.push(...res.data.dynamicContents.map((_content) => `{${_content}}`));
      },
    });
  };

  return (
    <>
      <SendMailDialog
        fullWidth={true}
        maxWidth="lg"
        open={props.openSendMailDialog}
        classes={{ paper: "maxWidth_1000" }}
        aria-labelledby="SendEmailTemplate"
      >
        <DialogContent sx={{ py: 1.25 }}>
          <div className="email_text">
            <Box>
              <p className="Email_Field">
                <FormLabel className="w_100">From : </FormLabel>
                {fromEmailAddress}
              </p>
              <p className="Email_Field">
                <FormLabel className="w_100">Display Name : </FormLabel>
                {emailDisplayName}
              </p>
              <p className="Email_Field">
                <FormLabel className="w_100">To : </FormLabel> {props.mailTo ? props.mailTo : ""}
              </p>
              <div className="mt_5" style={{ marginTop: 5 }}>
                <FormLabel>+ CC</FormLabel>
                <FormLabel className="ml_10">+ BCC</FormLabel>
              </div>
            </Box>
            <Box display={"flex"} gap={2} alignItems="center" my={2}>
              <FormLabel className="w_100">Subject : </FormLabel>
              <TextField
                size="small"
                id="mail_subject"
                variant="outlined"
                placeholder="Enter Subject"
                value={emailSubject}
                error={errEmailSubject}
                helperText={errEmailSubject ? "please enter subject" : ""}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrEmailSubject(false);
                  } else {
                    setErrEmailSubject(true);
                  }
                }}
                sx={{
                  width: "90%",
                }}
              />
            </Box>
            <div>
              <Editor
                dynamicContent={dynamicContent}
                setDynamicContent={setDynamicContent}
                selectedTemplateName={selectedTemplateName}
                selectedEmailTemplateContent={props.emailTemplate}
              />
            </div>
            <div className="mt_10 pl_10" style={{ whiteSpace: "pre-wrap" }}>
              {signTxt ? (
                <span>{signTxt}</span>
              ) : (
                <>
                  <p>
                    <FormLabel className="w_100">Thanks,</FormLabel>
                  </p>
                  <p>{actor?.name}</p>
                  {/* <p>{props.emailDisplayName}</p> */}
                </>
              )}
            </div>
            <div className="Signature_Box">
              {signImg && <img src={signImg} style={{ width: "180px", height: "80px" }} />}
            </div>
            <div className="Footer_Content">
              <div style={{ height: 10 }} className="mb_10 flex_end">
                {copyAlert && (
                  <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -30 }}>
                    Copied!
                  </Alert>
                )}
              </div>
              <Box display={"flex"} gap={1}>
                {files !== null &&
                  files.length > 0 &&
                  files.map((_file, fileInd) => (
                    <Chip
                      title={files[fileInd].name}
                      key={files[fileInd].name + fileInd}
                      label={getShortName(files[fileInd].name)}
                      onDelete={() => {
                        getShortName(files[fileInd].name);
                        files.splice(fileInd, 1);
                        setFiles([...files]);
                      }}
                      deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                      sx={{
                        maxWidth: 200,
                        "&, span": {
                          textOverflow: "unset !important",
                        },
                      }}
                    />
                  ))}
              </Box>
              <Grid item={true} xs={12} sm={12} className="d_flex">
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <div>
                      <Button
                        variant="contained"
                        component="label"
                        className="theme_btn"
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        Attachment
                        <input
                          hidden={true}
                          multiple={true}
                          type="file"
                          onChange={(e) => {
                            setFiles([...files, ...Array.from(e.target.files)]);
                          }}
                        />
                      </Button>
                    </div>
                  </Grid>
                </div>

                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <Autocomplete
                      size="small"
                      onChange={(_, value: any) => {
                        if (value !== null) {
                          navigator.clipboard.writeText(value);
                          // insertDynamiccontent(value);
                          setCopyAlert(true);
                          setTimeout(() => {
                            setCopyAlert(false);
                          }, 3000);
                        }
                      }}
                      options={dynamicContentOptions}
                      //   value={dynamicContent}
                      renderInput={(params) => (
                        <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                      )}
                    />
                  </Grid>
                </div>
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4} />
                </div>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="email_dialog_footer_btn">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn"
              onClick={() => {
                sendEmail();
              }}
              disabled={showLoadingIcon}
            >
              <LoadingIcon loading={showLoadingIcon} />
              Send
            </Button>
          </div>
        </DialogActions>
      </SendMailDialog>
    </>
  );
};

import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { SingleIssue } from "../BalanceConfirmationPortal/FinalPage";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import { IFilledDetails, NdButton, NdStepIcon, NdTextField } from "./CommonComponents";
import { FilledDetailsAndFilesRes } from "./DetailsPage2";
import { CommonUploaderBox, FilledDetailsGrid, LoadingPage, UploadFileType } from "./PartnerPortal.common";
import $ from "./PartnerPortal.module.scss";
import wrapPromise from "./wrapPromise";

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

const cache = new Map<string, Promise<any>>();

interface FinalPageResponse extends Omit<FilledDetailsAndFilesRes, "isPdfDownloaded" | "isDetailsChanged"> {
  isCompanyMsme: boolean;
}

interface RaiseRequestData {
  name: string;
  email: string;
  reason: string;
}
const FinalPage = () => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  function fetchData<T = any>(uri: string) {
    if (!cache.has(uri)) {
      const promise = new Promise<T>((resolve, reject) => {
        useFetch<T>(uri, "GET", {
          config: {
            params: {
              data: encryptedData,
            },
          },
          thenCallBack: (res) => {
            resolve(res.data);
          },
          errorCallback: reject,
          catchCallBack: reject,
        });
      });
      cache.set(uri, promise);
    }
    return wrapPromise<T>(cache.get(uri).then((res) => res));
  }

  const getFinalPageDetails = () => fetchData<FinalPageResponse>("/api/partnerPortal/GetDetailsForFinalPage");
  const promisedFinalPageDetails = getFinalPageDetails();

  const FinalPageDetails = () => {
    const data = promisedFinalPageDetails.read();

    const [loadedData] = useState<IFilledDetails>({
      companyName: data?.firmDetails?.name,
      panNumber: data?.firmDetails?.pan,
      msmeUdyamNumber: data?.firmDetails?.udyamRegistrationNumber,
      segment: data?.firmDetails?.segment,
      personName: data?.authorisedPersonDetails?.name,
      email: data?.authorisedPersonDetails?.email,
      designation: data?.authorisedPersonDetails?.designation,
    } as IFilledDetails);

    const { isCompanyMsme } = data;

    const [openDialog, setOpenDialog] = useState(false);

    const viewDocAndCertificate = (_type: UploadFileType) => {
      useFetch<{ signedUrl: string }>("/api/partnerPortal/ViewUploadedPdf", "POST", {
        showSuccessToast: true,
        data: {
          data: encryptedData,
          type: _type,
        },
        thenCallBack: (_res) => {
          const link = document.createElement("a");
          link.href = _res.data.signedUrl;
          if (_type === "signedPdf") {
            link.download = data.uploadedFileDetails?.signedPdf;
          } else {
            link.download = data.uploadedFileDetails?.udyamCertificate;
          }
          link.target = "_blank";
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      });
    };

    return (
      <>
        <Stack className="fade_in details_box" py={4} gap={3} maxWidth={900} mx={"auto"}>
          <Box className={$.BR_fix + " vertical_center_align"} px={4} py={3} gap={2} bgcolor={"#2E7D32"} color={"#fff"}>
            <NdStepIcon icon={1} active={false} completed={true} sx={{ color: "#fff !important" }} />
            <Typography variant="h5">
              Your response has been sent to {loadedData.companyName[0].toUpperCase() + loadedData.companyName.slice(1)}
            </Typography>
          </Box>
          <Box
            className={$.BR_fix + " vertical_center_align space_between"}
            px={2}
            py={"12px"}
            gap={2}
            border="1px solid #D7D7D7"
          >
            <Typography variant="subtitle1">Company/Firm/Enterprise registered as MSME?</Typography>
            <Typography variant="subtitle1" color={"#2E7D32"} fontWeight={700}>
              {isCompanyMsme ? `YES` : `NO`}
            </Typography>
          </Box>
          <Stack gap={1}>
            <Stack className={$.BR_fix} border="1px solid #D7D7D7">
              <Box py={"12px"} px={2}>
                <Typography variant="subtitle1" fontWeight={700}>
                  {isCompanyMsme
                    ? `Company/Firm/Enterprise details used in MSME Confirmation Document`
                    : `Company/Firm/Enterprise and Authorized person details`}
                </Typography>
              </Box>
              <Box py={"12px"} px={2}>
                <FilledDetailsGrid data={loadedData} isNotMsme={!isCompanyMsme} />
              </Box>
            </Stack>
            {isCompanyMsme && (
              <CommonUploaderBox
                fileName={data.uploadedFileDetails?.signedPdf}
                fileType={"signedPdf"}
                viewFn={viewDocAndCertificate}
                viewMode={true}
              />
            )}
            {isCompanyMsme && (
              <CommonUploaderBox
                fileName={data.uploadedFileDetails?.udyamCertificate}
                fileType={"udyamCertificate"}
                viewFn={viewDocAndCertificate}
                viewMode={true}
              />
            )}
          </Stack>
          <Box
            className={$.BR_fix + " vertical_center_align space_between"}
            px={2}
            py={"12px"}
            gap={2}
            border="1px solid #D7D7D7"
          >
            <Typography variant="subtitle1">
              If you think it's a mistake, please raise a request to resolve it
            </Typography>
            <Button className={$.BR_fix} onClick={() => setOpenDialog(true)}>
              Raise Request
            </Button>
          </Box>
        </Stack>
        <RaiseRequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} finalPage={true} />
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingPage noHeader={true} />}>
        <FinalPageDetails />
      </Suspense>
    </>
  );
};

interface RaiseRequestDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  finalPage?: boolean;
}

export const RaiseRequestDialog = ({ openDialog, setOpenDialog, finalPage }: RaiseRequestDialogProps) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [partnerName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isNewIssue, setIsNewIssue] = useState<boolean>(true);
  const [issues, setIssues] = useState<RaisedIssueBc[]>([]);

  useEffect(() => {
    if (openDialog) {
      getAllPreviouslyRaisedIssues();
    }
  }, [openDialog]);

  const getAllPreviouslyRaisedIssues = async () => {
    setLoading(true);
    useFetch<{ previousRequest: RaisedIssueBc[] }>("/api/partnerPortal/GetPreviouslyRaisedIssue", "GET", {
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (response) => {
        const { previousRequest } = response.data;
        if (previousRequest) {
          setIsNewIssue(previousRequest.length === 0);
          setIssues(previousRequest);
        }
        setLoading(false);
      },
    });
  };

  const markIssueAsResolved = async (issueId: number) => {
    useFetch("/api/partnerPortal/PartnerMarkIssueAsResolved", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        issueId,
      },
      thenCallBack: (_res) => {
        getAllPreviouslyRaisedIssues();
      },
    });
  };
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        {loading ? (
          <LoadingPage noHeader={true} />
        ) : isNewIssue ? (
          <DialogContentNewIssue openDialog={openDialog} setOpenDialog={setOpenDialog} finalPage={finalPage} />
        ) : (
          <DialogContentExistingIssue
            setOpen={setOpenDialog}
            setIsNewIssue={setIsNewIssue}
            issues={issues}
            bpName={partnerName}
            markIssueResolved={markIssueAsResolved}
          />
        )}
      </Dialog>
    </>
  );
};

const DialogContentNewIssue = ({
  openDialog,
  setOpenDialog,
}: {
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  finalPage?: boolean;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [notFilled, setNotFilled] = useState(true);
  const [filledData, setFilledData] = useState<RaiseRequestData>({ name: "", email: "", reason: "" });

  const [submittingRequest, setSubmittingRequest] = useState(false);

  const checkFormInvalid = () => {
    for (const key in filledData) {
      if (!filledData[key]) return true;
    }
    return false;
  };

  useEffect(() => {
    if (checkFormInvalid()) setNotFilled(true);
    else setNotFilled(false);
  }, [filledData]);

  useEffect(() => {
    if (!openDialog) setFilledData({ name: "", email: "", reason: "" });
  }, [openDialog]);

  const submitRequest = async () => {
    setSubmittingRequest(true);
    useFetch("/api/partnerPortal/RaiseRequest", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        name: filledData.name,
        email: filledData.email,
        reason: filledData.reason,
      },
      thenCallBack: (_res) => {
        setSubmittingRequest(false);
        setOpenDialog(false);
      },
      catchCallBack: () => {
        setSubmittingRequest(false);
      },
    });
  };

  return (
    <>
      <DialogTitle>Raise Request</DialogTitle>
      <DialogContent className={$.flex_column} sx={{ pb: 2, px: 3, gap: 2 }}>
        <Stack gap={2} pt={2}>
          <Box className="d_flex" gap={2} sx={{ "&>div": { width: "50%" } }}>
            <NdTextField
              label="Full Name"
              required
              value={filledData.name}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, name: _e.target.value }))}
            />
            <NdTextField
              label="Email Address"
              required
              value={filledData.email}
              onChange={(_e) => setFilledData((_d) => ({ ..._d, email: _e.target.value }))}
            />
          </Box>
          <NdTextField
            label="Reason"
            required
            fullWidth
            multiline
            maxRows={4}
            value={filledData.reason}
            onChange={(_e) => setFilledData((_d) => ({ ..._d, reason: _e.target.value }))}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button className={$.BR_fix} onClick={() => setOpenDialog(false)} color="inherit">
          Cancel
        </Button>
        <NdButton
          onClick={() => {
            submitRequest();
          }}
          variant="contained"
          disabled={notFilled || submittingRequest}
        >
          <LoadingIcon loading={submittingRequest} />
          Submit
        </NdButton>
      </DialogActions>
    </>
  );
};

interface DialogContentExistingIssueProps {
  setOpen: StateDispatch<boolean>;
  setIsNewIssue: StateDispatch<boolean>;
  issues: RaisedIssueBc[];
  bpName: string;
  markIssueResolved: (issueId: number) => Promise<void>;
}

const DialogContentExistingIssue = ({ setOpen, setIsNewIssue, issues, bpName }: DialogContentExistingIssueProps) => {
  return (
    <>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <Box className="left vertical_center_align">
          <DialogTitle>Previously Raised Query/ Issues</DialogTitle>
          <NdButton variant="contained" onClick={() => setIsNewIssue(true)}>
            Raise New Issue
          </NdButton>
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent className={$.flex_column} sx={{ p: 2, gap: 2 }}>
        <Stack gap={2} className="Issues_container">
          {issues.map((issue) => (
            <SingleIssue key={issue.issueId} bpName={bpName} {...issue} markIssueResolved={null} />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
};
export default FinalPage;

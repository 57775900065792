import { Button, Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import React, { useState } from "react";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import useFetch from "../Common/useFetch";
import Table from "../ReactTable/Table";
import { ListBpLedgerResponse, ListOwnLedgerResponse } from "./AdminInterfaces";

const LedgerData = () => {
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [, setSelectedRow] = useState<any>([]);
  const [actorId, setActorId] = useState<number>(null);
  const [supplierCode, setSupplierCode] = useState<number>(null);
  const [type, setType] = useState<string>(null);

  const columnDefinition = React.useMemo(
    () => [
      {
        Header: "OwnId",
        accessor: "ownId",
        Sort: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
      },
      {
        Header: "BusinessPartnerId",
        accessor: "businessPartnerId",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },

      {
        Header: "DocumentNumber",
        accessor: "documentNumber",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "DocumentType",
        accessor: "documentType",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "DocumentDate",
        accessor: "documentDate",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "PostingDate",
        accessor: "postingDate",
        Sort: true,
        minWidth: 180,
        maxWidth: 180,
        width: 180,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
      {
        Header: "Reference",
        accessor: "referenceNumber",
        Sort: true,
        minWidth: 200,
        maxWidth: 200,
        width: 200,
      },
    ],
    []
  );

  const getListOwnLedger = async () => {
    await useFetch<ListOwnLedgerResponse>("/api/ListOwnLedger", "POST", {
      data: { ownId: actorId, businessPartnerId: supplierCode },
      thenCallBack: (response) => {
        setType("Own");
        setRowsData(response.data.ledgers);
      },
    });
  };
  const getListBusinessPartnerLedger = async () => {
    await useFetch<ListBpLedgerResponse>("/api/ListBusinessPartnerLedger", "POST", {
      data: { ownId: actorId, businessPartnerId: supplierCode },
      thenCallBack: (response) => {
        setType("BusinessPartner");
        setRowsData(response.data.ledgers);
      },
    });
  };

  const deleteAllRow = async () => {
    if (type === "Own") {
      await useFetch("/api/DeleteOwnLedger", "DELETE", {
        showSuccessToast: true,
        config: {
          data: {
            ownId: actorId,
            businessPartnerId: supplierCode,
          },
        },
        thenCallBack: () => {
          getListOwnLedger();
          setType(null);
        },
      });
    } else if (type === "BusinessPartner") {
      await useFetch("/api/DeleteBusinessPartnerLedger", "DELETE", {
        showSuccessToast: true,
        config: {
          data: {
            ownId: actorId,
            businessPartnerId: supplierCode,
          },
        },
        thenCallBack: () => {
          getListBusinessPartnerLedger();
          setType(null);
        },
      });
    }
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Ledger Data")}>
      <Grid style={{ paddingBottom: "40px" }}>
        <Grid>
          <Card
            className="center_align"
            style={{
              padding: "20px 40px",
              alignItems: "center",
              flexFlow: "column",
            }}
          >
            <Grid className="center_align">
              <TextField
                id="outlined-number"
                label="Own Id"
                type="number"
                value={actorId}
                onChange={(e: any) => setActorId(e.target.value)}
              />
              <TextField
                id="outlined-number"
                label="BusinessPartner Id"
                type="number"
                style={{ margin: "0 40px" }}
                value={supplierCode}
                onChange={(e: any) => setSupplierCode(e.target.value)}
              />
            </Grid>
            <Grid className="center_align" style={{ marginTop: "30px" }}>
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  height: "40px",
                  marginRight: "40px",
                  minWidth: "200px",
                }}
                onClick={() => getListOwnLedger()}
              >
                OwnLedger
              </Button>
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  height: "40px",
                }}
                onClick={() => getListBusinessPartnerLedger()}
              >
                BusinessPartnerLedger
              </Button>
            </Grid>
          </Card>
        </Grid>
        {rowsData.length > 0 ? (
          <Grid>
            <Grid>
              <p className="box_head">{type === "Own" ? "Own Ledger Data" : "Business Partner Ledger Data"}</p>
            </Grid>
            <Grid className="account_table_column">
              <Table
                columns={columnDefinition}
                rows={rowsData}
                sortEnable={true}
                setSelectedRow={setSelectedRow}
                actorType="admin"
                showPagination={true}
              />
            </Grid>
            <Grid className="center_align">
              <Button
                variant="contained"
                className="theme_btn"
                style={{
                  margin: "30px",
                }}
                onClick={() => deleteAllRow()}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </LoggedInSkeleton>
  );
};

export default LedgerData;

import { AttachFile } from "@mui/icons-material";
import { Alert, Box, Button, Chip, FormControl, FormControlLabel, Stack, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "src/Components/Common/useFetch";
import {
  IFilledDetails,
  NdButton,
  NdCheckbox,
  NdRadio,
  NdTextField,
  NotMsmeDetails,
  validateEmail,
  validatePAN,
  validatePhoneNumber,
} from "./CommonComponents";
import { BackdropLoader, FilledDetailsGrid } from "./PartnerPortal.common";
import $ from "./PartnerPortal.module.scss";

const base64ToBlob = (base64str: string, fileType = "") => {
  const fileData = Buffer.from(base64str, "base64");
  let foundType = "";
  try {
    if (!fileType) foundType = base64str?.split(";")[0].split(":")[1];
  } finally {
    if (!foundType) foundType = "";
  }
  const blob = new Blob([fileData], { type: foundType || fileType });
  return blob;
};

enum fileType {
  noHeader = "noHeader",
  header = "header",
}

export const DownloadFilledPDFDialog = ({ openDialog, setOpenDialog, afterDownload }) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [pdfType, setPdfType] = useState<fileType>(null);
  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const getPdfToUpload = () => {
    setOpenBackDrop(true);
    useFetch<{ pdfBase64: string }>("/api/partnerPortal/GetPdfToUpload", "GET", {
      showSuccessToast: true,
      config: {
        params: {
          data: encryptedData,
          selectedType: pdfType,
        },
      },
      thenCallBack: (res) => {
        saveAs(base64ToBlob(res.data?.pdfBase64), `PartnerDetails${pdfType}.pdf`);
        handleClose();
        if (afterDownload) afterDownload();
      },
      catchCallBack: handleClose,
      errorCallback: handleClose,
    });
  };

  const handleClose = () => {
    setOpenBackDrop(false);
    setOpenDialog(false);
  };

  return (
    <>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={""} />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ pb: "4px" }}>Download MSME Confirmation Document</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ p: 0 }}>
          <Typography variant="body1" px={3}>
            Details provided by you in the previous step will be used in this document
          </Typography>
          <FormControl sx={{ py: 2, px: 3 }}>
            <RadioGroup
              name="pdf-type"
              value={pdfType}
              onChange={(_e, val) => setPdfType(val as fileType)}
              sx={{ gap: 2 }}
            >
              <FormControlLabel
                value={fileType.header}
                control={<NdRadio />}
                label={
                  <Box>
                    <Typography variant="body1">Company letterhead available</Typography>
                    <Typography variant="body2">I will print this document on that</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value={fileType.noHeader}
                control={<NdRadio />}
                label={
                  <Box>
                    <Typography variant="body1">Don't have company letterhead</Typography>
                    <Typography variant="body2">I will print this on blank A4 size paper</Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button className={$.BR_fix} onClick={() => setOpenDialog(false)} color="inherit">
            Cancel
          </Button>
          <NdButton disabled={!pdfType} onClick={() => getPdfToUpload()} autoFocus variant="contained">
            Download
          </NdButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface UploadDocDialogProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  data?: IFilledDetails;
  fileRef: React.MutableRefObject<File>;
  uploadFn: () => void;
  openBackDrop: boolean;
}

export const UploadConfirmDocumentDialog = ({
  openDialog,
  setOpenDialog,
  data,
  fileRef,
  uploadFn,
  openBackDrop,
}: UploadDocDialogProps) => {
  const [checkbox1Ticked, setCheckbox1Ticked] = useState<boolean>(false);
  const [checkbox2Ticked, setCheckbox2Ticked] = useState<boolean>(false);

  useEffect(() => {
    if (openDialog) {
      setCheckbox1Ticked(false);
      setCheckbox2Ticked(false);
    }
  }, [openDialog]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ pb: "4px" }}>Upload the file as MSME Confirmation Document?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Box className="vertical_center_align" pt={"4px"} gap={1}>
            <Typography variant="subtitle1">Uploading File: </Typography>
            <Chip
              sx={{ borderRadius: "8px" }}
              variant="outlined"
              label={
                <div className="vertical_center_align">
                  <AttachFile fontSize="small" />
                  <Typography px={"6px"}>{fileRef.current?.name}</Typography>
                </div>
              }
            />
          </Box>
          <Stack py={2} gap={1}>
            <Alert className={$.BR_fix} severity="info" sx={{}}>
              <Typography fontSize={14}>
                Please make sure that below details should match with the details in the uploading file
              </Typography>
            </Alert>
            <Box className={$.BR_fix} bgcolor={"#f3f3f3"} py={"12px"} px={2}>
              <FilledDetailsGrid data={data} />
            </Box>
          </Stack>
          <FormControlLabel
            control={<NdCheckbox checked={checkbox1Ticked} onChange={() => setCheckbox1Ticked(!checkbox1Ticked)} />}
            label="I confirm that above details matches with the details in the uploading file"
          />
          <FormControlLabel
            control={<NdCheckbox checked={checkbox2Ticked} onChange={() => setCheckbox2Ticked(!checkbox2Ticked)} />}
            label={
              <Typography>
                I confirm that the uploading file is <b style={{ fontFamily: "inherit" }}>duly signed and stamped</b> by
                authorized person
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              fileRef.current = null;
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdButton disabled={!checkbox1Ticked || !checkbox2Ticked} onClick={uploadFn} autoFocus variant="contained">
            Upload
          </NdButton>
        </DialogActions>
      </Dialog>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Uploading File..."} />
    </>
  );
};

export const UploadMsmeCertificateDialog = ({
  openDialog,
  setOpenDialog,
  fileRef,
  uploadFn,
  openBackDrop,
}: UploadDocDialogProps) => {
  const [checkboxTicked, setCheckboxTicked] = useState<boolean>(false);

  useEffect(() => {
    if (openDialog) {
      setCheckboxTicked(false);
    }
  }, [openDialog]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Please confirm the filled details are correct</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Box className="vertical_center_align" py={"4px"} gap={1}>
            <Typography variant="subtitle1">Uploading File: </Typography>
            <Chip
              sx={{ borderRadius: "8px" }}
              variant="outlined"
              label={
                <div className="vertical_center_align">
                  <AttachFile fontSize="small" />
                  <Typography px={"6px"}>{fileRef.current?.name}</Typography>
                </div>
              }
            />
          </Box>
          <FormControlLabel
            control={<NdCheckbox checked={checkboxTicked} onChange={() => setCheckboxTicked(!checkboxTicked)} />}
            label={
              <Typography>
                I confirm that the uploading file is <b style={{ fontFamily: "inherit" }}>MSME / Udyam Certificate</b>
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              fileRef.current = null;
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdButton disabled={!checkboxTicked} onClick={uploadFn} autoFocus variant="contained">
            Upload
          </NdButton>
        </DialogActions>
      </Dialog>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Uploading File..."} />
    </>
  );
};

export const NotMSMEDialog = ({
  openDialog,
  setOpenDialog,
  setOpenFinalPage,
}: {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenFinalPage: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<NotMsmeDetails>({
    companyName: "",
    designation: "",
    email: "",
    panNumber: "",
    personName: "",
  });
  const [optionalData, setOptionalData] = useState<Pick<NotMsmeDetails, "phoneNumber">>({
    phoneNumber: "",
  });

  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [notFilled, setNotFilled] = useState<boolean>(true);
  const [checkboxTicked, setCheckboxTicked] = useState(false);

  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const checkFormInvalid = () => {
    for (const key in data) {
      if (!data[key]) return true;
    }
    return false;
  };

  const submitData = () => {
    setOpenBackDrop(true);
    useFetch("/api/partnerPortal/UpdateFirmAndAuthorisedPersonDetails", "POST", {
      showSuccessToast: true,
      data: {
        data: encryptedData,
        firmName: data.companyName,
        pan: data.panNumber,
        udyamRegistrationNumber: null,
        enterpriseSegment: null,
        authorisedPersonName: data.personName,
        designation: data.designation,
        email: data.email,
        phoneNumber: optionalData.phoneNumber,
        isMSME: false,
      },
      thenCallBack: (_res) => {
        setOpenDialog(false);
        setOpenBackDrop(false);
        setOpenFinalPage(true);
        setTimeout(() => {
          setOpenFinalPage(true);
        }, 500);
      },
      catchCallBack: (_err) => {
        setOpenBackDrop(false);
      },
      errorCallback: (_err) => {
        setOpenBackDrop(false);
      },
    });
  };

  useEffect(() => {
    if (checkFormInvalid()) setNotFilled(true);
    else setNotFilled(false);
  }, [data]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Fill out the details to confirm that your Company/Firm/Enterprise is not registered as MSME
        </DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3, gap: 2 }}>
          <Stack gap={3}>
            <Box className="d_flex" flexDirection={"column"} gap={1}>
              <Typography variant="body1">Company/Firm/Enterprise details</Typography>
              <FormControl fullWidth sx={{ gap: 2, flexFlow: "row", "&>div": { width: "50%" } }}>
                <NdTextField
                  label="Name of the firm"
                  required
                  value={data.companyName}
                  onChange={(_e) => setData((_d) => ({ ..._d, companyName: _e.target.value }))}
                />
                <NdTextField
                  label="PAN Number"
                  required
                  value={data.panNumber}
                  onChange={(_e) => setData((_d) => ({ ..._d, panNumber: _e.target.value }))}
                  error={data.panNumber && !validatePAN(data.panNumber)}
                />
              </FormControl>
            </Box>
            <Box className="d_flex" flexDirection={"column"} gap={1}>
              <Typography variant="body1">Authorized person details</Typography>
              <FormControl fullWidth sx={{ gap: 2, flexFlow: "row", "&>div": { width: "50%" } }}>
                <NdTextField
                  label="Full Name"
                  required
                  value={data.personName}
                  onChange={(_e) => setData((_d) => ({ ..._d, personName: _e.target.value }))}
                />
                <NdTextField
                  label="Designation"
                  required
                  value={data.designation}
                  onChange={(_e) => setData((_d) => ({ ..._d, designation: _e.target.value }))}
                />
              </FormControl>
              <FormControl fullWidth sx={{ gap: 2, flexFlow: "row", "&>div": { width: "50%" } }}>
                <NdTextField
                  label="Email"
                  type="email"
                  required
                  value={data.email}
                  onChange={(_e) => setData((_d) => ({ ..._d, email: _e.target.value }))}
                  error={data.email && !validateEmail(data.email)}
                />
                <NdTextField
                  label="Phone Number"
                  type="number"
                  value={optionalData.phoneNumber}
                  onChange={(_e) => setOptionalData((_d) => ({ ..._d, phoneNumber: _e.target.value }))}
                  error={optionalData.phoneNumber && !validatePhoneNumber(optionalData.phoneNumber)}
                />
              </FormControl>
            </Box>
          </Stack>
          <FormControlLabel
            control={<NdCheckbox checked={checkboxTicked} onChange={() => setCheckboxTicked(!checkboxTicked)} />}
            label="I confirm that above given information is correct"
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdButton disabled={notFilled || !checkboxTicked} onClick={submitData} autoFocus variant="contained">
            Submit
          </NdButton>
        </DialogActions>
      </Dialog>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Saving Details..."} />
    </>
  );
};

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useRef, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import delete_icon_grey from "../../../Graphics/AutomatedRecon/delete_icon_grey.svg";
import delete_icon_red from "../../../Graphics/AutomatedRecon/delete_icon_red.svg";
import download_icon from "../../../Graphics/AutomatedRecon/download_icon2.svg";
import upload_icon from "../../../Graphics/AutomatedRecon/upload_icon2.svg";
import { Recon360Context } from "../Recon360";
import BulkTasksUploadIssueModal from "./BulkTasksUploadIssueModal";

interface AddBulkTasksModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTickets: any;
  setShowBulkTasksModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddBulkTasksModal({
  open,
  setOpen,
  fetchTickets,
  setShowBulkTasksModal,
}: AddBulkTasksModalProps) {
  const { companyId, branchCode } = useContext(Recon360Context);
  const fileUploader = useRef<HTMLInputElement>(null);
  const selectedFile = useRef<File>(null);
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [uploadingBulkTasks, setUploadingBulkTasks] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [showBulkTasksUploadIssueModal, setShowBulkTasksUploadIssueModal] = useState<boolean>(false);
  const [errorWorkbook, setErrorWorkbook] = useState(null);
  const [tasksReport, setTasksReport] = useState({ totalTasks: 0, failedTasks: 0, createdTasks: 0 });

  const downloadAddBulkTaskTemplate = () => {
    setDownloadingTemplate(true);
    useFetch("/api/tickets/DownloadBulkCreateTasksTemplate ", "GET", {
      showSuccessToast: true,
      thenCallBack: (response) => {
        setDownloadingTemplate(false);
        const excelData = Buffer.from(response.data?.file, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, "add-bulk-task-template.xlsx");
      },
      catchCallBack: () => {
        setDownloadingTemplate(false);
      },
    });
  };

  const uploadBulkTasks = () => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", selectedFile.current);
    setUploadingBulkTasks(true);
    useFetch<{ updatedFilesList: string[] }>("/api/tickets/CreateTicketInBulk", "POST", {
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (res) => {
        setUploadingBulkTasks(false);
        selectedFile.current = null;
        setFileName(null);
        fetchTickets();
        if (res.data?.file) {
          setErrorWorkbook(res.data?.file);
          setTasksReport({
            totalTasks: res.data?.totalTasks,
            failedTasks: res.data?.remaningTasks,
            createdTasks: res.data?.taskCreated,
          });
          setShowBulkTasksUploadIssueModal(true);
        } else {
          setOpen(false);
          setShowBulkTasksModal(false);
        }
      },
      catchCallBack: () => {
        setUploadingBulkTasks(false);
      },
    });
  };
  return (
    <>
      <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
          <Typography variant="h6">Add Bulk Tasks</Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
              selectedFile.current = null;
              setFileName(null);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <List sx={{ padding: "16px" }}>
          <ListItem disablePadding>
            <ListItemButton
              disabled={downloadingTemplate || uploadingBulkTasks}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_DOWNLOADTEMPLATE_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_DOWNLOADTEMPLATE_CLICK.message }
                );
                downloadAddBulkTaskTemplate();
              }}
            >
              <ListItemIcon>
                <img src={download_icon} alt="download icon" />
              </ListItemIcon>
              <ListItemText primary="Download Template" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <input
              ref={fileUploader}
              name="ConfirmDocUploader"
              type="file"
              multiple={false}
              onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
              onChange={(e) => {
                selectedFile.current = e.target.files[0];
                setFileName(selectedFile.current.name);
              }}
              hidden
              accept=".xlsx,.xls"
            />
            <ListItemButton
              disabled={downloadingTemplate || uploadingBulkTasks}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_CLICK.message }
                );
                fileUploader.current && fileUploader.current.click();
              }}
            >
              <ListItemIcon>
                <img src={upload_icon} alt="upload icon" />
              </ListItemIcon>
              <ListItemText primary="Upload Template" secondary={fileName ? `File Uploaded:${fileName}` : ""} />
            </ListItemButton>
            <ListItemButton
              sx={{ flexGrow: 0, width: "60px" }}
              disabled={uploadingBulkTasks || downloadingTemplate || (selectedFile.current ? false : true)}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_DELETE_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_DELETE_CLICK.message }
                );
                selectedFile.current = null;
                setFileName(null);
              }}
            >
              <ListItemIcon>
                <img src={selectedFile.current ? delete_icon_red : delete_icon_grey} alt="delete-icon" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
          <Button
            disabled={uploadingBulkTasks || (selectedFile.current ? false : true)}
            variant="contained"
            sx={{
              background: "#27B27C",
              padding: "8px 22px",
              color: "#FFF",
              borderRadius: "4px !important",
              fontWeight: 500,
              fontSize: "15px",
              "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
            }}
            onClick={() => {
              uiLogger(
                uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_CONFIRM_CLICK.functionName,
                companyId.current,
                branchCode.current,
                { message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_ADDTASKS_UPLOADTEMPLATE_CONFIRM_CLICK.message }
              );
              uploadBulkTasks();
            }}
          >
            <LoadingIcon loading={uploadingBulkTasks} />
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
      <BulkTasksUploadIssueModal
        open={showBulkTasksUploadIssueModal}
        setOpen={setShowBulkTasksUploadIssueModal}
        errorWorkbook={errorWorkbook}
        tasksReport={tasksReport}
        fetchTickets={fetchTickets}
      />
    </>
  );
}

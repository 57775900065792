/* eslint-disable no-restricted-globals */
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import React, { ErrorInfo, useEffect, useRef } from "react";
import { PathType } from "./entity/models/FrontendActor";
// import { redirect } from "./Components/Common/SideBar";
import useFetch from "./Components/Common/useFetch";
import astronautSrc from "./Graphics/astronaut.png";

type MyProps = {
  [k: string]: any;
  children: React.ReactNode[] | React.ReactNode;
};

type MyState = {
  hasError: boolean;
  error: Error;
  errorInfo: ErrorInfo;
};

export enum PathToFile {
  "admin" = "Userlist.tsx",
  "recon" = "Recon360.tsx",
  "bank" = "BankDisbursement.tsx",
}

export default class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  protected static getDerivedStateFromError(_error: string) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log the error and store in state

    this.setState((_state) => ({
      error,
      errorInfo,
    }));

    console.log(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      // render any custom fallback UI
      return <FallBackUI errorInfo={this.state?.errorInfo} />;
    }

    return this.props.children;
  }
}

const logout = () => {
  localStorage.clear();
  useFetch("/logout", "GET", {
    thenCallBack: (_response) => {
      setTimeout(() => {
        location.href = "/";
      }, 500);
    },
  });
};

const CheckErrorOnHome = () => {
  const locationPath = location.pathname;
  const reconMatchPath = PathType.recon.replace("/:accountName", "");

  const matches = [];
  [PathType.admin, PathType.bank, reconMatchPath].forEach((path) => {
    const match = locationPath.match(path);
    if (match) matches.push(...match);
  });

  const filtered = matches.filter((match) => match);

  if (!filtered) return;

  if (filtered?.length > 0) {
    logout();
  } else location.href = "/";
};

const FallBackUI = ({ errorInfo }: { errorInfo: ErrorInfo }) => {
  const background = useRef<HTMLDivElement>(null);
  const astronaut = useRef<HTMLImageElement>(null);

  let lastX = "0px";
  let lastY = "0px";

  let newX = "60px";
  let newY = "60px";

  function stars() {
    const newStar = document.createElement("span");
    newStar.className = "star";
    newStar.style.top = `${Math.random() * 100}%`;
    newStar.style.left = `${Math.random() * 100}%`;
    newStar.style.setProperty("--side", `${parseInt((Math.random() * 2 + 1).toString(), 10)}px`);
    newStar.style.setProperty("--delay", `${Math.random() * 25}s`);
    background.current.appendChild(newStar);
    newStar.onanimationend = (e) => {
      const target = e.currentTarget as HTMLElement;
      target.parentNode.removeChild(target);
      stars();
    };
  }

  useEffect(() => {
    for (let index = 0; index < 40; index++) {
      stars();
    }

    astronaut.current.style.setProperty("--x-move", newX);
    astronaut.current.style.setProperty("--y-move", newY);

    astronaut.current.style.setProperty("--last-x", lastX);
    astronaut.current.style.setProperty("--last-y", lastY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (astronaut.current)
    astronaut.current.onanimationiteration = () => {
      lastX = newX;
      lastY = newY;
      astronaut.current.style.setProperty("--last-x", lastX);
      astronaut.current.style.setProperty("--last-y", lastY);

      newX = parseInt((Math.random() * 200 + 120).toString(), 10).toString() + "px";
      newY = parseInt((Math.random() * 200 + 120).toString(), 10).toString() + "px";

      astronaut.current.style.setProperty("--x-move", newX);
      astronaut.current.style.setProperty("--y-move", newY);
    };

  return (
    <Box className="ErrorContainer">
      <div className="card">
        <div className="left">
          <span className="cutout" />
          <div className="ErrorHeading">Aw, Snap!</div>
          <div className="main-text">
            <h1>Oops!</h1>
            <h4>Something Went Wrong, While Displaying this Page.</h4>
          </div>

          <Box className="d_flex">
            <Accordion disableGutters={true} sx={{ boxShadow: "0 1px 8px rgba(0,0,0,0.15)" }}>
              <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-content" id="panel-header">
                <Typography>View Stack Trace</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ maxHeight: 200, overflow: "auto" }}>
                <Typography fontSize={10} color={"#99a"} className="stackTraceRoot" sx={{ "& div": { fontSize: 10 } }}>
                  {errorInfo?.componentStack.split("\n").map((i, key) => {
                    return <div key={key}>{i}</div>;
                  })}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>

          <a className="anchor" href="#home" onClick={() => CheckErrorOnHome()}>
            <button type="button" className="btn">
              Take Me Back
            </button>
          </a>
        </div>
        <div className="right">
          <div ref={background} className="background">
            <div className="planet" />
            <img ref={astronaut} src={astronautSrc} className="astronaut" alt="Astronaut" />
          </div>
        </div>
      </div>
    </Box>
  );
};

import { Cached, Download, FilterAlt, FilterAltOff, Visibility } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  createFilterOptions,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import IndeterminateCheckbox from "src/Components/Admin/IndeterminateCheckbox";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import BpDashboardTable from "src/Components/ReactTable/BpDashboardTable";
import {
  BusinessPartnersListForLrBetaHistory,
  BusinessPartnersListForLrBetaHistoryRes,
  MailThread,
  MsmeMailThread,
  UploadFileType,
} from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import { LedgerRequestBetaColorMap, LedgerRequestBetaStatuses } from "src/Utils/PartnerCommunication";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../CommonComponents";
import MailThreadDialog from "../MailThreadDialog";
import { getBlankData } from "./LedgerRequestBeta";
import LedgerRequestBetaFilter from "./LedgerRequestBetaFilter";

const filter = createFilterOptions<any>();

type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};

const LedgerRequestHistory = ({
  companyId,
  branchCode,
  setOpen,
}: {
  companyId: string;
  branchCode: string;
  setOpen: (val: boolean) => void;
}) => {
  const { actor } = useContext(userContext);

  const [loader, setLoader] = useState<boolean>(true);
  const [, setSelectedRow] = useState<BusinessPartnersListForLrBetaHistory[]>([]);
  const [rowsDataLrBeta, setRowsDataLrBeta] = useState<BusinessPartnersListForLrBetaHistory[]>(getBlankData());
  const [storeRowsDataLrBeta, setStoreRowsDataLrBeta] = useState<BusinessPartnersListForLrBetaHistory[]>([]);

  const storeAllCategories = useRef<string[]>([]);
  const storeLrBetaData = useRef<BusinessPartnersListForLrBetaHistory[]>([]);
  const [isFilterAppliedLrBeta, setIsFilterAppliedLrBeta] = useState<boolean>(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [, setAppliedFilters] = useState<{ [key: string]: string }>({});

  const [openLrBetaFilter, setOpenLrBetaFilter] = useState<boolean>(false);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [mailLoading, setMailLoading] = useState(false);

  const columnDefinitionLrBeta = useMemo(
    (): ColDef<BusinessPartnersListForLrBetaHistory>[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div>
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        minSize: 35,
        maxSize: 35,
        size: 35,
      },
      {
        header: () => <div>Business Partner</div>,
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a>{row.original.businessPartnerName}</a>
          </div>
        ),
        maxSize: 300,
        minSize: 280,
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row.vendorCode,
        enableSorting: true,
        size: 130,
      },

      {
        header: "Email Initiation Date",
        accessorFn: (row) => row.mailInitiatingDate,
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original.mailInitiatingDate?.toString()}
              variant="outlined"
              disabled={true}
              type="text"
              size="small"
              value={moment(row.original.mailInitiatingDate)?.format("YYYY-MM-DD")}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="vertical_center_align" style={{ gap: 8 }}>
            Status
          </div>
        ),
        id: "Status",
        accessorFn: (row) => row.ledgerRequestStatus,
        maxSize: 280,
        minSize: 220,
        size: 240,
        cell: ({ row }) => (
          <>
            {row.original.ledgerRequestStatus ? (
              <div className="vertical_center_align gap_10">
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "240px",
                    overflowWrap: "break-word",
                    color: LedgerRequestBetaColorMap[row.original.ledgerRequestStatus]?.color || "#000",
                    backgroundColor: LedgerRequestBetaColorMap[row.original.ledgerRequestStatus]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.ledgerRequestStatus === LedgerRequestBetaStatuses.ReminderSent
                    ? row.original.ledgerRequestStatusWithReminderCount || row.original.ledgerRequestStatus
                    : row.original.ledgerRequestStatus}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Category",
        accessorFn: (row) => row.category?.[0]?.toLowerCase(),
        maxSize: 200,
        minSize: 200,
        size: 200,
        cell: ({ row }) => (
          <div className="category_ui">
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_options, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: "Ledger",
        accessorFn: (row) => row.downloadLedgerKey,
        size: 260,
        cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.downloadLedgerKey ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() =>
                  viewUploadedPartnerLedger(
                    row.original.businessPartnerId,
                    "signedPdf",
                    row.original?.newMailInitiationCount
                  )
                }
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "Ledger Received Date",
        accessorFn: (row) => row.ledgerReceivedDate,
        enableSorting: true,
        size: 200,
        cell: ({ row }) => (
          <p>
            {" "}
            {row.original.ledgerReceivedDate !== null
              ? moment(row.original.ledgerReceivedDate).format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: "Actions",
        accessorKey: "businessPartnerId",
        enableSorting: false,
        size: 160,
        sticky: "right",
        cell: ({ row }) => (
          <Stack direction="row" gap={1} alignItems="center" position="relative" top={-1}>
            {/* View Email Button */}
            <Tooltip title={"View Email"} arrow={true}>
              <IconButton
                onClick={async () => {
                  setMailLoading(true);
                  setOpenMailThread(true);
                  setMailThreads([]);
                  setBpName(row.original.businessPartnerName);
                  const { emails } = await viewEmail(
                    row.original.businessPartnerId,
                    row.original?.newMailInitiationCount
                  );
                  setMailLoading(false);
                  setMailThreads(emails || []);
                }}
              >
                <Visibility sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
            {/* View Recon */}
            <Tooltip title={"View Recon"} arrow={true}>
              <IconButton
                onClick={() => {
                  const { businessPartnerId, businessPartnerName } = row.original;
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank").focus();
                }}
              >
                <Cached sx={{ fill: "#541c4c" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history]
  );

  useEffect(() => {
    ListAllBusinessPartnerForLedgerRequest();
  }, []);

  // Main ROWs API
  const ListAllBusinessPartnerForLedgerRequest = async () => {
    setLoader(true);
    useFetch<BusinessPartnersListForLrBetaHistoryRes>(
      "/api/ledgerRequestPortal/ListHistoryOfAllBusinessPartnersForLedgerRequest",
      "GET",
      {
        config: {
          params: {
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          // Extract the businessPartnerId values and store them in a new array
          if (!response.data?.businessPartnersList)
            response.data = { businessPartnersList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
          storeLrBetaData.current = response.data?.businessPartnersList || [];
          storeAllCategories.current = response.data?.allCategories || [];
          setRowsDataLrBeta(response.data.businessPartnersList || []);
          setStoreRowsDataLrBeta(response.data.businessPartnersList || []);
          setLoader(false);
          // if (Object.keys(appliedFilters).length) setReFilter(true);
        },
      }
    );
  };

  // Get Ledger Request Beta Rows Report
  const getDownloadLrBetaReport = () => {
    useFetch<{ workbookBase64: string }>("/api/ledgerRequestPortal/DownloadExcelReportForLedgerRequestHistory", "GET", {
      config: {
        params: {
          companyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        downloadLrBetaReport(response.data?.workbookBase64);
      },
    });
  };

  // Download Ledger Request Beta Rows Report
  const downloadLrBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Ledger Request Report.xlsx`);
  };

  // View LR Beta Email Thread
  const viewEmail = (businessPartner: number, newMailInitiationCount: number) => {
    return new Promise<{ emails: MailThread[] }>((resolve, reject) => {
      useFetch<{ emails: MsmeMailThread[] }>("/api/ledgerRequestPortal/ViewEmailThreadForLedgerRequestHistory", "GET", {
        config: {
          params: {
            businessPartnerId: businessPartner,
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
            newMailInitiationCount: newMailInitiationCount,
          },
        },
        thenCallBack: (response) => {
          if (response.data.emails) {
            const { emails } = response.data;
            const mailThreads: MailThread[] = emails.map((email) => ({
              emailBody: email.body,
              fromEmailId: email.from,
              timestamp: email.emailTime.toString(),
              attachments: [],
            }));

            resolve({ emails: mailThreads });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  // View Rows Uploaded Partner Ledger
  const viewUploadedPartnerLedger = (_bpId: number, _fileType: UploadFileType, _newMailInitiationCount: number) => {
    useFetch<{ signedUrls: string[] }>("/api/ledgerRequestPortal/DownloadUploadedFilesForLedgerRequestHistory", "GET", {
      config: {
        params: {
          businessPartnerId: _bpId,
          companyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
          type: _fileType,
          newMailInitiationCount: _newMailInitiationCount,
        },
      },
      thenCallBack: (_res) => {
        if (_res.data.signedUrls?.length > 0) {
          _res.data.signedUrls.forEach((url) => {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      },
    });
  };

  const GREEN = "#27B27C";

  return (
    <>
      {/* History Side Button */}
      <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
        {
          <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
            <Tooltip title={"Filter"} arrow={true}>
              <Avatar sx={{ background: GREEN }}>
                <span style={{ cursor: "pointer" }}>
                  <IconButton
                    onClick={() => setOpenLrBetaFilter(true)}
                    color="inherit"
                    sx={{
                      pointerEvents: "auto !important",
                    }}
                  >
                    {isFilterAppliedLrBeta ? <FilterAlt /> : <FilterAltOff />}
                  </IconButton>
                </span>
              </Avatar>
            </Tooltip>
            <Tooltip title="Download Balance Confirmation Report" arrow={true}>
              <Avatar sx={{ background: GREEN }}>
                <IconButton onClick={() => getDownloadLrBetaReport()} color="inherit">
                  <Download />
                </IconButton>
              </Avatar>
            </Tooltip>
          </div>
        }
      </div>

      {/* Title and Back Button */}
      <Box className="space_between" my={2}>
        <Typography variant="h6">Past Communications with Partners</Typography>
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            borderRadius: "32px",
            border: "1px solid #111",
            background: "#FFF",
          }}
          onClick={() => setOpen(false)}
        >
          Back
        </Button>
      </Box>
      <BpDashboardTable
        columns={columnDefinitionLrBeta}
        rows={rowsDataLrBeta}
        sortEnable={true}
        setSelectedRow={setSelectedRow}
        actorType="BPDashboard"
        loading={loader}
        truncateHeaders={true}
      />

      <LedgerRequestBetaFilter
        openLedgerRequestFilter={openLrBetaFilter}
        setOpenLedgerRequestFilter={setOpenLrBetaFilter}
        allCategories={storeAllCategories.current}
        rowsDataLedgerRequest={rowsDataLrBeta}
        setRowsDataLedgerRequest={setRowsDataLrBeta}
        storeRowsDataLedgerRequest={storeRowsDataLrBeta}
        setIsFilterAppliedLedgerRequest={setIsFilterAppliedLrBeta}
        reFilter={reFilter}
        setReFilter={setReFilter}
        setAppliedFilters={setAppliedFilters}
        historyConsumer={true}
      />

      <MailThreadDialog
        open={openMailThread}
        setOpen={setOpenMailThread}
        mailThreads={mailThreads}
        s3Attachments={[]}
        businessPartnerName={bpName}
        loading={mailLoading}
        msme={true}
        companyId={companyId}
        branchCode={branchCode}
        useCaseType={null}
        emailSubject={null}
        listAllBusinessPartnersWSR={null}
      />
    </>
  );
};

export default LedgerRequestHistory;
